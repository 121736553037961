import Swal from "sweetalert2";
const producao = !window.isLocalhost;

export const SetNumber = n => {

    n = String(n)?.replace(/[^0-9]+/g, '')?.replace(/[^\d]+/g, '');

    if (!n) return null
    if (Array.isArray(n) && n.length === 0) return null
    if (typeof n === 'string' && !n.trim()) return null

    return n;
}

export const SetCoin = v => {

    if (!v) return null;

    if (v.indexOf(',') > -1) {
        v = v?.replace(/[.]/, '').replace(/[,]/, '.');
    }

    if (!v) return null
    if (Array.isArray(v) && v.length === 0) return null
    if (typeof v === 'string' && !v.trim()) return null

    return Number(v);
}

export const formatCPF = cpf => {

    if (!cpf) return null;
    return cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    //return cpf?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export const formatCNPJ = cnpj => {

    if (!cnpj) return null;


    return cnpj?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1 $2 $3/$4-$5");
}

export const formatDate = date => {

    if (!date) return null;

    if ((typeof (date) === 'string' && date.indexOf('-') > -1) && date.indexOf('T') === -1) {
        return date.split('-').reverse().join('/');
    }
    return new Date(date).toLocaleDateString('pt-BR');
    //return date?.split('-').reverse().join('/');
}

export const formatCoin = v => {
    if (!v) v = 0;

    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
    });

    return formatter.format(v);
    //return v?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    //return `R$ ${Number(v)?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}`;
}

export const formatCoinNotCurrency = v => {
    if (!v) v = 0;

    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'decimal',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    });

    return formatter.format(v);
    //return v?.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'});
    //return `R$ ${Number(v)?.toLocaleString('pt-br', { minimumFractionDigits: 2 })}`;
}

export const formatCoinNotRS = v => {
    if (!v) v = 0;

    return Number(v)?.toLocaleString('pt-br', { minimumFractionDigits: 2 });
}

export const existsOrError = (value, msg) => {
    if (!value) throw msg
    if (Array.isArray(value) && value.length === 0) throw msg
    if (typeof value === 'string' && !value.trim()) throw msg
    if (typeof value === 'object' && Object.values(value).length === 0) {
        throw msg
    }
}

export const equalsOrError = (valueA, valueB, msg) => {
    if (valueA !== valueB) throw msg
}

export const SetDate = date => {
    if (!date) return null;

    let date_temp = date.split('T');

    return date_temp.shift();
}

export const EncodeBase64 = str => {
    if (typeof (str) === 'object') {
        str = JSON.stringify(str);
    }
    return window.btoa(unescape(encodeURIComponent(str)));
}

export const DecodeBase64 = str => {
    return decodeURIComponent(escape(window.atob(str)));
}

export const IsNumeric = n => {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export const TextSlug = text => {
    if (!text) return null;

    return text.toLowerCase()
        .replace(/[^\w ]+/g, '')
        .replace(/ +/g, '-');
}

export const ValidateInput = data => {

    if (!data) return null;
    if (data === "") return null;

    return data;
}

export const ShowError = error => {

    if (!error) return false;

    if (error?.response) {
        var msg = error?.response?.data;

        if (typeof (msg) === 'object' && !!!producao) {
            msg = '<pre style="text-align: left;">' + JSON.stringify(msg, null, '\t') + '</pre>';
        } else if (typeof (msg) === 'string') {
        } else if (msg?.msg && typeof (msg.msg) === 'string') {
            msg = msg?.msg;
        } else {
            msg = '<span>Algo deu errado, Tente novamente mais tarde ou entre em contato com o Nosso Suporte!</span>';
        }

        return Swal.fire({
            icon: "warning",
            title: "Atenção",
            html: `
                <div className="text-center">
                    ${msg}
                </div>
            `,
            customClass: {
                confirmButton: 'btn btn-primary px-5'
            },
            buttonsStyling: false
        });

    } else {

        if (error.msg) {

            return Swal.fire({
                icon: "error",
                title: "Atenção",
                html: `
                    <div className="text-center">
                        ${error.msg}
                    </div>
                `,
                customClass: {
                    confirmButton: 'btn btn-primary px-5'
                },
                buttonsStyling: false
            });
        }

        return Swal.fire({
            icon: "error",
            title: "Atenção",
            html: `
                <div className="text-center">
                    ${typeof (error) === 'string' ? error : error}
                </div>
            `,
            customClass: {
                confirmButton: 'btn btn-primary px-5'
            },
            buttonsStyling: false
        });
    }

    // eslint-disable-next-line
    return true;
}

export const ShowSwalHTML = html => {
    return Swal.fire({
        html: `
            <div class="text-center">
                ${html}
            </div>
        `,
        customClass: {
            confirmButton: 'btn btn-primary px-5'
        },
        buttonsStyling: false
    });
}

export const FormSwal = json => {
    return Swal.fire({
        html: `
            <div class="text-left">
            <pre>
                ${JSON.stringify(json, null, '\t')}
            </pre>
            </div>
        `,
        customClass: {
            confirmButton: 'btn btn-primary px-5'
        },
        buttonsStyling: false
    });
}

export const MaiorIdade = data => {
    if (!data) return false;

    let ns = new Date(data);
    ns.setDate(ns.getDate() + 1);
    let hj = new Date();
    hj.setFullYear(hj.getFullYear() - 18);

    if (ns <= hj) return true;

    return false;
}

export const formatMatricula = x => {
    if (!x) return false;
    return x.replace(/(.{4})/g, "$1 ").trim();
}

export const PrintHTML = (html, head = false) => {
    if (!html) return false;

    var conteudo = '';
    if (head) {
        let style = `
            <style type="text/css">
                table { page-break-inside:auto }
                table tr{ page-break-inside:avoid; page-break-after:auto }
                .table-responsive {overflow: inherit !important;}
                .card {border: 0 !important;}
            </style>
        `;
        style += document.querySelector('head').innerHTML;
        conteudo = `<head>${style}</head>`;
    }

    //var conteudo = document.getElementById('sua_div').innerHTML,
    conteudo += html;

    //tela_impressao = window.open('about:blank');
    var tela_impressao = window.open('/');
    tela_impressao.document.write(conteudo);
    tela_impressao.window.print();
    //tela_impressao.window.close();
}


export const VerificaSenhaForte = e => {

    let value = e.target.value;

    let numeros = /([0-9])/;
    let alfabeto = /([a-zA-Z])/;
    let chEspeciais = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;

    // Minimo de digitos
    let spanLength = document.createElement('small');
    spanLength.setAttribute('class', 'text-danger');
    spanLength.setAttribute('id', 'passLength');
    spanLength.innerHTML = 'Senha Fraca! Minimo 8 dígitos';

    let spanPass = document.createElement('small');
    spanPass.setAttribute('class', 'text-danger');
    spanPass.setAttribute('id', 'spanPass');
    spanPass.innerHTML = 'Senha Fraca! Mínimo 8 dígitos com letras, números e caracter especial!';

    if (value.length < 8) {
        if (!document.querySelector('#passLength')) {
            e.target.after(spanLength);
        }
        if (document.querySelector('#spanPass'))
            document.querySelector('#spanPass').remove();
    }
    // Tem mais de 8 digitos
    else {
        if (document.querySelector('#passLength'))
            document.querySelector('#passLength').remove();

        if (!value.match(numeros) || !value.match(alfabeto) || !value.match(chEspeciais)) {

            if (!document.querySelector('#spanPass')) {
                e.target.after(spanPass);
            }
        }
        // Fecho a senha
        else {
            if (document.querySelector('#spanPass'))
                document.querySelector('#spanPass').remove();
        }
    }
}

export const validaSenhaForte = senha => {

    let value = senha;

    // eslint-disable-next-line
    if (!senha) throw 'Senha inválida!';

    let numeros = /([0-9])/;
    let alfabeto = /([a-zA-Z])/;
    let chEspeciais = /([~,!,@,#,$,%,^,&,*,-,_,+,=,?,>,<])/;

    if (value.length < 8 || !value.match(numeros) || !value.match(alfabeto) || !value.match(chEspeciais)) {
        // eslint-disable-next-line
        throw 'Senha Fraca! Mínimo 8 digitos com letras, números e caracter especial!';
    }
}


export const swalConfirm = async dados => {
    if (!dados) return false;

    const dataSwal = {
        confirmButtonText: 'CONFIRMAR <i class="las la-check-circle d-none"></i>',
        cancelButtonText: 'CANCELAR <i class="las la-times-circle d-none"></i>',
        showCancelButton: true,
        customClass: {
            confirmButton: 'btn btn-primary px-3 mr-2',
            cancelButton: 'btn btn-danger px-3'
        },
        buttonsStyling: false
    };

    if (dados.title) dataSwal.title = dados.title;
    if (dados.text) dataSwal.text = dados.text;
    if (dados.html) dataSwal.html = dados.html;
    if (dados.icon) dataSwal.icon = dados.icon;
    if (dados.btnText) dataSwal.confirmButtonText = dados.btnText;
    if (dados.btnText2) dataSwal.cancelButtonText = dados.btnText2;

    const alert = await Swal.fire({ ...dataSwal });

    return { ...alert }
}


export const MesStr = date_mes => {

    // Mês
    switch (Number(date_mes)) {
        case 1:
            date_mes = "Janeiro"
            break;
        case 2:
            date_mes = "Fevereiro"
            break;
        case 3:
            date_mes = "Março"
            break;
        case 4:
            date_mes = "Abril"
            break;
        case 5:
            date_mes = "Maio"
            break;
        case 6:
            date_mes = "Junho"
            break;
        case 7:
            date_mes = "Julho"
            break;
        case 8:
            date_mes = "Agosto"
            break;
        case 9:
            date_mes = "Setembro"
            break;
        case 10:
            date_mes = "Outubro"
            break;
        case 11:
            date_mes = "Novembro"
            break;
        case 12:
            date_mes = "Dezembro"
            break;
        default:
            break;
    }

    return date_mes;
}



// eslint-disable-next-line
export default {
    SetNumber,
    SetCoin,
    formatDate,
    formatCPF,
    formatCNPJ,
    formatCoin,
    SetDate,
    formatCoinNotRS,
    EncodeBase64,
    DecodeBase64,
    IsNumeric,
    TextSlug,
    ValidateInput,
    formatCoinNotCurrency,
    ShowError,
    ShowSwalHTML,
    formatMatricula,
    PrintHTML,
    VerificaSenhaForte,
    validaSenhaForte,
    swalConfirm,
    FormSwal,
    MesStr
}