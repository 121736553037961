import React from "react";
import { Link } from "react-router-dom";
import './Style.css';
import { TableHover, Tbody, Td, Thead } from "../../../../components/Table-z";

export const CieloCardShowRecorrencia = props => {

    /*
    rec_status_str: "Ativa"
    rec_criada_format: "09/08/2022 às 00:00:00"
    rec_next_format: "08/09/2022"
    rec_start_format: "08/09/2022"
    res_valor_format: "R$ 19,90"
    rec_day: 9
    rec_intervalo: "Mensal"
    rec_pagamentos: []
    rec_criada: "2022-08-09T00:00:00"
    rec_next: "2022-09-09"
    res_valor: 19.9
    rec_start: "2022-09-09"
    rec_tentativa: 0
    rec_status: 1
    recs_ok: 0
    rec_cliente: "Ezequias - 83489697561"
    */

    const p = props;

    return (
        <div className="card-filiado-filiado">
            <div className="card">
                <div className="card-body">
                    <div className="text-muted filiado-header">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="icon d-flex align-items-center">
                                <i className="las la-chart-line text-danger mr-2"></i>
                                <b>COBRANÇA RECORRÊNTE</b>
                            </div>
                        </div>
                        <hr className="mt-1 mb-2" />
                    </div>
                    <div className="filiado-body text-muted">
                        <div className="situacao py-3 d-flex align-items-center justify-content-between">
                            <div className="icon">
                                <i className="las la-file-invoice-dollar mr-2"></i>
                                Situação
                            </div>
                            <span className="value">{p?.rec_status_str}</span>
                        </div>
                        <div className="cadastro py-3 d-flex align-items-center justify-content-between">
                            <div className="icon">
                                <i className="las la-calendar-check mr-2"></i>
                                Cadastrado
                            </div>
                            <span className="value">{p?.rec_criada_format}</span>
                        </div>
                        <div className="cadastro py-3 d-flex align-items-center justify-content-between">
                            <div className="icon">
                                <i className="las la-calendar-day mr-2"></i>
                                Próxima Cobrança
                            </div>
                            <span className="value">{p?.rec_next_format}</span>
                        </div>
                        <div className="cadastro py-3 d-flex align-items-center justify-content-between">
                            <div className="icon">
                                <i className="las la-coins mr-2"></i>
                                Valor Cobrança
                            </div>
                            <span className="value">{p?.res_valor_format}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card">
                <div className="card-body">
                    <div className="text-muted filiado-header">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="icon d-flex align-items-center">
                                <i className="las la-money-check-alt text-success mr-2"></i>
                                <b>LANÇAMENTOS DA RECORRÊNCIA</b>
                            </div>
                        </div>
                        <hr className="mt-1 mb-2" />
                    </div>
                    {!p?.pagamentos?.length && <div className="text-center p-4 text-muted"><i>Sem lançamento vinculado</i></div>}
                    {!!p?.pagamentos?.length &&
                        <TableHover className="mb-lg-0 text-center">
                            <Thead
                                th={[
                                    "Situação",
                                    "Valor",
                                    "Data operação",
                                    "Ação",
                                ]}
                            />
                            <Tbody>
                                {
                                    p?.pagamentos?.map((p, index) => {
                                        return (
                                            <tr key={index}>
                                                <Td>
                                                    <span
                                                        data-title={`${p?.pag_status}`}
                                                        className={`rounded-circle badge badge-${p?.pag_status === 'Negado' ? "danger" : "success"
                                                            } mx-auto p-2 d-inline-block`}
                                                    ></span>
                                                </Td>
                                                <Td>
                                                    {p?.pag_valor}
                                                </Td>
                                                <Td>
                                                    {p?.pag_data}
                                                </Td>
                                                <Td>
                                                    <Link to={`${p?.pag_url || '/'}`} title="Ver Pagamento" className="btn p-0 m-0" target="_blank">
                                                        <i className="las la-external-link-square-alt text-success"></i>
                                                    </Link>
                                                </Td>
                                            </tr>
                                        )
                                    })
                                }
                            </Tbody>
                        </TableHover>
                    }
                </div>
            </div>
        </div>
    )
}

export default CieloCardShowRecorrencia;