import React, { useEffect, useState } from "react";
import $ from 'jquery';
import './input.css';
import api from "../../../configs/api";
import { toast } from "react-toastify";
import { existsOrError, ShowError } from "../../../configs/Functions";
import CONNECT_API from "../../../configs/connect";
import { CardContent } from "../../Card-z";
import { useIsLoading } from '../../../contexts/LoadingContext';
import { Link } from "react-router-dom";
import { Modal } from "../../Modal-z";

const FileArray = props => {

    const {
        fileList,
        setFileList
    } = props;

    const nameInput = props?.name || 'files';

    const [fileUploadProgress, setFileUploadProgress] = useState(0);

    const { setIsLoading } = useIsLoading();

    function click_btn_file(e) {
        let btn = e.target;
        let container = $(btn).closest('.dropzone-container');
        let file = container.find('[type="file"]');
        file.click();
    }

    function upload_file(e) {

        try {

            //
            // Function base request api
            //
            // FILE → Arquivo para upload.

            let file_upload = e.target.files[0];
            if (!file_upload) new Error('Anexo não informado na requisição!');


            api.defaults.headers['Content-Type'] = `multipart/form-data`;
            const data_file = new FormData();
            data_file.append("file", file_upload);

            let file_upload_ = {
                nome: file_upload.name,
                preview: URL.createObjectURL(file_upload),
                file: file_upload,
                progress: 0
            };

            setIsLoading(true);
            api.post("/files", data_file, {
                onUploadProgress: e => {
                    const progress = parseInt(Math.round((e.loaded * 100) / e.total));
                    setFileUploadProgress(progress);
                }
            })
                .then(response => {

                    const file = response.data.file;
                    setFileList(fileList.concat({ ...file_upload_, ...file, uploaded: true }));
                    e.target.value = '';
                    setFileUploadProgress(0);

                    setIsLoading(false);
                })
                .catch(() => {
                    toast.error('Falha ao carregar o arquivo');
                    setIsLoading(false);
                    setFileUploadProgress(0);
                });

            toast.success('Upload realizado com sucesso!');

        } catch (error) {

            if (!error?.response) {
                ShowError(typeof (error) === 'string' ? error : 'Tente novamente mais tarde!')
            } else {
                const msg = error.response.data;
                ShowError(msg)
            }
        }
    }

    // Define no input o valor do arr de arquivos
    async function set_file_id_input() {

        // Definindo no input o array de files.
        const files_uploads = [];
        /*if (typeof (fileList) != 'object') {
            files_uploads.push(fileList);
            return false;
        };*/

        fileList?.map(file => files_uploads.push(file.id));

        props.reset({
            [nameInput]: files_uploads
        })
        return true;
    }

    async function delete_file(id) {
        try {

            existsOrError(id, 'Arquivo não localizado!');
            setIsLoading(true);
            await CONNECT_API.del(`/files/${id}`);
            $('.modal').modal('hide');
            setIsLoading(false);

            setFileList(
                fileList?.filter(file => file.id !== id)
            );


        } catch (error) {
            toast.error(error);
            setIsLoading(false);
        }
    }

    async function update_file(id) {
        try {

            existsOrError(id, 'Arquivo não localizado!');

            const name = $(`[name="input-name-${id}"]`).val();

            const fields = {
                nome: name,
            }

            setIsLoading(true);
            await CONNECT_API.put(`/files/${id}`, { ...fields });
            setIsLoading(false);

            setFileList(fileList?.filter((file, i) => {
                if (file.id === id) {
                    file.nome = name;
                }
                return true;
            }));

        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }

    useEffect(() => {
        set_file_id_input();
        // eslint-disable-next-line
    }, [fileList]);


    return (
        <div className="dropzone" style={{ "boxShadow": "0 .5rem 1rem rgba(0,0,0,.15)" }}>
            <div className="card" style={{ "boxShadow": "none", "marginBottom": "0" }}>
                <div className="card-body" >
                    <div className="dropzone-container">
                        {<div className="dropzone-progress">
                            <div className="load-progress" style={{ "width": `${fileUploadProgress}%` }}></div>
                        </div>}
                        <div className="row align-items-center justify-content-center position-relative">

                            <input type="hidden" name={nameInput} className="input-dropzone" {...props.register(nameInput)} />


                            <input type="file" onChange={upload_file} className="dropzone_input_file" />
                            <div className="col text-center drive-over">
                                <div
                                    className="rounded bg-light py-5 text-primary"
                                    onClick={click_btn_file} style={{ "cursor": "pointer" }}>
                                    <i className="la la-upload "></i>
                                    <b>Enviar Arquivo</b>
                                </div>
                            </div>
                            <img src="" alt="" className="img-dropzone-file" />
                        </div>
                        <div className="dropzone-list">
                            <div className="form-row mt-3 align-items-center--- flex-column--">


                                {
                                    fileList?.map(i => {
                                        const iframe =
                                            <object
                                                data={i?.url}
                                                type={i?.tipo}
                                                width="100%"
                                                min-height="500"
                                                style={{ "height": "auto", "minHeight": "500px" }}>
                                                <embed src={i?.url} type={i?.tipo} width="100%" height="500" />
                                            </object>

                                        const modal =
                                            <Modal modalId={`modal-dropzone-file-${i.id}`}>
                                                <CardContent>
                                                    <div className="col-12">
                                                        <div className="cpm" style={{ "boxShadow": "0 .5rem 1rem rgba(0,0,0,.15)", "textAlign": "center" }}>{iframe}</div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="edit py-4">
                                                            <div className="group mb-4">
                                                                <ul className="p-0 mb-2 text-muted">
                                                                    <li className={i.colaborador ? 'd-block' : 'd-none'}><b>Enviado por: </b>{i.colaborador}</li>
                                                                    <li className={i.empresa ? 'd-block' : 'd-none'}><b>Empresa: </b>{i.empresa}</li>
                                                                    <li className={i.tipo ? 'd-block' : 'd-none'}><b>Tipo do arquivo: </b>{i.tipo}</li>
                                                                    <li className={i.criado ? 'd-block' : 'd-none'}><b>Arquivo criado em: </b>{i.criado}</li>
                                                                    <li className={i.alterado ? 'd-block' : 'd-none'}><b>Arquivo alterado em: </b>{i.alterado}</li>
                                                                </ul>
                                                            </div>
                                                            <div className={`custom-group group-input`}>
                                                                <div className={`form-group`}>
                                                                    <input
                                                                        name={`input-name-${i.id}`}
                                                                        type={'text'}
                                                                        className={`form-control form-control-lg`}
                                                                        id={`input-name-${i.id}`}
                                                                        preenchido="true"
                                                                        defaultValue={i.nome}
                                                                    />
                                                                    <label className="custom-label" htmlFor={``}>Nome do arquivo</label>
                                                                </div>
                                                            </div>
                                                            <div className="buttons text-center">
                                                                <div
                                                                    onClick={() => delete_file(i.id)}
                                                                    className="btn btn-danger mr-2">
                                                                    <i className="las la-trash-alt"></i> Deletar
                                                                </div>
                                                                <div
                                                                    onClick={() => update_file(i.id)}
                                                                    className="btn btn-success">
                                                                    <i className="las la-save"></i> Salvar
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CardContent>
                                            </Modal>

                                        return (
                                            <div className="col-auto mb-3" key={i.id}>
                                                <div className="card mb-2">
                                                    <div className="card-body">
                                                        <div className="form-row justify-content-between align-items-center">
                                                            <div className="col-auto">
                                                                <i className="las la-file-signature mr-2"></i>
                                                                <small><b>{i.nome}</b></small>
                                                            </div>
                                                            <div className="col-auto">
                                                                <span>
                                                                    <div className="btn btn-sm text-danger" onClick={() => delete_file(i.id)}>
                                                                        <i className="las la-trash" style={{ "fontSize": "17px" }}></i>
                                                                    </div>
                                                                </span>
                                                                <span>
                                                                    <div className="btn btn-sm text-info" data-toggle="modal" data-target={`#modal-dropzone-file-${i.id}`}>
                                                                        <i className="las la-edit" style={{ "fontSize": "17px" }}></i>
                                                                    </div>
                                                                </span>
                                                                <span>
                                                                    <Link to={{pathname: i.url || '/'}} className="btn btn-sm text-success" target={'_blank'}>
                                                                        <i className="las la-external-link-alt" style={{ "fontSize": "17px" }}></i>
                                                                    </Link>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {modal}
                                            </div>
                                        )
                                    })
                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default FileArray;