import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ButtonSave from "../../../../../components/ButtonComponent/ButtonSave";
import { CardTitle } from "../../../../../components/Card-z";
import { GroupSelectFilter } from "../../../../../components/Form";
import { Modal } from "../../../../../components/Modal-z";
import CONNECT_API from "../../../../../configs/connect";
import { existsOrError, ShowError, swalConfirm } from "../../../../../configs/Functions";
import { useIsLoading } from "../../../../../contexts/LoadingContext";

export const ModalToDependente = props => {

    const [filiados, setFiliados] = useState([]);

    const { setIsLoading } = useIsLoading();
    const { handleSubmit, register, reset } = useForm();

    async function modalShow() {
        try {
            const id = props.match.params.id;

            setIsLoading(true);
            const { data } = await CONNECT_API.get(
                `filiado/${id}/configuracao/mudanca-titularidade`
            );
            setFiliados(data?.filiados);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    async function modalHide() {
        reset();
    }

    async function onSubmit(formData) {
        try {
            const id = props.match.params.id;
            const titular = formData["select-titular"][0];

            existsOrError(titular, "Novo titular não definido!");

            const ok = await swalConfirm({
                icon: "warning",
                text: "Deseja alterar a titularidade?",
                btnText: "Sim",
                btnText2: "Não"
            });

            if (ok?.isConfirmed) {
                setIsLoading(true);
                await CONNECT_API.put(
                    `filiado/${id}/configuracao/mudanca-titularidade`,
                    { titular }
                );
                setIsLoading(false);
                if (props.loaded)
                    await props.loaded();
            }
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }


    return <Modal
        modalId={props.modalId}
        text={props.text}
        icon={props.icon}
        className={props.className}
        modalShow={modalShow}
        modalHide={modalHide}
        sm={true}
    >
        <div className="card">
            <div className="card-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <CardTitle>
                            Selecione o Titular
                        </CardTitle>
                        <GroupSelectFilter
                            name="select-titular"
                            label=""
                            textFilter="Buscar por nome ou CPF"
                            col="12"
                            register={register}
                        >
                            {filiados?.map((f) => (
                                <option key={f?.id} value={f?.id}>
                                    {f?.nome}
                                </option>
                            ))}
                        </GroupSelectFilter>
                    </div>
                    <ButtonSave
                        type="submit"
                        className="btn btn-primary float-right"
                    />
                </form>
            </div>
        </div>
    </Modal>
}


export default ModalToDependente;