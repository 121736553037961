import React, { useEffect, useState } from "react";
import HeaderComponent from "../../../../components/HeaderComponent";
import { useIsLoading } from "../../../../contexts/LoadingContext";
import CONNECT_API from "../../../../configs/connect";
import { ShowError } from "../../../../configs/Functions";
import CardLink from "../../../../components/CardLinks";
import { withRouter } from "react-router-dom";


// HOME DA PÁGINA ANÚNCIOS
export const PainelPlano = props => {


    const { setIsLoading } = useIsLoading();
    const plano_id = props.match.params.idp;

    const [pg, setPg] = useState([]);
    const [menus, setMenus] = useState([]);

    async function get_data_page() {
        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/portal-do-filiado/painel?plano=${plano_id}`);
            setMenus(data?.menus);
            setPg(data?.pg);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);


    return (
        <>
            <div className="painel">
                <HeaderComponent {...pg} />
                <div className="">
                    <div className="jumbotron">
                        <div className="jumbotron-body">
                            A exibição de conteúdos no aplicativo é personalizada de acordo com o plano ativo no cadastro do usuário. <br />
                            Aqui, você pode configurar os conteúdos, anúncios e promoções específicos para cada plano.
                        </div>
                    </div>
                    <div className="row">
                        {
                            menus?.map(m => {
                                return (
                                    <div className="col-lg-4 col-md-6 col-12" key={m.id}>
                                        <CardLink {...m} url={`/portal-do-filiado/painel/${plano_id}/${m.id}`} />
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    );
}


export default withRouter(PainelPlano);