import React, { useEffect, useState } from "react";
import HeaderComponent from "../../../components/HeaderComponent";
import { toast } from "react-toastify";
import CONNECT_API from "../../../configs/connect";
import { existsOrError, formatCoin, MaiorIdade, SetNumber, ShowError } from "../../../configs/Functions";
import { useIsLoading } from "../../../contexts/LoadingContext";
import { useForm } from "react-hook-form";
import ImputMask from 'inputmask';
import { CardContent, CardTitle } from "../../../components/Card-z";
import { GroupInput, GroupRadio, GroupSelect } from "../../../components/Form";
import ButtonSave from "../../../components/ButtonComponent/ButtonSave";
import { Link, Redirect } from "react-router-dom";
import CredCard from "../../md_4_pagamento_integrado/cielo/apoio/CredCard";


export const FiliadoNovo = props => {

    const [pg, setPg] = useState([]);
    const [colaboradores, setColaboradores] = useState([]);
    const [planos, setPlanos] = useState([]);

    // Plano selecionado
    const [plano, setPlano] = useState([]);
    const [terminaisPlano, setTerminaisPlano] = useState([]);
    const [terminaisFaturamento, setTerminaisFaturamento] = useState([]);
    const [beneficios, setBeneficios] = useState([]);

    // SELECIONADO A OPÇÃO DE PAGAMENTO NO CARTÃO 
    const [card, setCard] = useState(undefined);

    // VINCULAR UM FILIADO A UMA EMPRESA
    const [fornecedores, setFornecedores] = useState([]);

    // NOVO FILIADO
    const [novoFiliadoId, setNovoFiliadoId] = useState(undefined);

    const { handleSubmit, reset, register } = useForm();
    const { setIsLoading } = useIsLoading();


    const [credCardNome, setCredCardNome] = useState('');
    const [credCardNumero, setCredCardNumero] = useState('');
    const [credCardVencimento, setCredCardVencimento] = useState('');
    const [credCardSeguranca, setCredCardSeguranca] = useState('');
    const [credCardFocus, setCredCardFocus] = useState('');

    ///////////
    // GET DADOS DA PÁGINA
    ///////////
    async function get_data_page() {

        try {
            setIsLoading(true);

            const { data } = await CONNECT_API.get('/filiado/novo');
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setColaboradores(data?.colaboradores);
            setPlanos(data?.planos);
            setFornecedores(data?.fornecedores);
            setPg(data.pg);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }

    /////////////
    // GET PLANO
    /////////////
    async function getPlanoId(e) {

        let id = e.target.value;
        setCard(null);
        reset({ terminal_id: null });
        reset({ terminal_faturamento_id: null });
        reset({ beneficio_id: null });
        reset({ cad_pj_id: null });
        reset({ card_nome: null });
        reset({ card_numero: null });
        reset({ card_vencimento: null });
        reset({ card_seguranca: null });


        if (!id) {
            setPlano([]);
            setTerminaisPlano([]);
            setTerminaisFaturamento([]);
            setBeneficios([]);
            setCard(null);
            return false
        }


        try {
            setIsLoading(true);

            const ok = await CONNECT_API.get(`${pg?.end_point}/plano/${id}`);
            existsOrError(ok?.data, 'Não foi possivel carregar os dados!');
            setPlano(ok.data?.plano);
            setTerminaisPlano(ok.data?.terminais_plano);
            setTerminaisFaturamento(ok.data?.terminais_faturamento);
            setBeneficios(ok.data?.beneficios);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }



    async function onSubmit(form) {

        try {

            const {
                nome,
                data,
                card_nome,
                // card_vencimento
            } = form;
            const cpf = SetNumber(form.cpf);
            const plano_id = SetNumber(form.plano_id);
            const colaborador_id = SetNumber(form.colaborador_id);
            const beneficio_id = SetNumber(form.beneficio_id);
            const terminal_id = SetNumber(form.terminal_id);
            const terminal_faturamento_id = SetNumber(form.terminal_faturamento_id);
            const cad_pj_id = SetNumber(form.cad_pj_id);
            const card_numero = SetNumber(form.card_numero);
            const card_seguranca = SetNumber(form.card_seguranca);
            const dia_vencimento = SetNumber(form.dia_vencimento);

            existsOrError(nome, "Nome não informado!");
            existsOrError(cpf, "CPF não informado!");
            existsOrError(data, "Data de nascimento não informada!");
            existsOrError(plano_id, "Plano não definido!");
            existsOrError(colaborador_id, "Vendedor não definido!");
            existsOrError(terminal_id, "Terminal de pagamento não definido!");


            ////////////////
            // VERIFICANDO SE O FILIADO É MAIOR DE IDADE
            ////////////////
            const maior_idade = MaiorIdade(data);
            existsOrError(maior_idade, "Filiado informado é menor de idade!");


            ////////////
            // DADOS DO POST
            ////////////
            const fields_post = {
                nome,
                cpf,
                data,
                plano_id,
                colaborador_id,
                terminal_id
            }

            //////////////
            // VERIFICANDO SE TEM BENEFICIO LANÇADO
            //////////////
            if (beneficio_id) fields_post.beneficio_id = beneficio_id;


            //////////
            // VERIFICANDO SE O TERMINAL PRECISA DE CARTÃO
            //////////
            let t_selected = terminaisPlano.filter(t => t?.id === Number(terminal_id));
            existsOrError(t_selected, "Terminal selecionado não disponível!");
            if (t_selected[0].card) {

                let anoFull = new Date().getFullYear();
                let card_vencimento = form.card_vencimento;
                ///// EXIGE 
                existsOrError(card_nome, "Cartão - Nome não informado!");
                existsOrError(card_numero, "Cartão - Número não informado!");
                existsOrError(card_vencimento, "Cartão - Vencimento do cartão não informado!");

                // Vencimento do cartão
                card_vencimento = ImputMask.unmask(card_vencimento, { mask: "99/99" })
                existsOrError(card_vencimento?.length === 4, "Cartão - Vencimento do cartão não informado invalido!");
                existsOrError(card_seguranca, "Cartão - Código de segurança do cartão não informado!");


                let mes = card_vencimento?.substr(0, 2);
                let ano = `${anoFull.toString()?.substr(0, 2)}${card_vencimento?.substr(2, 2)}`

                card_vencimento = `${mes}/${ano}`;
                fields_post.card_nome = card_nome;
                fields_post.card_numero = card_numero;
                fields_post.card_vencimento = card_vencimento;
                fields_post.card_seguranca = card_seguranca;
            } else {
                // Se não for recorrencia precisa definir a data de vencimento
                // const hoje = new Date().toLocaleDateString('pt-BR');
                // var vencimento = new Date();
                // vencimento.setDate(dia_vencimento);
                // vencimento = vencimento.toLocaleDateString('pt-BR');
                // existsOrError(vencimento >= hoje, "Data do vencimento da primeira cobrança não pode ser retroativa!");
                // existsOrError(dia_vencimento, "Dia do vencimento da primeira cobrança não definida!");
                // existsOrError(dia_vencimento.length <= 2, "Dia do vencimento da primeira cobrança não definida!");

                // Pode ser personalizado a data de vencimento
                if (dia_vencimento) fields_post.dia_vencimento = dia_vencimento;

                // Terminal de pagamento da Adessão e 1º Mensalidade
                existsOrError(terminal_faturamento_id, "Terminal Financeiro da Adessão/1º Mensalidade não foi informado!");
                fields_post.terminal_faturamento_id = terminal_faturamento_id;
            }

            //////////////
            // VINCULO A UMA EMPRESA
            //////////////
            if (cad_pj_id) fields_post.cad_pj_id = cad_pj_id;


            setIsLoading(true);

            const filiado_ok = await CONNECT_API.post(pg?.end_point, { ...fields_post });
            if (filiado_ok) {
                setNovoFiliadoId(filiado_ok?.data?.filiado?.id);
                // setNovoFiliadoId(filiado_ok?.data?.filiado?.id);
                //ShowError(filiado_ok?.data?.filiado?.id);
            }
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }


    }


    function onChangeCard(e) {
        let el = e.target;
        let c = el.name;
        let v = el.value;
        switch (c) {
            case 'card_nome':
                setCredCardNome(v);
                setCredCardFocus('name');
                break;
            case 'card_numero':
                setCredCardNumero(v);
                setCredCardFocus('number');
                break;
            case 'card_seguranca':
                setCredCardSeguranca(v);
                setCredCardFocus('cvc');
                break;
            case 'card_vencimento':
                setCredCardVencimento(v);
                setCredCardFocus('expiry');
                break;
            default:
                break;
        }

    }


    /////////////////
    // START
    /////////////////

    //Carregamnento dos dados
    useEffect(() => {
        get_data_page();
        ImputMask({ "mask": '999.999.999-99' }).mask('[name="cpf"]');
        // eslint-disable-next-line
    }, []);

    useEffect(() => {

        if (card) {
            ImputMask({ "mask": '999' }).mask('[name="card_seguranca"]');
            ImputMask({ "mask": '9999 9999 9999 9999' }).mask('[name="card_numero"]');
            ImputMask({ "mask": '99/99' }).mask('[name="card_vencimento"]');
        }
        // eslint-disable-next-line
    }, [card]);

    useEffect(() => {
        // let h = new Date().getDate();
        reset({
            dia_vencimento: 10
        })
        // eslint-disable-next-line
    }, [plano]);

    if (novoFiliadoId) return <Redirect to={`/filiado/${novoFiliadoId}`} />
    return (
        <div className="novo-filiado">
            <HeaderComponent {...pg} />
            <form onSubmit={handleSubmit(onSubmit)}>

                <div className="row justify-content-center">

                    <div className="col-lg-9">
                        <CardContent>
                            <CardTitle>
                                Cadastro cliente
                            </CardTitle>
                            <GroupInput name="nome" required col="12" label="Nome" register={register} />
                            <GroupInput name="cpf" required col="8" label="CPF" register={register} />
                            <GroupInput name="data" type="date" required col="4" label="Data Nascimento" register={register} />
                        </CardContent>
                    </div>

                    <div className="col-lg-9">
                        <CardContent>
                            <CardTitle>
                                Cadastro serviço
                            </CardTitle>
                            <GroupSelect name="plano_id" onChange={getPlanoId} col="6" required label="Plano" register={register}>
                                <option value="">Selecione...</option>
                                {
                                    planos?.map(p => {
                                        return <option key={p?.id} value={p?.id}>{p?.nome}</option>
                                    })
                                }
                            </GroupSelect>
                            <GroupSelect name="colaborador_id" col="6" required label="Vendendor" register={register}>
                                <option value="">Selecione...</option>
                                {
                                    colaboradores?.map(p => {
                                        return <option key={p?.id} value={p?.id}>{p?.nome}</option>
                                    })
                                }
                            </GroupSelect>
                        </CardContent>
                    </div>

                    <div className="col-lg-9">
                        { // CADASTRO DA FORMA DE PAGAMENTO 
                            plano?.id
                                ?
                                <CardContent>
                                    <CardTitle>
                                        Cadastro pagamento
                                    </CardTitle>
                                    <div className="col-12 mb-3">
                                        <small><b>Resumo do plano</b></small> <br />
                                        <small>Plano: {plano?.nome}</small> <br />
                                        <small>Valor: {formatCoin(plano?.valor)}</small> <br />
                                        <small>Valor da adessão: {formatCoin(plano?.valor_adesao)}</small> <br />
                                        {plano?.recorrencia ? <><small>Recorrência: {plano?.recorrencia}</small> <br /></> : ''}
                                        {plano?.vigencia ? <><small>Vigência: {plano?.vigencia}</small> <br /></> : ''}
                                        <hr />
                                    </div>
                                    <div className="col-12 mb-3">

                                        {// BENEFICIOS DO PLANO
                                            beneficios?.length ?
                                                <>
                                                    <small><b>Benefícios disponível no plano</b></small>
                                                    <div className="row mt-2">
                                                        {
                                                            beneficios?.map(t => {
                                                                return <GroupRadio
                                                                    key={t?.id}
                                                                    name="beneficio_id"
                                                                    id={`option-bene-${t?.id}`}
                                                                    value={t?.id}
                                                                    col="12"
                                                                    label={t?.nome}
                                                                    register={register} />
                                                            })
                                                        }
                                                    </div>
                                                </>
                                                :
                                                <small className="">
                                                    <b>
                                                        Nenhum benefícios disponível para este plano
                                                    </b>
                                                </small>
                                        }
                                        <hr />
                                    </div>
                                    <div className="col-12 mb-3">

                                        {// TERMINAIS DO PLANO
                                            terminaisPlano?.length ?
                                                <>
                                                    <small><b>Selecione a forma de pagamento</b></small>
                                                    <div className="row mt-2">
                                                        {
                                                            terminaisPlano?.map(t => {
                                                                return <GroupRadio
                                                                    key={t?.id}
                                                                    name="terminal_id"
                                                                    id={`option-tr-${t?.id}-pl`}
                                                                    value={t?.id}
                                                                    col="12"
                                                                    label={t?.terminal}
                                                                    onChange={() => setCard(t?.card)}
                                                                    register={register} />
                                                            })
                                                        }
                                                        {
                                                            !card
                                                                ?
                                                                <div className="check-pj col-12 mt-2">
                                                                    <div className="row">
                                                                        <div className="col-12 mb-1">
                                                                            <small><b>Dia do vencimento</b></small>
                                                                        </div>
                                                                        <GroupSelect col="4" label="" name="dia_vencimento" id="dia_vencimento" register={register}>
                                                                            <option value="1">Dia 01</option>
                                                                            <option value="2">Dia 02</option>
                                                                            <option value="3">Dia 03</option>
                                                                            <option value="4">Dia 04</option>
                                                                            <option value="5">Dia 05</option>
                                                                            <option value="6">Dia 06</option>
                                                                            <option value="7">Dia 07</option>
                                                                            <option value="8">Dia 08</option>
                                                                            <option value="9">Dia 09</option>
                                                                            <option value="10">Dia 10</option>
                                                                            <option value="11">Dia 11</option>
                                                                            <option value="12">Dia 12</option>
                                                                            <option value="13">Dia 13</option>
                                                                            <option value="14">Dia 14</option>
                                                                            <option value="15">Dia 15</option>
                                                                            <option value="16">Dia 16</option>
                                                                            <option value="17">Dia 17</option>
                                                                            <option value="18">Dia 18</option>
                                                                            <option value="19">Dia 19</option>
                                                                            <option value="20">Dia 20</option>
                                                                            <option value="21">Dia 21</option>
                                                                            <option value="22">Dia 22</option>
                                                                            <option value="23">Dia 23</option>
                                                                            <option value="24">Dia 24</option>
                                                                            <option value="25">Dia 25</option>
                                                                            <option value="26">Dia 26</option>
                                                                            <option value="27">Dia 27</option>
                                                                            <option value="28">Dia 28</option>
                                                                            <option value="29">Dia 29</option>
                                                                            <option value="30">Dia 30</option>
                                                                        </GroupSelect>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-12 mb-1">
                                                                            <small><b>Vincule a cobrança do filiado a uma empresa</b></small>
                                                                        </div>
                                                                        <GroupSelect col="8" label="" name="cad_pj_id" register={register}>
                                                                            <option value="">Selecione...</option>
                                                                            {
                                                                                fornecedores?.map((p, index) => {
                                                                                    return <option key={index} value={p.id}>{p.nome}</option>
                                                                                })
                                                                            }
                                                                        </GroupSelect>
                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-12 mb-2">
                                                                            <small><b>Selecione a forma de pagamento Adessão/1º Mensalidade</b></small>
                                                                        </div>
                                                                        {
                                                                            terminaisFaturamento?.map(t => {
                                                                                return <GroupRadio
                                                                                    key={t?.id}
                                                                                    name="terminal_faturamento_id"
                                                                                    id={`option-tr-${t?.id}-ft`}
                                                                                    value={t?.id}
                                                                                    col="12"
                                                                                    label={t?.terminal}
                                                                                    register={register} />
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                                : ""
                                                        }
                                                    </div>
                                                </>
                                                :
                                                <small className="text-danger">
                                                    <i className="las la-exclamation-triangle mr-2"></i>
                                                    Nenhum terminal configurado para este plano!
                                                </small>
                                        }
                                    </div>
                                </CardContent>
                                : ''
                        }
                    </div>
                    <div className="col-lg-9">
                        { /// SE A OPÇÃO SELECIONADA TEM CARTÃO 
                            card
                                ?
                                <CardContent>
                                    <CardTitle>
                                        Dados do cartão
                                    </CardTitle>
                                    <div className="col-12">
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div className="text-center">
                                                    <CredCard
                                                        cvc={credCardSeguranca}
                                                        expiry={credCardVencimento}
                                                        focus={credCardFocus}
                                                        name={credCardNome}
                                                        number={credCardNumero}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-lg-6">
                                                <div className="row">
                                                    <GroupInput col="12" name="card_nome" onKeyUp={onChangeCard} label="Nome impresso no Cartão" register={register} />
                                                    <GroupInput col="12" name="card_numero" onKeyUp={onChangeCard} label="Número do Cartão" register={register} />
                                                    <GroupInput col="6" name="card_vencimento" onKeyUp={onChangeCard} label="Data de vencimento" register={register} />
                                                    <GroupInput col="6" name="card_seguranca" onKeyUp={onChangeCard} label="Código de segurança" register={register} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardContent>
                                : ''
                        }
                    </div>
                    <div className="col-lg-9">
                        <div className="card">
                            <div className="card-body">
                                <div className="row justify-content-between align-items-center">
                                    <div className="col-auto">
                                        <span className="text-muted">
                                            <Link to={'/'} className="btn btn-danger px-5 py-2 bg-gradient">
                                                <i className="las la-undo"></i> Sair do cadastro
                                            </Link>
                                        </span>
                                    </div>
                                    <div className="col-auto">
                                        <ButtonSave className="mt-2 px-5" type="submit" text="Finalizar Cadastro" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}


export default FiliadoNovo;