import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";
import api from '../../../configs/api';
import { useIsLoading } from "../../../contexts/LoadingContext";

export const CardNotificacao = props => {

    const { isNotificacao, setIsNotificacao } = useIsLoading();
    const [load, setLoad] = useState(1);

    const notificacao = isNotificacao;

    async function onClick(id) {
        $('#collapse-notify').collapse('hide');
        if (!id) return false;
        try {
            if (id === "full")
                await api.put(`/search/notificacao/0`, { viewed: 'full' });
            else
                await api.put(`/search/notificacao/${id}`, { viewed: true });

            var ids = Number(load) + 1;

            setLoad(ids);
        } catch (error) {

        }
    }

    useEffect(() => {
        (async _ => {
            const data = await api.get('/search/notificacao');
            setIsNotificacao(data?.data?.notificacao);
        })();
        // eslint-disable-next-line
    }, [load]);


    return (
        <div className="card-notificacao">
            <button className="p-1 btn-notificacao" data-toggle="collapse" data-target="#collapse-notify" aria-expanded="false" aria-controls="collapse-notify">
                <span className={`icon-badge badge badge-light rounded-circle ${!notificacao?.length && 'd-none'}`}>{notificacao?.length > 0 ? notificacao?.length : ''}</span>
                <i className="las la-bell text-white" style={{ "fontSize": "33px" }}></i>
            </button>
            <div className="collapse" id="collapse-notify">
                <div className="cards-notificacao text-muted pt-4">
                    <h4>Notificações</h4>
                    {
                        !notificacao?.length ?
                            <div className="text-center">
                                <i>Sem novas notificações</i>
                            </div> : ''
                    }
                    {
                        notificacao?.length ?
                            <div className="text-right mb-2">
                                <i className=" btn p-0 text-primary" style={{ "fontSize": "11px" }} onClick={() => onClick('full')}>Marcar todas como lida</i>
                            </div> : ''
                    }
                    <div className="container-cards list-group">
                        {
                            notificacao?.map(n => (
                                <Link key={n?.id} className="list-group-item list-group-item-action flex-column align-items-start" to={n?.url || n.link || '/'} onClick={() => onClick(n?.id)} style={{ "whiteSpace": "inherit" }}>
                                    <div className="d-flex w-100 justify-content-start align-items-center">
                                        {n?.icon ? <img src={n?.icon} width="20" height="20" className="mr-2" alt="" /> : ''}
                                        <h5 className="mb-1">{n?.title}</h5>
                                        <small className="tag d-none" dangerouslySetInnerHTML={{ __html: n?.tag }}></small>
                                    </div>
                                    <div className="desc" style={{ "fontSize": "12px" }} dangerouslySetInnerHTML={{ __html: n?.descricao || n?.body }}></div>
                                </Link>
                            ))
                        }
                    </div>
                    <hr />
                    <div className="text-center mt-3">
                        <Link to={`/notificacoes`} onClick={() => onClick()} className="btn btn-sm btn-link"><i className="las la-comment mr-2"></i><i>Ver todas as notificações</i></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default CardNotificacao;