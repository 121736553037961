import api from "./api";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";

const nameToken = process.env.REACT_APP_NAMETOKEN;
export const login = async ({ email, password }) => {

    if (!email || !password) return false;


    api.post('/login', { email, password })
        .then(user => {

            localStorage.setItem(nameToken, user.data.token);
            window.location.reload();
        })
        .catch(err => {
            if (!err.response) {
                toast.error('Tente novamente mais tarde!');
            } else {
                const msg = err.response.data;
                toast.error(msg)
            }
        })
}

export const Autenticado = (isNav, res) => {

    const velidateToken = token => {

        const tokenDecode = jwt_decode(token);
        if (new Date(tokenDecode.exp * 1000) > new Date()) {
            return true;
        }
        return false;
    }

    // tratamento dos menus
    const menus = JSON.stringify(isNav);

    var pathname = res?.computedMatch.path;

    /*
    let url = pathname?.split('/').pop();

    if ( url?.indexOf(':') > -1 ){
        pathname = pathname?.split('/');
        pathname?.pop();
        pathname = pathname?.join('/');
    }
    */
    // Token de acesso no front
    const token_local = localStorage.getItem(nameToken);
    if (!token_local) {
        return {
            permissao: false,
            logado: false
        };
    }

    const token_valido = velidateToken(token_local);
    let permissao = false;
    let logado = false;

    // Acesso ao menu
    if (menus?.includes(`"${pathname}"`)) permissao = true;

    // Token valido
    if (token_valido) logado = true;

    return {
        permissao,
        logado
    };
};

// eslint-disable-next-line
export default { Autenticado, login };