import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useIsLoading } from '../../../contexts/LoadingContext';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Filler,
    Legend
);




export const GraficoAreaDasboard = props => {
    const { isCompany } = useIsLoading();

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
                display: false
            },
            title: {
                display: true,
                text: props?.title?.toUpperCase() || 'GRÁFICOS DE ÁREA',
            },
        },
    };

    const vendas_mes = props?.data?.f_m ? props?.data?.f_m : [];
    const labels_fm = Object.keys(vendas_mes);
    const data_fm = vendas_mes;

    const labels = labels_fm;

    const data = {
        labels,
        datasets: [
            {
                fill: true,
                label: isCompany?.nome || '',
                data: labels.map((e) => data_fm[e]),
                borderColor: isCompany?.colors?.primary_color || 'rgb(53, 162, 235)',
                backgroundColor: isCompany?.colors?.primary_color || 'rgba(53, 162, 235, 1)',
                borderRadius: 6,
            }
        ],
    };


    return <Bar options={options} data={data} />;
};