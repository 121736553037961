import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import HeaderComponent from "../../../../components/HeaderComponent";
import CONNECT_API from "../../../../configs/connect";
import { existsOrError, ShowError } from "../../../../configs/Functions";
import { useIsLoading } from "../../../../contexts/LoadingContext";
import CardChangeTitular from "./cardsConfig/dependente/CardChangeTitular";
import CardB2BConfigFiliado from "./CardsFiliado/CardB2BConfigFiliado";
import CardCadastroConfigFiliado from "./CardsFiliado/CardCadastroConfigFiliado";
import CardEmpresaConfigFiliado from "./CardsFiliado/CardEmpresaConfigFiliado";
import CardPlanoConfigFiliado from "./CardsFiliado/CardPlanoConfigFiliado";
import CardTerminalConfigFiliado from "./CardsFiliado/CardTerminalConfigFiliado";
import ChangeDataVencimentoMensalidades from "./ChangeDataVencimento";
import ChangeStatusFiliado from "./ChangeStatusFiliado";
import ModalNovoTitular from "./novo_titular/CardNovoTitular";
import ModalToDependente from "./novo_titular/CardToDependente";
import ChangeDefinedNotification from "./ChangeDefinedNotification";

export const FiliadoConfiguracao = (props) => {
    const [pg, setPg] = useState([]);
    const [filiado, setFiliado] = useState([]);
    const [plano, setPlano] = useState([]);
    const [terminal, setTerminal] = useState([]);
    const [empresaFiliado, setEmpresaFiliado] = useState([]);

    const [cardsChange, setCardsChange] = useState([]);

    const { setIsLoading } = useIsLoading();

    async function get_data_page() {
        try {
            const id = props.match.params.id;
            setIsLoading(true);
            const { data } = await CONNECT_API.get(
                `filiado/${id}/configuracao`
            );
            existsOrError(data, "Não foi possivel carregar os dados!");
            setFiliado(data?.filiado);
            setPlano(data?.plano);
            setTerminal(data?.terminal);
            setCardsChange(data?.cards_change);
            setEmpresaFiliado(data?.empresa_filiado);
            setPg(data?.pg);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);
    return (
        <div className="terminais">
            <HeaderComponent {...pg}>
                {!filiado?.titular_id && filiado?.situacao?.toUpperCase() === "CANCELADO" && (
                    <div className="d-flex justify-content-end">
                        <ModalNovoTitular
                            {...props}
                            modalId="modal-novo-titular"
                            text="Reativar Cadastro"
                            icon="user-check"
                            className="btn btn-outline-primary"
                            loaded={get_data_page}
                        />
                        <ModalToDependente
                            {...props}
                            modalId="modal-dependente"
                            text="Torna-o Dependente"
                            icon="user-friends"
                            className="btn btn-outline-warning"
                            loaded={get_data_page}
                        />
                    </div>
                )}
            </HeaderComponent>
            <div className="planos">
                <div className="row justify-content-between">
                    <div className="col-lg-7">
                        {/* Escopo de titular */}
                        {!filiado?.titular_id && (
                            <>
                                {/* SOBRE AS NOTIFICAÇÕES DO FILIADO */}
                                <ChangeDefinedNotification
                                    {...props}
                                    sendNotification={filiado?.sendNotification}
                                    changePage={get_data_page}
                                />
                                
                                {/* Alterando a data de vencimento dos lançamentos */}
                                {cardsChange?.change_day &&
                                    filiado?.situacao?.toUpperCase() !==
                                    "CANCELADO" &&
                                    !filiado?.titular_id && (
                                        <ChangeDataVencimentoMensalidades
                                            {...props}
                                        />
                                    )}


                                {filiado?.situacao?.toUpperCase() !==
                                    "CANCELADO" &&
                                    !filiado?.titular_id && (
                                        <ChangeStatusFiliado
                                            {...props}
                                            changePage={get_data_page}
                                        />
                                    )}
                            </>
                        )}

                        {filiado?.titular_id && (
                            <>
                                <CardChangeTitular {...props} loaded={get_data_page} />
                            </>
                        )}
                    </div>

                    {/* Coluna de descrição */}
                    <div className="col-lg-5">
                        <div className="card sticky-top">
                            <div className="card-body">
                                <CardCadastroConfigFiliado filiado={filiado} />
                                {plano && (
                                    <CardPlanoConfigFiliado plano={plano} />
                                )}
                                {terminal && (
                                    <CardTerminalConfigFiliado
                                        terminal={terminal}
                                    />
                                )}

                                {empresaFiliado?.id && (
                                    <CardB2BConfigFiliado
                                        empresaFiliado={empresaFiliado}
                                    />
                                )}
                                {filiado?.vendedor && (
                                    <CardEmpresaConfigFiliado
                                        filiado={filiado}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default withRouter(FiliadoConfiguracao);
