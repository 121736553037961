import React from 'react';


export const ButtonSave = props => {


    return (
        <div className="cmp-button">
            <button type={`${props.type || "button"}`} className={`btn btn-primary px-4 py-2 bg-gradient ${props.className}`} onClick={props.onClick}>
                <i className={`las la-${props.icon ? props.icon : "save"}`}></i>
                <label htmlFor="botão salvar" className='m-0 ml-2'>{props?.text ? props?.text : "Salvar"}</label>
            </button>
        </div>
    )
}

export const ButtonDanger = props => {


    return (
        <div className="cmp-button">
            <button type={`${props.type || "button"}`} className={`btn btn-danger px-4 py-2 bg-gradient ${props.className}`} onClick={props.onClick}>
                <i className={`las la-${props.icon ? props.icon : "save"}`}></i>
                <label htmlFor="botão" className='m-0 ml-2'>{props?.text ? props?.text : "Salvar"}</label>
            </button>
        </div>
    )
}



export default ButtonSave