import React from "react";
import { CardContent, CardTitle } from "../../../../components/Card-z";
import { Modal } from "../../../../components/Modal-z";
import { useForm } from 'react-hook-form';
import { GroupInput } from "../../../../components/Form";
import ButtonSave from "../../../../components/ButtonComponent/ButtonSave";
import ImputMask from 'inputmask';
import { existsOrError, SetNumber, ShowError } from "../../../../configs/Functions";
import { useIsLoading } from "../../../../contexts/LoadingContext";
import CONNECT_API from "../../../../configs/connect";

export const ModalChangeCard = props => {

    const { handleSubmit, register, reset } = useForm();
    const { setIsLoading } = useIsLoading();
    async function modalShow() {

        ImputMask({ "mask": '999' }).mask('[name="card_seguranca"]');
        ImputMask({ "mask": '9999 9999 9999 9999' }).mask('[name="card_numero"]');
        ImputMask({ "mask": '99/9999' }).mask('[name="card_vencimento"]');
    }

    async function onSubmit(form_data) {
        const filiad_id = props?.filiado_id;

        try {

            const card_nome = form_data.card_nome;
            const card_vencimento = form_data.card_vencimento;
            const card_numero = SetNumber(form_data.card_numero);
            const card_seguranca = SetNumber(form_data.card_seguranca);

            existsOrError(filiad_id, "Filiado ID não informado!");
            existsOrError(card_nome, "Nome não informado!");
            existsOrError(card_numero, "Número do cartão não informado!");
            existsOrError(card_vencimento, "Vencimento do cartão não informado!");
            existsOrError(card_seguranca, "Código de segurança do cartão não informado!");

            const fields = {
                card_nome,
                card_numero,
                card_seguranca,
                card_vencimento
            }

            setIsLoading(true);
            const ok = await CONNECT_API.post(`/filiado/${filiad_id}/terminal/change-card`, { ...fields });
            if (ok?.data && props.loaded) {
                props.loaded();
                reset();
            }
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    return (
        <Modal modalId="modal-card" text={props.text} className="btn btn-sm btn-success" modalShow={modalShow}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                    <CardTitle>
                        Dados do novo cartão
                    </CardTitle>
                    <GroupInput col="12" name="card_nome" label="Nome impresso no Cartão" register={register} />
                    <GroupInput col="12" name="card_numero" label="Número do Cartão" register={register} />
                    <GroupInput col="6" name="card_vencimento" label="Data de vencimento" register={register} />
                    <GroupInput col="6" name="card_seguranca" label="Código de segurança" register={register} />
                    <div className="col-12">
                        <ButtonSave type="submit" className="float-right" />
                    </div>
                </CardContent>
            </form>
        </Modal>
    )
}


export default ModalChangeCard;