import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CardContent } from "../../components/Card-z";
import {
    GroupCheckBox,
    GroupInput,
    GroupSelect,
    GroupText,
} from "../../components/Form";
import HeaderComponent from "../../components/HeaderComponent";
import CONNECT_API from "../../configs/connect";
import {
    existsOrError,
    formatCNPJ,
    formatCoin,
    formatCPF,
    formatDate,
    SetCoin,
} from "../../configs/Functions";
import { useIsLoading } from "../../contexts/LoadingContext";
import {
    TableFooter,
    TableHover,
    Tbody,
    Td,
    Th,
    Thead,
} from "../../components/Table-z";
import $ from "jquery";
import { Link } from "react-router-dom";
import { Modal } from "../../components/Modal-z";
import { toast } from "react-toastify";
import ButtonSave from "../../components/ButtonComponent/ButtonSave";

export const FaturamentoSearch = (props) => {
    //////////////
    // DATA LOCAL DO FILTRO
    //////////////
    const data_local = new Date();
    const hoje = data_local
        .toLocaleDateString("pt-BR")
        .split("/")
        .reverse()
        .join("-");

    const [pg, setPg] = useState([]);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState([]);
    const [faturamento, setFaturamento] = useState([]);
    const [colaboradores, setColaboradores] = useState([]);
    const [terminais, setTerminais] = useState([]);
    const [totais, setTotais] = useState([]);

    const [faturamentoEditar, setFaturamentoEditar] = useState([]);

    const { setIsLoading } = useIsLoading();
    const { register, handleSubmit, reset } = useForm();

    // async function get_data_page() {

    //     try {

    //         setIsLoading(true);
    //         const { data } = await CONNECT_API.get(`/faturamento/search`);
    //         existsOrError(data, 'Não foi possivel carregar os dados!');
    //         setFaturamento(data.faturamento);
    //         setPagination(data?.pagination);
    //         setColaboradores(data?.colaboradores);
    //         setTerminais(data?.terminais);
    //         setTotais(data?.totais);
    //         setPage(data?.page);
    //         setPg(data.pg);
    //         setIsLoading(false);

    //     } catch (error) {
    //         setIsLoading(false);
    //     }
    // }

    ///////
    // CONTROLE DOS COLAPSES
    ///////
    async function onCollapse(e) {
        let checked = e.target.checked;
        let id = e.target.id;

        if (checked) {
            $(`div#${id}`).collapse("show");
        } else {
            $(`div#${id}`).collapse("hide");
        }
    }

    ////////////
    // FILTRO
    ////////////
    async function onSubmit(data) {
        const {
            colaborador,
            data_in,
            data_out,
            pf,
            pj,
            receita,
            despesa,
            cancelado,
            query_terminal,
        } = data;

        var query = `?page=${page}`;
        if (colaborador) query += `&colaborador=${colaborador}`;
        if (data_in) query += `&data_in=${data_in}`;
        if (data_out) query += `&data_out=${data_out}`;
        if (pf) query += `&pf=${pf}`;
        if (pj) query += `&pj=${pj}`;
        if (receita) query += `&receita=${receita}`;
        if (despesa) query += `&despesa=${despesa}`;
        if (cancelado) query += `&cancelado=${cancelado}`;
        if (query_terminal) query += `&terminal=${query_terminal}`;

        try {
            setIsLoading(true);

            const { data } = await CONNECT_API.get(
                `/faturamento/search/${query}`
            );
            existsOrError(data, "Não foi possivel carregar os dados!");
            setFaturamento(data.faturamento);
            setPagination(data?.pagination);
            setTotais(data?.totais);
            setColaboradores(data?.colaboradores);
            setTerminais(data?.terminais);
            setPg(data.pg);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    ////////////
    // GET FATURAMENTO
    ////////////
    async function onGetFaturamento(id) {
        if (!id) return false;

        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/faturamento/${id}/editar`);
            setFaturamentoEditar({ ...data });
            $("#modal-editar-faturamento").modal("show");
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }

    //////////////////
    // ATUALIZANDO FATURAMENTO
    //////////////////
    async function onSubmitFaturamento(data) {
        try {
            const { cancelar_faturamento, novo_valor_faturamento, motivo } =
                data;
            const novo_valor_faturado = SetCoin(data.novo_valor_faturado);

            existsOrError(motivo, "Motivo da alteração não informado!");

            if (novo_valor_faturamento) {
                existsOrError(
                    novo_valor_faturado,
                    "Valor do faturamento não informado!"
                );
            }

            const fields = {
                cancelar_faturamento,
                novo_valor_faturado,
                novo_valor_faturamento,
                motivo,
            };

            setIsLoading(true);
            const ok = await CONNECT_API.put(faturamentoEditar?.pg?.end_point, {
                ...fields,
            });

            if (ok) {
                reset({
                    cancelar_faturamento: null,
                    novo_valor_faturado: null,
                    novo_valor_faturamento: null,
                    motivo: null,
                });
                handleSubmit(onSubmit)();
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }

    useEffect(() => {
        handleSubmit(onSubmit)();
        // eslint-disable-next-line
    }, [page]);


    useEffect(() => {
        if (!faturamento) return false;

        setTimeout(() => {
            document
                .querySelector(".page > div")
                .scrollIntoView({ behavior: "smooth", block: "start" });
        }, 100);
        // eslint-disable-next-line
    }, [faturamento]);

    /////////////////////////
    // MODAIS
    /////////////////////////
    const fe = faturamentoEditar?.faturamento;
    const modal_editar = (
        <Modal modalId="modal-editar-faturamento">
            <form action="" onSubmit={handleSubmit(onSubmitFaturamento)}>
                <CardContent>
                    <div className="col-12 mb-4">
                        <HeaderComponent {...faturamentoEditar?.pg} />
                        <div className="descricao">
                            Fornecedor:{" "}
                            {fe?.pf_nome ? fe?.pf_nome : fe?.pj_nome} <br />
                            Parcela: {fe?.numero_atual_x}/
                            {fe?.numero_total_x
                                ? fe?.numero_total_x
                                : fe?.numero_atual_x}{" "}
                            <br />
                            Tipo:{" "}
                            {fe?.tipo_receita
                                ? fe?.tipo_receita
                                : "Despesa"}{" "}
                            <br />
                            Valor Lançado: {formatCoin(
                                fe?.valor_lancamento
                            )}{" "}
                            <br />
                            Valor Faturado: {formatCoin(
                                fe?.valor_faturamento
                            )}{" "}
                            <br />
                            Terminal: {fe?.terminal_faturamento} <br />
                            Banco: {fe?.financeira_faturamento} <br />
                            Faturado em: {formatDate(fe?.data_faturamento)}{" "}
                            <br />
                            {fe?.cancelado_faturamento && (
                                <span className="text-danger">
                                    Faturamento cancelado em:{" "}
                                    {formatDate(fe?.cancelado_faturamento)}
                                </span>
                            )}
                        </div>
                    </div>
                    {!fe?.cancelado_faturamento ? (
                        <>
                            <GroupCheckBox
                                name="cancelar_faturamento"
                                id="cancelar-faturamento"
                                value="1"
                                col="auto"
                                label="Cancelar Faturamento"
                                register={register}
                            />
                            {fe?.card && (
                                <div
                                    className="col-12 mb-3"
                                    style={{ marginTop: "-15px" }}
                                >
                                    <small className="text-danger">
                                        Se realizar o cancelamento o valor vai
                                        ser estornado ao cliente!
                                    </small>
                                </div>
                            )}
                            {!fe?.card && (
                                <GroupCheckBox
                                    name="novo_valor_faturamento"
                                    id="novo-valor-faturamento"
                                    value="1"
                                    col="auto"
                                    onChange={onCollapse}
                                    label="Ajustar Valor"
                                    register={register}
                                />
                            )}
                            <div className="col-12">
                                <div
                                    className="collapse"
                                    id="novo-valor-faturamento"
                                >
                                    <div className="container-collapse pt-2">
                                        <div className="row justify-content-start">
                                            <GroupInput
                                                name="novo_valor_faturado"
                                                label="Valor faturado"
                                                col="12"
                                                register={register}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <GroupText
                                name="motivo"
                                label="Justifique a alteração"
                                required
                                col="12"
                                register={register}
                            />
                            <div className="col-12">
                                <ButtonSave
                                    type="submit"
                                    className="float-right"
                                />
                            </div>
                        </>
                    ) : (
                        ""
                    )}
                </CardContent>
            </form>
        </Modal>
    );

    return (
        <div className="relatorios">
            <HeaderComponent {...pg}>
                <div className="row justify-content-end">
                    {totais ? (
                        <div className="col-lg-auto">
                            <div className="card bg-danger">
                                <div className="card-body text-center text-light">
                                    <b>
                                        Lançado{" "}
                                        {formatCoin(totais?.total_lancado)}
                                    </b>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                    {totais ? (
                        <div className="col-lg-auto">
                            <div
                                className="card"
                                style={{ background: "var(--lancamento)" }}
                            >
                                <div className="card-body text-center text-light">
                                    <b>
                                        Faturado{" "}
                                        {formatCoin(totais?.total_faturado)}
                                    </b>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </HeaderComponent>
            <div className="search">
                <form
                    action=""
                    name="search-fornecedor"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <CardContent>
                        <div className="col-lg-12">
                            <div className="row justify-content-between">
                                <GroupInput
                                    name="data_in"
                                    type="date"
                                    defaultValue={hoje}
                                    label="Data inicio"
                                    col="2"
                                    register={register}
                                />
                                <GroupInput
                                    name="data_out"
                                    type="date"
                                    defaultValue={hoje}
                                    label="Data final"
                                    col="2"
                                    register={register}
                                />
                                <GroupSelect
                                    name="query_terminal"
                                    col="4"
                                    label="Terminal financeiro"
                                    register={register}
                                >
                                    {terminais?.map((r) => {
                                        return (
                                            <option
                                                value={r.id}
                                                key={r.id}
                                            >{`${r.financeira} → ${r.terminal}`}</option>
                                        );
                                    })}
                                    <option value="">Todos...</option>
                                </GroupSelect>
                                <GroupSelect
                                    name="colaborador"
                                    col="4"
                                    label="Colaborador"
                                    register={register}
                                >
                                    {colaboradores?.map((r) => {
                                        return (
                                            <option value={r.id} key={r.id}>
                                                {r.nome}
                                            </option>
                                        );
                                    })}
                                    <option value="">Todos...</option>
                                </GroupSelect>
                                <GroupCheckBox
                                    name="cancelado"
                                    id="cancelado"
                                    value="1"
                                    col="6"
                                    label="Faturamento cancelado"
                                    register={register}
                                />
                                <GroupCheckBox
                                    name="pf"
                                    id="pf"
                                    value="1"
                                    col="6"
                                    label="Fornecedor PF"
                                    register={register}
                                />
                                <GroupCheckBox
                                    name="pj"
                                    id="pj"
                                    value="1"
                                    col="6"
                                    label="Fornecedor PJ"
                                    register={register}
                                />

                                <GroupCheckBox
                                    name="receita"
                                    id="receita"
                                    value="1"
                                    col="6"
                                    label="Tipo receita"
                                    register={register}
                                />
                                <GroupCheckBox
                                    name="despesa"
                                    id="despesa"
                                    value="1"
                                    col="6"
                                    label="Tipo despesa"
                                    register={register}
                                />
                                <div className="col-lg-12">
                                    <button
                                        className="btn btn-primary float-right"
                                    >
                                        <i className="las la-search mr-2"></i>
                                        Buscar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </form>
            </div>

            <div className="table-list">
                <CardContent>
                    <div className="col-12">
                        <TableHover className="mb-0 text-center">
                            <Thead
                                th={[
                                    "id",
                                    "Tipo",
                                    "Cadastro",
                                    "Lançamento",
                                    "Data",
                                    "Valor",
                                    "Parcela",
                                    "Terminal",
                                    "Ação",
                                ]}
                            />
                            <Tbody>
                                {faturamento?.map((f) => {
                                    const receita = (
                                        <i
                                            className="las la-arrow-up text-success"
                                            style={{ fontSize: "25px" }}
                                        ></i>
                                    );
                                    const despesa = (
                                        <i
                                            className="las la-arrow-down text-danger"
                                            style={{ fontSize: "25px" }}
                                        ></i>
                                    );
                                    const pj = (
                                        <Link
                                            to={f?.url_fornecedor || "/"}
                                            className="btn btn-sm m-0 text-dark"
                                            target="_blank"
                                        >
                                            {formatCNPJ(f?.cnpj)} <br />
                                            <small>{f.pj_nome}</small>
                                        </Link>
                                    );
                                    const pf = (
                                        <Link
                                            to={f?.url_fornecedor || "/"}
                                            className="btn btn-sm m-0 text-dark"
                                            target="_blank"
                                        >
                                            {formatCPF(f?.cpf)} <br />
                                            <small>{f?.pf_nome}</small>
                                        </Link>
                                    );

                                    const lancamento_link = (
                                        <Link
                                            to={
                                                f?.url_lancamento ||
                                                f?.link_lancamento ||
                                                "/"
                                            }
                                            className="btn btn-sm m-0 text-muted"
                                            target="_blank"
                                        >
                                            #{f?.id_lancamento}
                                        </Link>
                                    );

                                    const btn_editar = (
                                        <button
                                            className="btn btn-sm m-0 text-muted"
                                            title="Editar"
                                            onClick={() =>
                                                onGetFaturamento(f.id)
                                            }
                                        >
                                            <i
                                                className="las la-edit"
                                                style={{ fontSize: "20px" }}
                                            ></i>
                                        </button>
                                    );

                                    const btn_ver = (
                                        <Link
                                            to={f.url || f.link || "/"}
                                            className="btn btn-sm m-0 text-success"
                                            title="Ver"
                                            target="_blank"
                                        >
                                            <i
                                                className="las la-external-link-alt"
                                                style={{ fontSize: "20px" }}
                                            ></i>
                                        </Link>
                                    );

                                    const btns = (
                                        <div
                                            className="btn-group"
                                            role="group"
                                            aria-label="buttons ação"
                                        >
                                            {!f.cancelado_faturamento &&
                                                btn_editar}
                                            {btn_ver}
                                        </div>
                                    );

                                    return (
                                        <tr key={f.id}>
                                            <Th>#{f.id}</Th>
                                            <Td>
                                                {f.receita ? (
                                                    <>
                                                        {receita} <br />
                                                        <small className="text-success">
                                                            {f.tipo_receita}
                                                        </small>
                                                    </>
                                                ) : (
                                                    <>
                                                        {despesa} <br />
                                                        <small className="text-danger">
                                                            Despesa
                                                        </small>
                                                    </>
                                                )}
                                            </Td>
                                            <Td>{f.cpf ? pf : pj}</Td>
                                            <Td>{lancamento_link}</Td>
                                            <Td>
                                                {f.cancelado_faturamento ? (
                                                    <>
                                                        <span className="badge badge-danger mr-1 px-2">
                                                            Cancelado
                                                        </span>{" "}
                                                        <br />
                                                        <small className="text-danger">
                                                            {formatDate(
                                                                f.cancelado_faturamento
                                                            )}
                                                        </small>
                                                    </>
                                                ) : (
                                                    <>
                                                        {formatDate(
                                                            f.data_faturamento
                                                        )}
                                                    </>
                                                )}
                                            </Td>
                                            <Td>
                                                {formatCoin(f.valor_lancamento)}{" "}
                                                <br />
                                                {!f.cancelado && (
                                                    <small className="text-success">
                                                        {formatCoin(
                                                            f.valor_faturamento
                                                        )}
                                                    </small>
                                                )}
                                            </Td>
                                            <Td>
                                                {f.numero_atual_x}/
                                                {f.numero_total_x
                                                    ? f.numero_total_x
                                                    : f.numero_atual_x}
                                            </Td>
                                            <Td>{`${f.terminal_faturamento} → ${f.financeira_faturamento}`}</Td>
                                            <Td>{btns}</Td>
                                        </tr>
                                    );
                                })}
                            </Tbody>
                        </TableHover>
                        <TableFooter
                            {...pagination}
                            setPage={setPage}
                            page={page}
                        />
                    </div>
                </CardContent>
            </div>
            {modal_editar}
        </div>
    );
};

export default FaturamentoSearch;
