import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ImputMask from 'inputmask';
import { withRouter } from "react-router-dom";
import CONNECT_API from "../../../configs/connect";
import { existsOrError, SetNumber } from "../../../configs/Functions";
import { useIsLoading } from "../../../contexts/LoadingContext";
import HeaderComponent from "../../../components/HeaderComponent";
import ButtonSave from "../../../components/ButtonComponent/ButtonSave";
import { CardContent, CardTitle } from "../../../components/Card-z";
import { GroupInput } from "../../../components/Form";
import NavVerticalButton from "../../../components/NavBarVertical/ButtonLink";



export const FornecedorEditarPJ = props => {

    const { register, handleSubmit, reset } = useForm();
    const { setIsLoading } = useIsLoading();
    const [fornecedor, setFornecedor] = useState([]);
    const [fornecedorKey, setFornecedorKey] = useState([]);
    const [pg, setPg] = useState([]);

    // PARAMS
    const tipo = "PJ";
    const fornecedor_id = props.match.params.id;

    async function get_data_page() {

        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/fornecedor/${tipo}/${fornecedor_id}/editar`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setFornecedor(data.fornecedor);
            setPg(data.pg);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    async function get_data_cep(e) {

        const cep = SetNumber(e.target.value);
        if (!cep) return false;

        if (cep.length === 8) {

            setIsLoading(true);

            const json = await CONNECT_API.getCep(cep);

            reset({
                uf: json?.data.uf,
                cidade: json?.data.localidade,
                bairro: json?.data.bairro,
                logradouro: json?.data.logradouro,
                complemento: json?.data.complemento
            })

            setIsLoading(false);
        }
    }

    async function onSubmit(data) {

        try {

            existsOrError(data.nome, "Nome não informado!");

            const {
                nome,
                social,
                tributacao,
                cidade,
                logradouro,
                complemento,
                bairro,
                uf,
                ger_email,
                ger_nome,
                fin_email,
                at_email,
                instagram,
                linkedin,
                facebook,
                website,
            } = data;

            const inscricao_estadual = SetNumber(data.inscricao_estadual);
            const numero = SetNumber(data.numero);
            const cep = SetNumber(data.cep);
            const ger_cpf = SetNumber(data.ger_cpf);
            const ger_tel = SetNumber(data.ger_tel);
            const ger_whats = SetNumber(data.ger_whats);
            const fin_tel = SetNumber(data.fin_tel);
            const fin_whats = SetNumber(data.fin_whats);
            const at_tel = SetNumber(data.at_tel);
            const at_whats = SetNumber(data.at_whats);

            const fields = {
                nome,
                social,
                tributacao,
                cidade,
                logradouro,
                complemento,
                bairro,
                uf,
                ger_email,
                ger_nome,
                fin_email,
                at_email,
                instagram,
                linkedin,
                facebook,
                website,
                inscricao_estadual,
                numero,
                cep,
                ger_cpf,
                ger_tel,
                ger_whats,
                fin_tel,
                fin_whats,
                at_tel,
                at_whats
            }

            setIsLoading(true);
            await CONNECT_API.put(`${pg.end_point}`, { ...fields });
            await get_data_page();
            setIsLoading(false);

        } catch (error) {
            toast.error(error);
        }

    }

    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {

        setFornecedorKey(Object.keys(fornecedor));

        reset({...fornecedor});

        // eslint-disable-next-line
    }, [fornecedor]);

    //Masc
    useEffect(() => {
        ImputMask({ "mask": '99.999.999/9999-99' }).mask('[name="cnpj"]');
        ImputMask({ "mask": '(99) 99999-9999' }).mask('[type="tel"]');
        ImputMask({ "mask": '99999-999' }).mask('[name="cep"]');
        ImputMask({ "mask": '999.999.999-99' }).mask('[name*="cpf"]');
        // eslint-disable-next-line
    }, []);

    return (
        <div className="fornecedor-ditar pb-2">
            <HeaderComponent {...pg}>
                <ButtonSave onClick={handleSubmit(onSubmit)} />
            </HeaderComponent>
            <form action="" onSubmit={handleSubmit(onSubmit)}>

                <div className="row align-items-start">
                    <div className="col-lg-3 position-sticky">
                        <div className="nav flex-column nav-pills" role="tablist" aria-orientation="vertical">
                            <NavVerticalButton href="#resumo" label="Resumo" icon="file" active="true" />
                            <NavVerticalButton href="#cadastro" label="Cadastro" icon="file-alt" />
                            <NavVerticalButton href="#endereco" label="Endereço" icon="map-marked-alt" />
                            <NavVerticalButton href="#gerencia" label="Gerência" icon="user-tie" />
                            <NavVerticalButton href="#financeiro" label="Financeiro" icon="coins" />
                            <NavVerticalButton href="#atendimento" label="Atendimento" icon="comment-alt" />
                            <NavVerticalButton href="#redes-sociais" label="Redes Sociais" icon="share-alt-square" />
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="resumo" role="tabpanel">
                                <CardContent>
                                    <div className="text-muted">
                                        {
                                            fornecedorKey?.map((f, index) => {
                                                return (
                                                    <div key={index} className="mb-1">
                                                        <b className="mr-1">{f?.toUpperCase()}:</b>
                                                        <span>{fornecedor[f]}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </CardContent>
                            </div>

                            <div className="tab-pane fade" id="cadastro" role="tabpanel">
                                <CardContent>
                                    <CardTitle>
                                        Cadastro
                                    </CardTitle>
                                    <GroupInput col="12" name="nome" label="Nome" id="" register={register} />
                                    <GroupInput col="12" name="social" label="Razão Social" id="" register={register} />
                                    <GroupInput col="4" name="cnpj" label="CNPJ" id="" disabled register={register} />
                                    <GroupInput col="4" name="inscricao_estadual" label="Inscrição Estadual" id="" register={register} />
                                    <GroupInput col="4" name="tributacao" label="Tributação" id="" register={register} />
                                </CardContent>
                            </div>

                            <div className="tab-pane fade" id="endereco" role="tabpanel">
                                <CardContent>
                                    <CardTitle>
                                        Endereço
                                    </CardTitle>
                                    <GroupInput col="3" name="cep" label="CEP" id="" onKeyUp={get_data_cep} register={register} />
                                    <GroupInput col="9" name="logradouro" label="Rua" id="" register={register} />
                                    <GroupInput col="4" name="bairro" label="Bairro" id="" register={register} />
                                    <GroupInput col="4" name="cidade" label="Cidade" id="" register={register} />
                                    <GroupInput col="2" name="numero" label="Número" id="" register={register} />
                                    <GroupInput col="2" name="uf" label="UF" id="" register={register} />
                                    <GroupInput col="12" name="complemento" label="Complemento" id="" register={register} />
                                </CardContent>
                            </div>

                            <div className="tab-pane fade" id="gerencia" role="tabpanel">
                                <CardContent>
                                    <CardTitle>
                                        Gerente Administrador
                                    </CardTitle>

                                    <GroupInput col="12" name="ger_nome" label="Nome" id="" register={register} />
                                    <GroupInput col="4" name="ger_cpf" label="CPF" id="" register={register} />
                                    <GroupInput col="8" name="ger_email" label="E-mail" id="" register={register} />
                                    <GroupInput col="6" name="ger_tel" label="Telefone" type="tel" id="" register={register} />
                                    <GroupInput col="6" name="ger_whats" label="Whatsapp" type="tel" id="" register={register} />
                                </CardContent>
                            </div>

                            <div className="tab-pane fade" id="financeiro" role="tabpanel">
                                <CardContent>
                                    <CardTitle>
                                        Financeiro
                                    </CardTitle>
                                    <GroupInput col="12" name="fin_email" label="E-mail" id="" register={register} />
                                    <GroupInput col="12" name="fin_tel" label="Telefone" type="tel" id="" register={register} />
                                    <GroupInput col="12" name="fin_whats" label="Whatsapp" type="tel" id="" register={register} />
                                </CardContent>
                            </div>
                            <div className="tab-pane fade" id="atendimento" role="tabpanel">
                                <CardContent>
                                    <CardTitle>
                                        Atendimento
                                    </CardTitle>
                                    <GroupInput col="12" name="at_email" label="E-mail" id="" register={register} />
                                    <GroupInput col="12" name="at_tel" label="Telefone" type="tel" id="" register={register} />
                                    <GroupInput col="12" name="at_whats" label="Whatsapp" type="tel" id="" register={register} />
                                </CardContent>
                            </div>
                            <div className="tab-pane fade" id="redes-sociais" role="tabpanel">
                                <CardContent>
                                    <CardTitle>
                                        Redes sociais
                                    </CardTitle>
                                    <GroupInput col="12" name="facebook" label="Facebook" id="" register={register} />
                                    <GroupInput col="12" name="instagram" label="Instagram" id="" register={register} />
                                    <GroupInput col="12" name="linkedin" label="Linkedin" id="" register={register} />
                                    <GroupInput col="12" name="website" label="Website" id="" register={register} />
                                </CardContent>
                            </div>
                        </div>
                        <ButtonSave type="submit" className="float-right" />
                    </div>
                </div>
            </form>
        </div>
    );
}


export default withRouter(FornecedorEditarPJ);