import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ButtonSave from "../../components/ButtonComponent/ButtonSave";
import { CardContent, CardTitle } from "../../components/Card-z";
import FileSingle from "../../components/File/Single/input";
import { GroupInput, GroupText } from "../../components/Form";
import HeaderComponent from "../../components/HeaderComponent";
import CONNECT_API from "../../configs/connect";
import { ShowError } from "../../configs/Functions";
import { useIsLoading } from "../../contexts/LoadingContext";


export const CompanyConfigLayout = props => {

    const [layout, setLayout] = useState([]);
    const [fileListLogo, setFileListLogo] = useState([]);
    const [fileListSMTP, setFileListSMTP] = useState([]);
    const [pg, setPg] = useState([]);
    const { register, handleSubmit, reset } = useForm();
    const { setIsLoading } = useIsLoading();


    /**
     * Funções de apoio do componente
     */
    async function get_data_page() {
        try {
            setIsLoading(true);

            const { data } = await CONNECT_API.get(`/empresa/layout`);
            setLayout(data?.layout);
            setPg(data.pg);


            if (typeof (data?.layout?.fileSMTP) == 'object' && data?.layout?.fileSMTP)
                setFileListSMTP([data?.layout?.fileSMTP]);

            if (typeof (data?.layout?.fileLogo) == 'object' && data?.layout?.fileLogo)
                setFileListLogo([data?.layout?.fileLogo]);



            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    async function onSubmit(formData) {
        try {

            const {
                fileLogo,
                fileSMTP,
            } = formData;


            if (fileLogo && typeof (fileLogo) == 'object') formData.fileLogo = fileLogo[0];
            if (fileSMTP && typeof (fileSMTP) == 'object') formData.fileSMTP = fileSMTP[0];
            setIsLoading(true);
            await CONNECT_API.put('/empresa/layout', { ...formData });
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }

    }



    // 
    // START 
    // 

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {

        reset({ ...layout });
        console.log(fileListLogo);

        // eslint-disable-next-line
    }, [layout]);


    return (
        <div className="config-layout-company">
            <HeaderComponent {...pg} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                    <CardTitle>
                        Config. Layout Padrão
                    </CardTitle>
                    <div className="col-lg">
                        <div className="row">
                            <GroupInput name="primary_color" type="color" defaultValue={'#4e73df'} col="3" label="Cor Primária" register={register} />
                            <GroupInput name="secondary_color" type="color" defaultValue={'#858796'} col="3" label="Cor Secundária" register={register} />
                            <div className="col-12">
                                <hr />
                                <h4 className="mb-4">Estilo dos Botões</h4>
                                <div className="row">
                                    <GroupInput name="btn_primary" type="color" defaultValue={'#4e73df'} col="3" label="Botão Primário" register={register} />
                                    <GroupInput name="btn_secondary" type="color" defaultValue={'#858796'} col="3" label="Botão Secundário" register={register} />
                                    <GroupInput name="btn_success" type="color" defaultValue={"#1cc88a"} col="3" label="Botão Sucesso" register={register} />
                                    <GroupInput name="btn_danger" type="color" defaultValue={"#e74a3b"} col="3" label="Botão Alerta" register={register} />
                                    <GroupInput name="btn_warning" type="color" defaultValue={"#f6c23e"} col="3" label="Botão Aviso" register={register} />
                                    <GroupInput name="btn_info" type="color" defaultValue={"#36b9cc"} col="3" label="Botão Informação" register={register} />
                                    <GroupInput name="btn_light" type="color" defaultValue={"#f8f9fc"} col="3" label="Botão Claro" register={register} />
                                    <GroupInput name="btn_dark" type="color" defaultValue={"#5a5c69"} col="3" label="Botão Escuro" register={register} />
                                    <GroupInput name="btn_link" type="color" defaultValue={"#000000"} col="3" label="Botão Link" register={register} />

                                </div>
                            </div>
                            <div className="col-12 mt-4">
                                <div className="form-group">
                                    <ButtonSave type="submit" className="float-right px-5" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-auto">
                        <h4 className="mb-2"><small>Logomarca do Sistema</small></h4>
                        <FileSingle
                            type="image"
                            fileList={fileListLogo}
                            setFileList={setFileListLogo}
                            register={register}
                            reset={reset}
                            label=""
                            name="fileLogo" />
                    </div>
                </CardContent>
                <CardContent>
                    <CardTitle>
                        Config. SMTP
                    </CardTitle>
                    <div className="col-lg">
                        <div className="row">
                            <GroupInput name="bg_color_mail" type="color" defaultValue={'#4e73df'} col="3" label="Cor Fundo" register={register} />
                            <GroupInput name="color_mail" type="color" defaultValue={'#000000'} col="3" label="Cor de Fonte" register={register} />
                            <GroupInput name="mail_from" type="email" col="6" label="E-mail SMTP" register={register} />
                            <div className="col-12 mb-4"></div>
                            <CardTitle>
                                Link no rodapé do E-mail
                            </CardTitle>
                            <GroupInput name="fb" col="6" label="Facebook" register={register} />
                            <GroupInput name="ig" col="6" label="Instagram" register={register} />
                            <GroupInput name="wpp" col="6" label="Whatsapp" register={register} />
                            <GroupInput name="site" col="6" label="Site" register={register} />
                            <GroupText name="info" col="12" row="4" label="Informações complementares no rodapé do e-mail" register={register} />
                            <div className="col-12 mt-4">
                                <div className="form-group">
                                    <ButtonSave type="submit" className="float-right px-5" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-auto mb-4">
                        <h4 className="mb-2"><small>Logomarca no E-mail</small></h4>
                        <FileSingle
                            type="image"
                            fileList={fileListSMTP}
                            setFileList={setFileListSMTP}
                            register={register}
                            reset={reset}
                            label=""
                            name="fileSMTP"
                        />
                    </div>
                </CardContent>
            </form>
        </div>
    )
}


export default CompanyConfigLayout;