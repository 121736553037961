import $ from 'jquery';
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CardContent, CardTitle } from "../../../components/Card-z";
import { GroupCheckBox, GroupInput, GroupSelect, GroupText } from "../../../components/Form";
import CONNECT_API from "../../../configs/connect";
import { existsOrError, SetCoin } from "../../../configs/Functions";
import { useIsLoading } from "../../../contexts/LoadingContext";
import FileArray from "../../../components/File/Array/input";
import { toast } from 'react-toastify';
import { Modal } from '../../../components/Modal-z';
import ButtonSave from '../../../components/ButtonComponent/ButtonSave';

import Select from 'react-select';


export const LancamentoReceita = props => {

    const [pg, setPg] = useState([]);
    const [terminais, setTerminais] = useState([]);
    const [colaboradores, setColaboradores] = useState([]);
    const [tipos_receita, setTipos_receita] = useState([]);
    const [intervalos_recorrencia, setIntervalos_recorrencia] = useState([]);
    const [fileList, setFileList] = useState([]);

    const [fornecedores, setFornecedores] = useState([]);
    const [fornecedorSelected, setFornecedorSelected] = useState([]);


    const { setIsLoading } = useIsLoading();
    const { register, handleSubmit, reset } = useForm();

    ///////
    // CARREGAMENTO DOS DADOS
    ///////
    async function get_data_page() {

        try {
            setIsLoading(true);

            var { data } = await CONNECT_API.get(`/lancamento/receita/add`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setPg(data?.pg);
            setTipos_receita(data?.tipos_receita);
            setIntervalos_recorrencia(data?.intervalos_recorrencia);
            setTerminais(data?.terminais);
            setColaboradores(data?.colaboradores);

            // CARREGANDO PESSOAS
            // eslint-disable-next-line
            var { data } = await CONNECT_API.get(`/search/cadastro?type=select2`);
            setFornecedores(data?.pessoas);

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }

    ///////
    // CONTROLE DOS COLAPSES
    ///////
    async function onCollapse(e) {
        let checked = e.target.checked;
        let id = e.target.id;


        if (checked) {
            $(`div#${id}`).collapse('show');
        } else {
            $(`div#${id}`).collapse('hide');
        }
    }

    ////////
    // SUBMETE O FORMULÁRIO GERAL
    ////////
    async function onSubmit(data) {
        try {
            const {
                tipo_receita,
                vencimento,
                obs,
                faturado,
                terminal_financeiro,
                valor_faturado,
                recorrencia,
                intervalo_recorrencia,
                numero_total_x,
                comissao,
                colaborador_comissao,
                files
            } = data;
            const valor = SetCoin(data?.valor);
            const valor_comissao = SetCoin(data?.valor_comissao);

            existsOrError(tipo_receita, "Informe o tipo da receita!");
            existsOrError(vencimento, "Informe a data de vencimento!");
            existsOrError(fornecedorSelected, "Informe o fornecedor da receita!");
            existsOrError(valor, "Informe o valor da receita!");

            const fields = {
                tipo_receita,
                valor,
                vencimento,
                obs,
                fornecedor: fornecedorSelected,
                files
            }

            /////////
            // SE TEM RECORRÊNCIA
            /////////
            if (recorrencia) {
                existsOrError(numero_total_x, "Informe a quantidade da recorrência!");
                existsOrError(intervalo_recorrencia, "Informe o intervalo da recorrência!");

                fields.intervalo_recorrencia = intervalo_recorrencia;
                fields.numero_total_x = numero_total_x;
            } else {

                delete fields.intervalo_recorrencia;
                delete fields.numero_total_x;
            }

            ///////
            // FATURADO OU NÃO
            ///////
            if (faturado) {
                existsOrError(terminal_financeiro, "Informe o terminal financeiro usado no faturamento!");
                existsOrError(valor_faturado, "Informe o valor faturado!");

                fields.faturado = true;
                fields.valor_faturado = SetCoin(valor_faturado);
                fields.terminal_financeiro = terminal_financeiro;


                ///// Comissão
                if (comissao) {

                    existsOrError(colaborador_comissao, "Colaborador comissionado não informado!");
                    existsOrError(valor_comissao, "Valor da comissão não informado!");
                    fields.comissao = true;
                    fields.colaborador_comissao = colaborador_comissao;
                    fields.valor_comissao = valor_comissao;
                } else {

                    delete fields.comissao;
                    delete fields.colaborador_comissao;
                    delete fields.valor_comissao;
                }


            } else {

                delete fields.faturado;
                delete fields.valor_faturado;
                delete fields.terminal_financeiro;
            }

            setIsLoading(true);
            const ok = await CONNECT_API.post(`${pg.end_point}`, { ...fields });
            setIsLoading(false);

            if (ok) {
                //////// 
                // LINPANDO O FORMULÁRIO
                ///////
                reset({
                    faturado: 0,
                    files: null,
                    fornecedor: null,
                    intervalo_recorrencia: null,
                    numero_total_x: null,
                    obs: null,
                    recorrencia: null,
                    terminal_financeiro: null,
                    tipo_receita: null,
                    valor: null,
                    valor_faturado: null,
                    vencimento: null,
                    _key: null,
                    _type_key: null,
                    comissao: null,
                    valor_comissao: null,
                    colaborador_comissao: null
                });
                $('.collapse').collapse('hide');
                setFileList([]);
            }



        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }

    const fornecedorChange = (selectedOption) => {
        const type = selectedOption.value.split('.');
        setFornecedorSelected({
            tipo: type[0],
            id: type[1]
        });
    };


    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    const page = (
        <div className="lancamento-receita">
            <div className="row justify-content-center">
                <div className="col-12">
                    <form action="" onSubmit={handleSubmit(onSubmit)}>
                        <CardContent>
                            <div className="col-12 text-center mb-3">
                                <i className="las la-arrow-circle-up text-success mr-2"></i>
                                Nova receita
                                <hr />
                            </div>
                            <GroupSelect name="tipo_receita" col="12" label="Tipo da receita" register={register}>
                                {
                                    tipos_receita.map(r => {
                                        return <option value={r.nome} key={r.id}>{r.nome}</option>
                                    })
                                }
                            </GroupSelect>
                            <div className='custom-group group-input col-12 col-lg-12 mb-3' style={{"marginTop": "-14px"}}>
                                <label className="custom-label mb-0" style={{"fontSize":"11px"}}>Fornecedor</label>
                                <Select placeholder="Selecione Fornecedor" options={fornecedores} onChange={fornecedorChange} />
                            </div>
                            <GroupInput name="vencimento" type="date" label="Vencimento" col="12" register={register} />
                            <GroupInput name="valor" label="Valor" col="12" register={register} />
                            <GroupText name="obs" label="Observações" col="12" register={register} />
                            <GroupCheckBox name="recorrencia" id="collapse-recorrencia" value="1" col="12" label="Receita recorrênte" onChange={onCollapse} register={register} />
                            {/** collapse-recorrencia **/}
                            <div className="col-12">
                                <div className="collapse" id="collapse-recorrencia">
                                    <div className="row mt-3">
                                        <CardTitle>
                                            Recorrência
                                        </CardTitle>
                                        <GroupSelect name="intervalo_recorrencia" col="12" label="Intervalo da recorrência" register={register}>
                                            {
                                                intervalos_recorrencia.map(r => {
                                                    return <option value={r.nome} key={r.id}>{r.nome}</option>
                                                })
                                            }
                                        </GroupSelect>
                                        <GroupInput name="numero_total_x" label="Número de vezes" col="12" register={register} />
                                    </div>
                                </div>
                            </div>
                            <GroupCheckBox name="faturado" id="collapse-faturamento" value="1" col="12" label="Receita faturada" onChange={onCollapse} register={register} />
                            <div className="col-12">
                                <div className="collapse" id="collapse-faturamento">
                                    <div className="row mt-3">
                                        <CardTitle>
                                            Faturamento
                                        </CardTitle>
                                        <GroupSelect name="terminal_financeiro" col="12" label="Terminal financeiro" register={register}>
                                            {terminais.length === 0 && <option disabled={true}>Nenhum terminal disponível</option>}
                                            {
                                                terminais?.map(r => {
                                                    return <option value={r.id} key={r.id}>{r.nome}</option>
                                                })
                                            }
                                        </GroupSelect>
                                        <GroupInput name="valor_faturado" label="Valor faturado" col="12" register={register} />

                                        <GroupCheckBox name="comissao" id="collapse-comissao" value="1" col="12" label="Lançar comissão" onChange={onCollapse} register={register} />
                                        <div className="col-12">
                                            <div className="collapse" id="collapse-comissao">
                                                <div className="row mt-3">
                                                    <CardTitle>
                                                        Comissão
                                                    </CardTitle>
                                                    <GroupSelect name="colaborador_comissao" col="12" label="Colaborador comissionado" register={register}>
                                                        {colaboradores?.length === 0 && <option disabled={true}>Nenhum colaborador disponível</option>}
                                                        {
                                                            colaboradores?.map(r => {
                                                                return <option value={r.id} key={r.id}>{r.nome}</option>
                                                            })
                                                        }
                                                    </GroupSelect>
                                                    <GroupInput name="valor_comissao" label="Valor comissionado" col="12" register={register} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <CardTitle>
                                    Anexos
                                </CardTitle>
                                <FileArray register={register} reset={reset} fileList={fileList} setFileList={setFileList} />
                                <ButtonSave type="submit" className="float-right mt-4" />
                            </div>
                        </CardContent>
                    </form>
                </div>
            </div>
        </div>
    );

    return <Modal modalId="lancar-nova-receita" text="Receita" icon="plus-square" className="btn-outline-success btn-lg p-3" sm="true">
        {page}
    </Modal>
}


export default LancamentoReceita;