import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonSave from "../../components/ButtonComponent/ButtonSave";
import { CardContent, CardTitle } from "../../components/Card-z";
import HeaderComponent from "../../components/HeaderComponent";
import { Modal } from "../../components/Modal-z";
import TextEditor from "../../components/TextEditor";
import CONNECT_API from "../../configs/connect";
import { existsOrError } from "../../configs/Functions";
import { useIsLoading } from "../../contexts/LoadingContext";
import $ from 'jquery';

export const Anotacoes = props => {

    // PARAMS
    const tipo = props.match.params.tipo;
    const fornecedor_id = props.match.params.id;

    const [text, setText] = useState('');
    const [pg, setPg] = useState([]);
    const [anotacoes, setAnotacoes] = useState([]);
    const { register, reset, handleSubmit } = useForm();
    const { setIsLoading } = useIsLoading();

    async function get_data_page() {

        try {
            setIsLoading(true);

            const { data } = await CONNECT_API.get(`/fornecedor/${tipo}/${fornecedor_id}/anotacoes`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setAnotacoes(data.anotacoes);
            setPg(data.pg);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        reset({ text: text });
        // eslint-disable-next-line
    }, [text]);


    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    async function onSubmit(data) {
        try {
            const fields = {
                tipo: tipo,
                id_cadastro: fornecedor_id,
                text: data.text,
            }

            setIsLoading(true);
            await CONNECT_API.post(`${pg.end_point}`, { ...fields });
            await get_data_page();
            $('.modal').modal('hide');
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }

    }

    async function onDelete(id) {

        if (!id) return false;

        try {
            setIsLoading(true);
            await CONNECT_API.del(`${pg.end_point}/${id}`);
            await get_data_page();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }

    const modal = <Modal modalId="modal-anotacao" icon="plus-square" text="Nova" className={`text-success`}>
        <CardContent>
            <CardTitle>
                Nova anotação
            </CardTitle>
            <div className="col-12">
                <form action="" name="anotacao" onSubmit={handleSubmit(onSubmit)}>
                    <input type="hidden" name="text" {...register("text")} />
                    <TextEditor text={text} setText={setText} />
                    <ButtonSave type="submit" className="mt-4 float-right" />
                </form>
            </div>
        </CardContent>
    </Modal>

    return (
        <div className="anotacoes">
            <HeaderComponent {...pg} parent={modal} />
            <div className="anotacoes-list">
                <div className="row">
                    {
                        anotacoes?.map(a => {
                            return (
                                <div className="col-12 col-lg-" key={a.id}>
                                    <div className="card">
                                        <div className="card-body" >
                                            <div className="card-body-info">
                                                <div className="row justify-content-between">
                                                    <div className="col-lg-auto">

                                                    </div>
                                                    <div className="col-lg-auto">
                                                        <small>
                                                            Anotação atualizada em {a.updated_at} by <i className="las la-user"></i>{a.colaborador || 'Admin'}
                                                        </small>
                                                        {
                                                            a.delete && <span className="p-2 text-danger" onClick={() => onDelete(a.id)}>
                                                                <i className="las la-trash-alt"></i>
                                                            </span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                            <div className="card-body-text" dangerouslySetInnerHTML={{ __html: a.txt }}></div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
}


export default withRouter(Anotacoes);
