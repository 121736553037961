import React, { useEffect, useState } from "react";
import HeaderComponent from "../../../../components/HeaderComponent";
import CONNECT_API from "../../../../configs/connect";
import { existsOrError, ShowError } from "../../../../configs/Functions";
import { useIsLoading } from "../../../../contexts/LoadingContext";


export const RelatorioFiliadosVendas = props => {

    const { setIsLoading } = useIsLoading();
    // const [links, setLinks] = useState([]);
    const [pg, setPg] = useState([]);

    // PARAMS
    // const plano_id = props.match.params.id;

    async function get_data_page() {

        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/filiado/relatorio/vendas`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            // setLinks(data?.links);
            setPg(data.pg);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);



    return (
        <div className="relatorio-de-vendas">
            <HeaderComponent {...pg} />
        </div>
    );
}


export default RelatorioFiliadosVendas;