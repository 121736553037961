import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ButtonSave from "../../../components/ButtonComponent/ButtonSave";
import { CardContent, CardTitle } from "../../../components/Card-z";
import HeaderComponent from "../../../components/HeaderComponent";
import CONNECT_API from "../../../configs/connect";
import { existsOrError } from "../../../configs/Functions";
import { useIsLoading } from "../../../contexts/LoadingContext";
import { useForm } from 'react-hook-form';
import { GroupInput, GroupSelect } from "../../../components/Form";
import $ from 'jquery';

export const AsaasCadastro = props => {

    const [pg, setPg] = useState([]);
    const [asaas, setAsaas] = useState([]);

    const { setIsLoading } = useIsLoading();
    const { register, handleSubmit, reset } = useForm();


    async function get_data_page() {

        try {
            setIsLoading(true);

            const { data } = await CONNECT_API.get(`/asaas/editar`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setAsaas(data?.asaas);
            setPg(data?.pg);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }

    ////////////////
    // COLLAPSE OPERAÇÕES
    ////////////////
    async function onCollapse(e) {
        let sandbox = Number(e.target.value);

        if (sandbox) {
            $('div#collapse-sandbox').collapse('show');
            $('div#collapse-producao').collapse('hide');
        } else {
            $('div#collapse-sandbox').collapse('hide');
            $('div#collapse-producao').collapse('show');
        }

    }

    //////////////////
    // SUBMIT CADASTRO
    //////////////////
    async function onSubmit(data) {

        try {

            const {
                email,
                senha,
                ativo,
                sandbox,
                token_sandbox,
                token_api,
                multa_tipo,
                multa_valor,
                juro_mes,
                gerar_lancamento_antes
            } = data;

            existsOrError(email, "E-mail de acesso não informado!");
            // existsOrError(senha, "Senha de acesso não informado!");

            if (Number(sandbox)) {
                existsOrError(token_sandbox, "SandBox Token não informado!");
            } else {
                existsOrError(token_api, "API Token não informado!");
            }

            const fields = {
                email,
                senha,
                ativo,
                sandbox,
                token_api,
                token_sandbox,
                multa_tipo,
                multa_valor,
                juro_mes,
                gerar_lancamento_antes
            }
            setIsLoading(true);
            await CONNECT_API.put(`/asaas/editar`, { ...fields });

            await get_data_page();
            setIsLoading(false);


        } catch (error) {
            setIsLoading(false);
            toast.error(error)
        }
    }




    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!asaas) return false;

        reset({ ...asaas });

        // eslint-disable-next-line
    }, [asaas]);

    return (
        <div className="asaas-link">
            <HeaderComponent {...pg}>
                <ButtonSave className="float-right px-5" onClick={handleSubmit(onSubmit)} />
            </HeaderComponent>
            <form action="" onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                    <CardTitle>
                        Cadastro
                    </CardTitle>
                    <GroupInput name="email" type="email" required col="5" label="E-mail de acesso" register={register} />
                    <GroupInput name="senha" col="5" label="Senha de acesso" register={register} />
                    <GroupSelect name="ativo" col="2" label="Situação" register={register}>
                        <option value="0">Inativo</option>
                        <option value="1">Ativo</option>
                    </GroupSelect>

                    <GroupSelect name="multa_tipo" col="3" label="Tipo Multa [ATRASO]" register={register}>
                        <option value="FIXED">Valor Fixo</option>
                        <option value="PERCENTAGE">Percentual</option>
                    </GroupSelect>
                    <GroupInput name="multa_valor" col="3" label="Valor Multa [ATRASO]" register={register} />
                    <GroupInput name="juro_mes" col="3" label="Percentual de Juros ao mês" register={register} />

                    <GroupSelect name="gerar_lancamento_antes" col="3" label="Gerar Lançamento quantos dias antes? [ATRASO]" register={register}>
                        <option value="2">2 dias</option>
                        <option value="5">5 dias</option>
                        <option value="10">10 dias</option>
                        <option value="15">15 dias</option>
                    </GroupSelect>

                    <GroupSelect name="sandbox" col="3" label="Operação em" onChange={onCollapse} register={register}>
                        <option value="">Produção</option>
                        <option value="1">SandBox</option>
                    </GroupSelect>
                    <div className="col-9">
                        <div className={`collapse collapse-sandbox ${asaas?.sandbox ? 'show' : ''}`} id="collapse-sandbox">
                            <div className="row">
                                <GroupInput name="token_sandbox" col="12" label="SandBox Token" register={register} />
                            </div>
                        </div>
                        <div className={`collapse collapse-producao ${asaas?.sandbox ? '' : 'show'}`} id="collapse-producao">
                            <div className="row">
                                <GroupInput name="token_api" col="12" label="API Token" register={register} />
                            </div>
                        </div>
                        <ButtonSave className="float-right mt-3 px-5" type="submit" />
                    </div>
                </CardContent>
            </form>
        </div>
    );
}

export default AsaasCadastro;