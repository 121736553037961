import { createContext, useContext, useState } from 'react';


const LoadingContext = createContext({
    isLoading: false,
    setIsLoading: null,
    isCollaborator: [],
    setIsCollaborator: null,
    isCompany: [],
    setIsCompany: null,
    isNav: [],
    setIsNav: null,
    isNotificacao: [],
    setIsNotificacao: null
});


export function LoadingProvider({ children }) {

    const [isLoading, setIsLoading] = useState(false);
    const [isCollaborator, setIsCollaborator] = useState(undefined);
    const [isCompany, setIsCompany] = useState([]);
    const [isNav, setIsNav] = useState([]);
    const [isNotificacao, setIsNotificacao] = useState([]);

    const value = {
        isLoading,
        setIsLoading,
        isCollaborator,
        setIsCollaborator,
        isCompany,
        setIsCompany,
        isNav,
        setIsNav,
        isNotificacao,
        setIsNotificacao
    };

    return (
        <LoadingContext.Provider value={value}>{children}</LoadingContext.Provider>
    )
}

export function useIsLoading() {
    const context = useContext(LoadingContext);

    if (!context) {
        throw new Error('Context Carregamento não definida....');
    }
    return context;
}