import React from "react";
import './Style.css';

export const CardPlanoFiliado = props => {

    /*
    beneficio_filiado: null
    data_cadastro: "09/08/2022"
    data_vencimento: "09/08/2023"
    plano: "Mensal"
    tipo_vigencia: "Mensal"
    valor: "R$ 19,90"
    valor_adesao: "R$ 22,00"
    vigencia: 12
    */

    const p = props;

    return (
        <div className="card-filiado-plano">
            <div className="card">
                <div className="card-body">
                    <div className="text-muted plano-header">
                        <div className="d-flex align-items-center">
                            <i className="las la-globe text-primary mr-2"></i>
                            <b>PLANO</b>
                        </div>
                        <hr className="mt-1 mb-2" />
                    </div>
                    <div className="plano-body text-muted">
                        <div className="plano py-3 d-flex align-items-center justify-content-between">
                            <div className="icon">
                                <i className="las la-globe-americas mr-2"></i>
                                Plano
                            </div>
                            <span className="value">{p?.plano}</span>
                        </div>
                        <div className="vigencia py-3 d-flex align-items-center justify-content-between">
                            <div className="icon">
                                <i className="las la-hourglass-half mr-2"></i>
                                Vigência
                            </div>
                            <span className="value">{p?.str_vigencia}</span>
                        </div>
                        <div className="beneficio py-3 d-flex align-items-center justify-content-between">
                            <div className="icon">
                                <i className="las la-hand-holding-usd mr-2"></i>
                                Benefício
                            </div>
                            <span className="value">{p?.beneficio_filiado || '-'}</span>
                        </div>
                        <div className="cadastro py-3 d-flex align-items-center justify-content-between">
                            <div className="icon">
                                <i className="las la-calendar-check mr-2"></i>
                                Cadastrado
                            </div>
                            <span className="value">{p?.data_cadastro || '-'}</span>
                        </div>
                        <div className="cadastro py-3 d-flex align-items-center justify-content-between">
                            <div className="icon">
                                <i className="las la-calendar-times mr-2"></i>
                                Vencimento
                            </div>
                            <span className="value">{p?.data_vencimento || '-'}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardPlanoFiliado;