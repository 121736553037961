import "./styles/Header.css";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useIsLoading } from "../../contexts/LoadingContext";
import CONNECT_API from "../../configs/connect";
import { ShowError } from "../../configs/Functions";
import $ from "jquery";
import CardNotificacao from "./notificacao/CardNotificacao";

import Avatar from "../../images/default-avatar.png";
const nameToken = process.env.REACT_APP_NAMETOKEN;

function onControlerNav() {
    document.querySelector("#content-page").classList.toggle("active");
    document.querySelector("#content-page").classList.toggle("show");
}

function logout() {
    localStorage.removeItem(nameToken);
    window.location.href = "/"; // carrega a pagina normal
}

export const Header = (props) => {
    const {
        setIsCollaborator,
        setIsCompany,
        setIsLoading,
        setIsNav,
        isCompany,
        isCollaborator,
    } = useIsLoading();

    const [conta, setConta] = useState([]);

    //
    // funções de apoio
    //
    async function get_data_page() {
        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get("/dashboard");
            setIsCollaborator(data?.colaborador);
            setIsCompany(data?.empresa);
            setIsNav(data?.nav_menu);
            setConta(data?.conta);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }


    useEffect(()=>{

        if( isCompany?.id ){
            $('[name="defined-layout"]').remove();
            $('body').append(`
                <style name="defined-layout">
                    :root{
                        --bg-primary: ${isCompany?.colors?.primary_color || '#1868b9'};
    
                        --color-primary:${isCompany?.colors?.primary_color || '#00427b'};
                        --color-secondary:${isCompany?.colors?.secondary_color || '#1868b9'};
                        --primary-color:${isCompany?.colors?.primary_color || '#00427b'};
                        --secondary-color:${isCompany?.colors?.secondary_color || '#1868b9'};
                        
                        --btn-primary: ${isCompany?.colors?.btn_primary || '#0d6efd'};
                        --btn-secondary: ${isCompany?.colors?.btn_secondary || '#6c757d'};
                        --btn-success: ${isCompany?.colors?.btn_success || '#198754'};
                        --btn-danger: ${isCompany?.colors?.btn_danger || '#dc3545'};
                        --btn-warning: ${isCompany?.colors?.btn_warning || '#ffc107'};
                        --btn-info: ${isCompany?.colors?.btn_info || '#0dcaf0'};
                        --btn-light: ${isCompany?.colors?.btn_light || '#f8f9fa'};
                        --btn-dark: ${isCompany?.colors?.btn_dark || '#212529'};
                        --btn-link: ${isCompany?.colors?.btn_link || '#00000000'};
                    }
                </style>
            `);

            setTimeout(()=>{
                $('body').removeClass('loading').removeAttr('style');
            },150);
        }

    },[isCompany]);

    /**
     *
     *
     * Hocks do módulo
     *
     */
    // Carregamento dos dados da pagina
    useEffect(() => {
        const token = localStorage.getItem(nameToken);
        if (!token) window.location.reload();

        $("#content-page .nav-bar").hover(
            function () {
                if (!$("#content-page").hasClass("show"))
                    $("#content-page").addClass("active");
            },
            function () {
                if (!$("#content-page").hasClass("show"))
                    $("#content-page").removeClass("active");
            }
        );
        get_data_page();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="header header-nav text-light">
            <div className="container-off">
                <nav className="navbar navbar-light py-2">
                    <div className="nav-controler d-flex align-items-center">
                        <button
                            className="btn btn-icon"
                            onClick={onControlerNav}
                        >
                            <i className="las la-bars"></i>
                        </button>
                        <div className="nav-title ml-3">
                            <h4 className="m-0">{isCompany?.nome}</h4>
                        </div>
                    </div>
                    <div className="nav-user">
                        <div className="row align-items-center">
                            <div className="col-auto d-none d-lg-block-- d-none">
                                <div className="form-search-nav">
                                    <form
                                        className="form-inline my-2 my-lg-0"
                                        action="#"
                                    >
                                        <input
                                            className="form-control mr-sm-2"
                                            type="search"
                                            placeholder="Buscar por CPF"
                                            aria-label="Search"
                                        />
                                        <button
                                            className="btn btn-outline-light my-2 my-sm-0"
                                            type="submit"
                                        >
                                            <i className="las la-search"></i>
                                        </button>
                                    </form>
                                </div>
                            </div>
                            <div className="col-auto">
                                <CardNotificacao />
                            </div>
                            <div className="col-auto">
                                <div className="user-account">
                                    <div className="user-accont-container">
                                        <div
                                            className="nav-link dropdown-toggle text-light p-0"
                                            id="user-accont"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <img
                                                src={conta?.img_url || Avatar}
                                                size="35"
                                                width="35"
                                                height="35"
                                                className="rounded img-fluid avatar"
                                                alt=""
                                            />
                                        </div>
                                        <div
                                            id="dropdown-user-accont"
                                            className="dropdown-menu p-0"
                                            aria-labelledby="user-accont"
                                        >
                                            <div className="card w-100 mb-0">
                                                <div className="card-body">
                                                    <div className="card-img text-center mb-2">
                                                        <img
                                                            className=" avatar"
                                                            size="90"
                                                            width="90"
                                                            height="90"
                                                            src={
                                                                conta?.img_url ||
                                                                Avatar
                                                            }
                                                            alt=""
                                                        />
                                                    </div>
                                                    <h5 className="card-title text-center">
                                                        {isCollaborator?.nome}
                                                    </h5>
                                                    <p className="card-text text-center">
                                                        <small className="d-block">
                                                            {conta?.cargo}
                                                        </small>
                                                        <small className="d-block">
                                                            {conta?.email}
                                                        </small>
                                                    </p>
                                                </div>
                                                <ul className="list-group list-group-flush">
                                                    {conta?.menus?.map((m) => {
                                                        return (
                                                            <li
                                                                className="list-group-item"
                                                                key={m?.id}
                                                            >
                                                                <Link
                                                                    className="btn w-100 border-0 text-dark p-0 m-0"
                                                                    to={
                                                                        m?.url ||
                                                                        "/"
                                                                    }
                                                                >
                                                                    <div className="form-row align-items-center justify-content-center">
                                                                        <div className="col-auto">
                                                                            <i
                                                                                style={{
                                                                                    fontSize:
                                                                                        "40px",
                                                                                }}
                                                                                className={`las la-${m?.icon}`}
                                                                            ></i>
                                                                        </div>
                                                                        <div className="col-auto text-left">
                                                                            <span className="m-0">
                                                                                {
                                                                                    m?.label
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            </li>
                                                        );
                                                    })}
                                                    <li
                                                        className="list-group-item"
                                                        onClick={logout}
                                                    >
                                                        <button className="btn w-100 border-0 text-dark p-0 m-0">
                                                            <div className="form-row align-items-center justify-content-center">
                                                                <div className="col-auto">
                                                                    <i
                                                                        style={{
                                                                            fontSize:
                                                                                "40px",
                                                                        }}
                                                                        className={`las la-sign-out-alt`}
                                                                    ></i>
                                                                </div>
                                                                <div className="col-auto text-left">
                                                                    <span className="m-0">
                                                                        Sair
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
};

export default Header;
