import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useIsLoading } from "../../../../contexts/LoadingContext";
import CONNECT_API from "../../../../configs/connect";
import { Modal } from "../../../../components/Modal-z";
import { CardContent, CardTitle } from "../../../../components/Card-z";
import { GroupInput, GroupSelect } from "../../../../components/Form";
import { ShowError } from "../../../../configs/Functions";



export const ModalEditBloco = props => {

    const { handleSubmit, reset, register } = useForm();
    const { setIsLoading } = useIsLoading();
    const bloco = props.bloco;


    async function onSubmit(formData) {

        try {
            // existsOrError(formData.name, 'Nome não informado!');
            setIsLoading(true);

            await CONNECT_API.put(`/portal-do-filiado/painel?bloco=${bloco?.id}`, { ...formData });
            if (typeof (props?.get_data_page) == 'function') props.get_data_page();

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    async function onDelete() {

        try {
            setIsLoading(true);
            await CONNECT_API.del(`/portal-do-filiado/painel?bloco=${bloco?.id}`);
            if (typeof (props?.get_data_page) == 'function') props.get_data_page();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    useEffect(() => {
        reset({ ...bloco });
        // eslint-disable-next-line
    }, [bloco]);



    return <Modal modalId={`modal-edit-bloco-${bloco?.id}`} text={'Bloco'} icon="edit" className="btn btn-sm btn-outline-success p-0 px-1 ml-1" sm={true} modalHide={() => reset()}>
        <CardContent>
            <CardTitle>
                {'Editando Bloco - ' + bloco?.type}
            </CardTitle>
            <div className="col-12">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <GroupSelect name="type" col="12" required label="Tipo" register={register}>
                            <option value="Container">Container</option>
                            <option value="Card">Card</option>
                            <option value="Slide">Slide</option>
                        </GroupSelect>
                        {/* <GroupInput name="name" col="12" required label="Nome" register={register} /> */}
                        {
                            (bloco.type === 'Card' || bloco.type === 'Container') &&
                            <>
                                <GroupSelect name="colLg" col="12" required label="Número de conteúdos por linha (Desktop)" register={register}>
                                    <option value="12">1</option>
                                    <option value="6">2</option>
                                    <option value="4">3</option>
                                    <option value="3">4</option>
                                </GroupSelect>
                                <GroupSelect name="colSm" col="12" required label="Número de conteúdos por linha (Mobile)" register={register}>
                                    <option value="12">1</option>
                                    <option value="6">2</option>
                                    <option value="4">3</option>
                                    <option value="3">4</option>
                                </GroupSelect>
                            </>
                        }
                        {
                            bloco.type === 'Slide' &&
                            <>
                                <GroupSelect name="lgPerPage" col="12" required label="Número de conteúdos por linha (Desktop)" register={register}>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                </GroupSelect>
                                <GroupSelect name="smPerPage" col="12" required label="Número de conteúdos por linha (Mobile)" register={register}>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                </GroupSelect>
                                <GroupSelect name="viewType" col="12" required label="Efeito visual" register={register}>
                                    <option value="slide">Slide</option>
                                    <option value="loop">Loop</option>
                                    <option value="fade">Esmaecer</option>
                                </GroupSelect>
                            </>
                        }
                        <GroupSelect name="mb" col="12" required label="Margem Final" register={register}>
                            <option value="2">Pequena</option>
                            <option value="4">Média</option>
                            <option value="5">Grande</option>
                        </GroupSelect>

                        <GroupInput name="class" col="12" label="Classe Personalizada" register={register} />
                        <GroupInput name="or" col="12" label="Ordem de Exibição" register={register} />
                    </div>
                    <div className="form-group text-center">
                        <button type="button" onClick={onDelete} className="btn btn-danger bg-gradient mr-2">Excluir</button>
                        <button type="submit" className="btn btn-primary bg-gradient">Atualizar</button>
                    </div>
                </form>
            </div>
        </CardContent>
    </Modal>
}

export default ModalEditBloco;