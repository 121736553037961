import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ButtonSave from "../../../../../../components/ButtonComponent/ButtonSave";
import { GroupSelectFilter } from "../../../../../../components/Form";
import CONNECT_API from "../../../../../../configs/connect";
import {
    existsOrError,
    formatMatricula,
    ShowError,
    swalConfirm,
} from "../../../../../../configs/Functions";
import { useIsLoading } from "../../../../../../contexts/LoadingContext";
import ModalNovoTitular from "../../novo_titular/CardNovoTitular";

export const CardChangeTitular = (props) => {
    const [filiados, setFiliados] = useState([]);
    const [titular, setTitular] = useState([]);

    const { setIsLoading } = useIsLoading();
    const { handleSubmit, register } = useForm();

    async function get_data_page() {
        try {
            const id = props.match.params.id;

            setIsLoading(true);
            const { data } = await CONNECT_API.get(
                `filiado/${id}/configuracao/mudanca-titularidade`
            );
            setFiliados(data?.filiados);
            setTitular(data?.titular);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    async function onSubmit(formData) {
        try {
            const id = props.match.params.id;
            const titular = formData["select-titular"][0];

            existsOrError(titular, "Novo titular não definido!");

            const ok = await swalConfirm({
                icon: "warning",
                text: "Deseja alterar a titularidade?",
                btnText: "Sim",
                btnText2: "Não"
            });

            if (ok?.isConfirmed) {
                setIsLoading(true);
                await CONNECT_API.put(
                    `filiado/${id}/configuracao/mudanca-titularidade`,
                    { titular }
                );
                setIsLoading(false);
                await get_data_page();
            }
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <div className="card">
                <div className="card-body">
                    <div className="card_header text-uppercase ">
                        <div className="row justify-content-between">
                            <div className="col-lg-auto">
                                <i className="las la-user-alt"></i>
                                <b> Mudança de titularidade</b>
                            </div>
                            <div className="col-lg-auto">
                                <ModalNovoTitular
                                    {...props}
                                    modalId="modal-novo-titular"
                                    text="SER UM TITULAR"
                                    icon="user-check"
                                    className="font-weight-bold"
                                    loaded={props.loaded}
                                />
                            </div>
                        </div>
                        <hr className="mt-1" />
                    </div>
                    <div className="mb-4">
                        <div className="mb-1">
                            Titular
                        </div>
                        <div
                            className={`border rounded p-3 m-0 w-100 text-left border-primary `}
                        >
                            <div
                                className="form-row justify-content-between"
                                style={{ fontSize: "11px" }}
                            >
                                <div className="col-lg-auto">
                                    <div className="mb-1">
                                        Nome <br />
                                        <b>{titular?.nome}</b>
                                    </div>
                                    <div className="mb-1">
                                        Matrícula <br />
                                        <b>
                                            {formatMatricula(
                                                titular?.matricula
                                            )}
                                        </b>
                                    </div>
                                    <div className="mb-1">
                                        CPF <br />
                                        <b>{titular?.cpf}</b>
                                    </div>
                                </div>

                                <div className="col-lg-auto">
                                    <div className="mb-1">
                                        Data de nascimento <br />
                                        <b>{titular?.nascimento}</b>
                                    </div>
                                    <div>
                                        Data do cadastro <br />
                                        <b>{titular?.data_cadastro}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="change mb-3">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <GroupSelectFilter
                                    name="select-titular"
                                    label="Novo Titular"
                                    textFilter="Buscar por nome"
                                    col="12"
                                    register={register}
                                >
                                    {filiados?.map((f) => (
                                        <option key={f?.id} value={f?.id}>
                                            {f?.nome}
                                        </option>
                                    ))}
                                </GroupSelectFilter>
                            </div>
                            <ButtonSave
                                type="submit"
                                className="btn btn-primary float-right"
                            />
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CardChangeTitular;
