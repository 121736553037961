import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ImputMask from 'inputmask';
import { withRouter } from "react-router-dom";
import { useIsLoading } from "../../../../contexts/LoadingContext";
import CONNECT_API from "../../../../configs/connect";
import { existsOrError, formatCPF, formatDate, SetDate, SetNumber } from "../../../../configs/Functions";
import HeaderComponent from "../../../../components/HeaderComponent";
import ButtonSave from "../../../../components/ButtonComponent/ButtonSave";
import NavVerticalButton from "../../../../components/NavBarVertical/ButtonLink";
import { GroupInput, GroupSelect } from "../../../../components/Form";
import { CardContent, CardTitle } from "../../../../components/Card-z";
import FileSingle from "../../../../components/File/Single/input";



export const FiliadoEditar = props => {

    const { register, handleSubmit, reset } = useForm();
    const { setIsLoading } = useIsLoading();
    const [filiado, setFiliado] = useState([]);
    const [situacao, setSituacao] = useState([]);
    const [pg, setPg] = useState([]);

    const [fileListIcon, setFileListIcon] = useState([]);

    // PARAMS
    const filiado_id = props.match.params.id;

    async function get_data_page() {

        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/filiado/${filiado_id}/editar`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setFiliado(data.filiado);
            setSituacao(data.situacao);
            setPg(data.pg);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    async function get_data_cep(e) {

        const cep = SetNumber(e.target.value);
        if (!cep) return false;

        if (cep.length === 8) {

            setIsLoading(true);

            const json = await CONNECT_API.getCep(cep);

            reset({
                uf: json?.data.uf,
                cidade: json?.data.localidade,
                bairro: json?.data.bairro,
                logradouro: json?.data.logradouro,
                complemento: json?.data.complemento
            })

            setIsLoading(false);
        }
    }

    async function onSubmit(data_form) {

        try {

            existsOrError(data_form.nome, "Nome não informado!");

            const {
                nome,
                rg_em,
                data,
                cidade,
                logradouro,
                complemento,
                bairro,
                uf,
                instagram,
                linkedin,
                facebook,
                email,
                situacao_id,
                icon
            } = data_form;

            const numero = SetNumber(data_form.numero);
            const cep = SetNumber(data_form.cep);
            const rg = SetNumber(data_form.rg);
            const tel = SetNumber(data_form.tel);
            const whats = SetNumber(data_form.whats);

            const fields = {
                nome,
                data,
                rg,
                rg_em,
                cidade,
                logradouro,
                complemento,
                bairro,
                uf,
                instagram,
                linkedin,
                facebook,
                email,
                numero,
                cep,
                tel,
                whats,
                icon,
                situacao_id
            }

            setIsLoading(true);
            const ok = await CONNECT_API.put(`${pg.end_point}`, { ...fields });
            if (ok) await get_data_page();
            setIsLoading(false);

        } catch (error) {
            toast.error(error);
        }

    }

    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {

        const set_filiado = filiado;
        set_filiado.data = SetDate(set_filiado.data);
        reset({ ...set_filiado });

        if (typeof (set_filiado?.fileIcon) == 'object' && set_filiado?.fileIcon) setFileListIcon([set_filiado?.fileIcon]);

        // eslint-disable-next-line
    }, [filiado]);

    //Masc
    useEffect(() => {
        ImputMask({ "mask": '99.999.999/9999-99' }).mask('[name="cnpj"]');
        ImputMask({ "mask": '(99) 99999-9999' }).mask('[type="tel"]');
        ImputMask({ "mask": '99999-999' }).mask('[name="cep"]');
        ImputMask({ "mask": '999.999.999-99' }).mask('[name*="cpf"]');
        // eslint-disable-next-line
    }, []);


    return (
        <div className="filiado-ditar pb-2">
            <HeaderComponent {...pg}>
                <ButtonSave onClick={handleSubmit(onSubmit)} />
            </HeaderComponent>
            <form action="" onSubmit={handleSubmit(onSubmit)}>
                <div className="row align-items-start">
                    <div className="col-lg-3 position-sticky">
                        <div className="nav flex-column nav-pills" role="tablist" aria-orientation="vertical">
                            <NavVerticalButton href="#resumo" label="Resumo" icon="file" active="true" />
                            <NavVerticalButton href="#cadastro" label="Cadastro" icon="file-alt" />
                            <NavVerticalButton href="#endereco" label="Endereço" icon="map-marked-alt" />
                            <NavVerticalButton href="#contato" label="Contato" icon="share-alt-square" />
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="resumo" role="tabpanel">
                                <CardContent>
                                    <div className="col-12">
                                        {filiado?.situacao?.toUpperCase() === "REGULAR"
                                            ?
                                            <div className="text-center mb-3 text-success" style={{ "fontSize": "20px" }}>
                                                <b>
                                                    {filiado?.situacao}
                                                    <i className="las la-check-square ml-2"></i>
                                                </b>
                                            </div>
                                            :
                                            <div className="text-center mb-3 text-danger" style={{ "fontSize": "20px" }}>
                                                <b>
                                                    {filiado?.situacao}
                                                    <i className="las la-exclamation-triangle mf-2"></i>
                                                </b>
                                            </div>

                                        }
                                        <div className="row justify-content-between mb-2">
                                            <div className="col-auto">Nome</div>
                                            <div className="text-right">{filiado?.nome}</div>
                                        </div>
                                        <div className="row justify-content-between mb-2">
                                            <div className="col-auto">Matricula</div>
                                            <div className="text-right"><small className="badge badge-danger px-2">{filiado?.matricula}</small></div>
                                        </div>
                                        <div className="row justify-content-between mb-2">
                                            <div className="col-auto">CPF</div>
                                            <div className="text-right">{formatCPF(filiado?.cpf)}</div>
                                        </div>
                                        <div className="row justify-content-between mb-2">
                                            <div className="col-auto">Data de nascimento</div>
                                            <div className="text-right">{formatDate(filiado?.data)}</div>
                                        </div>
                                        <div className="row justify-content-between mb-2">
                                            <div className="col-auto">E-mail</div>
                                            <div className="text-right">{filiado?.email}</div>
                                        </div>
                                        <div className="row justify-content-between mb-2">
                                            <div className="col-auto">Telefone</div>
                                            <div className="text-right">{filiado?.tel}</div>
                                        </div>
                                        <div className="row justify-content-between mb-2">
                                            <div className="col-auto">Whatsapp</div>
                                            <div className="text-right">{filiado?.whats}</div>
                                        </div>


                                        <h2 className="mt-4">Endereço</h2>
                                        <hr />
                                        <div className="row justify-content-between mb-2">
                                            <div className="col-auto">Logradouro</div>
                                            <div className="text-right">{filiado?.logradouro}</div>
                                        </div>
                                        <div className="row justify-content-between mb-2">
                                            <div className="col-auto">Bairro</div>
                                            <div className="text-right">{filiado?.bairro}</div>
                                        </div>
                                        <div className="row justify-content-between mb-2">
                                            <div className="col-auto">Número</div>
                                            <div className="text-right">{filiado?.numero}</div>
                                        </div>
                                        <div className="row justify-content-between mb-2">
                                            <div className="col-auto">Estado</div>
                                            <div className="text-right">{filiado?.uf}</div>
                                        </div>
                                        <div className="row justify-content-between mb-2">
                                            <div className="col-auto">Cidade</div>
                                            <div className="text-right">{filiado?.cidade}</div>
                                        </div>
                                        <div className="row justify-content-between mb-2">
                                            <div className="col-auto">Complemento</div>
                                            <div className="text-right">{filiado?.complemento}</div>
                                        </div>
                                    </div>
                                </CardContent>
                            </div>

                            <div className="tab-pane fade" id="cadastro" role="tabpanel">
                                <CardContent>
                                    <CardTitle>
                                        Cadastro
                                    </CardTitle>
                                    <div className="col-3">
                                        <FileSingle
                                            type="image"
                                            fileList={fileListIcon}
                                            setFileList={setFileListIcon}
                                            register={register}
                                            reset={reset}
                                            label=""
                                            name="icon" />
                                    </div>
                                    <div className="col-9">
                                        <div className="row">
                                            <GroupInput col={situacao?.length ? '8' : '12'} name="nome" label="Nome" id="" register={register} />
                                            {situacao?.length
                                                ?
                                                <GroupSelect name="situacao_id" col="4" label="Situação" classNameGroup="mb-0" register={register}>
                                                    {
                                                        situacao?.map(r => {
                                                            return <option value={r.id} key={r.id}>{r.nome}</option>
                                                        })
                                                    }
                                                </GroupSelect>
                                                :
                                                ''
                                            }
                                            <GroupInput col="6" name="cpf" label="CPF" id="" disabled register={register} />
                                            <GroupInput col="6" name="data" type="date" label="Data Nascimento" id="" register={register} />
                                            <GroupInput col="6" name="rg" label="RG" id="" register={register} />
                                            <GroupInput col="6" name="rg_em" label="Emissor" id="" register={register} />
                                        </div>
                                    </div>
                                </CardContent>
                            </div>

                            <div className="tab-pane fade" id="endereco" role="tabpanel">
                                <CardContent>
                                    <CardTitle>
                                        Endereço
                                    </CardTitle>
                                    <GroupInput col="3" name="cep" label="CEP" id="" onKeyUp={get_data_cep} register={register} />
                                    <GroupInput col="9" name="logradouro" label="Rua" id="" register={register} />
                                    <GroupInput col="4" name="bairro" label="Bairro" id="" register={register} />
                                    <GroupInput col="4" name="cidade" label="Cidade" id="" register={register} />
                                    <GroupInput col="2" name="numero" label="Número" id="" register={register} />
                                    <GroupInput col="2" name="uf" label="UF" id="" register={register} />
                                    <GroupInput col="12" name="complemento" label="Complemento" id="" register={register} />
                                </CardContent>
                            </div>


                            <div className="tab-pane fade" id="contato" role="tabpanel">
                                <CardContent>
                                    <CardTitle>
                                        Contato
                                    </CardTitle>
                                    <GroupInput col="12" name="email" label="E-mail" id="" register={register} />
                                    <GroupInput col="6" name="tel" label="Telefone" type="tel" id="" register={register} />
                                    <GroupInput col="6" name="whats" label="Whatsapp" type="tel" id="" register={register} />
                                    <GroupInput col="12" name="facebook" label="Facebook" id="" register={register} />
                                    <GroupInput col="12" name="instagram" label="Instagram" id="" register={register} />
                                    <GroupInput col="12" name="linkedin" label="Linkedin" id="" register={register} />
                                </CardContent>
                            </div>
                        </div>
                        <ButtonSave type="submit" className="float-right" />
                    </div>
                </div>
            </form>
        </div>
    );
}


export default withRouter(FiliadoEditar);