import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { CardContent } from "../../../components/Card-z";
import HeaderComponent from "../../../components/HeaderComponent";
import { TableHover, Tbody, Td, Th, Thead } from "../../../components/Table-z";
import CONNECT_API from "../../../configs/connect";
import { ShowError } from "../../../configs/Functions";
import { useIsLoading } from "../../../contexts/LoadingContext";
import ModalAddLink from "./modais/ModalAddLink";


// HOME DA PÁGINA DO MEUS LINKS
export const MeusLinksIndex = props => {

    const [pg, setPg] = useState([]);
    const [links, setLinks] = useState([]);

    const { setIsLoading, isCompany } = useIsLoading();


    async function get_data_page() {
        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/vendas-online/meus-links?view=list`);
            setPg(data?.pg);
            setLinks(data?.links);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }


    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);



    function shareMob(link) {
        const shareData = {
            title: "Solicite seu Cartão",
            text: "Vem fazer parte da familia " + isCompany?.nome + ".",
            url: link.url_share
        }
        navigator.share(shareData);
        return false;
    }

    // 
    // Função de copiar o link para a área de transferência
    function copyUrl(link) {
        if (link.url_share) {
            navigator.clipboard.writeText(link.url_share);
            toast.success('Link Copiado para a área de transferência!');
        } else {
            toast.error('Que pena, não foi possível copiar o link!');
        }
    }


    return (
        <>
            <div className="meus-links">
                <HeaderComponent {...pg}>
                    <ModalAddLink {...props} />
                </HeaderComponent>
                <div className="table-list">
                    <CardContent>
                        <div className="col-12">
                            {links?.length ?
                                <TableHover className="mb-lg-0 text-left">
                                    <Thead
                                        th={[
                                            "Situação",
                                            "Nome",
                                            "Hash",
                                            "Criado em / por",
                                            "Ação",
                                        ]}
                                    />
                                    <Tbody>
                                        {
                                            links?.map((f) => {
                                                return (
                                                    <tr key={f?.id}>
                                                        <Th>
                                                            <span
                                                                className={`badge badge-${!f?.ativo
                                                                    ? "danger"
                                                                    : "primary"
                                                                    } mr-1 px-2`}
                                                            >
                                                                {!f?.ativo ? "Inativo" : "Ativo"}
                                                            </span>
                                                        </Th>
                                                        <Td>
                                                            <Link
                                                                to={f?.url || "/"}
                                                                className="btn btn-sm m-0 text-dark px-0"
                                                            >
                                                                {f?.nome}
                                                            </Link>
                                                        </Td>
                                                        <Td>
                                                            {f?.hash}
                                                        </Td>
                                                        <Td>
                                                            {f?.created_at} <br />
                                                            {f?.vendedor}
                                                        </Td>
                                                        <Td>
                                                            <div className="group-button">
                                                                <button className="btn btn-outline-success btn-sm mr-2" title="Compartilhar Link" disabled={f.url ? false : true} onClick={() => shareMob(f)}><i className="las la-share-alt"></i></button>
                                                                <button className="btn btn-outline-primary btn-sm mr-2" title="Copiar Link" disabled={f.url ? false : true} onClick={() => copyUrl(f)}><i className="las la-copy"></i></button>
                                                                <Link to={{ pathname: f.url || '/' }} className="btn btn-danger btn-sm" title="Editar Link"><i className="las la-edit"></i></Link>
                                                            </div>
                                                        </Td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </Tbody>
                                </TableHover>
                                :
                                <div className="text-center"><i>Sem Links cadastrado</i></div>
                            }
                        </div>
                    </CardContent>
                </div>
            </div>
        </>
    );
}


export default MeusLinksIndex;