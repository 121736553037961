import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { CardContent } from "../../../../components/Card-z";
import { GroupInput, GroupSelect } from "../../../../components/Form";
import HeaderComponent from "../../../../components/HeaderComponent";
import { TableFooter, TableHover, Tbody, Td, Th, Thead } from "../../../../components/Table-z";
import CONNECT_API from "../../../../configs/connect";
import { existsOrError, formatDate, ShowError } from "../../../../configs/Functions";
import { useIsLoading } from "../../../../contexts/LoadingContext";

export const ListLeadSearch = (props) => {
    const history = useHistory();
    var params = Object.fromEntries(
        new URLSearchParams(history.location.search)
    );

    const [pg, setPg] = useState([]);
    const [situacao, setSituacao] = useState([]);
    const [resp, setResp] = useState([]);
    const [leads, setLeads] = useState([]);
    const [pagination, setPagination] = useState([]);
    const [page, setPage] = useState(params?.page || 1);

    const { setIsLoading } = useIsLoading();
    const { register, handleSubmit, reset, getValues } = useForm();

    const hj = new Date().toLocaleDateString('pt-BR').split('/').reverse().join('-');

    // DEFINE O GET NA URL A CADA ALTERAÇÃO EM CAMPOS NO FORM
    async function setSearchURL(e) {
        const formData = getValues();
        if (e) formData[e.target.name] = e.target.value;
        const { situacao_id, tipo_cadastro, q, data_in, data_out, res_id } = formData;

        var query = `?page=${page}`;
        if (situacao_id) query += `&s=${situacao_id}`;
        if (q) query += `&q=${q}`;
        if (tipo_cadastro) query += `&tc=${tipo_cadastro}`;
        if (data_in) query += `&din=${data_in}`;
        if (data_out) query += `&dout=${data_out}`;
        if (res_id) query += `&r=${res_id}`;
        history.replace({ search: query });
        // history.push({ search: query });
    }


    async function onSubmit() {

        // FORMATA A BUSCA DE ACORDO COM A URL
        const data = Object.fromEntries(
            new URLSearchParams(history.location.search)
        );

        const { s, tc, q, din, dout, r } = data;

        var query = `?page=${page}`;
        if (s) query += `&situacao_id=${s}`;
        if (q) query += `&q=${q}`;
        if (tc) query += `&tc=${tc}`;
        if (din) query += `&data_in=${din}`;
        if (dout) query += `&data_out=${dout}`;
        if (r) query += `&res_id=${r}`;

        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/leads/lista/${query}`);
            existsOrError(data, "Não foi possivel carregar os dados!");
            setSituacao(data?.situacao);
            setLeads(data?.leads);
            setPagination(data?.pagination);
            setResp(data?.resp);
            setPg(data.pg);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    /////////////////
    // START
    /////////////////

    //Carregamnento dos dados
    useEffect(() => {
        reset({
            tipo_cadastro: params?.tc || '',
            situacao_id: params?.s || '',
            q: params?.q || '',
            data_in: params?.din || hj,
            data_out: params?.dout || hj,
            res_id: params?.r || ''
        });
        // eslint-disable-next-line
    }, [resp]);

    useEffect(() => {
        setSearchURL();
        handleSubmit(onSubmit)();
        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        if (!leads) return false;

        setTimeout(() => {
            if (document.querySelector(".page > div"))
                document
                    .querySelector(".page > div")
                    .scrollIntoView({ behavior: "smooth", block: "start" });
        }, 100);
        // eslint-disable-next-line
    }, [leads]);

    useEffect(() => {

        var params = Object.fromEntries(
            new URLSearchParams(history.location.search)
        );

        switch (history.action) {
            case 'POP':
                reset({
                    tipo_cadastro: params?.tc || '',
                    situacao_id: params?.s || '', // aguarando o contato
                    q: params?.q || '',
                    data_in: params?.din || hj,
                    data_out: params?.dout || hj,
                    res_id: params?.r || ''
                });
                handleSubmit(onSubmit)();
                break;
            case 'REPLACE':
                break;
            case 'PUSH':
                break;
            default:
                break;
        }
        // eslint-disable-next-line
    }, [history.location.search]);

    return (
        <div className="ListLeadSearch">
            <HeaderComponent {...pg} />
            <div className="search">
                <form
                    action=""
                    name="search-filiado"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <CardContent>
                        <div className="col-lg-11">
                            <div className="row justify-content-between">
                                <GroupSelect
                                    name="situacao_id"
                                    col="2"
                                    label="Situação"
                                    classNameGroup="mb-lg-0"
                                    register={register}
                                    onChange={setSearchURL}
                                >
                                    {situacao?.map((r) => {
                                        return (
                                            <option value={r.id} key={r.id}>
                                                {r.nome}
                                            </option>
                                        );
                                    })}
                                    <option value="">Todos...</option>
                                </GroupSelect>
                                <GroupSelect
                                    name="res_id"
                                    col="3"
                                    label="Responsável"
                                    classNameGroup="mb-lg-0"
                                    register={register}
                                    onChange={setSearchURL}
                                >
                                    {resp?.map((r) => {
                                        return (
                                            <option value={r.id} key={r.id}>
                                                {r.nome}
                                            </option>
                                        );
                                    })}
                                    <option value="">Todos...</option>
                                </GroupSelect>
                                <GroupInput
                                    name="q"
                                    label="Buscar..."
                                    col="3"
                                    classNameGroup="mb-lg-0"
                                    register={register}
                                    onKeyUp={setSearchURL}
                                />
                                <GroupInput
                                    name="data_in"
                                    label="Data Inicio"
                                    type="date"
                                    col="2"
                                    classNameGroup="mb-lg-0"
                                    register={register}
                                    onKeyUp={setSearchURL}
                                />
                                <GroupInput
                                    name="data_out"
                                    label="Data Fim"
                                    type="date"
                                    col="2"
                                    classNameGroup="mb-lg-0"
                                    register={register}
                                    onKeyUp={setSearchURL}
                                />
                            </div>
                        </div>
                        <div className="col-lg-1">
                            <button
                                className="btn btn-primary h-100 w-100"
                                style={{ fontSize: "24px" }}
                            >
                                <i className="las la-search mr-2"></i>
                                {/* Buscar */}
                            </button>
                        </div>
                    </CardContent>
                </form>
            </div>

            <div className="table-list">
                <CardContent>
                    <div className="col-12">
                        <TableHover className="mb-lg-0 text-center">
                            <Thead
                                th={[
                                    "Situação",
                                    "Nome",
                                    "origem",
                                    "Whatsapp",
                                    "Responsáveis",
                                    "Cadastrado",
                                ]}
                            />
                            <Tbody>
                                {leads?.map((f) => {
                                    return (
                                        <tr key={f?.id}>
                                            <Th>
                                                <span
                                                    className={`badge badge-${f?.situacao ===
                                                        "Bloqueado" ||
                                                        f?.situacao ===
                                                        "Finalizado"
                                                        ? "danger"
                                                        : "primary"
                                                        } mr-1 px-2`}
                                                >
                                                    {f?.situacao}
                                                </span>
                                            </Th>
                                            <Td>
                                                <Link
                                                    to={f?.url || "/"}
                                                    className="btn btn-sm m-0 text-dark"
                                                >
                                                    {f?.nome}
                                                </Link>
                                            </Td>
                                            <Td>{f?.origem}</Td>

                                            <Td>
                                                <Link
                                                    to={{
                                                        pathname:
                                                            f?.url_whats || "/",
                                                    }}
                                                    target="_blank"
                                                    className="btn btn-sm m-0 text-dark"
                                                >
                                                    <i className="lab la-whatsapp text-success"></i>{" "}
                                                    {f?.whats || "-"}
                                                </Link>
                                            </Td>
                                            <Td>
                                                <div className="d-flex flex-column justify-content-center align-items-center">
                                                    {f?.resp?.map((r) => (
                                                        <span
                                                            key={r?.id}
                                                            className="badge badge-success my-1"
                                                        >
                                                            {r?.nome}
                                                        </span>
                                                    ))}
                                                </div>
                                            </Td>
                                            <Td>
                                                {formatDate(f?.created_at) ||
                                                    "-"}
                                            </Td>
                                        </tr>
                                    );
                                })}
                            </Tbody>
                        </TableHover>
                        <TableFooter
                            {...pagination}
                            setPage={setPage}
                            page={page}
                        />
                    </div>
                </CardContent>
            </div>
        </div>
    );
};

export default ListLeadSearch;
