import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonSave from "../../../../components/ButtonComponent/ButtonSave";
import { CardContent, CardTitle } from "../../../../components/Card-z";
import HeaderComponent from "../../../../components/HeaderComponent";
import { Modal } from "../../../../components/Modal-z";
import TextEditor from "../../../../components/TextEditor";
import CONNECT_API from "../../../../configs/connect";
import { existsOrError, ShowError } from "../../../../configs/Functions";
import { useIsLoading } from "../../../../contexts/LoadingContext";
import $ from 'jquery';

export const FiliadoAnotacoes = props => {

    // PARAMS
    const filiado_id = props.match.params.id;

    const [text, setText] = useState('');
    const [pg, setPg] = useState([]);
    const [anotacoes, setAnotacoes] = useState([]);
    const { register, reset, handleSubmit } = useForm();
    const { setIsLoading } = useIsLoading();

    async function get_data_page() {

        try {
            setIsLoading(true);

            const { data } = await CONNECT_API.get(`/filiado/${filiado_id}/anotacoes`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setAnotacoes(data.anotacoes);
            setPg(data.pg);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        reset({ text: text });
        // eslint-disable-next-line
    }, [text]);


    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);


    async function onSubmit(data) {
        try {

            const text = data.text;
            const an_id = data.an_id;
            existsOrError(text?.toString('utf8'), "Anotação vazia!");


            setIsLoading(true);
            var an_ok = null;
            // atualizando
            if (an_id) {
                an_ok = await CONNECT_API.put(`${pg.end_point}/${an_id}`, { text });
            }
            // adicionando
            else {
                an_ok = await CONNECT_API.post(`${pg.end_point}`, { text });
            }
            
            if (an_ok) await get_data_page();
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }

    }

    async function onDelete(id) {

        if (!id) return false;

        try {
            setIsLoading(true);
            const del_ok = await CONNECT_API.del(`${pg.end_point}/${id}`);
            if (del_ok) await get_data_page();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }

    async function onEdit(id) {
        if (!id) return false;
        const txt = anotacoes.filter(a => a.id === id);
        setText(txt[0].txt);
        reset({ an_id: id });
        $('#modal-anotacao').modal('show');
    }

    function modalHide() {
        setText('');
        reset({ an_id: null });
    }


    const modal = <Modal modalId="modal-anotacao" icon="plus-square" text="Nova" className={`text-success`} modalHide={modalHide}>
        <CardContent>
            <CardTitle>
                Nova anotação
            </CardTitle>
            <div className="col-12">
                <form action="" name="anotacao" onSubmit={handleSubmit(onSubmit)}>
                    <input type="hidden" name="text" {...register("text")} />
                    <input type="hidden" name="an_id" {...register("an_id")} />
                    <TextEditor text={text} setText={setText} />
                    <ButtonSave type="submit" className="mt-4 float-right" />
                </form>
            </div>
        </CardContent>
    </Modal>

    return (
        <div className="anotacoes">
            <HeaderComponent {...pg} parent={modal} />
            <div className="anotacoes-list">
                <div className="row justify-content-center">
                    <div className="col-lg-8">
                        <div className="row">
                            {
                                anotacoes?.map(a => {
                                    return (
                                        <div className="col-12 col-lg-" key={a.id}>
                                            <div className="card">
                                                <div className="card-body" >
                                                    <div className="card-body-info">
                                                        <div className="row justify-content-between">
                                                            <div className="col-lg-auto">
                                                                <small className="badge badge-primary">{a?.categoria}</small>
                                                            </div>
                                                            <div className="col-lg-auto">
                                                                <small>
                                                                    Anotação atualizada em {a.updated_at} by <i className="las la-user"></i>{a.colaborador || 'Admin'}
                                                                </small>
                                                                {
                                                                    a.delete && <span className="p-2 text-danger btn btn-sm" onClick={() => onDelete(a.id)}>
                                                                        <i className="las la-trash-alt"></i>
                                                                    </span>
                                                                }
                                                                {
                                                                    a.edit && <span className="p-2 text-success btn btn-sm" onClick={() => onEdit(a.id)}>
                                                                        <i className="las la-edit"></i>
                                                                    </span>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className="card-body-text" dangerouslySetInnerHTML={{ __html: a.txt }}></div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default withRouter(FiliadoAnotacoes);
