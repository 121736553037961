import React from "react";


// HOME DA PÁGINA PAINEL
export const PainelIndex = props => {
    return (
        <>
            <div className="painel">
                PAINEL
            </div>
        </>
    );
}


export default PainelIndex;