import React from "react";
import "./ButtonLink.css";


export const NavVerticalButton = props => {

    return (
        <a
            style={{ "color": props?.color || "#343a40", "textDecoration": "none" }}
            className={`ctt-button-nav card mb-2 p-0 nav-link align-items-center ${props.active ? "active" : ''}`}
            data-toggle="pill"
            href={props.href || props?.url || props?.link || '/'}
            role="tab"
            // eslint-disable-next-line
            aria-selected={`${props.active ? true : false}`}
        >
            <div className="w-100">
                <div className="card-body py-3">
                    <div className="form-row justify-content-center align-items-center text-left">
                        <div className="col-auto">
                            <i style={{ "fontSize": "30px" }} className={`las la-${props?.icon}`}></i>
                        </div>
                        <div className="col">
                            <h4 className='m-0 p-0'>
                                {props?.label || props?.nome || props?.name}
                                {
                                    props.subTitle ? <><br /><small className="" style={{"fontSize":"10px"}}>{props.subTitle}</small></> : ''
                                }
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </a>
    );
}


export default NavVerticalButton;