import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { GroupSelect } from "../../../../components/Form";
import HeaderComponent from "../../../../components/HeaderComponent";
import { TableHover, Tbody, Td, Thead } from "../../../../components/Table-z";
import CONNECT_API from "../../../../configs/connect";
import { existsOrError, formatCPF, formatDate, PrintHTML, ShowError } from "../../../../configs/Functions";
import { useIsLoading } from "../../../../contexts/LoadingContext";

import FormQueryRelatorioFiliados from "./FormQueryRelatorioFiliados";


export const RelatorioFiliadosFiliados = props => {
    const { setIsLoading } = useIsLoading();
    const { register, reset } = useForm();

    const [pg, setPg] = useState([]);
    const [queryAvancado, setQueryAvancado] = useState(false);
    const [cadastros, setCadastros] = useState([]);
    const [cadastrosOr, setCadastrosOr] = useState(1);
    const [totais, setTotais] = useState([]);

    // PARAMS
    // const plano_id = props.match.params.id;

    async function get_data_page(key) {

        try {

            if (!key) key = 7;
            let query = `?days=${key}`;
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/filiado/relatorio/filiados/${query}`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setCadastros(data?.cadastros);
            setTotais(data?.totais);
            setPg(data?.pg);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    // 
    // Controle do filtro avançado
    async function onShowQuery(e) {
        let target = e.target;
        let val = target.value;
        if (!val && !queryAvancado) {
            setQueryAvancado(true);
        } else if (queryAvancado) {
            setQueryAvancado(false);
        }

        // Se não for filtro avançado ai submete dnv o form
        if (val) await get_data_page(val);
    }

    function PrintRelatorio() {
        let html = document.querySelector('.result-body').innerHTML;
        PrintHTML(html, true);
    }


    function orderCadastro(lista, option) {
        switch (option) {
            case "Endereço":
                return lista.sort((a, b) => {
                    if (!a.cidade) return 1;
                    if (!b.cidade) return -1;
                    if (a.cidade > b.cidade) return 1;
                    if (a.cidade < b.cidade) return -1;
                    return 0;
                }).map(p => p);
            case "CPF":
                return lista.sort((a, b) => {
                    if (!a.cpf) return 1;
                    if (!b.cpf) return -1;
                    if (a.cpf > b.cpf) return 1;
                    if (a.cpf < b.cpf) return -1;
                    return 0;
                }).map(p => p);
            case "Situação":
                return lista.sort((a, b) => {
                    if (!a.situacao) return 1;
                    if (!b.situacao) return -1;
                    if (a.situacao > b.situacao) return 1
                    if (a.situacao < b.situacao) return -1
                    return 0;
                }).map(p => p);
            case "Nome":
                return lista.sort((a, b) => {
                    if (!a.nome) return 1;
                    if (!b.nome) return -1;
                    if (a.nome > b.nome) return 1;
                    if (a.nome < b.nome) return -1;
                    return 0;
                }).map(p => p);
            case "Matricula":
                return lista.sort((a, b) => {
                    if (!a.matricula) return 1;
                    if (!b.matricula) return -1;
                    if (a.matricula > b.matricula) return 1;
                    if (a.matricula < b.matricula) return -1;
                    return 0;
                }).map(p => p);
            case "Nascimento":
                return lista.sort((a, b) => {
                    if (!a.nascimento) return 1;
                    if (!b.nascimento) return -1;
                    if (a.nascimento > b.nascimento) return 1;
                    if (a.nascimento < b.nascimento) return -1;
                    return 0;
                }).map(p => p);
            case "E-mail":
                return lista.sort((a, b) => {
                    if (!a.email) return 1;
                    if (!b.email) return -1;
                    if (a.email > b.email) return 1;
                    if (a.email < b.email) return -1;
                    return 0;
                }).map(p => p);
            case "Telefone/Whatsapp":
                return lista.sort((a, b) => {
                    if (!a.whats) return 1;
                    if (!b.whats) return -1;
                    if (a.whats > b.whats) return 1;
                    if (a.whats < b.whats) return -1;
                    return 0;
                }).map(p => p);
            case "Vendedor":
                return lista.sort((a, b) => {
                    if (!a.vendedor) return 1;
                    if (!b.vendedor) return -1;
                    if (a.vendedor > b.vendedor) return 1;
                    if (a.vendedor < b.vendedor) return -1;
                    return 0;
                }).map(p => p);
            case "Cadastrado":
                return lista.sort((a, b) => {
                    if (a.created_at > b.created_at) return 1;
                    if (a.created_at < b.created_at) return -1;
                    return 0;
                }).map(p => p);
            case "Agregados":
                return lista.sort((a, b) => {
                    if (!a.agregado[0]?.nome) return 1;
                    if (!b.agregado[0]?.nome) return -1;
                    if (a.agregado[0]?.nome > b.agregado[0]?.nome) return 1;
                    if (a.agregado[0]?.nome < b.agregado[0]?.nome) return -1;
                    return 0;
                }).map(p => p);
            default:
                return lista.map(p => p);
        }
    }

    function ordenacao(key) {
        let lista = cadastros;
        setCadastros(orderCadastro(lista, key));
        setCadastrosOr(Math.floor(Math.random() * 100));
        return;
    }

    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        reset({ intervalo: 7 })
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setCadastros(cadastros);
        // eslint-disable-next-line
    }, [cadastrosOr]);



    return (
        <div className="relatorio-de-filiados">
            <HeaderComponent {...pg}>
                <div className="query-menu">
                    <div className="row justify-content-end">
                        <GroupSelect name="intervalo" classe="sm pr-4" col="auto" onChange={onShowQuery} label="" classNameGroup="mb-0" register={register}>
                            <option value="30">Cadastros dos últimos 30 dias</option>
                            <option value="15">Cadastros dos últimos 15 dias</option>
                            <option value="7">Cadastros dos últimos 7 dias</option>
                            <option className="" value="">Filtro Avançado</option>
                        </GroupSelect>
                        <button className="btn btn-sm px-4 py-0 btn-success border-0" style={{ "fontSize": "20px" }} title="Imprimir página" onClick={PrintRelatorio}><i className="las la-print"></i></button>
                    </div>
                </div>
            </HeaderComponent>
            <div className="query-body">
                <div className={`collapse ${queryAvancado ? 'show' : ''}`}>
                    <FormQueryRelatorioFiliados setCadastros={setCadastros} setTotais={setTotais} />
                </div>
            </div>
            <div className="result-card">
                <div className="row">

                    {
                        totais?.cards?.map((c, i) => {
                            return (
                                <div className="col-lg-4" key={i}>
                                    <div className="card" title={c?.attrTitle} >
                                        <div className="card-body p-3">
                                            <div className="row">
                                                <div className="col-lg text-muted">
                                                    <h4 className="font-weight-bold">{c?.label}</h4>
                                                    <div className="value">
                                                        {c?.valor}
                                                    </div>
                                                    <div className="descricao">
                                                        <span className="desc">{c?.descricao}</span>
                                                        <span className="text-success"> {c?.sub_descricao}</span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-auto">
                                                    <span className={`d-flex align-items-center justify-content-center rounded-circle ${c?.icon_class}`} style={{ 'fontSize': '30px', 'height': '60px', 'width': '60px' }}>
                                                        <i className={`las ${c?.icon}`}></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="result-body">
                <div className="card">
                    <div className="card-body">
                        <TableHover className="mb-0 text-left">
                            <Thead click={ordenacao} or={true}
                                th={[
                                    "Situação",
                                    "Nome",
                                    "CPF",
                                    "Matricula",
                                    "Nascimento",
                                    "E-mail",
                                    "Telefone/Whatsapp",
                                    "Endereço",
                                    "Agregados",
                                    "Vendedor",
                                    "Cadastrado",
                                ]}
                            />
                            <Tbody>
                                {
                                    cadastros?.map(f => {

                                        return (
                                            <tr key={f?.id}>
                                                <Td>
                                                    {f?.situacao?.toUpperCase() === "REGULAR"
                                                        ?
                                                        <span className="badge badge-success mr-1 px-2">{f?.situacao}<span>{f?.or}</span></span>
                                                        :
                                                        <span className="badge badge-danger mr-1 px-2">{f?.situacao}</span>
                                                    }
                                                </Td>
                                                <Td>
                                                    <Link to={f?.url || '/'} target="_blank" className="btn btn-sm m-0 p-0 text-dark">
                                                        {f?.nome}
                                                    </Link>
                                                </Td>
                                                <Td><span className="text-nowrap">{formatCPF(f?.cpf)}</span></Td>
                                                <Td><span className="text-nowrap">{f?.matricula}</span></Td>
                                                <Td><span className="text-nowrap">{formatDate(f?.nascimento)}</span></Td>
                                                <Td>
                                                    {
                                                        f?.email &&
                                                        <Link to={{ pathname: f.url_email || '/' }} target="_blank" className="btn p-0 m-0 text-info" style={{ "fontSize": "10px" }}>
                                                            <i className="las la-envelope mr-1"></i><span className="text-nowrap">{f?.email}</span>
                                                        </Link>
                                                    }
                                                </Td>
                                                <Td>
                                                    {
                                                        f?.tel &&
                                                        <>
                                                            {f?.tel} <br />
                                                        </>
                                                    }
                                                    {
                                                        f?.whats &&
                                                        <Link to={{ pathname: f.url_whats || '/' }} target="_blank" className="btn p-0 m-0 text-success" style={{ "fontSize": "10px" }}>
                                                            <i className="lab la-whatsapp mr-1"></i><span className="text-nowrap">{f?.whats}</span>
                                                        </Link>
                                                    }
                                                </Td>

                                                <Td>
                                                    <span className="text-nowrap">
                                                        {f?.cidade} -
                                                        &nbsp;{f?.uf}
                                                    </span>
                                                </Td>

                                                <Td>
                                                    <ul className="list-group list-group-flush">
                                                        {
                                                            f?.agregado?.map(a => {
                                                                return (
                                                                    <li className="list-group-item bg-transparent px-0 py-0 border-0" key={a?.id}>
                                                                        <Link to={a.url || '/'} className="btn p-0 m-0" target="_blank" style={{ "fontSize": "10px" }}>
                                                                            {a?.nome}
                                                                        </Link>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </Td>

                                                <Td><span className="text-nowrap">{f?.vendedor}</span></Td>
                                                <Td>{formatDate(f?.created_at) || '-'}</Td>
                                            </tr>
                                        );
                                    })
                                }
                            </Tbody>
                        </TableHover>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default RelatorioFiliadosFiliados;



