import React from "react";
import "./pagination.css";


// qtdPage, link, setPage
export const Pagination = props => {

    const {
        limite,
        count,
        page
    } = props;

    let qtd = Math.ceil(count / limite);
    let i = 1;
    const qtdPage = [];

    for (i = 1; i <= qtd; i++) {
        qtdPage.push(i);
    }

    // SEM ELEMENTOS PARA PAGINAÇÃO
    if (count < limite) {
        return "";
    }

    if (count < limite && page > 1) {
        props.setPage(1);
    }



    return (
        <nav aria-label="navigation" className="page-navigation">
            <ul className="pagination  justify-content-center mt-3">
                <li className="page-item">
                    <button onClick={() => props?.setPage(1)} className="page-link" >
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>
                    </button>
                </li>
                {
                    qtdPage?.map((e, index) => {
                        return (
                            <li key={index} className={`page-item number ${Number(props?.page) === Number(index + 1) ? "active" : ""}`} id={`page-${(index + 1)}`}>
                                <button onClick={() => props?.setPage(index + 1)} className={`page-link`} >
                                    {(index + 1)}
                                </button>
                            </li>
                        );
                    })
                }
                <li className="page-item">
                    <button onClick={() => props.setPage(qtdPage?.length)} className="page-link" >
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only">Next</span>
                    </button>
                </li>
            </ul>
        </nav>
    );
}


export default Pagination;