import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CardContent } from "../../../components/Card-z";
import HeaderComponent from "../../../components/HeaderComponent";
import { TableHover, Tbody, Td, Th, Thead } from "../../../components/Table-z";
import CONNECT_API from "../../../configs/connect";
import { existsOrError, formatCNPJ, formatCoin, formatCPF, formatDate, ShowError } from "../../../configs/Functions";
import { useIsLoading } from "../../../contexts/LoadingContext";


export const MyExtract = props => {
    const { setIsLoading } = useIsLoading();

    const [pg, setPg] = useState([]);
    const [faturamento, setFaturamento] = useState([]);
    const [totais, setTotais] = useState([]);

    async function get_data_page() {

        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/empresa/colaborador/caixa`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setFaturamento(data.faturamento);
            setTotais(data?.totais);
            setPg(data.pg);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    /////
    // START
    /////
    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);


    return (
        <div className="caixa-diario">
            <HeaderComponent {...pg}>
                <div className="row justify-content-end">
                    {
                        totais?.cancelado ?
                            <div className="col-lg-auto">
                                <div className="card bg-danger">
                                    <div className="card-body text-center text-light">
                                        <b>Estorno {formatCoin(totais?.cancelado)}</b>
                                    </div>
                                </div>
                            </div> : ""
                    }
                    {
                        totais?.total ?
                            <div className="col-lg-auto">
                                <div className="card" style={{ "background": "var(--lancamento)" }}>
                                    <div className="card-body text-center text-light">
                                        <b>Saldo {formatCoin(totais?.total)}</b>
                                    </div>
                                </div>
                            </div> : ""
                    }
                </div>
            </HeaderComponent>
            <div className="table-list">
                <CardContent>
                    <div className="col-12">
                        <TableHover className="mb-0 text-center">
                            <Thead
                                th={[
                                    "id",
                                    "Tipo",
                                    "Cadastro",
                                    "Data",
                                    "Valor",
                                    "Vencimento",
                                    "Faturado",
                                    "Parcela",
                                    "Terminal",
                                    "Ação",
                                ]}
                            />
                            <Tbody>
                                {
                                    faturamento?.map(f => {
                                        const pj =
                                            <Link to={f?.url_fornecedor || "/"} className="btn btn-sm m-0 text-dark" target="_blank">
                                                {formatCNPJ(f?.cnpj)} <br />
                                                <small>{f.pj_nome}</small>
                                            </Link>
                                        const pf =
                                            <Link to={f?.url_fornecedor || "/"} className="btn btn-sm m-0 text-dark" target="_blank">
                                                {formatCPF(f?.cpf)} <br />
                                                <small>{f?.pf_nome}</small>
                                            </Link>
                                        const receita = <i className="las la-arrow-up text-success" style={{ "fontSize": "25px" }}></i>
                                        const despesa = <i className="las la-arrow-down text-danger" style={{ "fontSize": "25px" }}></i>
                                        const faturado = <i className="las la-check-square text-success" style={{ "fontSize": "25px" }}></i>
                                        const n_faturado = <i className="las la-exclamation text-danger" style={{ "fontSize": "25px" }}></i>
                                        const btn_ver =
                                            <Link to={f.url || f.link || '/'} className="btn btn-sm m-0 text-success" title="Ver" target="_blank">
                                                <i className="las la-external-link-alt" style={{ "fontSize": "20px" }}></i>
                                            </Link>
                                        const btns = (
                                            <div className="btn-group" role="group" aria-label="buttons ação">
                                                {btn_ver}
                                                {/**(!f.faturado && !f.cancelado) ? btn_editar : btn_ver */}
                                            </div>
                                        )

                                        return (
                                            <tr key={f.id}>
                                                <Th>
                                                    #{f.id}
                                                </Th>
                                                <Td>
                                                    {f.receita
                                                        ?
                                                        <>
                                                            {receita} <br />
                                                            <small className="text-success">{f.tipo_receita}</small>
                                                        </>
                                                        :
                                                        <>
                                                            {despesa} <br />
                                                            <small className="text-danger">Despesa</small>
                                                        </>
                                                    }
                                                </Td>
                                                <Td>
                                                    {f.cpf ? pf : pj}
                                                </Td>
                                                <Td>
                                                    {formatDate(f.created_at)} <br />
                                                    <small className="text-success">{formatDate(f.faturado)}</small>
                                                </Td>
                                                <Td>
                                                    {formatCoin(f.valor)} <br />
                                                    <small className="text-success">{formatCoin(f.valor_faturado)}</small>
                                                </Td>
                                                <Td>
                                                    {formatDate(f.vencimento)}
                                                </Td>
                                                <Td>
                                                    {(f.faturado && !f.cancelado)
                                                        ?
                                                        <>
                                                            {faturado} <br />
                                                            <small className="text-success">{formatDate(f.faturado)}</small>
                                                        </>
                                                        :
                                                        !f.cancelado ? n_faturado :
                                                            <>
                                                                <span className="badge badge-danger mr-1 px-2">Cancelado</span> <br />
                                                                <small className="text-danger">{formatDate(f.cancelado)}</small>
                                                            </>
                                                    }
                                                </Td>
                                                <Td>
                                                    {f.numero_atual_x}/{f.numero_total_x ? f.numero_total_x : f.numero_atual_x}
                                                </Td>
                                                <Td>
                                                    {f.banco} → {f.terminal}
                                                </Td>
                                                <Td>
                                                    {btns}
                                                </Td>
                                            </tr>
                                        );
                                    })
                                }
                            </Tbody>
                        </TableHover>
                    </div>
                </CardContent>
                <div className="totais">
                    <div className="row">
                        {
                            totais?.array?.map(t => {
                                let tr = totais[t];
                                return (
                                    <div className="col-lg-4" key={tr?.id}>
                                        <div className="card">
                                            <div className="card-body text-center">
                                                <div className="row justify-content-between">
                                                    <div className="col-auto">{tr?.label}</div>
                                                    <div className="col-auto">{formatCoin(tr?.valor)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}



export default MyExtract;