import axios from 'axios';
//import { baseURL, nameToken } from './config';

const nameToken = process.env.REACT_APP_NAMETOKEN;
var baseURL = `https://ws-${document.location.hostname}`;

// CARTÃO VIDA É ESPECIAL
if (window.isLocalhost) baseURL = `http://localhost:3003`;

export const api = axios.create({
    baseURL: baseURL
});

const token = localStorage.getItem(nameToken);

if (token) {
    api.defaults.headers['authorization'] = `Bearer ${token}`;
}
api.defaults.headers['Content-Type'] = `application/json`;


export default api;