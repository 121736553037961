import React from "react";
import "./styles/Form.css";
import $ from "jquery";
import { useState } from "react";

// const data_local = new Date()
// const year = data_local.getFullYear();
// const month = data_local.getMonth() + 1; // mês começa em 0
// const day = data_local.getDate();
// const data_inicio = `${year}-${ Number(month) < 10 ? ("0" + month) : month }-${day.length === 1 ? ("0" + day) : day}`;
const data_local = new Date();
const hoje = data_local
    .toLocaleDateString("pt-BR")
    .split("/")
    .reverse()
    .join("-");
const data_inicio = hoje;

function keyPrevent(e) {
    if (e.key === "Enter") {
        e.preventDefault();
    }
}

export const GroupInput = (props) => {
    return (
        <div className={`custom-group group-input col-12 col-lg-${props.col}`}>
            <div
                className={`form-group ${props.classNameGroup || ""} ${props.hidden ? "d-none" : ""
                    }`}
            >
                <input
                    name={props.name}
                    {...props.register(props.name)}
                    type={props.type || "text"}
                    className={`form-control form-control-lg ${props.classe || ""
                        }`}
                    id={props.id || props.name}
                    placeholder={props.placeholder}
                    autoComplete={props.autoComplete || "off"}
                    required={props.required}
                    disabled={props.disabled}
                    autoFocus={props.autoFocus}
                    preenchido="true"
                    maxLength={props.max}
                    // onChange={props.onChange ? props.onChange : ()=>{}} //Erro não pegava o valor do campo
                    onKeyUp={props.onKeyUp}
                    onKeyPress={props.onKeyPress}
                    onClick={props.onClick}
                    defaultValue={
                        !props.defaultValue && props.type === "date"
                            ? data_inicio
                            : props.defaultValue
                    }
                />
                {props.label ? (
                    <label className="custom-label" htmlFor={props.for}>
                        {props.label}
                        <b className="text-danger"> {props.required && "*"}</b>
                    </label>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export const GroupInputSearch = (props) => {
    return (
        <div className={`custom-group group-input col-12 col-lg-${props.col}`}>
            <div
                className={`form-group position-relative mb-0 ${props.classNameGroup || ""} ${props.hidden ? "d-none" : ""
                    }`}
            >
                <input
                    name={props.name}
                    {...props.register(props.name)}
                    type={props.type || "text"}
                    className={`form-control form-control-lg pr-5 ${props.classe || ""
                        }`}
                    id={props.id || props.name}
                    placeholder={props.placeholder}
                    autoComplete={props.autoComplete || "off"}
                    required={props.required}
                    disabled={props.disabled}
                    autoFocus={props.autoFocus}
                    preenchido="true"
                    maxLength={props.max}
                    //onChange={props.onChange} //Erro não pegava o valor do campo
                    onKeyUp={props.onKeyUp}
                    onKeyPress={props.onKeyPress}
                    onClick={props.onClick}
                    defaultValue={
                        !props.defaultValue && props.type === "date"
                            ? data_inicio
                            : props.defaultValue
                    }
                />
                <button
                    type="submit"
                    className="btn btn-sm p-0 m-0"
                    style={{
                        "position": "absolute",
                        "top": "50%",
                        "right": "15px",
                        "zIndex": "1",
                        "fontSize": "23px",
                        "transform": "translateY(-50%)",
                    }}
                >
                    <i className="las la-search"></i>
                </button>
                {props.label ? (
                    <label className="custom-label" htmlFor={props.for}>
                        {props.label}
                        <b className="text-danger"> {props.required && "*"}</b>
                    </label>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export const GroupCheckBox = (props) => {
    return (
        <div className={`group-checkbox col-12 col-lg-${props.col}`}>
            <div
                className={`form-group ${props.classNameGroup || ""} ${props.hidden ? "d-none" : ""
                    }`}
            >
                <div className="custom-control custom-checkbox">
                    <input
                        name={props.name}
                        {...props.register(props.name)}
                        type="checkbox"
                        className={`custom-control-input ${props.classe || ""}`}
                        id={props.id || props.name}
                        required={props.required}
                        value={props.value}
                        disabled={props.disabled}
                        preenchido="true"
                        onChange={props.onChange}
                        defaultChecked={props.checked}
                    />
                    {props.label ? (
                        <label
                            className="custom-label custom-control-label text-uppercase"
                            htmlFor={props.for || props.id}
                        >
                            {props.label}
                            <b className="text-danger">
                                {" "}
                                {props.required && "*"}
                            </b>
                        </label>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </div>
    );
};

export const GroupRadio = (props) => {


    const [checked, setChecked] = useState([]);

    function onClick(e) {

        if (e.target.checked) {
            let name = e.target.name;
            let value = e.target.value;

            if (checked[`${name}-${value}`]) {
                e.target.checked = false;
                let ar = checked;
                ar[`${name}-${value}`] = 0;
                setChecked({ ...ar });
            } else {
                let ar = checked;
                ar[`${name}-${value}`] = 1;
                setChecked({ ...ar });
            }

        }

        if (props.onClick) props.onClick(e);
    }


    return (
        <div className={`group-checkbox col-12 col-lg-${props.col}`}>
            <div className={`form-group ${props.classNameGroup || ""}`}>
                <div className="custom-control custom-radio custom-control-inline">
                    <input
                        name={props.name}
                        {...props.register(props.name)}
                        type="radio"
                        className={`custom-control-input ${props.classe || ""}`}
                        id={props.id || props.name}
                        required={props.required}
                        disabled={props.disabled}
                        value={props.value}
                        onChange={props.onChange}
                        onClick={onClick}
                        onKeyUp={props.onKeyUp}
                        defaultChecked={props.checked}
                    />
                    {props.label ? (
                        <label
                            className="custom-label custom-control-label text-uppercase"
                            htmlFor={props.for || props.id}
                        >
                            {props.label}
                            <b className="text-danger">
                                {" "}
                                {props.required && "*"}
                            </b>
                        </label>
                    ) : (
                        ""
                    )}
                </div>
            </div>
        </div>
    );
};

export const GroupSelect = (props) => {
    return (
        <div className={`custom-group group-input col-12 col-lg-${props.col}`}>
            <div
                className={`form-group ${props.classNameGroup || ""} ${props.hidden ? "d-none" : ""
                    }`}
            >
                <select
                    className={`custom-select form-control form-control-lg ${props.classe || ""
                        }`}
                    id={props.id || props.name}
                    name={props.name}
                    {...props.register(props.name)}
                    placeholder-off={props.placeholder || props.label}
                    autoComplete={props.autoComplete || "off"}
                    required={props.required || false}
                    disabled={props.disabled}
                    value={props.value}
                    preenchido="true"
                    onChange={props.onChange}
                    onKeyDown={keyPrevent}
                    defaultValue={props.defaultValue}
                >
                    {props.children}
                </select>
                {props.label ? (
                    <label className="custom-label" htmlFor={props.for}>
                        {props.label}
                        <b className="text-danger"> {props.required && "*"}</b>
                    </label>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

export const GroupText = (props) => {
    return (
        <div
            className={`custom-group group-textarea col-12 col-lg-${props.col}`}
        >
            <div className="form-group">
                <textarea
                    rows={props.row || "3"}
                    name={props.name}
                    {...props.register(props.name)}
                    className={`form-control form-control-lg ${props.classe || ""
                        }`}
                    id={props.id || props.name}
                    placeholder-off={props.placeholder || props.label}
                    autoComplete={props.autoComplete || "off"}
                    required={props.required || false}
                    disabled={props.disabled}
                    preenchido="true"
                >
                    {props.children}
                </textarea>
                {props.label ? (
                    <label className="custom-label" htmlFor={props.for}>
                        {props.label}
                        <b className="text-danger"> {props.required && "*"}</b>
                    </label>
                ) : (
                    ""
                )}
            </div>
        </div>
    );
};

// label, value
export const InputStatic = (props) => {
    return (
        <div className={`col-12 col-lg-${props.col}`}>
            <div className={`form-group ${props.m}`}>
                <div className="form-control">
                    <b>{props.label}</b> {props.value}
                </div>
            </div>
        </div>
    );
};

// children
export const InputStaticContent = (props) => {
    return (
        <div className={`col-12 col-lg-${props.col}`}>
            <div className={`form-group ${props.m} ${props.d}`}>
                <div className="form-control">{props.children}</div>
            </div>
        </div>
    );
};

// col, name, registrer
export const InputFile = (props) => {
    /*const fileImage = data.file;
    const formData = new FormData();
    formData.append('file', fileImage[0]);
    formData.append('ativo', data.situacao);
    formData.append('nome', data.nome);
    formData.append('nascimento', data.datanascimento);
    formData.append('emissorrg', data.emissorrg);
    formData.append('uf', data.uf);
    formData.append('municipio', data.municipio);
    formData.append('bairro', data.bairro);
    formData.append('lagradouro', data.lagradouro);
    formData.append('referencia', data.referencia);
    formData.append('email', data.email);
    formData.append('nometelefone', data.nometelefone);
    formData.append('rg', SetNumber(data.rg));
    formData.append('generoId', SetNumber(data.genero));
    formData.append('estadocivilId', SetNumber(data.estadocivil));
    formData.append('cep', SetNumber(data.cep));
    formData.append('numeroendereco', SetNumber(data.numeroendereco));
    formData.append('whatsapp', SetNumber(data.whatsapp));
    formData.append('telefone', SetNumber(data.telefone));
    formData.append('planoId', SetNumber(data.plano));
    formData.append('colaboradorId', SetNumber(data.vendedor));

    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }*/

    return (
        <div className={`custom-group group-input col-12 col-lg-${props.col}`}>
            <div className={`form-group ${props.hidden ? "d-none" : ""}`}>
                <div className="custom-file">
                    <input
                        encType={props.encType}
                        type="file"
                        {...props.register(props.name)}
                        name={props.name}
                        className={`custom-file-input`}
                        id={props.name}
                        onChange={props.onChange}
                    />
                    {props.label && (
                        <label
                            className="custom-file-label"
                            htmlFor={props.name}
                        >
                            {props.label}
                        </label>
                    )}
                </div>
            </div>
        </div>
    );
};

export const GroupSelectFilter = (props) => {

    $(`[name="${props.name}-select-filter"]`)
        .unbind("keyup")
        .keyup(function (e) {
            var val = $(this).val();
            var select = $(`[name="${props.name}"]`);
            select.find("option").each(function () {
                if (
                    $(this).html().toLowerCase().indexOf(val.toLowerCase()) >= 0
                ) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
            });
        });

    return (
        <div
            className={`select-filter custom-group group-input col-12 col-lg-${props.col}`}
        >
            <div
                className={`form-group ${props.classNameGroup || ""} ${props.hidden ? "d-none" : ""
                    }`}
            >
                {
                    <label className="w-100" htmlFor={props.for}>
                        {props.label && <div className="mb-1">{props.label}</div>}
                        <b className="text-danger"> {props.required && "*"}</b>
                        <input
                            type="text"
                            name={`${props.name}-select-filter`}
                            className={`form-control form-control-sm py-2 mb-2 w-100`}
                            style={{ minHeight: "0" }}
                            placeholder={`${props.textFilter ? props.textFilter : "Buscar"
                                }`}
                            preenchido="true"
                        />
                    </label>
                }
                <select
                    multiple
                    className={`custom-select form-control form-control-lg px-2 ${props.classe || ""
                        }`}
                    id={props.id || props.name}
                    name={props.name}
                    {...props.register(props.name)}
                    placeholder-off={props.placeholder || props.label}
                    autoComplete={props.autoComplete || "off"}
                    required={props.required || false}
                    disabled={props.disabled}
                    value={props.value}
                    preenchido="true"
                    onChange={props.onChange}
                    onKeyDown={keyPrevent}
                    defaultValue={props.defaultValue}
                >
                    {props.children}
                </select>
            </div>
        </div>
    );
};

export const GroupSelectSearch = (props) => {
    $(`[name="${props.name}-select-filter"]`)
        .unbind("keyup")
        .keyup(function (e) {
            var val = $(this).val();
            var select = $(`[name="${props.name}"]`);
            select.find("option").each(function () {
                if (
                    $(this).html().toLowerCase().indexOf(val.toLowerCase()) >= 0
                ) {
                    $(this).show();
                } else {
                    $(this).hide();
                }
            });
        });

    return (
        <div
            className={`select-filter custom-group group-input col-12 col-lg-${props.col}`}
        >
            <div
                className={`form-group ${props.classNameGroup || ""} ${props.hidden ? "d-none" : ""
                    }`}
            >
                {
                    <label className="w-100" htmlFor={props.for}>
                        {props.label && <div className="mb-1">{props.label}</div>}
                        <b className="text-danger"> {props.required && "*"}</b>
                        <input
                            type="text"
                            name={`${props.name}-select-filter`}
                            className={`form-control form-control-sm py-2 mb-2 w-100`}
                            style={{ minHeight: "0" }}
                            placeholder={`${props.textFilter ? props.textFilter : "Buscar"
                                }`}
                            preenchido="true"
                        />
                    </label>
                }
                <select
                    className={`custom-select form-control form-control-lg px-2 ${props.classe || ""
                        }`}
                    id={props.id || props.name}
                    name={props.name}
                    {...props.register(props.name)}
                    placeholder-off={props.placeholder || props.label}
                    autoComplete={props.autoComplete || "off"}
                    required={props.required || false}
                    disabled={props.disabled}
                    value={props.value}
                    preenchido="true"
                    onChange={props.onChange}
                    onKeyDown={keyPrevent}
                    defaultValue={props.defaultValue}
                >
                    {props.children}
                </select>
            </div>
        </div>
    );
};

// eslint-disable-next-line
export default {
    GroupInput,
    GroupInputSearch,
    GroupText,
    GroupSelect,
    GroupCheckBox,
    GroupRadio,
    InputStatic,
    InputStaticContent,
    GroupSelectFilter,
    GroupSelectSearch
};
