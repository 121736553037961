import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import HeaderComponent from "../../../../../components/HeaderComponent";
import CONNECT_API from "../../../../../configs/connect";
import { ShowError } from "../../../../../configs/Functions";
import { useIsLoading } from "../../../../../contexts/LoadingContext";
import ModalNotificacao from "./ModalNotificacao";


export const NotificacaoPagamentoByTerminal = props => {

    const { setIsLoading } = useIsLoading();
    const [pg, setPg] = useState([]);
    const [notificacoes, setNotificacoes] = useState([]);

    async function get_data_page() {

        try {
            setIsLoading(true);
            const id = props.match.params.id;
            const { data } = await CONNECT_API.get(`/filiado/configuracao?get=notificacao-pagamento-terminal&tr=${id}`);
            setPg(data.pg);
            setNotificacoes(data.notificacoes);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="config-filiados-notificacao-pagamento-terminal">
            <HeaderComponent {...pg}>
                <ModalNotificacao 
                    loaded={get_data_page} 
                    terminal_id={props.match.params.id} 
                    btn_class="btn btn-primary btn-sm m-0"
                    label="Nova notificação" 
                    icon="plus" 
                    modalId="modal-nova-notificacao" 
                />
            </HeaderComponent>
            <div className="card">
                <div className="card-body">
                    <div className="table-resposive ">
                        <table className="table table-hover mb-0 text-center " style={{ "fontSize": "12px" }}>
                            <thead>
                                <tr>
                                    <th className="text-uppercase" scope="col">ID</th>
                                    <th className="text-uppercase" scope="col">Status</th>
                                    <th className="text-uppercase" scope="col">Título</th>
                                    <th className="text-uppercase" scope="col">Resumo</th>
                                    <th className="text-uppercase" scope="col">FROM</th>
                                    <th className="text-uppercase" scope="col">DATA/HORA</th>
                                    <th className="text-uppercase" scope="col">Ação</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    notificacoes?.map(n => {

                                        n.resumo = 'Notificar ' + n.days;
                                        if (n.before && n.after) {
                                            n.resumo += ' dia(s) antes e ' + n.days + ' dia(s) depois do vencimento!';
                                        } else if (n.before && !n.after) {
                                            n.resumo += ' dia(s) depois do vencimento!';
                                        } else if (!n.before && n.after) {
                                            n.resumo += ' dia(s) antes do vencimento!';
                                        } else {
                                            n.resumo = 'Período da notificação não definido!';
                                        }
                                        if (Number(n.days)===0) n.resumo = 'Notificar no dia do vencimento!';
                                        if (!n.msg) n.resumo = 'Mensagem da notificação não definida!';

                                        if( !n.mail_from ) n.mail_from = 'Padrão';

                                        return (
                                            <tr key={n.id}>
                                                <td>#{n.id}</td>
                                                <td>{n.ativo ? <span className="badge badge-success mr-1 px-2">Ativo</span> : <span className="badge badge-danger mr-1 px-2">Inativo</span>}</td>
                                                <td>{n.title}</td>
                                                <td>{n.resumo}</td>
                                                <td>{n.mail_from}</td>
                                                <td>{n.created_at}</td>
                                                <td>
                                                    <div className="group-button" style={{ "fontSize": "20px" }}>
                                                        <ModalNotificacao loaded={get_data_page} notify={n.id} btn_class="btn btn-sm- m-0 text-success" icon="edit" modalId={"modal-notificacao-"+n.id+"-terminal"} />
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(NotificacaoPagamentoByTerminal);