import React, { useEffect, useState } from "react";
import HeaderComponent from "../../../components/HeaderComponent";
import CONNECT_API from "../../../configs/connect";
import { useIsLoading } from "../../../contexts/LoadingContext";
import { ShowError } from "../../../configs/Functions";
import { DataCardBefore } from "../../../components/Dashboard/DataCards";
import { Link } from "react-router-dom";
import { GraficoCadastros, GraficoDias } from "./apoio/Graficos";

export const CieloLinks = props => {

    const [pg, setPg] = useState([]);
    const [menus, setMenus] = useState([]);
    const [recs, setRecs] = useState([]);

    const { setIsLoading } = useIsLoading();


    async function get_data_page() {

        try {
            setIsLoading(true);

            const dados = await CONNECT_API.get(`/cielo`);
            var data = dados?.data;
            setRecs(data?.recs);
            setMenus(data?.menus);
            setPg(data.pg);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }




    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);


    return (
        <div className="cielo-link">
            <HeaderComponent {...pg} classNameRow="align-items-center">
                <div className="btn-group-off" role="group">
                    {
                        menus?.map(m => {
                            return (
                                <Link
                                    key={m?.id}
                                    to={m?.url || '/'}
                                    className="btn bg-white ml-2 shadow-sm mb-lg-0 mb-2"
                                    style={{
                                        "color":`${m?.color ? m?.color : '#343a40'}`,
                                        "borderColor":`${m?.color ? m?.color : '#343a40'}`
                                    }}
                                >
                                    <i className={`las la-${m?.icon} mr-1`}></i>{m?.label}
                                </Link>
                            );
                        })
                    }
                </div>
            </HeaderComponent>
            <div className="row ">
                <div className="col-12">
                    <div className="row">
                        <div className="col-lg-4">
                            <DataCardBefore type="success" value={recs?.ativas} title="Recorrências Ativas" icon="check-circle" />
                        </div>
                        <div className="col-lg-4">
                            <DataCardBefore type="danger" value={recs?.canceladas} title="Recorrência Cancelada" icon="times-circle" />
                        </div>
                        <div className="col-lg-4">
                            <DataCardBefore type="primary" value={recs?.valor} title="Total" icon="coins" />
                        </div>
                        <div className="col-lg-7">
                            <div className="card">
                                <div className="card-body">
                                    <GraficoCadastros recs={recs} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="card">
                                <div className="card-body">
                                    <GraficoDias recs={recs} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table text-muted" style={{ "fontSize": "13px" }}>
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>Número</th>
                                            <th>Bandeira</th>
                                            <th>Cobrança</th>
                                            <th>Valor</th>
                                            <th>Cadastro</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            recs?.lista?.map(r => {
                                                var badge_cron = r?.cron_payment ? <small data-toggle="tooltip" title="Cartão Tokenizado" className="ml-1 badge badge-success">Token</small> : '';
                                                return (
                                                    <tr key={r?.id}>
                                                        <td>
                                                            <Link 
                                                                to={r?.url || '/'} 
                                                                className="btn btn-sm p-0 m-0 text-muted"
                                                                title="Cadastro completo da recorrência"
                                                                data-toggle="tooltip"
                                                            >
                                                                {r?.card_nome}
                                                            </Link>
                                                        </td>
                                                        <td>{r?.card_numero}{badge_cron}</td>
                                                        <td>{r?.card_bandeira}</td>
                                                        <td>{r?.data_inicio}</td>
                                                        <td>{r?.valor}</td>
                                                        <td>{r?.created_at}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CieloLinks;