import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { CardContent, CardTitle } from "../../components/Card-z";
import CardLink from "../../components/CardLinks";
import { GroupInput } from "../../components/Form";
import HeaderComponent from "../../components/HeaderComponent";
import { Modal } from "../../components/Modal-z";
import CONNECT_API from "../../configs/connect";
import { useIsLoading } from "../../contexts/LoadingContext";
import $ from 'jquery';
import { existsOrError } from "../../configs/Functions";
import { Redirect } from "react-router-dom";

export const Financeiras = props => {

    const [financeiras, setFinanceiras] = useState([]);
    const [financeiraId, setFinanceiraId] = useState(undefined);
    const [financeirasOff, setFinanceirasOff] = useState([]);
    const [pg, setPg] = useState([]);

    const { setIsLoading } = useIsLoading();
    const { register, handleSubmit } = useForm();


    async function get_data_page() {

        try {
            setIsLoading(true);

            const { data } = await CONNECT_API.get('/financeira');
            existsOrError(data, 'Não foi possivel carregar os dados!');

            setFinanceiras(data?.financeiras);
            setPg(data?.pg);

            const financeirasOff = data?.financeiras.filter(c => !c.situacao);
            setFinanceirasOff(financeirasOff);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }



    //Carregamnento dos dados
    useEffect(() => {
        get_data_page()
        // eslint-disable-next-line
    }, []);


    async function onSubmit(data) {
        try {

            const nome = data?.nome;
            existsOrError(nome, "Nome não informado!");

            setIsLoading(true);
            const novo_financeira = await CONNECT_API.post(pg.end_point, { nome });
            setIsLoading(false);

            $(`.modal`).modal('hide');
            setFinanceiraId(novo_financeira.data.financeira.id);


        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }


    const modal = <Modal modalId={'modal-finandeira'} icon="plus-square" text="Novo" className={`text-success`}>
        <form action="" onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
                <CardTitle>
                    Nova financeira
                </CardTitle>
                <GroupInput col="12" name="nome" label="Nome" register={register} />
                <div className=" col-12">
                    <div className="form-group mb-0">
                        <button className="btn btn-primary w-100 btn-lg">Cadastrar</button>
                    </div>
                </div>
            </CardContent>
        </form>
    </Modal>

    const modal_financeiras_off = <Modal modalId={'financeiras-inativos'} icon="exclamation-circle" text="Inativos" className={'text-warning'}>
        <CardContent>
            <CardTitle>
                Financeiras Inativos
            </CardTitle>
            {
                financeirasOff?.map(c => {
                    return (
                        <div className="col-lg-6" key={c.id}>
                            <CardLink {...c} />
                        </div>
                    )
                })
            }
        </CardContent>
    </Modal>

    const modais = (
        <div className="d-flex">
            {modal}
            {financeirasOff[0] && modal_financeiras_off}
        </div>
    )

    if (financeiraId) return <Redirect to={`${pg.end_point}/${financeiraId}`} />
    return (
        <div className="financeiras">
            <HeaderComponent {...pg} parent={modais} />
            <div className="row">
                <div className="col-lg-8">
                    <div className="row">
                        {
                            financeiras?.map(e => {
                                if (!e.situacao) return "";
                                return (
                                    <div className="col-lg-6  col-md-auto col-12" key={e.id}>
                                        <CardLink {...e} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Financeiras;