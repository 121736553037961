import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CONNECT_API from "../../configs/connect";
import { ShowError } from "../../configs/Functions";
import { useIsLoading } from "../../contexts/LoadingContext";

export const Links = (props) => {
    const [links, setLinks] = useState([]);
    const { setIsLoading } = useIsLoading();

    async function get_data_page() {
        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get("/admin/links");
            setLinks(data.links);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    //   sempre que a página for atualizada
    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    if (!links) return <div className="text-center">Sem Links definido!</div>;

    return (
        <div className="row ">
            <div className="col-lg-8">
                <div className="row">
                    {links?.map((m) => {
                        return (
                            <div
                                className="col-lg-4 col-md-auto col-12"
                                key={m.id}
                            >
                                <Link
                                    to={{ search: m?.search || "#" }}
                                    className={"p-0 m-0 btn w-100"}
                                    style={{ color: m?.color || "#343a40" }}
                                    title={m?.label}
                                >
                                    <div className="card">
                                        <div className="card-body py-4">
                                            <div className="form-row justify-content-center align-items-center text-left flex-lg-nowrap-off">
                                                <div className="col-lg-3 col-auto">
                                                    <i
                                                        style={{
                                                            fontSize: "40px",
                                                        }}
                                                        className={`las la-${m?.icon}`}
                                                    ></i>
                                                </div>
                                                <div className="col-lg-9 col-auto">
                                                    <h4
                                                        className="m-0 p-0"
                                                        style={{
                                                            whiteSpace:
                                                                "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow:
                                                                "ellipsis",
                                                        }}
                                                    >
                                                        {m?.label ||
                                                            m?.nome ||
                                                            m?.name}
                                                    </h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Links;
