import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useIsLoading } from "../../../contexts/LoadingContext";
import { ShowError, TextSlug } from "../../../configs/Functions";
import CONNECT_API from "../../../configs/connect";
import { CardContent, CardTitle } from "../../../components/Card-z";
import ButtonSave from "../../../components/ButtonComponent/ButtonSave";
import HeaderComponent from "../../../components/HeaderComponent";


// HOME DA PÁGINA ANÚNCIOS
export const Permissoes = props => {

    const { setIsLoading } = useIsLoading();
    const { handleSubmit, reset, register } = useForm();

    const [pg, setPg] = useState([]);
    const [config, setConfig] = useState([]);
    const [menus, setMenus] = useState([]);

    async function get_data_page() {
        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/portal-do-filiado/permissoes`);
            setConfig(data?.config);
            setMenus(data?.menu_tree);
            setPg(data?.pg);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    async function onSubmit(formData) {
        try {

            setIsLoading(true);
            await CONNECT_API.put(`/portal-do-filiado/permissoes`, { ...formData });
            setIsLoading(false);
        } catch (error) {
            ShowError(error);
        }
    }

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        reset({ ...config })
        // eslint-disable-next-line
    }, [config]);



    function menu_tree(m) {
        const mp = m.map((menu) => {
            const ul = (
                <ul key={menu.id} className="">
                    <li
                        className={
                            menu.sub_menu.length
                                ? "sub_menu d-block"
                                : "menu d-block"
                        }
                    >
                        <div className="form-group">
                            <div className="custom-control custom-checkbox">
                                <input
                                    name="menus"
                                    defaultChecked={menu.autorizado && "checked"}
                                    {...register("menus")}
                                    type="checkbox"
                                    className={"custom-control-input"}
                                    id={`${TextSlug(menu.label)}-menu-${menu.id
                                        }`}
                                    value={menu.id}
                                />
                                <label
                                    className="custom-label custom-control-label"
                                    htmlFor={`${TextSlug(menu.label)}-menu-${menu.id
                                        }`}
                                >
                                    {menu.label}
                                </label>
                            </div>
                        </div>
                        {menu_tree(menu.sub_menu)}
                    </li>
                </ul>
            );

            return ul;
        });
        return mp;
    }


    return (
        <>
            <div className="configuracao">
                <HeaderComponent {...pg} />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-lg-6">
                            <CardContent>
                                <CardTitle className="mb-2">
                                    Config. Menus
                                </CardTitle>
                                <div className="col-12">
                                    {menu_tree(menus)}
                                </div>
                                <div className="col-12 mt-4">
                                    <div className="form-group text-center">
                                        <ButtonSave type="submit" className="px-5" text="Atualizar Permissões" />
                                    </div>
                                </div>
                            </CardContent>
                        </div>
                        <div className="col-lg-6 d-none">
                            <CardContent>
                                <CardTitle className="mb-2">
                                    Config. Permissões
                                    <small className="d-block mt-2" style={{ "fontSize": "12px" }}>
                                        Defina as configurações de identificação das páginas.
                                    </small>
                                </CardTitle>
                                <div className="col-12 mt-4">
                                    <div className="form-group">
                                        <ButtonSave type="submit" className="float-right px-5" />
                                    </div>
                                </div>
                            </CardContent>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}


export default Permissoes;