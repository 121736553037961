import React from "react";
import { formatCPF, formatDate, formatMatricula } from "../../../../../configs/Functions";

export const CardCadastroConfigFiliado = (props) => {

    let filiado = props.filiado;

    return (
        <>
            <div className="cadastro mb-3">
                <div className="text-muted">
                    <b>
                        <i className="las la-passport text-info"></i> CADASTRO
                    </b>
                    <small className="float-right">
                        {filiado?.cat?.map((c) => (
                            <small
                                key={c?.id}
                                className={`ml-2 px-2 badge text-uppercase ${
                                    c?.color ? "text-white" : ""
                                }`}
                                style={{ background: c?.color }}
                            >
                                {c?.nome}
                            </small>
                        ))}
                    </small>
                    <hr className="mt-1 mb-2" />
                </div>
                <div className="text-muted mb-1">
                    {filiado?.nome}{" "}
                    <small className="float-right">{filiado?.situacao}</small>
                </div>
                <div className="text-muted mb-1">
                    {formatCPF(filiado?.cpf)}{" "}
                    <small className="float-right" title="Data de Nascimento">
                        {formatDate(filiado?.nascimento)}
                    </small>
                </div>
                <div className="text-muted mb-1">
                    {formatMatricula(filiado?.matricula)}{" "}
                    <small className="float-right" title="Data do Cadastro">
                        {formatDate(filiado?.data_cadastro)}
                    </small>
                </div>
                <div className="text-muted mb-1">
                    {filiado?.email || "E-MAIL"}{" "}
                    <small className="float-right" title="Whatsapp">
                        {filiado?.whats || "WHATSAPP"}
                    </small>
                </div>
            </div>
        </>
    );
};

export default CardCadastroConfigFiliado;