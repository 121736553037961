import React, { useEffect, useState } from "react";
import HeaderComponent from "../../../components/HeaderComponent";
import CONNECT_API from "../../../configs/connect";
import { existsOrError, formatCoin, ShowError } from "../../../configs/Functions";
import { useIsLoading } from "../../../contexts/LoadingContext";
import { Link, withRouter } from "react-router-dom";

export const CieloPagamentoById = props => {

    const [pg, setPg] = useState([]);
    const [pagamento, setPagamento] = useState([]);

    const { setIsLoading } = useIsLoading();


    async function get_data_page() {

        try {
            setIsLoading(true);
            const id = props.match.params.id;
            const { data } = await CONNECT_API.get(`/cielo/pagamento/${id}`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setPagamento(data?.pagamento);
            setPg(data.pg);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }




    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);


    // TRATANDO OS STATUS DE PAGAMENTO
    var paymentStatus = '';
    switch (Number(pagamento?.pag_status)) {
        case 2:
            paymentStatus = <span className="badge badge-success text-light px-4 py-2">Sucesso</span>;
            break;
        case 10:
            paymentStatus = <span className="badge badge-info text-light px-4 py-2">Estornado</span>;
            break;
        default:
            paymentStatus = <span className="badge badge-warning text-light px-4 py-2">Não Definido</span>;
            break;
    }



    return (
        <div className="cielo-pagamento">
            <HeaderComponent {...pg} />
            <div className="row justify-content-center">
                <div className="col-lg-6">
                    <div className="card bg-light">
                        <div className="card-body position-relative">
                            <div className="status text-right">
                                {paymentStatus}
                            </div>
                            <div className="text-center mb-3">
                                <b>{pagamento?.pag_tipo}</b>
                            </div>
                            <div className="text-muted- mb-4">
                                <div className="form-row justify-content-between mb-3">
                                    <div className="col-lg-auto">Cliente</div>
                                    <div className="col-lg-auto">{pagamento?.pag_cliente}</div>
                                </div>
                                <div className="form-row justify-content-between mb-3">
                                    <div className="col-lg-auto">Parcela</div>
                                    <div className="col-lg-auto">{pagamento?.pag_parcela}</div>
                                </div>
                                <div className="form-row justify-content-between mb-3">
                                    <div className="col-lg-auto">Data do Pagamento</div>
                                    <div className="col-lg-auto">{pagamento?.pag_data}</div>
                                </div>
                                <div className="form-row justify-content-between mb-3">
                                    <div className="col-lg-auto">Valor do pagamento</div>
                                    <div className="col-lg-auto">{formatCoin(pagamento?.pag_valor)}</div>
                                </div>
                                {
                                    pagamento?.pag_chargeBackData ?
                                        <div className="form-row justify-content-between mb-3">
                                            <div className="col-lg-auto">Data do Estorno</div>
                                            <div className="col-lg-auto">{pagamento?.pag_chargeBackData}</div>
                                        </div> : ""

                                }
                                {
                                    pagamento?.pag_chargeBack ?
                                        <div className="form-row justify-content-between mb-3">
                                            <div className="col-lg-auto">Valor Estornado</div>
                                            <div className="col-lg-auto text-danger">{formatCoin(pagamento?.pag_chargeBack)}</div>
                                        </div> : ''
                                }
                                <div className="form-row justify-content-between mb-3">
                                    <div className="col-lg-auto">Código da autorização</div>
                                    <div className="col-lg-auto">{pagamento?.pag_code_autorizacao}</div>
                                </div>
                                <div className="form-row justify-content-between mb-3">
                                    <div className="col-lg-auto">Ordem do pagamento</div>
                                    <div className="col-lg-auto">{pagamento?.pag_ordem}</div>
                                </div>
                                <div className="form-row justify-content-between mb-3">
                                    <div className="col-lg-auto">Recorrência do pagamento</div>
                                    <div className="col-lg-auto"><Link to={pagamento?.pag_url_recorrencia || "/"} className="btn bnt-sm p-0 m-0">{pagamento?.pag_recorrencia}</Link></div>
                                </div>
                            </div>
                            {
                                pagamento?.pag_card ?
                                    <div className="border rounded p-3">
                                        <div className="text-center">
                                            <b>Dados do cartão</b>
                                        </div>
                                        <div className="form-row justify-content-between">
                                            <div className="col-lg-auto mb-1">{pagamento?.pag_card?.nome}</div>
                                            <div className="col-lg-auto  mb-1">{pagamento?.pag_card?.bandeira}</div>
                                        </div>
                                        <div className="form-row justify-content-between">
                                            <div className="col-lg-auto"><b>{pagamento?.pag_card?.numero}</b></div>
                                            <div className="col-12-auto">{pagamento?.pag_card?.vencimento}</div>
                                        </div>
                                    </div>
                                    : ""
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(CieloPagamentoById);