import React from "react";

export const CardContent = props => {
    
    return(
        <div className={`card ${props.classNameCard || ''}`} style={{"marginBottom": "30px"}}>
            <div className={`card-body ${props.classNameBody || ''}`}>
                <div className={`row ${props.classNameRow || ''}`}>
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export const CardTitle = props=>{
    return(
        <div className={`col-12 text-center text-lg-left ${props.className ? props.className : ' mb-4'}`}>
            <h4>{props.children}</h4>
            <hr />
        </div>
    );
}

// eslint-disable-next-line
export default { CardContent, CardTitle };