import React, { useState } from "react";
import { CardContent } from "../../../../components/Card-z";
import { Modal } from "../../../../components/Modal-z";
import { useForm } from 'react-hook-form';
import { GroupInput, GroupRadio } from "../../../../components/Form";
import ButtonSave from "../../../../components/ButtonComponent/ButtonSave";
import ImputMask from 'inputmask';
import { useIsLoading } from "../../../../contexts/LoadingContext";
import CONNECT_API from "../../../../configs/connect";
import { existsOrError, formatDate, SetNumber, ShowError } from "../../../../configs/Functions";
import HeaderComponent from "../../../../components/HeaderComponent";

export const ModalNewTerminal = props => {

    const { handleSubmit, register } = useForm();
    const { setIsLoading } = useIsLoading();

    const [pg, setPg] = useState([]);
    const [terminalAtivo, setTerminalAtivo] = useState([]);
    const [terminais, setTerminais] = useState([]);
    // SELECIONADO A OPÇÃO DE PAGAMENTO NO CARTÃO 
    const [card, setCard] = useState(undefined);


    async function get_data_page() {

        try {
            const id = props.filiado_id;
            existsOrError(id, "ID filiado não informado!");

            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/filiado/${id}/terminal/change-terminal`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setTerminalAtivo(data.terminal_ativo);
            setTerminais(data.terminais);
            setPg(data.pg);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }


    async function modalShow() {

        ImputMask({ "mask": '999' }).mask('[name="card_seguranca"]');
        ImputMask({ "mask": '9999 9999 9999 9999' }).mask('[name="card_numero"]');
        ImputMask({ "mask": '99/9999' }).mask('[name="card_vencimento"]');

        // Carrega os dados
        await get_data_page();
    }

    async function onSubmit(form_data) {

        try {
            const terminal_id = SetNumber(form_data.terminal_id);
            existsOrError(terminal_id, "Novo terminal não selecionado!");

            // OBRIGATÓRIO
            const fields = {
                terminal_id: terminal_id
            }

            const card_nome = form_data.card_nome;
            const card_vencimento = form_data.card_vencimento;
            const card_numero = SetNumber(form_data.card_numero);
            const card_seguranca = SetNumber(form_data.card_seguranca);
            if (card) {
                existsOrError(card_nome, "Nome impresso no cartão não informado!");
                existsOrError(card_numero, "Número do cartão não informado!");
                existsOrError(card_vencimento, "Vencimento do cartão não informado!");
                existsOrError(card_seguranca, "Código de segurança do cartão não informado!");

                fields.card_nome = card_nome;
                fields.card_numero = card_numero;
                fields.card_vencimento = card_vencimento;
                fields.card_seguranca = card_seguranca;
            }


            setIsLoading(true);
            const ok = await CONNECT_API.post(pg.end_point, { ...fields });
            if (ok && props.loaded) await props.loaded();
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }


    return (
        <Modal modalId="modal-terminal" text={props.text} className="btn btn-sm btn-info" modalShow={modalShow}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                    <div className="col-12">
                        <HeaderComponent {...pg} />
                    </div>
                    <div className="col-12 mb-3">
                        <div className="row">
                            <div className="col-lg-6">
                                {
                                    terminalAtivo &&
                                    <div className="card border-OFF rounded-OFF">
                                        <div className="card-body">
                                            <div className="form-row justify-content-between">
                                                <div className="col-12 text-right mb-3">
                                                    <span className="badge badge-success px-3 py-1">Ativo</span>
                                                </div>
                                                <div className="col-lg-auto">
                                                    <b>{terminalAtivo?.banco} → {terminalAtivo?.terminal}</b>
                                                </div>
                                                <div className="col-lg-auto">
                                                    <i className="las la-calendar-check mr-2"></i>
                                                    {formatDate(terminalAtivo?.created_at)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="col-lg-6">
                                <div className="terminais">
                                    <div className="title mb-3">
                                        <b>Selecione novo terminal</b>
                                    </div>
                                    <div className="row">
                                        {
                                            terminais?.map(t => {
                                                return <GroupRadio
                                                    key={t?.id}
                                                    name="terminal_id"
                                                    id={`option-tr-${t?.id}`}
                                                    value={t?.id}
                                                    col="12"
                                                    label={`${t?.terminal} → ${t?.banco}`}
                                                    onChange={() => setCard(t?.card)}
                                                    register={register} />
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className={`collapse ${card ? "show" : ""}`} id="collapse-card">
                            <div className="row justify-content-center">
                                <div className="col-lg-8">
                                    <div className="rounded border p-4">
                                        <div className="text-center mb-3">
                                            <b>Dados do cartão</b>
                                        </div>
                                        <div className="row">
                                            <GroupInput col="12" name="card_nome" label="Nome impresso no Cartão" register={register} />
                                            <GroupInput col="12" name="card_numero" label="Número do Cartão" register={register} />
                                            <GroupInput col="6" classNameGroup="mb-0" name="card_vencimento" label="Data de vencimento" register={register} />
                                            <GroupInput col="6" classNameGroup="mb-0" name="card_seguranca" label="Código de segurança" register={register} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <ButtonSave type="submit" className="float-right" />
                    </div>
                </CardContent>
            </form>
        </Modal>
    )
}


export default ModalNewTerminal;