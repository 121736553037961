import React from "react";
import CONNECT_API from "../../../../../configs/connect";
import { ShowError } from "../../../../../configs/Functions";
import { useIsLoading } from "../../../../../contexts/LoadingContext";


export const CardCron = props => {

    const a = props.alert;
    const { setIsLoading } = useIsLoading();


    async function onClickDelete(id_cron) {
        if (!id_cron || !props.leadId) return false;

        try {
            setIsLoading(true);
            await CONNECT_API.del(`/leads/${props.leadId}/cron/${id_cron}`);
            setIsLoading(false);

            if( props?.changedLoad ) await props.changedLoad();

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    return (
        <div className="alert-item">
            <div className="card mb-3 position-relative">
                <div className="card-body">
                    <div className="mb-2">
                        <span className="" style={{ "fontSize": "13px" }}>
                            {
                                a?.finalizado ?
                                    <span style={{ "fontSize": "13px" }} className="badge badge-success">
                                        Finalizado <i className="las la-check-circle text-white"></i>
                                    </span>
                                    :
                                    <span className="">
                                        Agendado<span onClick={() => onClickDelete(a?.id)} className="ml-2 badge badge-danger btn " title="Excluir Alerta">
                                            <i className="las la-trash"></i>
                                        </span>
                                    </span>
                            }
                            <span className="float-right">
                                <i className="las la-clock mr-1"></i>
                                {a?.start}
                            </span>
                        </span>
                    </div>
                    <h4 style={{ "fontSize": "25px" }}>
                        <b>{a.title}</b>
                    </h4>
                    <div className="desc">{a?.descricao}</div>
                </div>
            </div>
        </div>
    )
}


export default CardCron;