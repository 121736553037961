import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import FileArray from "../../../../components/File/Array/input";
import HeaderComponent from "../../../../components/HeaderComponent";
import CONNECT_API from "../../../../configs/connect";
import { existsOrError, ShowError } from "../../../../configs/Functions";
import { useIsLoading } from "../../../../contexts/LoadingContext";

export const FiliadoAnexos = props => {

    // PARAMS
    const filiado_id = props.match.params.id;

    const [pg, setPg] = useState([]);
    const [fileList, setFileList] = useState([]);
    const { register, reset, handleSubmit } = useForm();
    const { setIsLoading } = useIsLoading();

    async function get_data_page() {

        try {
            setIsLoading(true);

            const { data } = await CONNECT_API.get(`/filiado/${filiado_id}/anexos`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setFileList(data.files);
            setPg(data.pg);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }

    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    async function onSubmit(data) {
        try {
            const files = data.files;
            setIsLoading(true);
            await CONNECT_API.post(`${pg.end_point}`, { files });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    useEffect(() => {

        if (!pg?.end_point) return false;

        handleSubmit(onSubmit)();
        // eslint-disable-next-line
    }, [fileList]);







    return (
        <div className="anexos">
            <HeaderComponent {...pg} parent={''} />
            <div className="files">
                <form onSubmit={handleSubmit(onSubmit)}></form>
                <FileArray register={register} reset={reset} fileList={fileList} setFileList={setFileList} />
            </div>
        </div>
    );
}


export default withRouter(FiliadoAnexos);
