import React, { useState } from "react";
import { CardContent } from "../../../../components/Card-z";
import { Modal } from "../../../../components/Modal-z";
import { useForm } from 'react-hook-form';
import { GroupRadio } from "../../../../components/Form";
import ButtonSave from "../../../../components/ButtonComponent/ButtonSave";
import ImputMask from 'inputmask';
import { useIsLoading } from "../../../../contexts/LoadingContext";
import CONNECT_API from "../../../../configs/connect";
import { existsOrError, formatCoin, formatDate, SetNumber, ShowError } from "../../../../configs/Functions";
import HeaderComponent from "../../../../components/HeaderComponent";

export const ModalNewPlano = props => {

    const { handleSubmit, register } = useForm();
    const { setIsLoading } = useIsLoading();

    const [pg, setPg] = useState([]);
    const [planoAtivo, setPlanoAtivo] = useState([]);
    const [planos, setPlanos] = useState([]);


    async function get_data_page() {

        try {
            const id = props.filiado_id;
            existsOrError(id, "ID filiado não informado!");

            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/filiado/${id}/plano/change-plano`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setPlanoAtivo(data.plano_ativo);
            setPlanos(data.planos);
            setPg(data.pg);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }


    async function modalShow() {

        ImputMask({ "mask": '999' }).mask('[name="card_seguranca"]');
        ImputMask({ "mask": '9999 9999 9999 9999' }).mask('[name="card_numero"]');
        ImputMask({ "mask": '99/9999' }).mask('[name="card_vencimento"]');

        // Carrega os dados
        await get_data_page();
    }

    async function onSubmit(form_data) {

        try {
            const plano_id = SetNumber(form_data.plano_id);
            existsOrError(plano_id, "Novo terminal não selecionado!");

            // OBRIGATÓRIO
            const fields = {
                plano_id: plano_id
            }

            setIsLoading(true);
            const ok = await CONNECT_API.post(pg.end_point, { ...fields });
            if (ok && props.loaded) await props.loaded();
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }


    return (
        <Modal modalId="modal-plano" text={props.text} className="btn btn-sm btn-success" modalShow={modalShow}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                    <div className="col-12">
                        <HeaderComponent {...pg} />
                    </div>
                    <div className="col-12 mb-3">
                        <div className="row justify-content-center">
                            <div className="col-lg-7">
                                {
                                    planoAtivo &&
                                    <div className="card border-OFF rounded-OFF">
                                        <div className="card-body">
                                            <div className="form-row justify-content-between">
                                                <div className="col-12 text-right mb-3">
                                                    <span className="badge badge-success px-3 py-1">Ativo</span>
                                                </div>
                                                <div className="col-lg-auto">
                                                    <b>{planoAtivo?.nome}</b>
                                                </div>
                                                <div className="col-lg-auto text-right">
                                                    <div>
                                                        <i className="las la-calendar-check mr-2"></i>
                                                        {formatDate(planoAtivo?.created_at)}
                                                    </div>
                                                    <div>
                                                        {formatCoin(planoAtivo?.valor)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="col-lg-7">
                                <div className="terminais">
                                    <div className="title mb-3">
                                        <b>Selecione novo plano</b>
                                    </div>
                                    <div className="row">
                                        {
                                            planos?.map(t => {

                                                return <GroupRadio
                                                    key={t?.id}
                                                    name="plano_id"
                                                    id={`option-tr-${t?.id}`}
                                                    value={t?.id}
                                                    col="12"
                                                    label={`${t?.nome} ${t?.checked ? '(Plano Atual)':''}`}
                                                    register={register} />
                                            })
                                        }
                                        <div className="col-lg-12">
                                            <ButtonSave type="submit" className="float-right" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </form>
        </Modal>
    )
}


export default ModalNewPlano;