import React, { useEffect, useState } from "react";
import GroupButtons from "../../../components/ButtonComponent/ButtonsGroup";
import HeaderComponent from "../../../components/HeaderComponent";
import CONNECT_API from "../../../configs/connect";
import { existsOrError, ShowError } from "../../../configs/Functions";
import { useIsLoading } from "../../../contexts/LoadingContext";
import { Redirect } from "react-router-dom";
import $ from 'jquery';
import ModalAddLead from "./lead/cadastro/ModalAddLead";


export const LeadsDashboardLinks = props => {
    const { setIsLoading } = useIsLoading();
    const [links, setLinks] = useState([]);
    const [dados, setDados] = useState([]);
    const [leadId, setLeadId] = useState(undefined);

    const [pg, setPg] = useState([]);

    const modalLeadId = 'modal-add-lead';

    async function get_data_page() {

        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/leads`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setLinks(data?.links);
            setDados(data.dados);
            setPg(data.pg);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {

        if (links?.length)
            // Show modal
            document.querySelector('[href="/leads/novo"]').addEventListener('click', function (e) {
                e.preventDefault();
                $(`#${modalLeadId}`).modal('show');
            });
        // eslint-disable-next-line
    }, [links]);

    if (leadId) return <Redirect to={`/leads/${leadId}`} />
    return (
        <div className="LeadsDashboardLinks">
            <HeaderComponent {...pg} >
                <GroupButtons links={links} btnTitle="Lead" btnClass="px-4 btn-secondary" btnIcon="stream" />
                <ModalAddLead modalId={modalLeadId} setLeadId={setLeadId} />
            </HeaderComponent>
            <div className="body-dados">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="mb-4">Situação dos Leads</h4>
                                <ul className="list-group">
                                    {
                                        dados?.status?.map((s, index) =>
                                            <li key={index} className="list-group-item d-flex justify-content-between align-items-center">{s?.label} <span className="badge badge-primary badge-pill px-2">{s?.value}</span></li>)
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <h4 className="mb-4">Informações complementares</h4>
                                <ul className="list-group">
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        E-mail no cadastro
                                        <span>
                                            <span className="badge badge-success badge-pill px-2 mr-2">{dados?.contato?.email?.sim} <i className="las la-check-circle"></i></span>
                                            <span className="badge badge-danger badge-pill px-2">{dados?.contato?.email?.nao} <i className="las la-exclamation-circle"></i></span>
                                        </span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        Telefone no cadastro
                                        <span>
                                            <span className="badge badge-success badge-pill px-2 mr-2">{dados?.contato?.tel?.sim} <i className="las la-check-circle"></i></span>
                                            <span className="badge badge-danger badge-pill px-2">{dados?.contato?.tel?.nao} <i className="las la-exclamation-circle"></i></span>
                                        </span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center">
                                        Whatsapp no cadastro
                                        <span>
                                            <span className="badge badge-success badge-pill px-2 mr-2">{dados?.contato?.whats?.sim} <i className="las la-check-circle"></i></span>
                                            <span className="badge badge-danger badge-pill px-2">{dados?.contato?.whats?.nao} <i className="las la-exclamation-circle"></i></span>
                                        </span>
                                    </li>
                                    <li className="list-group-item d-flex justify-content-between align-items-center"><b>Total de Leads</b> <span className="badge badge-success badge-pill px-2">{dados?.total}</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="mb-4">Origem dos Leads</h4>
                                <ul className="list-group">
                                    {
                                        dados?.origem?.map((s, index) =>
                                            <li key={index} className="list-group-item d-flex justify-content-between align-items-center">{s?.label} <span className="badge badge-secondary badge-pill px-2">{s?.value}</span></li>)
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default LeadsDashboardLinks