import React, { useEffect, useState } from "react";
import CardLink from "../../../../components/CardLinks";
import HeaderComponent from "../../../../components/HeaderComponent";
import CONNECT_API from "../../../../configs/connect";
import { ShowError } from "../../../../configs/Functions";
import { useIsLoading } from "../../../../contexts/LoadingContext";


export const NotificacaoPagamento = props => {

    const { setIsLoading } = useIsLoading();
    const [links, setLinks] = useState([]);
    const [pg, setPg] = useState([]);

    async function get_data_page() {

        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/filiado/configuracao?get=notificacao-pagamento`);
            setLinks(data?.links);
            setPg(data.pg);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="config-filiados-notificacao-pagamento">
            <HeaderComponent {...pg} />
            <div className="row ">
                <div className="col-lg-8">
                    <div className="row">
                        {
                            links?.map(m => {
                                return (
                                    <div className="col-lg-4 col-md-auto col-12" key={m.id}>
                                        <CardLink {...m} />
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotificacaoPagamento;