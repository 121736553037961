import React from 'react';
import Cards from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';


export const CredCard = props => {

    const cvc = props.cvc || "000";
    const expiry = props.expiry || "00/00";
    const focus = props.focus;
    const name = props.name || "NOME";
    const number = props.number || "0000 0000 0000 0000";

    return (
        <div className="cred-card-view ">
            <Cards
                cvc={cvc}
                expiry={expiry}
                focused={focus}
                name={name}
                number={number}
                preview={false}
                issuer={"mastercard"}
                placeholders={{
                    name: "NOME NO CARTÃO",
                    expiryvalid: "Vencimento"
                }}
            />
        </div>
    );
}


export default CredCard;