import React from "react";
import { Link } from "react-router-dom";


export const GroupButtons = props => {

    if (props.links && (typeof (props.links) == 'object')) {
        const links = props.links;

        return (
            <div className="btn-group" role="group">
                <button id={`${props?.btnId ? props?.btnId : ''}`} type="button" className={`btn dropdown-toggle ${props?.btnClass ? props?.btnClass : 'btn-secondary'}`} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <div className="form-row align-items-center flex-nowrap">
                        {props?.btnIcon
                            ?
                            <div className="col-auto">
                                <i className={`las la-${props?.btnIcon}`}></i>
                            </div>
                            : ''}
                        <div className="col">
                            {props?.btnName || props?.btnTitle}
                        </div>
                    </div>

                </button>
                <div className="dropdown-menu" aria-labelledby="">
                    {
                        links.map((l, index) => {
                            return (
                                <Link key={index} {...l.attr} className={`dropdown-item bg-transparent ${l.class ? l.class : ''}`} style={{ "color": l?.color || '' }} to={l?.url || l?.link || '/'}>
                                    <div className="form-row align-items-center flex-nowrap">
                                        {l?.icon
                                            ?
                                            <div className="col-auto">
                                                <i className={`las la-${l?.icon}`}></i>
                                            </div>
                                            : ''}
                                        <div className="col">
                                            {l?.nome || l?.label}
                                        </div>
                                    </div>
                                </Link>
                            )
                        })
                    }
                </div>
            </div>
        );
    }
    return (<></>);
}

export default GroupButtons;