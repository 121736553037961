import React from "react";


export const CardAnotacao = props => {

    const t = props.anotacao;
    const index = props.index;

    return (
        <div key={t?.id} className="time-line-item">
            <div className={`form-row ${index & 1 ? "justify-content-end text-left" : "justify-content-start text-right"}`} id={index & 1 ? "p" : "i"}>
                <div className="col-lg-6">
                    <div className="component" style={{ "fontSize": "14px" }}>
                        <div className="card mb-0">
                            <div className="card-body">
                                <div className="label mb-2">
                                    {
                                        index & 1 ?
                                            <>
                                                <span className="mr-2">
                                                    <i className="las la-user-tie mr-1"></i>
                                                    {t?.colaborador || 'Admin'}
                                                </span>
                                                <span>
                                                    <i className="las la-calendar mr-1"></i>
                                                    {t?.created_at}
                                                </span>
                                            </> :
                                            <>
                                                <span>
                                                    <i className="las la-calendar mr-1"></i>
                                                    {t?.created_at}
                                                </span>
                                                <span className="ml-2">
                                                    <i className="las la-user-tie mr-1"></i>
                                                    {t?.colaborador || 'Admin'}
                                                </span>

                                            </>

                                    }
                                </div>
                                <div className="resumo" dangerouslySetInnerHTML={{ __html: t.txt }}></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default CardAnotacao;