import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderComponent from "../../../../components/HeaderComponent";
import { TableHover, Tbody, Td, Thead, Tr } from "../../../../components/Table-z";
import CONNECT_API from "../../../../configs/connect";
import { ShowError, swalConfirm } from "../../../../configs/Functions";
import { useIsLoading } from "../../../../contexts/LoadingContext";
import TotaisFiliados from "./Totais";

export const PageFiliadosByFornecedor = props => {

    // PARAMS
    const tipo = "PJ";
    const fornecedor_id = props.match.params.id;

    const { setIsLoading } = useIsLoading();
    const [pg, setPg] = useState([]);
    const [filiados, setFiliados] = useState([]);
    const [debitos, setDebitos] = useState([]);
    const [cadastro, setCadastro] = useState([]);

    async function get_data_page() {

        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/fornecedor/${tipo}/${fornecedor_id}/filiados`);
            setPg(data?.pg);
            setFiliados(data?.filiados);
            setDebitos(data?.debitos);
            setCadastro(data?.cadastro);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }


    async function desvincularFiliado(id) {
        if (!id) return false;

        try {

            const filiado = filiados.filter(f => f.filiado_id === id)[0];
            const ok = await swalConfirm({
                icon: 'warning',
                html: `Deseja desvincular <b>${filiado?.nome}</b></br> da empresa <b>${cadastro?.nome}</b>?`,
                btnText: 'Sim',
                btnText2: 'Não'
            });
            if (ok?.isConfirmed) {
                setIsLoading(true);
                const ok = await CONNECT_API.put(`/fornecedor/${tipo}/${fornecedor_id}/filiados/desvincular`, { desvincular: true, filiado_id: id });
                if (ok) await get_data_page();
                setIsLoading(false);
            }
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }

    }

    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="fornecedor-filiado">
            <HeaderComponent {...pg} />
            <div className="card">
                <div className="card-body">
                    {!filiados.length && <div className="text-center"><i>Sem filiados vinculado a este fornecedor!</i></div>}
                    <div className={!filiados.length ? 'd-none' : ''}>
                        <TotaisFiliados dados={debitos} filiados={filiados} cadastro={cadastro} loaded={get_data_page} />
                        <TableHover>
                            <Thead th={["Situação", "Nome", "CPF", "Matricula", "Nascimento", "Cadastrado", "Vendedor", "Ação"]} />
                            <Tbody>
                                {
                                    filiados?.map(f => {

                                        var situacao = '';
                                        switch (f?.situacao) {
                                            case 'Regular':
                                                situacao = <span className="badge badge-success">{f?.situacao}</span>
                                                break;
                                            default:
                                                situacao = <span className="badge badge-danger">{f?.situacao}</span>
                                                break;
                                        }

                                        return (
                                            <Tr key={f?.filiado_id}>
                                                <Td>{situacao}</Td>
                                                <Td>
                                                    <Link className="text-muted" to={f?.url || ''}>
                                                        {f?.nome}
                                                    </Link>
                                                </Td>
                                                <Td>{f?.cpf}</Td>
                                                <Td>{f?.matricula}</Td>
                                                <Td>{f?.nascimento}</Td>
                                                <Td>{f?.created_at}</Td>
                                                <Td>{f?.vendedor}</Td>
                                                <Td>
                                                    <Link title="Abrir cadastro" style={{ "fontSize": "15px" }} className="text-muted p-0 px-2 d-inline-block" to={f?.url || ''}>
                                                        <i className="las la-external-link-alt text-success m-0"></i>
                                                    </Link>
                                                    <button
                                                        className="btn btn-sm m-0 pt-0 border-0  text-danger" title="Desvincular"
                                                        style={{ "fontSize": "15px" }}
                                                        onClick={() => desvincularFiliado(f?.filiado_id)}>
                                                        <i className="las la-user-slash"></i>
                                                    </button>
                                                </Td>
                                            </Tr>
                                        )
                                    })
                                }
                            </Tbody>
                        </TableHover>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(PageFiliadosByFornecedor);