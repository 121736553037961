import React from "react";


// HOME DA PÁGINA ANÚNCIOS
export const Mensagens = props => {
    return (
        <>
            <div className="mensagens">
                Mensagens
            </div>
        </>
    );
}


export default Mensagens;