import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useIsLoading } from "../../../../contexts/LoadingContext";
import CONNECT_API from "../../../../configs/connect";
import { Modal } from "../../../../components/Modal-z";
import { CardContent, CardTitle } from "../../../../components/Card-z";
import { GroupInput, GroupSelect, GroupText } from "../../../../components/Form";
import { ShowError } from "../../../../configs/Functions";
import FileSingle from "../../../../components/File/Single/input";



export const ModalEditCtt = props => {

    const [fileListImage, setFileListImage] = useState([]);
    const [fileListIcon, setFileListIcon] = useState([]);
    const { handleSubmit, reset, register } = useForm();
    const { setIsLoading } = useIsLoading();
    const ctt = props.ctt;


    async function onSubmit(formData) {

        try {
            // existsOrError(formData.name, 'Nome não informado!');
            setIsLoading(true);

            await CONNECT_API.put(`/portal-do-filiado/painel?ctt=${ctt?.id}`, { ...formData });
            if (typeof (props?.get_data_page) == 'function') props.get_data_page();

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    async function onDelete() {

        try {
            setIsLoading(true);
            await CONNECT_API.del(`/portal-do-filiado/painel?ctt=${ctt?.id}`);
            if (typeof (props?.get_data_page) == 'function') props.get_data_page();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    useEffect(() => {
        var config = ctt?.config[ctt.type];
        reset({ ...ctt, ...config });
        if (typeof (config?.fileIcon) == 'object' && config?.fileIcon) setFileListIcon([config?.fileIcon]);
        if (typeof (config?.fileImage) == 'object' && config?.fileImage) setFileListImage([config?.fileImage]);
        // eslint-disable-next-line
    }, [ctt]);

    return <Modal modalId={`modal-edit-conteudo-b-${ctt?.id}`} text={`${ctt.type}`} icon="edit" className="btn btn-sm btn-outline-info p-0 px-1" sm={true} modalHide={() => reset()}>
        <CardContent>
            <CardTitle>
                {`Editando Conteúdo ${ctt.type}`}
            </CardTitle>
            <div className="col-12">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        {/* <GroupInput name="name" col="12" required label="Nome" register={register} /> */}
                        {
                            ctt.type === 'Link' &&
                            <>
                                <GroupInput name="title" col="12" required label="Título" register={register} />
                                <GroupInput name="link" col="12" required label="Link" register={register} />
                                <div className="col-12 mb-3">
                                    <h4 className="mb-2"><small>Icone</small></h4>
                                    <FileSingle
                                        type="image"
                                        fileList={fileListIcon}
                                        setFileList={setFileListIcon}
                                        register={register}
                                        reset={reset}
                                        label=""
                                        name="icone" />
                                </div>
                            </>
                        }
                        {
                            ctt.type === 'Imagem' &&
                            <>
                                <div className="col-12 mb-3">
                                    <h4 className="mb-2"><small>Imagem</small></h4>
                                    <FileSingle
                                        type="image"
                                        fileList={fileListImage}
                                        setFileList={setFileListImage}
                                        register={register}
                                        reset={reset}
                                        label=""
                                        name="imagem" />
                                </div>
                                {/* <GroupInput name="imagem" col="12" required label="Imagem" register={register} /> */}
                                <GroupInput name="title" col="12" label="Título" register={register} />
                                <GroupText name="legenda" col="12" label="Legenda" register={register} />
                                <GroupSelect name="display" col="12" label="Tipo Exibição" register={register}>
                                    <option value="">Imagem Normal</option>
                                    <option value="rounded-circle">Icone Circular</option>
                                    <option value="rounded img-fluid">Imagem Arendondada</option>
                                    <option value="img-thumbnail img-fluid">Imagem Thumbnail</option>
                                </GroupSelect>
                                <GroupInput name="link" col="12" label="Link" register={register} />
                            </>
                        }
                        {
                            ctt.type === 'Texto' &&
                            <>
                                <GroupInput name="link" col="12" label="Link" register={register} />
                                <GroupText name="texto" col="12" required label="Texto" row="10" register={register} />
                            </>
                        }
                        {
                            ctt.type === 'Título' &&
                            <>
                                <GroupInput name="title" col="12" required label="Título" register={register} />
                                <GroupInput name="subTitle" col="12" label="Sub Título" register={register} />
                                <GroupInput name="link" col="12" label="Link" register={register} />
                            </>
                        }
                        <GroupInput name="class" col="12" label="Classe Personalizada" register={register} />
                        <GroupInput name="or" col="12" label="Ordem de Exibição" register={register} />
                    </div>
                    <div className="form-group text-center">
                        <button type="button" onClick={onDelete} className="btn btn-danger bg-gradient mr-2">Excluir</button>
                        <button type="submit" className="btn btn-primary bg-gradient">Atualizar</button>
                    </div>
                </form>
            </div>
        </CardContent>
    </Modal>
}

export default ModalEditCtt;