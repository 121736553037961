import React from "react";

export const CardEmpresaConfigFiliado = (props) => {
    let filiado = props.filiado;

    return (
        <>
            <div className="empresa mb-3">
                <div className="text-muted text-uppercase">
                    <b>
                        <i className="las la-building text-danger"></i>{" "}
                        {filiado?.empresa}
                    </b>
                    <hr className="mt-1 mb-2" />
                </div>
                <div className="text-muted mb-1">
                    <span>Comercial</span>
                    <span className="float-right">
                        <span className="badge badge-primary px-3">
                            {filiado?.vendedor}{filiado?.vendedor && filiado?.web_link ? ' / ' : ' '}{filiado?.web_link ? 'Web Site' : ''}
                        </span>
                    </span>
                </div>
            </div>
        </>
    );
};

export default CardEmpresaConfigFiliado;
