import './NotificacaoStyle.css';
import React, { useEffect, useState } from "react";
import { CardContent, CardTitle } from "../../../../components/Card-z";
import HeaderComponent from "../../../../components/HeaderComponent";
import CONNECT_API from "../../../../configs/connect";
import { ShowError } from "../../../../configs/Functions";
import { useIsLoading } from "../../../../contexts/LoadingContext";
import NavVerticalButton from '../../../../components/NavBarVertical/ButtonLink';
import TextEditor from '../../../../components/TextEditor';
import ButtonSave from '../../../../components/ButtonComponent/ButtonSave';
import FileArray from '../../../../components/File/Array/input';
import { useForm } from "react-hook-form";


export const NotificacaoFiliado = props => {

    const { setIsLoading } = useIsLoading();
    const [pg, setPg] = useState([]);
    const { register, reset, handleSubmit } = useForm();

    // TEXTOS DOS PADRÕES
    const [textNovoFiliado, setTextNovoFiliado] = useState('');
    const [textNovoDependente, setTextNovoDependente] = useState('');


    const [fileNovoFiliado, setFileNovoFiliado] = useState([]);
    const [fileNovoDependente, setFileNovoDependente] = useState([]);


    async function get_data_page() {

        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/filiado/configuracao/notificacao`);
            setPg(data.pg);

            setTextNovoDependente(data.templates?.newDependent?.html);
            setTextNovoFiliado(data.templates?.new?.html);

            setFileNovoFiliado(data.templates?.new?.files);
            setFileNovoDependente(data.templates?.newDependent?.files);


            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    async function onSubmit(formData) {

        CONNECT_API.put('/filiado/configuracao/notificacao', {
            new: textNovoFiliado,
            filesNew: formData.file_filiado,
            newDependent: textNovoDependente,
            filesNewDependent: formData.file_dependente,
        });
    }

    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="notificacao-filiado">
            <HeaderComponent {...pg} />
            <div className="form-row">
                <div className="col-lg-3 position-sticky">
                    <div className="nav flex-column nav-pills" role="tablist" aria-orientation="vertical">
                        <NavVerticalButton href="#new" label="Novo Cliente" subTitle="Cadastro via Web Site" icon="user" active="true" />
                        <NavVerticalButton href="#new-dependent" label="Novo Dependente" icon="users" />
                    </div>
                </div>
                <div className="col-lg-9">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="tab-content" id="v-pills-tabContent">
                            {/* CADASTRO */}
                            <div className="tab-pane fade show active" id="new" role="tabpanel">
                                <div className="form-row">
                                    <div className="col-lg-8">
                                        <CardContent>
                                            <CardTitle>
                                                <div className="row justify-content-between">
                                                    <div className="col-lg-auto">
                                                        Novo Cadastro
                                                    </div>
                                                    <div className="col-lg-auto">
                                                        <ButtonSave type="submit" className="float-right" />
                                                    </div>
                                                </div>
                                            </CardTitle>
                                            <div className="col-12">
                                                {textNovoFiliado && <TextEditor text={textNovoFiliado} setText={setTextNovoFiliado} />}
                                                {/* <TextEditor text={textNovoFiliado} setText={setTextNovoFiliado} /> */}
                                                <hr />
                                                <FileArray
                                                    name="file_filiado"
                                                    register={register}
                                                    reset={reset}
                                                    fileList={fileNovoFiliado}
                                                    setFileList={setFileNovoFiliado} />
                                                <ButtonSave type="submit" className="mt-3 float-right" />
                                            </div>
                                        </CardContent>
                                    </div>
                                    <div className="col-lg-4">
                                        <CardContent>
                                            <CardTitle className="mb-1">
                                                Variável
                                            </CardTitle>
                                            <div className="col-12" style={{ "fontSize": "12px" }}>
                                                <div className="dados-cadastro">
                                                    <label htmlFor="">Informações do Cadastro:</label> <br />
                                                    <b>Primeiro Nome: </b>{'{primeiro_nome}'} <br />
                                                    <b>Nome: </b>{'{nome}'} <br />
                                                    <b>Matrícula: </b>{'{matricula}'} <br />
                                                    <b>Telefone: </b>{'{telefone}'} <br />
                                                    <b>E-mail: </b>{'{email}'} <br />
                                                    <b>Data do Cadastro: </b>{'{data_cadastro}'}
                                                </div>
                                                <hr />
                                                <div className="dados-vendedor">
                                                    <label htmlFor="">Informações do Vendedor:</label> <br />
                                                    <b>Nome: </b>{'{v_nome}'} <br />
                                                    <b>Telefone: </b>{'{v_tel}'} <br />
                                                    <b>E-mail: </b>{'{v_email}'}
                                                </div>
                                                <hr />
                                                <div className="dados-plano">
                                                    <label htmlFor="">Informações do Plano:</label> <br />
                                                    <b>Nome: </b>{'{p_nome}'} <br />
                                                    <b>Vigência: </b>{'{p_vigencia}'}
                                                </div>
                                                <hr />
                                                <div className="dados-pagamento">
                                                    <label htmlFor="">Informações de Pagamento:</label> <br />
                                                    <b>Nome: </b>{'{pag_nome}'} <br />
                                                    <b>Dados do Pagamento: </b>{'{pag_dados}'} <br />
                                                </div>
                                                <hr />
                                                <div className="dados-lancamento">
                                                    <label htmlFor="">Lançamentos:</label> <br />
                                                    <b>Tabela de Lançamentos: </b>{'{lancamentos}'} <br />
                                                </div>
                                                <hr />
                                                <div className="dados-anexos">
                                                    <label htmlFor="">Anexos:</label> <br />
                                                    <b>Anexo: </b>{'{nome do anexo}'} <br />
                                                </div>
                                            </div>
                                        </CardContent>
                                    </div>
                                </div>

                            </div>
                            <div className="tab-pane fade" id="new-dependent" role="tabpanel">
                                <div className="form-row">
                                    <div className="col-lg-8">
                                        <CardContent>
                                            <CardTitle>
                                                <div className="row justify-content-between">
                                                    <div className="col-lg-auto">
                                                        Novo Dependente
                                                    </div>
                                                    <div className="col-lg-auto">
                                                        <ButtonSave type="submit" className="float-right" />
                                                    </div>
                                                </div>
                                            </CardTitle>
                                            <div className="col-12">
                                                {textNovoDependente && <TextEditor text={textNovoDependente} setText={setTextNovoDependente} />}
                                                {/* <TextEditor text={textNovoDependente} setText={setTextNovoDependente} /> */}
                                                <hr />
                                                <FileArray name="file_dependente" register={register} reset={reset} fileList={fileNovoDependente} setFileList={setFileNovoDependente} />
                                                <ButtonSave type="submit" className="mt-3 float-right" />
                                            </div>
                                        </CardContent>
                                    </div>
                                    <div className="col-lg-4">
                                        <CardContent>
                                            <CardTitle>
                                                Variável
                                            </CardTitle>
                                            <div className="col-12" style={{ "fontSize": "12px" }}>
                                                <div className="dados-cadastro">
                                                    <label htmlFor="">Informações do Cadastro:</label> <br />
                                                    <b>Nome: </b>{'{nome}'} <br />
                                                    <b>Matrícula: </b>{'{matricula}'} <br />
                                                    <b>Data do Cadastro: </b>{'{data_cadastro}'}
                                                </div>
                                                <hr />
                                                <div className="dados-titular">
                                                    <label htmlFor="">Informações do Titular:</label> <br />
                                                    <b>Nome: </b>{'{filiado_nome}'} <br />
                                                    <b>Telefone: </b>{'{filiado_tel}'} <br />
                                                    <b>E-mail: </b>{'{filiado_email}'} <br />
                                                    <b>Data do Cadastro: </b>{'{filiado_data_cadastro}'}
                                                </div>
                                            </div>
                                        </CardContent>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default NotificacaoFiliado;