import React, { useEffect } from "react";
import ButtonSave from "../../../../components/ButtonComponent/ButtonSave";
import CONNECT_API from "../../../../configs/connect";
import { ShowError } from "../../../../configs/Functions";
import { useIsLoading } from "../../../../contexts/LoadingContext";
import { GroupSelect } from "../../../../components/Form";
import { useForm } from "react-hook-form";


export const ChangeDefinedNotification = props => {


    const { setIsLoading } = useIsLoading();
    const { handleSubmit, register, reset } = useForm();


    async function onSubmit(formData) {


        try {

            const id = props.match.params.id;
            setIsLoading(true);
            const ok = await CONNECT_API.put(`/filiado/${id}/configuracao`, { action: 'notification', sendNotification: formData.sendNotification });
            setIsLoading(false);

            if (ok?.data && props.changePage) props.changePage();

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }


    }

    useEffect(() => {
        reset({ ...props });
    }, [props,reset]);

    return (
        <div className="change-status-filiado text-muted">
            <div className="card">
                <div className="card-body">
                    <div className="card_header text-uppercase mb-4">
                        <i className="las la-comments"></i><b> Notificações do filiado</b>
                        <hr className="mt-1" />
                    </div>
                    <div className="change">
                        <div className="text-muted mb-3">
                            Personalize suas preferências de notificação por e-mail aqui,
                            escolhendo se deseja receber notificações relacionadas a cobranças,
                            informações e ofertas. Fique por dentro das atualizações que mais lhe interessam.
                        </div>
                        <div className="text-muted mb-1">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-row justify-content-center">
                                    <GroupSelect col="4" label="" name="sendNotification" classe="sm" classNameGroup="mb-0" id="sendNotification" register={register}>
                                        <option value="0">Não enviar notificações</option>
                                        <option value="1">Enviar notificações</option>
                                    </GroupSelect>
                                    <ButtonSave type="submit" className="btn-sm" text="Salvar" icon="comment" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangeDefinedNotification;