import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ButtonSave from "../../../../../components/ButtonComponent/ButtonSave";
import { CardContent, CardTitle } from "../../../../../components/Card-z";
import { GroupInput, GroupSelect, GroupText } from "../../../../../components/Form";
import { Modal } from "../../../../../components/Modal-z";
import CONNECT_API from "../../../../../configs/connect";
import { existsOrError, SetDate, SetNumber, ShowError } from "../../../../../configs/Functions";
import { useIsLoading } from "../../../../../contexts/LoadingContext";
import ImputMask from 'inputmask';


export const ModalPutLead = props => {

    const { handleSubmit, reset, register } = useForm();
    const { setIsLoading } = useIsLoading();
    const [lead, setLead] = useState([]);

    if (!props.leadId) return null;


    // PARAMS
    async function get_data_page() {

        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/leads/${props.leadId}/cadastro`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setLead(data?.lead);

            data.lead.nascimento = SetDate(data.lead.nascimento);
            reset({ ...data?.lead });


            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }


    // START LAYOUT MODAL
    async function onSubmit(formData) {

        // bairro,cep,cidade,complemento,doc,email,facebook,instagram,linkedin,logradouro,nascimento,nome,numero,origem,plano,tel,uf,whats

        try {
            const { bairro, cep, cidade, complemento, doc, email, facebook, instagram, linkedin, logradouro, nascimento, nome, numero, origem, plano, tel, uf, whats } = formData;
            let nomeCompleto = nome.trim().split(' ');
            existsOrError(nomeCompleto.length > 1, 'Informe o nome completo!');

            const fields = {
                bairro,
                cidade,
                complemento,
                email,
                facebook,
                instagram,
                linkedin,
                logradouro,
                nascimento,
                nome,
                origem,
                plano,
                uf,
                doc,
                numero,
                cep: SetNumber(cep),
                tel: SetNumber(tel),
                whats: SetNumber(whats),
            }

            setIsLoading(true);
            await CONNECT_API.put(`/leads/${props.leadId}/cadastro`, { ...fields });
            setIsLoading(false);

            if (props?.changedLoad) await props.changedLoad();

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    // Abertura do modal
    async function modalShow() {
        await get_data_page();
        ImputMask({ "mask": '(99) 99999-9999' }).mask('[type="tel"]');
        ImputMask({ "mask": '99999-999' }).mask('[name="cep"]');
    }

    // Fechamento do modal
    function modalHide() {
        // reset({});
    }

    async function get_data_cep(e) {

        const cep = SetNumber(e.target.value);
        if (!cep) return false;

        if (cep.length === 8) {

            setIsLoading(true);

            const json = await CONNECT_API.getCep(cep);

            reset({
                uf: json?.data.uf,
                cidade: json?.data.localidade,
                bairro: json?.data.bairro,
                logradouro: json?.data.logradouro,
                complemento: json?.data.complemento
            })

            setIsLoading(false);
        }
    }



    return (
        <div className="ModalPutLead">
            <Modal
                modalId={props?.modalId}
                modalShow={modalShow}
                modalHide={modalHide}
                text="Cadastro"
                className="btn btn-secondary"
                icon="edit" >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <CardTitle>
                            <div className="form-row align-items-center">
                                <div className="col-auto">
                                    <i className="las la-male"></i>
                                </div>
                                <div className="col-auto">{lead?.nome}</div>
                            </div>
                        </CardTitle>

                        <GroupInput name="nome" required col="8" label="Nome" register={register} />
                        <GroupInput name="nascimento" type="date" col="4" label="Data Nascimento" register={register} />
                        <GroupInput name="doc" col="5" label="CPF/CNPJ" register={register} />
                        <GroupInput name="origem" col="4" label="Origem do lead" register={register} />
                        <GroupSelect name="plano" col="3" label="Plano" register={register}>
                            <option value="B2B">B2B - Business to Business</option>
                            <option value="B2C">B2C - Business to Consumer</option>
                        </GroupSelect>
                        <GroupInput name="tel" col="6" type="tel" label="Telefone" register={register} />
                        <GroupInput name="whats" col="6" type="tel" label="Whatsapp" register={register} />
                        <GroupInput name="email" col="12" label="E-mail" autoComplete="email" register={register} />
                        <GroupInput name="facebook" col="12" label="Facebook" register={register} />
                        <GroupInput name="instagram" col="12" label="Instagram" register={register} />
                        <GroupInput name="linkedin" col="12" label="Linkedin" register={register} />

                        <div className="col-12 mt-3">
                            <h4>Endereço</h4>
                            <hr className="mb-4" />
                        </div>
                        <GroupInput name="cep" col="4" label="CEP" onKeyUp={get_data_cep} register={register} />
                        <GroupInput name="logradouro" col="8" label="Logradouro" register={register} />
                        <GroupInput name="bairro" col="6" label="Bairro" register={register} />
                        <GroupInput name="cidade" col="6" label="Cidade" register={register} />
                        <GroupInput name="uf" col="6" label="Estado" register={register} />
                        <GroupInput name="numero" col="6" label="Número" register={register} />
                        <GroupText row="4" name="complemento" col="12" label="Complemento" register={register} />


                        <div className="col-12">
                            <ButtonSave type="submit" text="Atualizar" icon="edit" className="float-right" />
                        </div>
                    </CardContent>
                </form>
            </Modal>
        </div>
    )
}


export default ModalPutLead;