import React from "react";

export const DiasMes = () => (
    <>
        <option value="1">Dia 01</option>
        <option value="2">Dia 02</option>
        <option value="3">Dia 03</option>
        <option value="4">Dia 04</option>
        <option value="5">Dia 05</option>
        <option value="6">Dia 06</option>
        <option value="7">Dia 07</option>
        <option value="8">Dia 08</option>
        <option value="9">Dia 09</option>
        <option value="10">Dia 10</option>
        <option value="11">Dia 11</option>
        <option value="12">Dia 12</option>
        <option value="13">Dia 13</option>
        <option value="14">Dia 14</option>
        <option value="15">Dia 15</option>
        <option value="16">Dia 16</option>
        <option value="17">Dia 17</option>
        <option value="18">Dia 18</option>
        <option value="19">Dia 19</option>
        <option value="20">Dia 20</option>
        <option value="21">Dia 21</option>
        <option value="22">Dia 22</option>
        <option value="23">Dia 23</option>
        <option value="24">Dia 24</option>
        <option value="25">Dia 25</option>
        <option value="26">Dia 26</option>
        <option value="27">Dia 27</option>
        <option value="28">Dia 28</option>
        <option value="29">Dia 29</option>
        <option value="30">Dia 30</option>
    </>
);

export default DiasMes;
