import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import ButtonSave from "../../../../components/ButtonComponent/ButtonSave";
import { CardContent } from "../../../../components/Card-z";
import FileArray from "../../../../components/File/Array/input";
import { GroupInput, GroupRadio, GroupText } from "../../../../components/Form";
import HeaderComponent from "../../../../components/HeaderComponent";
import { Modal } from "../../../../components/Modal-z";
import CONNECT_API from "../../../../configs/connect";
import { existsOrError, formatCoin, formatCoinNotCurrency, formatDate, SetCoin, ShowError } from "../../../../configs/Functions";
import { useIsLoading } from "../../../../contexts/LoadingContext";



const ModalFaturarLancamento = props => {


    const [pg, setPg] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [lancamento, setLancamento] = useState([]);
    const [terminais, setTerminais] = useState([]);

    const { register, handleSubmit, reset } = useForm();
    const { setIsLoading } = useIsLoading();

    useEffect(() => {
        if (props.params) get_data_lancamento(props.params);
        // eslint-disable-next-line
    }, [props.params]);




    useEffect(() => {
        //// VALOR DO FATURAMENTO O MESMO DO LANÇAMENTO
        reset({
            valor_faturado: formatCoinNotCurrency(lancamento?.valor)
        })
        // eslint-disable-next-line
    }, [lancamento]);

    ///////////////
    // MODAL FATURAMENTO
    ///////////////
    async function get_data_lancamento(id) {
        if (!id) return false;

        try {

            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/lancamento/${id}/faturar`);
            setLancamento(data?.lancamento);
            setTerminais(data?.terminais);
            setPg(data?.pg);
            setIsLoading(false);


        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }
    ///////////////
    // POST FATURAMENTO
    ///////////////
    async function onSubmit(data) {

        try {

            const {
                files,
                obs
            } = data;

            const terminal_financeiro = data?.terminal_financeiro;
            const valor_faturado = SetCoin(data?.valor_faturado);

            existsOrError(terminal_financeiro, "Terminal financeiro não informado!");
            existsOrError(valor_faturado, "Valor faturado não informado!");

            const fields_faturamento = {
                terminal_financeiro,
                valor_faturado,
                files,
                obs
            }


            setIsLoading(true);
            const ok_post = await CONNECT_API.post(`${pg?.end_point}`, { ...fields_faturamento });
            if (ok_post) {

                if (props.loaded) props.loaded();

                reset({
                    valor_faturado: null,
                    terminal_financeiro: null,
                    obs: null
                });
            }

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }


    return (
        <Modal modalId={props.modalId} style={{ "maxWidth": "700px" }}>
            <CardContent classNameRow="justify-content-center">
                <div className="col-12">
                    <HeaderComponent {...pg} />
                </div>
                <div className="col-12 mt-4">
                    <CardContent>
                        <div className="col-12">
                            <div className="title mb-2">
                                <small className="text-success">{lancamento?.receita}</small> <br />
                                <b>{lancamento?.pf_nome}</b>
                            </div>
                            <div className="desc">
                                Vencimento: {formatDate(lancamento?.vencimento)} <br />
                                Valor: {formatCoin(lancamento?.valor)} <br />
                                Observação: {lancamento?.obs}
                            </div>
                        </div>
                    </CardContent>
                    <div className="terminal mt-4">

                        <form action="" onSubmit={handleSubmit(onSubmit)}>
                            <CardContent>
                                {terminais?.length ?
                                    <div className="col-12 mb-4">
                                        <div className="row">
                                            <div className="col-12 mb-1">
                                                <b>Selecione Terminal Financeiro</b>
                                            </div>
                                            {
                                                terminais?.map(t => {
                                                    return <GroupRadio
                                                        key={t?.id}
                                                        name="terminal_financeiro"
                                                        id={`option-tr-${t?.id}`}
                                                        value={t?.id}
                                                        col="12"
                                                        label={t?.nome}
                                                        register={register} />
                                                })
                                            }
                                        </div>
                                    </div>
                                    :
                                    <div className="col-12 py-3 text-center mb-4">
                                        <small><b>Nenhum terminal configurado para está operação! Verifique com o setor financeiro.</b></small>
                                    </div>
                                }
                                <GroupInput name="valor_faturado" required label="Valor faturado" col="12" register={register} />
                                <GroupText name="obs" label="Observação" col="12" register={register} />
                                <div className="col-12">
                                    <FileArray register={register} reset={reset} fileList={fileList} setFileList={setFileList} />
                                </div>
                            </CardContent>
                            <ButtonSave type="submit" className="float-right mt-1" />
                        </form>
                    </div>
                </div>
            </CardContent>
        </Modal>
    );
}

export default ModalFaturarLancamento;