import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderComponent from "../../../../components/HeaderComponent";
import CONNECT_API from "../../../../configs/connect";
import { existsOrError, formatCoin, formatDate, ShowError } from "../../../../configs/Functions";
import { useIsLoading } from "../../../../contexts/LoadingContext";
import ModalChangeCard from "./ModalChangeCard";
import ModalNewTerminal from "./ModalNewTerminal";


export const FiliadoTerminal = props => {

    const [terminais, setTerminais] = useState([]);
    const [pg, setPg] = useState([]);


    const { setIsLoading } = useIsLoading();

    async function get_data_page() {

        try {


            const id = props.match.params.id;
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`filiado/${id}/terminal`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setTerminais(data.terminais);
            setPg(data.pg);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }


    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);




    return (
        <div className="terminais">
            <HeaderComponent {...pg} />
            <div className="row">
                <div className="col-lg-9">
                    <div className="row">
                        {
                            terminais?.map(t => {
                                return (
                                    <div className="col-lg-6 col-md-auto col-12" key={t.id}>
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row justify-content-between align-items-center">
                                                    <div className="col-lg-auto">
                                                        <small><b>{t?.banco} → {t?.terminal}</b></small> <br />
                                                        <small>Cadastrado em: {formatDate(t?.data_start)}</small>
                                                    </div>
                                                    <div className="col-lg-auto text-right">
                                                        <span className={`badge badge-${!t?.data_end ? "success" : "danger"}`}>{!t?.data_end ? "Ativo" : "Baixado"}</span><br />
                                                        {t?.data_end && <small>Baixado em: {formatDate(t?.data_end)}</small>}
                                                    </div>
                                                </div>
                                                {/* QUANDO FOR ATIVO E FOR RECORRÊNCIA */}
                                                {
                                                    !t?.data_end && t.card &&
                                                    <div className="recorrencia mt-2">
                                                        <small className="mb-2 d-block w-100">
                                                            <div className="row justify-content-between">
                                                                <div className="col-lg-auto">Valor</div>
                                                                <div className="col-lg-auto">{formatCoin(t?.card?.valor)}</div>
                                                            </div>
                                                            <div className="row justify-content-between">
                                                                <div className="col-lg-auto">Dia da cobrança</div>
                                                                <div className="col-lg-auto">{t?.card?.dia_cobranca}</div>
                                                            </div>
                                                        </small>
                                                        <b className="text-center w-100 d-block mb-2">Recorrência</b>
                                                        <Link to={t?.card?.card_url || '/'} className="p-0 btn btn-sm w-100 text-left" title="Ver detalhes da recorrência">
                                                            <div className="border rounded p-3 text-muted">
                                                                <div className="form-row justify-content-between">
                                                                    <div className="col-lg-auto mb-1">{t?.card?.card_nome}</div>
                                                                    <div className="col-lg-auto  mb-1">{t?.card?.card_bandeira}</div>
                                                                </div>
                                                                <div className="form-row justify-content-between">
                                                                    <div className="col-lg-auto"><b>{t?.card?.card_numero}</b></div>
                                                                    <div className="col-12-auto">{t?.card?.card_vencimento}</div>
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        <div className="row mt-2 justify-content-between">
                                                            <div className="col-lg-auto">
                                                                <ModalNewTerminal text={`Novo Terminal`} filiado_id={props.match.params.id} loaded={get_data_page} />
                                                            </div>
                                                            <div className="col-lg-auto">
                                                                <ModalChangeCard text={`Atualizar Cartão`} filiado_id={props.match.params.id} loaded={get_data_page} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {/* QUANDO FOR ATIVO */}
                                                {
                                                    !t?.data_end && !t.card &&
                                                    <div className="row justify-content-center mt-4">
                                                        <div className="col-lg-auto">
                                                            <ModalNewTerminal text={`Novo Terminal`} filiado_id={props.match.params.id} loaded={get_data_page} />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

        </div>
    );
}


export default withRouter(FiliadoTerminal);