import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import HeaderComponent from "../../../../components/HeaderComponent";
import CONNECT_API from "../../../../configs/connect";
import { ShowError } from "../../../../configs/Functions";
import { useIsLoading } from "../../../../contexts/LoadingContext";
import CardAnotacao from "./anotacao/CardAnotacao";
import ModalAddAnotacao from "./anotacao/ModalAddAnotacao";
import ModalPutLead from "./cadastro/ModalPutLead";
import ModalPutSituacao from "./cadastro/ModalPutSituacao";
import CardCron from "./cron/CardCron";
import ModalAddCron from "./cron/ModalAddCron";
import CardResp from "./responsavel/CardResp";
import ModalAddResp from "./responsavel/ModalAddResp";
import $ from "jquery";
import ButtonVoltar from "../../../../components/ButtonComponent/ButtonVoltar";


export const LeadId = props => {

    const { setIsLoading } = useIsLoading();
    const [alert, setAlert] = useState([]);
    const [lead, setLead] = useState([]);
    const [texts, setTexts] = useState([]);
    const [resp, setResp] = useState([]);
    const [status, setStatus] = useState([]);
    const [colaboradores, setColaboradores] = useState([]);
    const [pg, setPg] = useState([]);

    const [dataApi, setDataApi] = useState([]);


    const lead_id = Number(props.match.params.id);

    // PARAMS

    async function get_data_page() {

        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/leads/${lead_id}`);
            setLead(data?.lead);
            setTexts(data?.texts);
            setAlert(data?.alert);
            setResp(data?.resp);
            setColaboradores(data?.colaboradores);
            setStatus(data?.status);
            setPg(data.pg);

            setDataApi(data);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, [lead_id]);

    if (!dataApi.lead) return (
        <div className="sem-acesso h-100">
            <div className="h-100 d-flex justify-content-center align-items-center ">
                <div className="text-center">
                    <b>Você não tem acesso ao lead, verifique com sua gerente!</b>
                    <ButtonVoltar className="btn-link" />
                </div>
            </div>
        </div>
    )
    return (
        <div className="lead-cadastro">
            <HeaderComponent {...pg} />
            <div className="jumbotron pt-4 pb-5">
                <div className="jumbotron-body">
                    <div className="lead-header mb-2">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-auto">
                                <ModalPutSituacao status={status} leadId={lead_id} changedLoad={get_data_page} modalId="modal-change-status" />
                                <span className="situacao mr-3">
                                    Situação &nbsp;
                                    {
                                        lead?.situacao === 'Bloqueado' || lead?.situacao === 'Finalizado' ?
                                            <span onClick={() => $(`#modal-change-status`).modal('show')} className="badge badge-danger btn">{lead?.situacao}</span>
                                            :
                                            <span onClick={() => $(`#modal-change-status`).modal('show')} className="badge badge-primary btn">{lead?.situacao}</span>
                                    }
                                </span>
                                <span className="responsavel">
                                    {resp?.length > 1 ? 'Responsáveis' : 'Responsável'}
                                    {
                                        resp?.map(r => <span key={r?.id} className="badge badge-success ml-2">{r?.colaborador}</span>)
                                    }
                                </span>
                            </div>
                            <div className="col-auto">
                                <ModalPutLead changedLoad={get_data_page} leadId={props.match.params?.id} modalId="lead-put-cadastro" />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="lead-body">
                        <h1 className="display-4">{lead?.nome}</h1>
                        {
                            lead?.email &&
                            <div className="e-mail mb-1">
                                <Link to={{ pathname: lead?.email_url || '/' }} target="_blank" className="btn p-0 text-dark">
                                    <i className="las la-envelope mr-2"></i>
                                    {lead?.email}
                                </Link>
                            </div>
                        }
                        {
                            lead?.whats &&
                            <div className="telefone mb-1">
                                <Link to={{ pathname: lead?.whats_url || '/' }} target="_blank" className="btn p-0 text-dark">
                                    <i className="lab la-whatsapp mr-2"></i>
                                    {lead?.whats}
                                </Link>
                            </div>
                        }
                        {
                            lead?.tel &&
                            <div className="whatsapp">
                                <Link to={{ pathname: lead?.tel_url || '/' }} target="_blank" className="btn p-0 text-dark">
                                    <i className="las la-phone mr-2"></i>
                                    {lead?.tel}
                                </Link>
                            </div>
                        }
                    </div>
                </div>
            </div>


            <div className="lead-page">
                <div className="row">
                    <div className="col-lg-7">
                        <div className="card">
                            <div className="card-body">
                                <div className="row justify-content-between align-items-center mb-3">
                                    <div className="col-auto">
                                        <b>Anotações</b>
                                    </div>
                                    <div className="col-auto">
                                        <ModalAddAnotacao leadId={lead_id} changedLoad={get_data_page} modalId="nova-anotacao" />
                                    </div>
                                </div>
                                <hr />
                                <div className="time-line">
                                    {!texts?.length && <div className="text-center text-muted">Sem Anotações!</div>}
                                    <div className="time-line-body">
                                        {
                                            texts?.map((t, index) => {
                                                return (
                                                    <CardAnotacao index={index} anotacao={t} key={t?.id} />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <div className="responsaveis">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row justify-content-between align-items-center">
                                        <div className="col-auto"><b>Responsáveis</b></div>
                                        <div className="col-auto">
                                            <ModalAddResp colaboradores={colaboradores} leadId={lead_id} changedLoad={get_data_page} modalId="modal-add-resp" />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="names">
                                        {!resp?.length && <div className="text-center text-muted">Sem Responsáveis!</div>}
                                        {
                                            resp?.map(r => {
                                                return (
                                                    <CardResp key={r?.id} resp={r} leadId={lead_id} changedLoad={get_data_page} />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cronjob">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row justify-content-between align-items-center">
                                        <div className="col-auto"><b>Alertas</b></div>
                                        <div className="col-auto">
                                            <ModalAddCron leadId={lead_id} modalId="add-cron" changedLoad={get_data_page} />
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="alerts">
                                        {!alert?.length && <div className="text-center text-muted">Sem Alertas!</div>}
                                        {
                                            alert?.map(a => {
                                                return (
                                                    <CardCron changedLoad={get_data_page} leadId={lead_id} alert={a} key={a?.id} />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default withRouter(LeadId);