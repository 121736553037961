import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import ButtonSave from "../../../../components/ButtonComponent/ButtonSave";
import { CardContent } from "../../../../components/Card-z";
import { GroupCheckBox, GroupInput, GroupRadio, GroupText } from "../../../../components/Form";
import HeaderComponent from "../../../../components/HeaderComponent";
import { Modal } from "../../../../components/Modal-z";
import CONNECT_API from "../../../../configs/connect";
import { existsOrError, formatCoin, formatCoinNotCurrency, formatCPF, formatDate, formatMatricula, PrintHTML, SetCoin, SetNumber, ShowError } from "../../../../configs/Functions";
import { useIsLoading } from "../../../../contexts/LoadingContext";



const ModalCardFiliado = props => {


    const [filiado, setFiliado] = useState([]);
    const [cardPrint, setCardPrint] = useState([]);
    const [card, setCard] = useState([]);
    const [terminais, setTerminais] = useState([]);
    const [pg, setPg] = useState([]);

    const { register, handleSubmit, reset } = useForm();
    const { setIsLoading } = useIsLoading();

    useEffect(() => {
        if (props.params) get_data_lancamento(props.params);
        // eslint-disable-next-line
    }, [props.params]);

    function modalHide() {
        return false;
    }

    ///////////////
    // MODAL CARD PRINT
    ///////////////
    async function get_data_lancamento(id) {
        if (!id) return false;

        try {

            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/filiado/${id}/card/print`);
            setFiliado(data?.filiado);
            setCardPrint(data?.card_print);
            setCard(data?.card);
            setTerminais(data?.terminais);
            setPg(data?.pg);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            return ShowError(error);
        }
    }


    ///////////////
    // POST CARD PRINT
    ///////////////
    async function onSubmit(data_form) {
        try {

            const terminal_id = SetNumber(data_form.terminal_id);
            const card_id = SetNumber(card?.id);
            const valor = SetCoin(data_form.valor);
            const obs = data_form.obs;
            const free = data_form.free;
            existsOrError(terminal_id, "Terminal não selecionado!");
            existsOrError(card_id, "Tipo do cartão pendente!");

            const fields = {
                terminal_id,
                card_id,
                valor,
                obs,
                free
            }
            setIsLoading(true);
            const card_ok = await CONNECT_API.post(pg.end_point, { ...fields });
            if (card_ok?.data) {
                if (props?.loaded) props.loaded();
                if (props.params) get_data_lancamento(props.params);
                reset();
                PrintHTML(card_ok?.data?.html_card);
                
            }
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    useEffect(() => {
        reset({ valor: formatCoinNotCurrency(card?.valor) });
        // eslint-disable-next-line
    }, [card?.valor]);


    return (
        <Modal modalId={props.modalId} modalHide={modalHide}>
            <CardContent classNameRow="justify-content-center">
                <div className="col-12">
                    <HeaderComponent {...pg} />
                </div>
                {
                    filiado &&
                    <div className="col-lg-6 col-md-auto col-12 mb-4">
                        <div className={`border rounded p-3 ${!filiado?.titular_id ? "border-primary" : "border-warning"}`} >
                            <div className="form-row justify-content-between" style={{ "fontSize": "11px" }}>
                                <div className="col-lg-auto">
                                    <div className="mb-1">
                                        Nome <br />
                                        <b>{filiado?.nome}</b>
                                    </div>
                                    <div className="mb-1">
                                        Matrícula <br />
                                        <b>{formatMatricula(filiado?.matricula)}</b>
                                    </div>
                                    <div className="mb-1">
                                        CPF <br />
                                        <b>{formatCPF(filiado?.cpf)}</b>
                                    </div>
                                </div>
                                <div className="col-lg-auto">
                                    <div className="mb-1">
                                        Situação <br />
                                        <b>{filiado?.situacao}</b>
                                    </div>
                                    <div>
                                        Data de nascimento <br />
                                        <b>{formatDate(filiado?.nascimento) || "-"}</b>
                                    </div>
                                    <div>
                                        Data do cadastro <br />
                                        <b>{formatDate(filiado?.data_cadastro) || "-"}</b>
                                    </div>
                                </div>
                                {
                                    card?.id ?
                                        <div className="col-12">
                                            <hr />
                                            < div className="form-row justify-content-between">
                                                <div className="col-lg-auto">
                                                    Cartão
                                                </div>
                                                <div className="col-lg-auto">
                                                    {card?.nome}
                                                </div>
                                            </div>
                                            < div className="form-row justify-content-between">
                                                <div className="col-lg-auto">
                                                    Valor
                                                </div>
                                                <div className="col-lg-auto">
                                                    {formatCoin(card?.valor)}
                                                </div>
                                            </div>
                                        </div> : ""
                                }
                                {
                                    cardPrint?.length ?
                                        <div className="col-12 mt-2">

                                            {
                                                cardPrint?.map((cp, i) => {
                                                    return (
                                                        < div key={cp?.id} className="form-row justify-content-between">
                                                            <div className="col-lg-auto">
                                                                {i + 1}° via
                                                            </div>
                                                            <div className="col-lg-auto">
                                                                {formatDate(cp?.data)}
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div> : ""

                                }

                            </div>
                        </div>
                        <div className="faturamento mt-4">
                            <div className="title mb-2">
                                <b>Selecione o terminal de pagamento</b>
                            </div>
                            <div className="content">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row">
                                        {
                                            terminais?.map(t => {
                                                return <GroupRadio
                                                    key={t?.id}
                                                    name="terminal_id"
                                                    id={`option-tr-${t?.id}`}
                                                    value={t?.id}
                                                    col="12"
                                                    label={`${t?.banco} → ${t?.terminal}`}
                                                    register={register} />
                                            })
                                        }
                                        <GroupInput col="12 mt-3" name="valor" label="Valor arrecadado" register={register} />
                                        <GroupText col="12" row="3" name="obs" label="Observações" register={register} />
                                        <GroupCheckBox name="free" id="free" value="1" col="12" label="Gratuita" register={register} />
                                        <div className="col-12">
                                            <ButtonSave type="submit" text="Gerar cartão" icon="print" className="float-right" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                }
            </CardContent>
        </Modal >
    );
}

export default ModalCardFiliado;