import React from "react";
import { useForm } from "react-hook-form";
import ButtonSave from "../../../../../components/ButtonComponent/ButtonSave";
import { CardContent, CardTitle } from "../../../../../components/Card-z";
import CONNECT_API from "../../../../../configs/connect";
import { existsOrError, SetNumber, ShowError } from "../../../../../configs/Functions";
import { useIsLoading } from "../../../../../contexts/LoadingContext";
import ImputMask from 'inputmask';
import { GroupInput } from "../../../../../components/Form";
import { Modal } from "../../../../../components/Modal-z";


export const ModalAddLead = props => {

    const { handleSubmit, reset, register } = useForm();
    const { setIsLoading } = useIsLoading();

    if (!props.setLeadId) return null;


    // START LAYOUT MODAL
    async function onSubmit(formData) {

        try {
            const { nome, whats, origem } = formData;
            let nomeCompleto = nome.trim().split(' ');
            existsOrError(nomeCompleto.length > 1, 'Informe o nome completo!');
            existsOrError(whats, 'Contato do Lead não informado!');
            existsOrError(origem, 'Informe a origem do Lead!');

            const fields = {
                nome,
                whats: SetNumber(whats),
                origem
            }

            setIsLoading(true);
            const { data } = await CONNECT_API.post(`/leads/add`, { ...fields });
            props.setLeadId(data?.id);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    // Abertura do modal
    function modalShow() {
        ImputMask({ "mask": '(99) 99999-9999' }).mask('[type="tel"]');
    }

    // Fechamento do modal
    function modalHide() {
        reset({});
    }


    return (
        <div className="ModalAddLead">
            <Modal sm={true} modalId={props?.modalId} modalShow={modalShow} modalHide={modalHide} >
                <form onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <CardTitle>
                            <div className="form-row align-items-center">
                                <div className="col-auto">
                                    <i className="las la-plus-square"></i>
                                </div>
                                <div className="col-auto">Lead</div>
                            </div>
                        </CardTitle>
                        <GroupInput name="nome" required col="12" label="Nome" register={register} />
                        <GroupInput name="whats" col="12" type="tel" label="Telefone/Whatsapp" register={register} />
                        <GroupInput name="origem" col="12" label="Origem do Lead" register={register} />
                        <div className="col-12">
                            <ButtonSave type="submit" text="Cadastrar" icon="plus-square" className="float-right" />
                        </div>
                    </CardContent>
                </form>
            </Modal>
        </div>
    )
}


export default ModalAddLead;