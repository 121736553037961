import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CardContent, CardTitle } from "../../../../../components/Card-z";
import {
    GroupInput,
    GroupRadio,
    GroupSelect,
    GroupSelectFilter,
} from "../../../../../components/Form";
import HeaderComponent from "../../../../../components/HeaderComponent";
import { Modal } from "../../../../../components/Modal-z";
import CONNECT_API from "../../../../../configs/connect";
import { existsOrError, formatCoin, SetNumber, ShowError } from "../../../../../configs/Functions";
import { useIsLoading } from "../../../../../contexts/LoadingContext";
import CredCard from "../../../../md_4_pagamento_integrado/cielo/apoio/CredCard";
import ImputMask from "inputmask";
import DiasMes from "./DiasMes";
import ButtonSave from "../../../../../components/ButtonComponent/ButtonSave";

export const ModalNovoTitular = (props) => {
    const [pg, setPg] = useState([]);

    ///////
    // inicio do cadastro
    ///////
    const [planos, setPlanos] = useState([]);
    const [colaboradores, setColaboradores] = useState([]);
    const [fornecedores, setFornecedores] = useState([]);
    ///////
    ///////
    //

    const [plano, setPlano] = useState([]);
    const [terminaisPlano, setTerminaisPlano] = useState([]);
    const [terminaisFaturamento, setTerminaisFaturamento] = useState([]);
    const [beneficios, setBeneficios] = useState([]);
    const [card, setCard] = useState(undefined);

    // cartão
    const [credCardNome, setCredCardNome] = useState("");
    const [credCardNumero, setCredCardNumero] = useState("");
    const [credCardVencimento, setCredCardVencimento] = useState("");
    const [credCardSeguranca, setCredCardSeguranca] = useState("");
    const [credCardFocus, setCredCardFocus] = useState("");

    const { setIsLoading } = useIsLoading();
    const { handleSubmit, reset, register } = useForm();

    async function get_data_page() {
        try {
            const id = props.match.params.id;
            setIsLoading(true);
            const { data } = await CONNECT_API.get(
                `filiado/${id}/configuracao/novo-titular`
            );
            setPlanos(data?.planos);
            setColaboradores(data?.colaboradores);
            setFornecedores(data?.fornecedores);
            setPg(data?.pg);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    /////////////
    // GET PLANO
    /////////////
    async function getPlanoId(e) {
        let id = e.target.value;
        setCard(null);
        reset({ terminal_id: null });
        reset({ terminal_faturamento_id: null });
        reset({ beneficio_id: null });
        reset({ cad_pj_id: null });
        reset({ card_nome: null });
        reset({ card_numero: null });
        reset({ card_vencimento: null });
        reset({ card_seguranca: null });

        if (!id) {
            setPlano([]);
            setTerminaisPlano([]);
            setTerminaisFaturamento([]);
            setBeneficios([]);
            setCard(null);
            return false;
        }

        try {
            setIsLoading(true);

            const { data } = await CONNECT_API.get(
                `filiado/${id}/configuracao/novo-titular?plano_id=${id}`
            );
            setPlano(data?.plano);
            setTerminaisPlano(data?.terminais_plano);
            setTerminaisFaturamento(data?.terminais_faturamento);
            setBeneficios(data?.beneficios);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    function onChangeCard(e) {
        let el = e.target;
        let c = el.name;
        let v = el.value;
        switch (c) {
            case "card_nome":
                setCredCardNome(v);
                setCredCardFocus("name");
                break;
            case "card_numero":
                setCredCardNumero(v);
                setCredCardFocus("number");
                break;
            case "card_seguranca":
                setCredCardSeguranca(v);
                setCredCardFocus("cvc");
                break;
            case "card_vencimento":
                setCredCardVencimento(v);
                setCredCardFocus("expiry");
                break;
            default:
                break;
        }
    }

    const onSubmit = async formData => {


        try {

            const {
                card_nome,
                // card_vencimento
            } = formData;
            const plano_id = SetNumber(formData.plano_id);
            const colaborador_id = SetNumber(formData.colaborador_id);
            const beneficio_id = SetNumber(formData.beneficio_id);
            const terminal_id = SetNumber(formData.terminal_id);
            const terminal_faturamento_id = SetNumber(formData.terminal_faturamento_id);
            const cad_pj_id = SetNumber(formData.cad_pj_id[0]);
            const card_numero = SetNumber(formData.card_numero);
            const card_seguranca = SetNumber(formData.card_seguranca);
            const dia_vencimento = SetNumber(formData.dia_vencimento);

            existsOrError(plano_id, "Plano não definido!");
            existsOrError(colaborador_id, "Vendedor não definido!");
            existsOrError(terminal_id, "Terminal de pagamento não definido!");


            ////////////
            // DADOS DO POST
            ////////////
            const fields_post = {
                plano_id,
                colaborador_id,
                terminal_id
            }

            //////////////
            // VERIFICANDO SE TEM BENEFICIO LANÇADO
            //////////////
            if (beneficio_id) fields_post.beneficio_id = beneficio_id;


            //////////
            // VERIFICANDO SE O TERMINAL PRECISA DE CARTÃO
            //////////
            let t_selected = terminaisPlano.filter(t => t?.id === Number(terminal_id));
            existsOrError(t_selected, "Terminal selecionado não disponível!");
            if (t_selected[0].card) {

                let anoFull = new Date().getFullYear();
                let card_vencimento = formData.card_vencimento;
                ///// EXIGE 
                existsOrError(card_nome, "Cartão - Nome não informado!");
                existsOrError(card_numero, "Cartão - Número não informado!");
                existsOrError(card_vencimento, "Cartão - Vencimento do cartão não informado!");

                // Vencimento do cartão
                card_vencimento = ImputMask.unmask(card_vencimento, { mask: "99/99" })
                existsOrError(card_vencimento?.length === 4, "Cartão - Vencimento do cartão não informado invalido!");
                existsOrError(card_seguranca, "Cartão - Código de segurança do cartão não informado!");


                let mes = card_vencimento?.substr(0, 2);
                let ano = `${anoFull.toString()?.substr(0, 2)}${card_vencimento?.substr(2, 2)}`

                card_vencimento = `${mes}/${ano}`;
                fields_post.card_nome = card_nome;
                fields_post.card_numero = card_numero;
                fields_post.card_vencimento = card_vencimento;
                fields_post.card_seguranca = card_seguranca;
            } else {

                // Pode ser personalizado a data de vencimento
                if (dia_vencimento) fields_post.dia_vencimento = dia_vencimento;

                // Terminal de pagamento da Adessão e 1º Mensalidade
                existsOrError(terminal_faturamento_id, "Terminal Financeiro da Adessão/1º Mensalidade não foi informado!");
                fields_post.terminal_faturamento_id = terminal_faturamento_id;
            }

            //////////////
            // VINCULO A UMA EMPRESA
            //////////////
            if (cad_pj_id) fields_post.cad_pj_id = cad_pj_id;

            setIsLoading(true);
            const id = props.match.params.id;
            existsOrError(id, "Filiado não localizado!");
            const filiado_ok = await CONNECT_API.post(`filiado/${id}/configuracao/novo-titular`, { ...fields_post });
            if (filiado_ok) {
                if (props.loaded)
                    await props.loaded();
            }
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }

    }

    // RODA QUANDO ABRE O MODAL
    async function modalShow() {
        await get_data_page();
        ImputMask({ mask: "999.999.999-99" }).mask('[name="cpf"]');
    }
    async function modalHide() {
        reset();
        setPlano([]);
        setCard(undefined);

    }

    useEffect(() => {
        if (card) {
            ImputMask({ mask: "999" }).mask('[name="card_seguranca"]');
            ImputMask({ mask: "9999 9999 9999 9999" }).mask(
                '[name="card_numero"]'
            );
            ImputMask({ mask: "99/99" }).mask('[name="card_vencimento"]');
        }
        // eslint-disable-next-line
    }, [card]);

    useEffect(() => {
        // let h = new Date().getDate();
        reset({
            dia_vencimento: 10
        })
        // eslint-disable-next-line
    }, [plano]);

    return (
        <Modal
            style={{ maxWidth: "700px" }}
            modalId={props.modalId}
            icon={props.icon}
            text={props.text}
            className={props.className}
            modalShow={modalShow}
            modalHide={modalHide}
        >
            <div className="card">
                <div className="card-body">
                    <HeaderComponent {...pg} />
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <CardContent>
                            <CardTitle>Cadastro serviço</CardTitle>
                            <GroupSelect
                                name="plano_id"
                                onChange={getPlanoId}
                                col="12"
                                required
                                label="Plano"
                                register={register}
                            >
                                <option value="">Selecione...</option>
                                {planos?.map((p) => {
                                    return (
                                        <option key={p?.id} value={p?.id}>
                                            {p?.nome}
                                        </option>
                                    );
                                })}
                            </GroupSelect>
                            <GroupSelect
                                name="colaborador_id"
                                col="12"
                                required
                                label="Vendendor"
                                register={register}
                            >
                                <option value="">Selecione...</option>
                                {colaboradores?.map((p) => {
                                    return (
                                        <option key={p?.id} value={p?.id}>
                                            {p?.nome}
                                        </option>
                                    );
                                })}
                            </GroupSelect>
                        </CardContent>
                        {plano?.id && (
                            <CardContent>
                                <CardTitle>Cadastro pagamento</CardTitle>
                                <div className="col-12 mb-3">
                                    <small>
                                        <b>Resumo do plano</b>
                                    </small>{" "}
                                    <br />
                                    <small>Plano: {plano?.nome}</small> <br />
                                    <small>
                                        Valor: {formatCoin(plano?.valor)}
                                    </small>{" "}
                                    <br />
                                    <small>
                                        Valor da adessão:{" "}
                                        {formatCoin(plano?.valor_adesao)}
                                    </small>{" "}
                                    <br />
                                    {plano?.recorrencia ? (
                                        <>
                                            <small>
                                                Recorrência: {plano?.recorrencia}
                                            </small>{" "}
                                            <br />
                                        </>
                                    ) : (
                                        ""
                                    )}
                                    {plano?.vigencia ? (
                                        <>
                                            <small>
                                                Vigência: {plano?.vigencia}
                                            </small>{" "}
                                            <br />
                                        </>
                                    ) : (
                                        ""
                                    )}
                                    <hr />
                                </div>
                                <div className="col-12 mb-3">
                                    {
                                        // BENEFICIOS DO PLANO
                                        beneficios?.length ? (
                                            <>
                                                <small>
                                                    <b>
                                                        Benefícios disponível no
                                                        plano
                                                    </b>
                                                </small>
                                                <div className="row mt-2">
                                                    {beneficios?.map((t) => {
                                                        return (
                                                            <GroupRadio
                                                                key={t?.id}
                                                                name="beneficio_id"
                                                                id={`option-bene-${t?.id}`}
                                                                value={t?.id}
                                                                col="12"
                                                                label={t?.nome}
                                                                register={register}
                                                            />
                                                        );
                                                    })}
                                                </div>
                                            </>
                                        ) : (
                                            <small className="">
                                                <b>
                                                    Nenhum benefícios disponível
                                                    para este plano
                                                </b>
                                            </small>
                                        )
                                    }
                                    <hr />
                                </div>
                                <div className="col-12 mb-3">
                                    {
                                        // TERMINAIS DO PLANO
                                        terminaisPlano?.length ? (
                                            <>
                                                <small>
                                                    <b>
                                                        Selecione a forma de
                                                        pagamento
                                                    </b>
                                                </small>
                                                <div className="row mt-2">
                                                    {terminaisPlano?.map((t) => {
                                                        return (
                                                            <GroupRadio
                                                                key={t?.id}
                                                                name="terminal_id"
                                                                id={`option-tr-${t?.id}-pl`}
                                                                value={t?.id}
                                                                col="12"
                                                                label={t?.terminal}
                                                                onChange={() =>
                                                                    setCard(t?.card)
                                                                }
                                                                register={register}
                                                            />
                                                        );
                                                    })}
                                                    {!card ? (
                                                        <div className="check-pj col-12 mt-2">
                                                            <div className="row">
                                                                <div className="col-12 mb-1">
                                                                    <small>
                                                                        <b>
                                                                            Dia do
                                                                            vencimento
                                                                        </b>
                                                                    </small>
                                                                </div>
                                                                <GroupSelect
                                                                    col="12"
                                                                    label=""
                                                                    name="dia_vencimento"
                                                                    id="dia_vencimento"
                                                                    register={
                                                                        register
                                                                    }
                                                                >
                                                                    <DiasMes />
                                                                </GroupSelect>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12 mb-1">
                                                                    <small>
                                                                        <b>
                                                                            Vincule a cobrança do filiado a uma empresa
                                                                        </b>
                                                                    </small>
                                                                </div>
                                                                <GroupSelectFilter
                                                                    col="12"
                                                                    label=""
                                                                    name="cad_pj_id"
                                                                    register={
                                                                        register
                                                                    }
                                                                >
                                                                    <option value="">
                                                                        Selecione...
                                                                    </option>
                                                                    {fornecedores?.map(
                                                                        (
                                                                            p,
                                                                            index
                                                                        ) => {
                                                                            return (
                                                                                <option
                                                                                    key={
                                                                                        index
                                                                                    }
                                                                                    value={
                                                                                        p.id
                                                                                    }
                                                                                >
                                                                                    {
                                                                                        p.nome
                                                                                    }
                                                                                </option>
                                                                            );
                                                                        }
                                                                    )}
                                                                </GroupSelectFilter>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-12 mb-2">
                                                                    <small>
                                                                        <b>
                                                                            Selecione
                                                                            a forma
                                                                            de
                                                                            pagamento
                                                                            Adessão/1º
                                                                            Mensalidade
                                                                        </b>
                                                                    </small>
                                                                </div>
                                                                {terminaisFaturamento?.map(
                                                                    (t) => {
                                                                        return (
                                                                            <GroupRadio
                                                                                key={
                                                                                    t?.id
                                                                                }
                                                                                name="terminal_faturamento_id"
                                                                                id={`option-tr-${t?.id}-ft`}
                                                                                value={
                                                                                    t?.id
                                                                                }
                                                                                col="12"
                                                                                label={
                                                                                    t?.terminal
                                                                                }
                                                                                register={
                                                                                    register
                                                                                }
                                                                            />
                                                                        );
                                                                    }
                                                                )}
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            </>
                                        ) : (
                                            <small className="text-danger">
                                                <i className="las la-exclamation-triangle mr-2"></i>
                                                Nenhum terminal configurado para
                                                este plano!
                                            </small>
                                        )
                                    }
                                </div>
                            </CardContent>
                        )}
                        {card && (
                            <CardContent>
                                <CardTitle>Dados do cartão</CardTitle>
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-lg-12 mb-4">
                                            <div className="text-center">
                                                <CredCard
                                                    cvc={credCardSeguranca}
                                                    expiry={credCardVencimento}
                                                    focus={credCardFocus}
                                                    name={credCardNome}
                                                    number={credCardNumero}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="row">
                                                <GroupInput
                                                    col="12"
                                                    name="card_nome"
                                                    onKeyUp={onChangeCard}
                                                    label="Nome impresso no Cartão"
                                                    register={register}
                                                />
                                                <GroupInput
                                                    col="12"
                                                    name="card_numero"
                                                    onKeyUp={onChangeCard}
                                                    label="Número do Cartão"
                                                    register={register}
                                                />
                                                <GroupInput
                                                    col="6"
                                                    name="card_vencimento"
                                                    onKeyUp={onChangeCard}
                                                    label="Data de vencimento"
                                                    register={register}
                                                />
                                                <GroupInput
                                                    col="6"
                                                    name="card_seguranca"
                                                    onKeyUp={onChangeCard}
                                                    label="Código de segurança"
                                                    register={register}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </CardContent>
                        )}
                        <ButtonSave type="submit" text="Finalizar Cadastro" icon="user-check" className="btn btn-primary float-right" />
                    </form>
                </div>
            </div>
        </Modal>
    );
};

export default ModalNovoTitular;
