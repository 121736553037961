import React from "react";
import ButtonSave from "../../../../components/ButtonComponent/ButtonSave";
import CONNECT_API from "../../../../configs/connect";
import { ShowError, swalConfirm } from "../../../../configs/Functions";
import { useIsLoading } from "../../../../contexts/LoadingContext";


export const ChangeStatusFiliado = props => {


    const { setIsLoading } = useIsLoading();


    async function onSubmit() {


        try {

            const ok = await swalConfirm({
                icon: 'warning',
                text: 'Deseja cancelar o fililiado?',
            });

            if (ok?.isConfirmed) {
                const id = props.match.params.id;
                setIsLoading(true);
                const ok = await CONNECT_API.put(`/filiado/${id}/configuracao/situacao`, { cancelar: true });
                setIsLoading(false);

                if (ok?.data && props.changePage) props.changePage();
            }
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }


    }

    return (
        <div className="change-status-filiado text-muted">
            <div className="card">
                <div className="card-body">
                    <div className="card_header text-uppercase mb-4">
                        <i className="las la-map-pin"></i><b> Situação do filiado</b>
                        <hr className="mt-1" />
                    </div>
                    <div className="change">
                        <div className="res">
                            Etapas para realizar o cancelamento do cadastro
                            <ul className="py-2 text-success">
                                <li>Realizar contato com o clinte.</li>
                                <li>Entender o motivo do cancelamento.</li>
                                <li>Indicar soluções justificando o motivo do cancelamento.</li>
                                <li>Iniciar negociações oferecendo benefícios as cliente.</li>
                                <li className="text-warning">Esgotou as tentativas para reverter o cancelamento?</li>
                                <li className="text-danger">Verifique as pendencias em aberta e tente uma negociação amigável!</li>
                            </ul>
                        </div>
                        <div className="form-row">
                            <div className="col-12 text-center">
                                <div className="mb-3">Verifique com seu gerente antes de realizar o cancelamento!</div>
                                <ButtonSave type="submit" onClick={onSubmit} className="btn-sm" text="CANCELAR CADASTRO" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangeStatusFiliado;