import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useForm } from 'react-hook-form';
import ImputMask from 'inputmask';
import { GroupInput, GroupText } from "../../../components/Form";
import { equalsOrError, existsOrError, SetDate, SetNumber, validaSenhaForte, VerificaSenhaForte } from "../../../configs/Functions";
import { CardContent, CardTitle } from "../../../components/Card-z";
import { useIsLoading } from "../../../contexts/LoadingContext";
import CONNECT_API from "../../../configs/connect";
import HeaderComponent from "../../../components/HeaderComponent";
import ButtonSave from "../../../components/ButtonComponent/ButtonSave";
import { toast } from "react-toastify";
import FileSingle from "../../../components/File/Single/input";


export const MinhaConta = props => {

    const [colaborador, setColaborador] = useState([]);
    const [acesso, setAcesso] = useState([]);
    const [pg, setPg] = useState([]);
    const [fileList, setFileList] = useState([]);


    const { register, handleSubmit, reset } = useForm();
    const { setIsLoading } = useIsLoading();



    async function get_data_page() {

        try {

            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/empresa/colaborador/cadastro`);
            existsOrError(data, 'Não foi possivel carregar os dados!');

            setColaborador(data?.colaborador);
            setAcesso(data?.acesso);
            setPg(data?.pg);
            setFileList(data?.files);

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }

    async function get_data_cep(e) {

        const cep = SetNumber(e.target.value);
        if (!cep) return false;

        if (cep.length === 8) {

            setIsLoading(true);

            const json = await CONNECT_API.getCep(cep);

            reset({
                uf: json?.data.uf,
                cidade: json?.data.localidade,
                bairro: json?.data.bairro,
                logradouro: json?.data.logradouro,
                complemento: json?.data.complemento
            })

            setIsLoading(false);
        }
    }



    //Carregamnento dos dados
    useEffect(() => {
        get_data_page()
        // eslint-disable-next-line
    }, []);


    // reset nos campos / title
    useEffect(() => {

        if (!acesso || !colaborador) return;

        reset({
            nome: colaborador?.nome,
            cpf: colaborador?.cpf,
            nascimento: SetDate(colaborador?.nascimento),
            habilidade: colaborador?.habilidade,
            cargo: colaborador.cargo,
            tel_pessoal: colaborador?.tel_pessoal,
            tel_profissional: colaborador?.tel_profissional,
            email: colaborador?.email,

            cep: colaborador?.cep,
            logradouro: colaborador?.logradouro,
            numero: colaborador?.numero,
            bairro: colaborador?.bairro,
            cidade: colaborador?.cidade,
            uf: colaborador?.uf,
            complemento: colaborador?.complemento,

            user: acesso?.user,
            senha1: acesso?.password,
            senha2: acesso?.password,
        });


        // eslint-disable-next-line
    }, [colaborador, acesso]);


    useEffect(() => {
        document.title = `SGA - ${pg?.title}`;
        // eslint-disable-next-line
    }, [pg]);

    // Mask
    useEffect(() => {
        ImputMask({ "mask": '999.999.999-99' }).mask('[name="cpf"]');
        ImputMask({ "mask": '(99) 99999-9999' }).mask('[type="tel"]');
        ImputMask({ "mask": '99.999-999' }).mask('[name="cep"]');
    }, []);


    async function onSubmit(data) {

        try {

            const fields = {
                nome: data.nome,
                nascimento: data.nascimento,
                habilidade: data.habilidade,
                tel_pessoal: SetNumber(data.tel_pessoal),
                tel_profissional: SetNumber(data.tel_profissional),
                email: data.email,

                cep: SetNumber(data.cep),
                logradouro: data.logradouro,
                numero: SetNumber(data.numero),
                bairro: data.bairro,
                cidade: data.cidade,
                uf: data.uf,
                complemento: data.complemento,

                senha1: data.senha1,
                senha2: data.senha2,

                files: data.files
            }

            // Campos obrigatórios
            existsOrError(fields.nome, 'Nome não informado!');

            if (fields.senha1) {
                existsOrError(fields.senha1, 'Senha de acesso não informado!');
                existsOrError(fields.senha2, 'Confirmação de senha não informado!');
                equalsOrError(fields.senha1, fields.senha2, 'Senhas de acesso não confere!');
                validaSenhaForte(fields.senha1);
            }

            setIsLoading(true);
            await CONNECT_API.put(`${pg.end_point}`, { ...fields });
            setIsLoading(false);

            // ATUALIZAR OS DADOS NA PÁGINA
            await get_data_page();

        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }

    return (
        <div className="minha-conta">
            <div className="row">
                <div className="col-12">
                    <HeaderComponent {...pg}>
                        <ButtonSave onClick={handleSubmit(onSubmit)} />
                    </HeaderComponent>
                </div>
                <div className="col-12">
                    <form action="" onSubmit={handleSubmit(onSubmit)}>
                        <CardContent>
                            <CardTitle>
                                Meus Dados
                            </CardTitle>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-auto">
                                        <FileSingle label="Foto de perfil" type="image" register={register} reset={reset} fileList={fileList} setFileList={setFileList} />
                                    </div>
                                    <div className="col">
                                        <div className="row">
                                            <GroupInput name="nome" col="12" label="Nome" register={register} />
                                            <GroupInput name="cpf" col="6" disabled="disabled" label="CPF" register={register} />
                                            <GroupInput name="nascimento" type="date" col="6" label="Data de nascimento" register={register} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </CardContent>

                        <CardContent>
                            <CardTitle>
                                Meu Endereço
                            </CardTitle>
                            <GroupInput name="cep" col="3" label="Cep" onKeyUp={get_data_cep} register={register} />
                            <GroupInput name="cidade" col="6" label="Cidade" register={register} />
                            <GroupInput name="uf" col="3" label="Estado" register={register} />
                            <GroupInput name="logradouro" col="6" label="Logradouro" register={register} />
                            <GroupInput name="bairro" col="4" label="Bairro" register={register} />
                            <GroupInput name="numero" col="2" label="Número" type="number" register={register} />
                            <GroupText name="complemento" col="12" label="Complemento" register={register} />
                        </CardContent>

                        <CardContent>
                            <CardTitle>
                                Contato
                            </CardTitle>
                            <GroupInput name="tel_pessoal" type="tel" col="6" label="Fone/Whatsapp pessoal" register={register} />
                            <GroupInput name="tel_profissional" type="tel" col="6" label="Fone/Whatsapp profissional" register={register} />
                            <GroupInput name="email" type="email" col="12" label="E-mail" register={register} />
                        </CardContent>

                        <CardContent>
                            <CardTitle>
                                Acesso
                            </CardTitle>
                            <div className="col-12">
                                <div className={`show`}>
                                    <div className="row">
                                        <GroupInput name="user" type="email" disabled col="6" label="E-mail de acesso" register={register} />
                                        <GroupInput name="senha1" type="password" col="3" onKeyUp={VerificaSenhaForte} label="Senha de acesso" register={register} />
                                        <GroupInput name="senha2" type="password" col="3" label="Confirme sua senha" register={register} />
                                    </div>
                                </div>
                            </div>
                        </CardContent>
                        <ButtonSave type="submit" className="float-right my-3" />
                    </form>
                </div>
            </div>
        </div >
    );

}

export default withRouter(MinhaConta);