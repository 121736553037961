import React from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

export const GraficoLinhaDasboard = props => {

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
                align: 'start',
                fullSize: true,
                labels: {
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                    fullSize: true,
                },
            },
            title: {
                display: true,
                text: props?.title?.toUpperCase() || 'GRÁFICOS DE LINHA',
                fullSize: true
            },
        },
    };

    const vendas_mes_label = props?.data?.f_m ? props?.data?.f_m : [];
    const labels_fm = Object.keys(vendas_mes_label);

    const vendas_mes = props?.data?.v_m ? props?.data?.v_m : [];
    const data_fm_key = Object.keys(vendas_mes);

    const data_fm = vendas_mes;
    // const labels = [];

    const labels = labels_fm;
    const datasets = [];

    const color_array = {};
    for (let i = 0; i < data_fm_key.length; i++) {

        const nome = data_fm_key[i];
        var color = gerar_cor_hexadecimal();
        if (color_array[color] === undefined) {
            color_array[color] = color;
        } else {
            color = gerar_cor_hexadecimal();
        }
        datasets.push({
            label: nome,
            data: labels.map((e) => data_fm[nome][e]),
            borderColor: color,
            backgroundColor: color,
        });
    }

    const data = {
        labels,
        datasets,
    };
    return <Line options={options} data={data} />;
};



function gerar_cor_hexadecimal() {
    return '#' + parseInt((Math.random() * 0xFFFFFF))
        .toString(16)
        .padStart(6, '0');
}

// eslint-disable-next-line
// function getDiaDaSemana(data) {
//     let dw = ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"];
//     return dw[data.getDay()];
// }

// function getSemana(s = '') {
//     var data = new Date();
//     var dia_semana = data.getDay();
//     var segDate = new Date();
//     var sabDate = new Date();

//     // Semana anterior
//     if (s === 'prev') {

//         switch (dia_semana) {
//             case 0: // Domingo
//                 segDate.setDate(segDate.getDate() - 6);
//                 sabDate.setDate(sabDate.getDate() - 1);
//                 break;
//             case 1: // Segunda
//                 sabDate.setDate(sabDate.getDate() - 5);
//                 break;
//             case 2: // Terça
//                 segDate.setDate(segDate.getDate() + 1);
//                 sabDate.setDate(sabDate.getDate() - 4);
//                 break;
//             case 3: // Quarta
//                 segDate.setDate(segDate.getDate() + 2);
//                 sabDate.setDate(sabDate.getDate() - 3);
//                 break;
//             case 4: // Quinta
//                 segDate.setDate(segDate.getDate() + 3);
//                 sabDate.setDate(sabDate.getDate() - 2);
//                 break;
//             case 5: // Sexta
//                 segDate.setDate(segDate.getDate() + 4);
//                 sabDate.setDate(sabDate.getDate() - 1);
//                 break;
//             case 6: // Sabado
//                 segDate.setDate(segDate.getDate() - 5);
//                 break;
//             default:
//                 break;
//         }
//         return {
//             seg: segDate,
//             sab: sabDate
//         }
//     }



//     // Semana normal
//     switch (dia_semana) {
//         case 0: // Domingo
//             segDate.setDate(segDate.getDate() + 1);
//             sabDate.setDate(sabDate.getDate() + 6);
//             break;
//         case 1: // Segunda
//             sabDate.setDate(sabDate.getDate() + 5);
//             break;
//         case 2: // Terça
//             segDate.setDate(segDate.getDate() - 1);
//             sabDate.setDate(sabDate.getDate() + 4);
//             break;
//         case 3: // Quarta
//             segDate.setDate(segDate.getDate() - 2);
//             sabDate.setDate(sabDate.getDate() + 3);
//             break;
//         case 4: // Quinta
//             segDate.setDate(segDate.getDate() - 3);
//             sabDate.setDate(sabDate.getDate() + 2);
//             break;
//         case 5: // Sexta
//             segDate.setDate(segDate.getDate() - 4);
//             sabDate.setDate(sabDate.getDate() + 1);
//             break;
//         case 6: // Sabado
//             segDate.setDate(segDate.getDate() - 5);
//             break;
//         default:
//             break;
//     }

//     return {
//         seg: segDate,
//         sab: sabDate
//     }
// }