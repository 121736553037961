import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Collaborator from "../pages/md_2_collaborator/Collaborator";
import CollaboratorDetail from "../pages/md_2_collaborator/CollaboratorDetail";
import Company from "../pages/md_2_company/Company";
import CompanyDetail from "../pages/md_2_company/CompanyDetail";
import Dashboard from "../pages/md_1_dashboard/Dashboard";
import Office from "../pages/md_2_office/Office";
import OfficeDetail from "../pages/md_2_office/OfficeDetail";
import { Autenticado } from "../configs/auth";
import { useIsLoading } from "../contexts/LoadingContext";
import SemAcesso from "../pages/md_1_semPermissao";
import PageNot from "../pages/md_1_paginaNaoEncontrada";
import Setor from "../pages/md_2_setor/Setor";
import SetorById from "../pages/md_2_setor/SetorById";
import MinhaConta from "../pages/md_2_collaborator/cadastro/MinhaConta";
import Fornecedores from "../pages/md_3_fornecedor/Fornecedores";
import FornecedorLinksPJ from "../pages/md_3_fornecedor/PJ/FornecedorLinks";
import FornecedorEditarPJ from "../pages/md_3_fornecedor/PJ/FornecedorEditar";
import FornecedorLinksPF from "../pages/md_3_fornecedor/PF/FornecedorLinks";
import FornecedorEditarPF from "../pages/md_3_fornecedor/PF/FornecedorEditar";
import Anotacoes from "../pages/md_3_fornecedor/Anotacoes";
import { Financeiras } from "../pages/md_3_financeira/Financeiras";
import FinanceiraLinks from "../pages/md_3_financeira/FinanceiraLinks";
import FinanceiraEditar from "../pages/md_3_financeira/FinanceiraEditar";
import Terminais from "../pages/md_3_financeira/terminal/Terminais";
// import LancamentoDashboard from "../pages/md_3_lancamento/LancamentoDashboard";
import { LancamentoReceita } from "../pages/md_3_lancamento/receita/LancamentoReceita";
import LancamentoDespesa from "../pages/md_3_lancamento/despesa/LancamentoDespesa";
import LancamentoSearch from "../pages/md_3_lancamento/LancamentoSearch";
import FaturamentoSearch from "../pages/md_3_faturamento/FaturamentoSearch";
import FaturamentoById from "../pages/md_3_faturamento/FaturamentoById";
import LancamentoById from "../pages/md_3_lancamento/LancamentoById";
import CieloLinks from "../pages/md_4_pagamento_integrado/cielo/CieloLinks";
import CieloCadastro from "../pages/md_4_pagamento_integrado/cielo/CieloEditar";
import CartaoDashboard from "../pages/md_5_filiado/cartao/CartaoDashboard";
import CartaoById from "../pages/md_5_filiado/cartao/CartaoById";
import PlanoDashboard from "../pages/md_5_filiado/plano/PlanoDashboard";
import PlanoLinks from "../pages/md_5_filiado/plano/PlanoLinks";
import PlanoById from "../pages/md_5_filiado/plano/cadastro/PlanoById";
import PlanoConfiguracaoById from "../pages/md_5_filiado/plano/configuracao/PlanoConfiguracaoById";
import FiliadoSearch from "../pages/md_5_filiado/filiado/FiliadoSearch";
import FiliadoNovo from "../pages/md_5_filiado/filiado/FiliadoNovo";
import FiliadoById from "../pages/md_5_filiado/filiado/cadastro/FiliadoById";
import FiliadoEditar from "../pages/md_5_filiado/filiado/cadastro/FiliadoEditar";
import { FiliadoDependente } from "../pages/md_5_filiado/filiado/dependente/DependenteSearch";
import FiliadoAnotacoes from "../pages/md_5_filiado/filiado/anotacao/FiliadoAnotacoes";
import { FiliadoAnexos } from "../pages/md_5_filiado/filiado/anexo/FiliadoAnexos";
import FiliadoLancamentoById from "../pages/md_5_filiado/filiado/lancamento/LancamentoFiliadoById";
import { FiliadoTerminal } from "../pages/md_5_filiado/filiado/terminal/TerminalFiliado";
import { CieloRecorrenciaById } from "../pages/md_4_pagamento_integrado/cielo/CieloShowRecorrencia";
import { CieloPagamentoById } from "../pages/md_4_pagamento_integrado/cielo/CieloShowPagamento";
import { FiliadoPlano } from "../pages/md_5_filiado/filiado/plano/PlanoFiliado";
import { FiliadoCard } from "../pages/md_5_filiado/filiado/card/CardFiliado";
import MyExtract from "../pages/md_2_collaborator/caixa/MyExtract";
import { FiliadoConfiguracao } from "../pages/md_5_filiado/filiado/configuracao/ConfiguracaoFiliado";
import RelatorioLinks from "../pages/md_5_filiado/relatorio/RelatorioLinks";
import RelatorioFiliadosVendas from "../pages/md_5_filiado/relatorio/vendas/RelatorioVendas";
import RelatorioFiliadosFiliados from "../pages/md_5_filiado/relatorio/filiados/RelatorioFiliados";
import LeadsDashboardLinks from "../pages/md_6_CRM_slim/leads/LeadsDashboardLinks";
import LeadId from "../pages/md_6_CRM_slim/leads/lead/LeadId";
import ListLeadSearch from "../pages/md_6_CRM_slim/leads/lista/ListLeadSearch";
import LeadConfig from "../pages/md_6_CRM_slim/config/LeadConfig";
import CompanyConfigLayout from "../pages/md_2_company/CompanyConfigLayout";
import ConfigFiliados from "../pages/md_5_filiado/configuracao/ConfiguracaoFiliados";
import NotificacaoFiliado from "../pages/md_5_filiado/configuracao/notificacao/NotificacaoFiliadoIndex";
import { PageFiliadosByFornecedor } from "../pages/md_3_fornecedor/PJ/filiados/Index";
import IdxAdmin from "../pages/md_admin/Index";
import MeusLinksIndex from "../pages/md_7_vendas_online/meus_links/Index";
import PainelIndex from "../pages/md_7_vendas_online/painel/Index";
import ConfiguracaoIndex from "../pages/md_7_vendas_online/configuracao/Index";
import AnunciosIndex from "../pages/md_7_vendas_online/anuncios/Index";
import LinkId from "../pages/md_7_vendas_online/meus_links/LinkId";
import CieloRecorrencia from "../pages/md_4_pagamento_integrado/cielo/recorrencia";
import CieloLancamento from "../pages/md_4_pagamento_integrado/cielo/lancamento";
import RelatorioWebsite from "../pages/md_5_filiado/relatorio/website";
import CieloOperacao from "../pages/md_4_pagamento_integrado/cielo/operacao";
import LancamentoDashboard from "../pages/md_3_relatorio/LancamentoDashboard";
import NotificacaoPagamento from "../pages/md_5_filiado/configuracao/notificacao-pagamento";
import { NotificacaoPagamentoByTerminal } from "../pages/md_5_filiado/configuracao/notificacao-pagamento/notificacao-terminal";
import Anuncios from "../pages/md_8_portal_assinante/anuncios";
import Painel from "../pages/md_8_portal_assinante/painel";
import Mensagens from "../pages/md_8_portal_assinante/mensagens";
import Permissoes from "../pages/md_8_portal_assinante/permissoes";
import Configuracoes from "../pages/md_8_portal_assinante/configuracoes";
import PainelPlano from "../pages/md_8_portal_assinante/painel/plano";
import { PainelPlanoMenus } from "../pages/md_8_portal_assinante/painel/menus";
import AsaasLinks from "../pages/md_4_pagamento_integrado/asaas/AsaasLinks";
import AsaasCadastro from "../pages/md_4_pagamento_integrado/asaas/AsaasEditar";

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { isNav, isCollaborator } = useIsLoading();
    const { logado, permissao } = Autenticado(isNav, rest);

    // Se for admin então pode passar direto
    if ((logado && permissao) || isCollaborator?.admin) {
        // SEGUE O FLUXO NORMAL
        return <Route {...rest} render={(props) => <Component {...props} />} />;
    } else if (logado) {
        // RETORNAR A PÁGINA COM AVISO DE SEM ACESSO
        return <Route {...rest} render={(props) => <SemAcesso />} />;
    } else {
        //return <Route {...rest} render={props => <Login {...props} />}/>
        window.location.reload();
        return;
    }
};

const Routers = () => {
    return (
        <Switch>
            {/*** MD 1 ****/}
            {/*<PrivateRoute exact path="/" component={Dashboard} /> */}
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            {/*** MD 2 ***/}
            <PrivateRoute
                exact
                path="/empresa/layout"
                component={CompanyConfigLayout}
            />
            <PrivateRoute
                exact
                path="/empresa/editar"
                component={CompanyDetail}
            />
            <PrivateRoute exact path="/empresa" component={Company} />

            <PrivateRoute exact path="/empresa/cargo" component={Office} />
            <PrivateRoute
                exact
                path="/empresa/cargo/:id"
                component={OfficeDetail}
            />

            <PrivateRoute exact path="/empresa/setor" component={Setor} />
            <PrivateRoute
                exact
                path="/empresa/setor/:id"
                component={SetorById}
            />

            <PrivateRoute
                exact
                path="/empresa/colaborador"
                component={Collaborator}
            />
            <PrivateRoute
                exact
                path="/empresa/colaborador/caixa"
                component={MyExtract}
            />
            <PrivateRoute
                exact
                path="/empresa/colaborador/cadastro"
                component={MinhaConta}
            />
            <PrivateRoute
                exact
                path="/empresa/colaborador/:id"
                component={CollaboratorDetail}
            />

            {/*** MD 3 ***/}
            <PrivateRoute exact path="/fornecedor" component={Fornecedores} />
            <PrivateRoute
                exact
                path="/fornecedor/PJ/:id"
                component={FornecedorLinksPJ}
            />
            <PrivateRoute
                exact
                path="/fornecedor/PJ/:id/editar"
                component={FornecedorEditarPJ}
            />
            <PrivateRoute
                exact
                path="/fornecedor/PF/:id"
                component={FornecedorLinksPF}
            />
            <PrivateRoute
                exact
                path="/fornecedor/PF/:id/editar"
                component={FornecedorEditarPF}
            />
            <PrivateRoute
                exact
                path="/fornecedor/:tipo/:id/anotacoes"
                component={Anotacoes}
            />
            <PrivateRoute
                exact
                path="/fornecedor/PJ/:id/filiados"
                component={PageFiliadosByFornecedor}
            />

            {/*** FINANCEIRA ***/}
            <PrivateRoute exact path="/financeira" component={Financeiras} />
            <PrivateRoute
                exact
                path="/financeira/:id"
                component={FinanceiraLinks}
            />
            <PrivateRoute
                exact
                path="/financeira/:id/editar"
                component={FinanceiraEditar}
            />
            <PrivateRoute
                exact
                path="/financeira/:id/terminais"
                component={Terminais}
            />

            {/*** LANÇAMENTO ***/}
            <PrivateRoute
                exact
                path="/lancamento"
                component={LancamentoSearch}
            // component={LancamentoDashboard}
            />
            <PrivateRoute
                exact
                path="/financeiro/relatorios"
                component={LancamentoDashboard}
            />
            <PrivateRoute
                exact
                path="/lancamento/receita/add"
                component={LancamentoReceita}
            />
            <PrivateRoute
                exact
                path="/lancamento/despesa/add"
                component={LancamentoDespesa}
            />
            <PrivateRoute
                exact
                path="/lancamento/search"
                component={LancamentoSearch}
            />
            <PrivateRoute
                exact
                path="/lancamento/:id"
                component={LancamentoById}
            />

            {/** FATURAMENTO **/}
            <PrivateRoute
                exact
                path="/faturamento"
                component={FaturamentoSearch}
            />
            <PrivateRoute
                exact
                path="/faturamento/:id"
                component={FaturamentoById}
            />

            {/** PAGAMENTO INTEGRADO **/}
            <PrivateRoute exact path="/cielo" component={CieloLinks} />
            <PrivateRoute exact path="/cielo/editar" component={CieloCadastro} />
            <PrivateRoute exact path="/cielo/recorrencia/:id" component={CieloRecorrenciaById} />
            <PrivateRoute exact path="/cielo/pagamento/:id" component={CieloPagamentoById} />
            <PrivateRoute exact path="/cielo/operacao" component={CieloOperacao} />
            <PrivateRoute exact path="/cielo/recorrencia" component={CieloRecorrencia} />
            <PrivateRoute exact path="/cielo/lancamento" component={CieloLancamento} />
            {/* ASAAS */}
            <PrivateRoute exact path="/asaas" component={AsaasLinks} />
            <PrivateRoute exact path="/asaas/editar" component={AsaasCadastro} />

            {/** CARTÕES **/}
            <PrivateRoute exact path="/cartao" component={CartaoDashboard} />
            <PrivateRoute exact path="/cartao/:id" component={CartaoById} />

            {/** PLANOS **/}
            <PrivateRoute exact path="/plano" component={PlanoDashboard} />
            <PrivateRoute exact path="/plano/:id" component={PlanoLinks} />
            <PrivateRoute
                exact
                path="/plano/:id/editar"
                component={PlanoById}
            />
            <PrivateRoute
                exact
                path="/plano/:id/configuracao"
                component={PlanoConfiguracaoById}
            />

            {/** FILIADO **/}
            <PrivateRoute
                exact
                path="/filiado/configuracao"
                component={ConfigFiliados}
            />
            <PrivateRoute
                exact
                path="/filiado/configuracao/notificacao-pagamento"
                component={NotificacaoPagamento}
            />
            <PrivateRoute
                exact
                path="/filiado/configuracao/notificacao-pagamento/:id"
                component={NotificacaoPagamentoByTerminal}
            />
            <PrivateRoute
                exact
                path="/filiado/configuracao/notificacao"
                component={NotificacaoFiliado}
            />
            <PrivateRoute
                exact
                path="/filiado/:id/editar"
                component={FiliadoEditar}
            />
            <PrivateRoute
                exact
                path="/filiado/:id/dependente"
                component={FiliadoDependente}
            />
            <PrivateRoute
                exact
                path="/filiado/:id/anotacoes"
                component={FiliadoAnotacoes}
            />
            <PrivateRoute
                exact
                path="/filiado/:id/anexos"
                component={FiliadoAnexos}
            />
            <PrivateRoute
                exact
                path="/filiado/:id/lancamentos"
                component={FiliadoLancamentoById}
            />
            <PrivateRoute
                exact
                path="/filiado/:id/terminal"
                component={FiliadoTerminal}
            />
            <PrivateRoute
                exact
                path="/filiado/:id/plano"
                component={FiliadoPlano}
            />
            <PrivateRoute
                exact
                path="/filiado/:id/card"
                component={FiliadoCard}
            />
            <PrivateRoute
                exact
                path="/filiado/:id/configuracao"
                component={FiliadoConfiguracao}
            />

            <PrivateRoute exact path="/filiado/novo" component={FiliadoNovo} />
            <PrivateRoute exact path="/filiado/relatorio/website" component={RelatorioWebsite} />
            <PrivateRoute exact path="/filiado/relatorio/filiados" component={RelatorioFiliadosFiliados} />
            <PrivateRoute
                exact
                path="/filiado/relatorio/vendas"
                component={RelatorioFiliadosVendas}
            />
            <PrivateRoute
                exact
                path="/filiado/relatorio"
                component={RelatorioLinks}
            />
            <PrivateRoute exact path="/filiado/:id" component={FiliadoById} />
            <PrivateRoute exact path="/filiado" component={FiliadoSearch} />

            {/* MÓDULO 6 → CRM Slim */}
            <PrivateRoute exact path="/leads" component={LeadsDashboardLinks} />
            <PrivateRoute
                exact
                path="/leads/lista"
                component={ListLeadSearch}
            />
            <PrivateRoute
                exact
                path="/leads/configuracao"
                component={LeadConfig}
            />
            <PrivateRoute exact path="/leads/:id" component={LeadId} />

            {/* MD 7 VENDAS ONLINE */}
            <PrivateRoute exact path="/vendas-online/meus-links" component={MeusLinksIndex} />
            <PrivateRoute exact path="/vendas-online/meus-links/:id" component={LinkId} />
            <PrivateRoute exact path="/vendas-online/painel" component={PainelIndex} />
            <PrivateRoute exact path="/vendas-online/configuracao" component={ConfiguracaoIndex} />
            <PrivateRoute exact path="/vendas-online/anuncios" component={AnunciosIndex} />

            {/* MD 8 PORTAL DO ASSINANTE */}
            <PrivateRoute exact path="/portal-do-filiado/painel" component={Painel} />
            <PrivateRoute exact path="/portal-do-filiado/painel/:idp" component={PainelPlano} />
            <PrivateRoute exact path="/portal-do-filiado/painel/:idp/:idm" component={PainelPlanoMenus} />
            <PrivateRoute exact path="/portal-do-filiado/anuncios" component={Anuncios} />
            <PrivateRoute exact path="/portal-do-filiado/mensagens" component={Mensagens} />
            <PrivateRoute exact path="/portal-do-filiado/permissoes" component={Permissoes} />
            <PrivateRoute exact path="/portal-do-filiado/configuracoes" component={Configuracoes} />

            {/* Configurações de administradors */}
            <PrivateRoute exact path="/admin" component={IdxAdmin} />

            <Route exact path="/pagina-nao-encontrada" component={PageNot} />
            <Redirect from="/" to="/dashboard" />
            <Redirect from="*" to="/pagina-nao-encontrada" />
        </Switch>
    );
};

export default Routers;
