import React, { useEffect, useState } from "react";
import { useIsLoading } from "../../contexts/LoadingContext";
import { toast } from "react-toastify";
import CONNECT_API from "../../configs/connect";
import { existsOrError, formatCoin, formatDate } from "../../configs/Functions";
import HeaderComponent from "../../components/HeaderComponent";


const LancamentoDashboard = props => {

    const [bancos, setBancos] = useState([]);
    const [receitas, setReceitas] = useState([]);
    const [despesas, setDespesas] = useState([]);

    const [dias, setDias] = useState(15);

    const [totais, setTotais] = useState([]);
    const [pg, setPg] = useState([]);
    const { setIsLoading } = useIsLoading();

    async function get_data_page(dias = 15) {

        try {

            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/financeiro/relatorio?dias=${dias}`);
            existsOrError(data, 'Não foi possivel carregar os dados!');

            setBancos(data?.bancos);
            setReceitas(data?.receitas);
            setDespesas(data?.despesas);
            setPg(data?.pg);

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }


    //Carregamnento dos dados
    useEffect(() => {
        get_data_page(dias);
        // eslint-disable-next-line
    }, [dias]);

    //Carregamnento dos dados
    useEffect(() => {

        var saida = 0;
        var entrada = 0;
        var saldo = 0;
        bancos?.map(b => {

            if (b?.dados?.saida) saida += Number(b?.dados?.saida);
            if (b?.dados?.saldo) entrada += Number(b?.dados?.saldo);
            if (b?.dados?.saldo_provisionado) entrada += Number(b?.dados?.saldo_provisionado);
            if (b?.dados?.saldo) saldo += Number(b?.dados?.saldo);

            return true;
        });
        setTotais({ saida, entrada, saldo });
        // eslint-disable-next-line
    }, [bancos]);


    return <div className="financeiro-relatorios">
        <HeaderComponent {...pg}>
            <div className="row justify-content-end align-items-center">
                <div className="col-lg-auto">
                    <div className="card mb-0">
                        <div className="card-body text-center cursor-pointer" data-toggle="tooltip" title="Saída Financeira">
                            {formatCoin(totais?.saida)}
                            <i className="ml-1 las la-arrow-alt-circle-down text-danger"></i>
                        </div>
                    </div>
                </div>
                <div className="col-lg-auto">
                    <div className="card mb-0">
                        <div className="card-body text-center cursor-pointer" data-toggle="tooltip" title="Entrada Financeira">
                            {formatCoin(totais?.entrada)}
                            <i className="las la-arrow-alt-circle-up text-success ml-1"></i>
                        </div>
                    </div>
                </div>
                <div className="col-lg-auto">
                    <div className="card mb-0">
                        <div className="card-body text-center cursor-pointer" data-toggle="tooltip" title="Disponível em Carteira">
                            {formatCoin(totais?.saldo)}
                            <i className="la la-wallet text-success ml-1"></i>
                        </div>
                    </div>
                </div>
            </div>
        </HeaderComponent>
        <div className="row">
            {
                bancos?.map(banco => {

                    return (
                        <div className="col-lg col-12" key={banco?.id}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="row align-items-center justify-content-between">
                                        <div className="col-auto">
                                            <h4 className="m-0">{banco.nome}</h4>
                                        </div>
                                        <div className="col-auto">
                                            <ul className="list-group list-group-flush">
                                                <li className="list-group-item">
                                                    <div className="row justify-content-between align-items-center">
                                                        <div className="col-auto">Saldo</div>
                                                        <div className="col-auto">
                                                            {formatCoin(banco.dados.saldo)}
                                                            <i className="las la-arrow-alt-circle-up text-success ml-1" data-toggle="tooltip" title="Valor em Carteira"></i>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item">
                                                    <div className="row justify-content-between align-items-center">
                                                        <div className="col-auto">Saldo Provisionado</div>
                                                        <div className="col-auto">
                                                            {formatCoin(banco.dados.saldo_provisionado)}
                                                            <i className="las la-arrow-alt-circle-up text-warning ml-1" data-toggle="tooltip" title="Valor agendado. Não disponível"></i>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li className="list-group-item">
                                                    <div className="row justify-content-between align-items-center">
                                                        <div className="col-auto">Saída</div>
                                                        <div className="col-auto">
                                                            {formatCoin(banco.dados.saida)}
                                                            <i className="ml-1 las la-arrow-alt-circle-down text-danger" data-toggle="tooltip" title="Valor Gasto"></i>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
        <div className="row">
            <div className="col-12">
                <div className="card">
                    <div className="card-body">
                        <h5 className="mb-0">Lançamentos para os próximos
                            <button className={`btn btn-sm btn-${dias===7?'primary':'secondary'} mr-1 ml-1`} onClick={() => setDias(7)}>7</button>
                            <button className={`btn btn-sm btn-${dias===15?'primary':'secondary'} mr-1`} onClick={() => setDias(15)}>15</button>
                            <button className={`btn btn-sm btn-${dias===30?'primary':'secondary'} mr-1`} onClick={() => setDias(30)}>30</button>
                            dias</h5>
                        <hr />
                        <div className="row">
                            <div className="col-lg-6">
                                <h6 className="mt-0">Receitas <i className="las la-arrow-alt-circle-up text-success ml-1"></i></h6>
                                {!receitas?.quantidade ? (
                                    <div className="p-3 text-center">
                                        <i>Sem lançamentos...</i>
                                    </div>
                                ) : (
                                    <ul className="list-group list-group-flush">
                                        {
                                            receitas?.lista?.map(r => {
                                                return (
                                                    <li key={r?.id} className="list-group-item">
                                                        <div className="row">
                                                            <div className="col">
                                                                <small>{r?.pessoa?.nome}</small>
                                                            </div>
                                                            <div className="col-auto">
                                                                <small>{formatCoin(r?.valor)}</small>
                                                            </div>
                                                            <div className="col-auto">
                                                                <small>{formatDate(r?.vencimento)}</small>
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            })
                                        }
                                        <li className="list-group-item">
                                            <div className="row justify-content-between">
                                                <div className="col-auto">{receitas?.quantidade - 5 > 0 ? (`... + ${receitas?.quantidade - 5} registros`) : ('...')}</div>
                                                <div className="col-auto">Total de {formatCoin(receitas?.valor_total)}</div>
                                            </div>
                                        </li>
                                    </ul>
                                )}
                            </div>
                            <div className="col-lg-6">
                                <h6 className="mt-0">Despesas <i className="ml-1 las la-arrow-alt-circle-down text-danger"></i></h6>
                                {!despesas?.quantidade ? (
                                    <div className="p-3 text-center">
                                        <i>Sem lançamentos...</i>
                                    </div>
                                ) : (
                                    <ul className="list-group list-group-flush">
                                        {
                                            despesas?.lista?.map(r => {
                                                return (
                                                    <li key={r?.id} className="list-group-item">
                                                        <div className="row">
                                                            <div className="col">
                                                                <small>{r?.pessoa?.nome}</small>
                                                            </div>
                                                            <div className="col-auto">
                                                                <small>{formatCoin(r?.valor)}</small>
                                                            </div>
                                                            <div className="col-auto">
                                                                <small>{formatDate(r?.vencimento)}</small>
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            })
                                        }
                                        <li className="list-group-item">
                                            <div className="row justify-content-between">
                                                <div className="col-auto">{despesas?.quantidade - 5 > 0 ? (`... + ${despesas?.quantidade - 5} registros`) : ('...')}</div>
                                                <div className="col-auto">Total de {formatCoin(despesas?.valor_total)}</div>
                                            </div>
                                        </li>
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
}



export default LancamentoDashboard;