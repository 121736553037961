import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { useIsLoading } from "../../../../contexts/LoadingContext";
import CONNECT_API from "../../../../configs/connect";
import HeaderComponent from "../../../../components/HeaderComponent";
import { CardContent } from "../../../../components/Card-z";
import { TableHover, Tbody, Td, Th, Thead } from "../../../../components/Table-z";
import { existsOrError, formatCoin, formatDate, ShowError, swalConfirm } from "../../../../configs/Functions";
import ModalFaturarLancamento from "./ModalFaturarLancamento";
import $ from 'jquery';
import ModalCieloDetalhes from "./ModalCieloDetalhes";


export const FiliadoLancamentoById = props => {

    const [pg, setPg] = useState([]);
    const [lancamentos, setLancamentos] = useState([]);
    const [terminal, setTerminal] = useState([]);
    const [lancamentoEdit, setLancamentoEdit] = useState(undefined);
    const [pagamentoDetalhes, setPagamentoDetalhes] = useState(undefined);


    const { setIsLoading } = useIsLoading();


    async function get_data_page() {

        try {

            const id = props.match.params.id;
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/filiado/${id}/lancamentos`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setLancamentos(data.lancamentos);
            setTerminal(data?.terminal)
            setPg(data.pg);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    async function criarLancamentoAsaas(lancamento_id) {
        try {
            setIsLoading(true);
            await CONNECT_API.post(`/lancamento/asaas`, { lancamento_id });
            setIsLoading(false);
            await get_data_page();
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }


    function showModal(id) {
        if (!id) return false;
        setLancamentoEdit(id);
        $(`#modal-faturamento`).modal('show');
    }

    function showModalDetalhesPagamento(id) {
        if (!id) return false;
        setPagamentoDetalhes(id);
        $(`#modal-cielo-detalhes`).modal('show');
    }

    async function cardPagarLancamento(lancamento_id) {

        try {

            existsOrError(lancamento_id, "Lançamento não informado!");

            // MODAL DE CONFIRMAÇÃO
            const confirm = await swalConfirm({
                icon: 'warning',
                title: 'Atenção',
                html: `
                        <div>
                            Deseja fazer a cobrança neste momento?<br> 
                            <small>
                                A cobrança vai ser lançada no cartão de crédito<br>
                                que está cadastrado na recorrência.
                            </small>
                        </div>`,
                btnText: 'Sim',
                btnText2: 'Não',
            });
            if (!confirm.isConfirmed) return false;
            //

            const id = props.match.params.id;
            setIsLoading(true);
            await CONNECT_API.post(`/filiado/${id}/lancamentos`, { lancamento_id });
            setIsLoading(false);

            await get_data_page();

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }


    //Carregamnento dos dados
    useEffect(() => {
        get_data_page()
        // eslint-disable-next-line
    }, []);


    return (
        <div className="relatorios">
            <HeaderComponent {...pg} />
            <div className="table-list">
                <CardContent>
                    <div className="col-12">
                        {
                            terminal?.card &&
                            <div className="text-center mb-3">
                                <small>Mensalidades com baixa automática, apenas lançamento pendente em atraso podem ser faturadas!</small><br />
                            </div>
                        }
                        <TableHover className="mb-0 text-center">
                            <Thead
                                th={[
                                    "id",
                                    "Tipo",
                                    "Data",
                                    "Valor",
                                    "Vencimento",
                                    "Faturado",
                                    "Parcela",
                                    "Ação",
                                ]}
                            />
                            <Tbody>
                                {
                                    lancamentos?.map(f => {

                                        const receita = <i className="las la-arrow-up text-success" style={{ "fontSize": "25px" }}></i>
                                        const faturado = <i className="las la-check-square text-success" style={{ "fontSize": "25px" }}></i>
                                        const n_faturado = f?.agendado ?
                                            <><span className="text-warning" title="Cobrança Agendada"><i className="las la-clock" style={{ "fontSize": "25px" }}></i><br /><small>{f?.agendado}</small></span></>
                                            :
                                            <i className="las la-exclamation text-danger" style={{ "fontSize": "25px" }}></i>
                                        const btn_faturar =
                                            <button className="btn btn-sm m-0 text-success" title="Faturar" onClick={() => showModal(f.id)}>
                                                <i className="las la-hand-holding-usd" style={{ "fontSize": "20px" }}></i>
                                            </button>;
                                        const detalhes_pagamento =
                                            <button className="btn btn-sm m-0 text-info" title="Detalhes de Pagamento" onClick={() => showModalDetalhesPagamento(f.id)}>
                                                <i className="las la-search" style={{ "fontSize": "20px" }}></i>
                                            </button>;
                                        const btn_ver =
                                            <Link to={f.url || f.link || '/'} className="btn btn-sm m-0 text-success" title="Ver" target="_blank">
                                                <i className="las la-external-link-alt" style={{ "fontSize": "20px" }}></i>
                                            </Link>
                                        const btn_payment =
                                            <button className="btn btn-sm m-0 text-success" title="Realizar Cobrança novamente" onClick={() => cardPagarLancamento(f?.id)}>
                                                <span className="badge badge-success">Pagar</span>
                                            </button>;


                                        var btn_asaas = <></>;
                                        if (f?.asaas) {
                                            var btn_asaas_create = (
                                                <button className="btn btn-sm m-0 text-info" title="Realizar o lançamento no Asaas" onClick={() => criarLancamentoAsaas(f.id)}>
                                                    <i className="las la-sync-alt" style={{ "fontSize": "20px" }}></i>
                                                </button>
                                            );

                                            if (f.faturado || f.cancelado) btn_asaas_create = <></>;

                                            var btn_asaas_invoice = (
                                                <Link to={{ pathname: f.asaas.invoiceUrl }} className="btn btn-sm m-0 text-primary" title="Ver Fatura Asaas" target="_blank">
                                                    <i className="las la-file-invoice" style={{ "fontSize": "20px" }}></i>
                                                </Link>
                                            );

                                            switch (f?.asaas?.type) {
                                                case 'BOLETO':
                                                    if (f?.asaas?.bankSlipUrl) btn_asaas = <>{btn_asaas}{
                                                        <Link to={{ pathname: f.asaas.bankSlipUrl }} className="btn btn-sm m-0 text-primary" title="Ver Boleto Asaas" target="_blank">
                                                            <i className="las la-file-pdf" style={{ "fontSize": "20px" }}></i>
                                                        </Link>
                                                    }</>
                                                // eslint-disable-next-line
                                                case 'UNDEFINED':
                                                case 'PIX':
                                                default:
                                                    if (!f?.asaas?.invoiceUrl) btn_asaas = <>{btn_asaas}{btn_asaas_create}</>;
                                                    if (f?.asaas?.invoiceUrl) btn_asaas = <>{btn_asaas}{btn_asaas_invoice}</>;
                                                    break;
                                            }

                                            if (f?.asaas?.transactionReceiptUrl) btn_asaas = <>{btn_asaas}{
                                                <Link to={{ pathname: f.asaas.transactionReceiptUrl }} className="btn btn-sm m-0 text-primary" title="Ver Comprovante Asaas" target="_blank">
                                                    <i className="las la-file-invoice-dollar" style={{ "fontSize": "20px" }}></i>
                                                </Link>
                                            }</>
                                        }


                                        const btns = (
                                            <div className="btn-group" role="group" aria-label="buttons ação">
                                                {(!f.faturado && !f.cancelado && terminal?.card_payment && f?.vencida) && btn_payment}
                                                {/* {(!f.faturado && !f.cancelado && terminal?.card_payment) && btn_payment} */}
                                                {((!f.faturado && !f.cancelado && !terminal?.card) || (!f.faturado && !f.cancelado && f?.vencida)) && btn_faturar}
                                                {(terminal?.card) && detalhes_pagamento}
                                                {btn_asaas}
                                                {btn_ver}
                                                {/**(!f.faturado && !f.cancelado) ? btn_editar : btn_ver */}
                                            </div>
                                        )

                                        return (
                                            <tr key={f?.id}>
                                                <Th>
                                                    #{f?.id}
                                                </Th>
                                                <Td>
                                                    {receita} <br />
                                                    <small className="text-success">{f?.tipo_receita}</small>
                                                </Td>
                                                <Td>
                                                    {formatDate(f?.created_at)}
                                                </Td>
                                                <Td>
                                                    {formatCoin(f?.valor)} <br />
                                                </Td>
                                                <Td>
                                                    {(!f.faturado && !f.cancelado && f?.vencida) &&
                                                        <span title="Vencida" className={`rounded-circle badge badge-danger p-1 d-inline-block mr-1`}></span>
                                                    }
                                                    {formatDate(f?.vencimento)}
                                                </Td>
                                                <Td>
                                                    {(f?.faturado && !f?.cancelado)
                                                        ?
                                                        <>
                                                            {faturado} <br />
                                                            <small className="text-success">{formatDate(f?.faturado)}</small>
                                                        </>
                                                        :
                                                        (!f?.cancelado) ? n_faturado :
                                                            <>
                                                                <span className="badge badge-danger mr-1 px-2">Cancelado</span> <br />
                                                                <small className="text-danger">{formatDate(f?.cancelado)}</small>
                                                            </>
                                                    }
                                                </Td>
                                                <Td>
                                                    {f?.numero_atual_x}/{f?.numero_total_x ? f?.numero_total_x : f?.numero_atual_x}
                                                </Td>
                                                <Td>
                                                    {btns}
                                                </Td>
                                            </tr>
                                        );
                                    })
                                }
                            </Tbody>
                        </TableHover>
                        <ModalFaturarLancamento loaded={get_data_page} params={lancamentoEdit} modalId="modal-faturamento" />
                        <ModalCieloDetalhes pagamento_id={pagamentoDetalhes} modalId="modal-cielo-detalhes" loaded={get_data_page} />
                    </div>
                </CardContent>
            </div>
        </div>
    )
}


export default withRouter(FiliadoLancamentoById);