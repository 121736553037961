import React, { useEffect, useState } from "react";
import { useIsLoading } from "../../contexts/LoadingContext";
import { useForm } from 'react-hook-form';
import CONNECT_API from "../../configs/connect";
import HeaderComponent from "../../components/HeaderComponent";
import { existsOrError, formatCNPJ, formatCPF } from "../../configs/Functions";
import { CardContent, CardTitle } from "../../components/Card-z";
import { GroupInput, GroupRadio } from "../../components/Form";
import ButtonEdit from "../../components/ButtonComponent/ButtonEdit";
import { Modal } from "../../components/Modal-z";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import ImputMask from 'inputmask';
import $ from "jquery";
import { TableFooter, TableHover, Tbody, Td, Th } from "../../components/Table-z";



export const Fornecedores = props => {

    const [pg, setPg] = useState([]);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState([]);
    const [fornecedores, setFornecedores] = useState([]);
    const [newFornecedorId, setNewFornecedorId] = useState({});

    const { setIsLoading } = useIsLoading();
    const { register, handleSubmit } = useForm();


    async function get_data_page() {

        try {
            setIsLoading(true);

            const { data } = await CONNECT_API.get(`/fornecedor`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setFornecedores(data.fornecedores);
            setPagination(data?.pagination);
            setPg(data.pg);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }

    ////////////
    // FILTRO
    ////////////
    async function onSubmit(data) {

        const {
            doc,
            tipo,
            nome
        } = data;

        const query = `?page=${page}&tipo=${tipo}&nome=${nome}&${tipo === "PF" ? "cpf" : "cnpj"}=${doc}`;

        try {
            setIsLoading(true);

            const { data } = await CONNECT_API.get(`/fornecedor/${query}`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setFornecedores(data.fornecedores);
            setPagination(data?.pagination);
            setPg(data.pg);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }

    /////////
    // Novo Cadastro
    /////////
    async function onSubmitPost(data) {

        const {
            _tipo,
            _doc,
            _nome
        } = data;

        try {

            existsOrError(_nome, "Nome do fornecedor não informado!");
            existsOrError(_doc, `${_tipo === "PF" ? "CPF" : "CNPJ"} do fornecedor não informado!`);

            const field_doc = _tipo === "PF" ? "cpf" : "cnpj";
            const fields = {
                tipo: _tipo,
                [field_doc]: _doc,
                nome: _nome
            }

            setIsLoading(true);
            const { data } = await CONNECT_API.post(`/fornecedor`, { ...fields });
            existsOrError(data, 'Não foi possivel carregar os dados!');

            $(`.modal`).modal('hide');
            setNewFornecedorId({
                tipo: _tipo,
                id: data.pf?.id || data.pj?.id
            });
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }


    async function onChangeType(type) {
        if (type === "PF") {
            ImputMask({ "mask": '999.999.999-99' }).mask('[name="_doc"]');
        } else {
            ImputMask({ "mask": '99.999.999/9999-99' }).mask('[name="_doc"]');
        }
    }



    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        handleSubmit(onSubmit)();
        // eslint-disable-next-line
    }, [page]);



    ImputMask({ "mask": '99.999.999/9999-99' }).mask('[name="_doc"]');
    const modal = <Modal modalId="modal-fornecedor" icon="plus-square" text="Novo" className={`text-success`}>
        <form action="" onSubmit={handleSubmit(onSubmitPost)}>
            <CardContent>
                <CardTitle>
                    Novo fornecedor
                </CardTitle>
                <GroupRadio name="_tipo" value="PJ" checked="true" id="_PJ" onChange={() => onChangeType("PJ")} label="PJ - Pessoa juridica" col="4" register={register} />
                <GroupRadio name="_tipo" value="PF" id="_PF" label="PF - Pessoa fisica" onChange={() => onChangeType("PF")} col="4" register={register} />
                <GroupInput col="12" name="_nome" label="Nome" register={register} />
                <GroupInput col="12" name="_doc" label="CPF/CNPJ" register={register} />
                <div className=" col-12">
                    <div className="form-group mb-0">
                        <button className="btn btn-primary w-100 btn-lg">Cadastrar</button>
                    </div>
                </div>
            </CardContent>
        </form>
    </Modal>

    if (newFornecedorId.id) return <Redirect to={`${pg.end_point}/${newFornecedorId.tipo}/${newFornecedorId.id}`} />
    return (
        <div className="lista-fornecedores pb-2">
            <HeaderComponent {...pg} parent={modal} />
            <div className="search">
                <form action="" name="search-fornecedor" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <div className="col-lg-9">
                            <div className="form-row">
                                <GroupRadio name="tipo" value="PJ" checked="true" id="PJ" label="PJ - Pessoa juridica" col="4" register={register} />
                                <GroupRadio name="tipo" value="PF" id="PF" label="PF - Pessoa fisica" col="4" register={register} />
                                <GroupInput name="nome" label="Nome" col="8" classNameGroup="mb-lg-0" register={register} />
                                <GroupInput name="doc" label="CPF/CNPJ" col="4" classNameGroup="mb-lg-0" register={register} />
                            </div>
                        </div>
                        <div className="col-lg-3">
                            <button className="btn btn-primary btn-lg h-100 w-100" style={{ "fontSize": "30px" }}>
                                <i className="las la-search mr-2"></i>
                                Buscar
                            </button>
                        </div>
                    </CardContent>
                </form>
            </div>
            <div className="table-list">
                <CardContent>
                    <div className="col-12">
                        <TableHover className="mb-0 text-center--">
                            <Tbody>
                                {
                                    fornecedores?.map(f => {
                                        return (
                                            <tr key={f.id}>
                                                <Th>#{f.id}</Th>
                                                <Td>{f.nome}</Td>
                                                <Td>
                                                    <span className="badge badge-secondary mr-1 px-2">{f.tipo}</span>
                                                    <span>{f.tipo === "PJ" ? formatCNPJ(f.cnpj) : formatCPF(f.cpf)}</span>
                                                </Td>
                                                <Td className="text-right">
                                                    <ButtonEdit {...f} className="btn-sm" />
                                                </Td>
                                            </tr>
                                        );
                                    })
                                }
                            </Tbody>
                        </TableHover>
                        <TableFooter {...pagination} setPage={setPage} page={page} />
                    </div>
                </CardContent>
            </div>
        </div>
    );
}



export default Fornecedores;