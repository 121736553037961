import React from "react";
import { Link } from "react-router-dom";
import { formatCNPJ, formatDate } from "../../../../../configs/Functions";

export const CardB2BConfigFiliado = (props) => {
    let empresaFiliado = props.empresaFiliado;

    return (
        <>
            <div className="empresa-b2b mb-3">
                <div className="text-muted text-uppercase">
                    <b>
                        <i className="las la-building text-info"></i> Empresa
                        B2B
                    </b>
                    <hr className="mt-1 mb-2" />
                </div>
                <Link
                    to={empresaFiliado?.url || "/"}
                    className="btn w-100 text-left p-0 m-0"
                >
                    <div className="text-muted mb-1">
                        {empresaFiliado?.nome}{" "}
                        <small className="float-right" title="Data do Cadastro">
                            {formatDate(empresaFiliado?.created_at)}
                        </small>
                    </div>
                    <div className="text-muted mb-1">
                        CNPJ: {formatCNPJ(empresaFiliado?.cnpj)}
                    </div>
                </Link>
            </div>
        </>
    );
};


export default CardB2BConfigFiliado;