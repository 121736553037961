import React, { useEffect, useState } from "react";
import HeaderComponent from "../../../components/HeaderComponent";
import CONNECT_API from "../../../configs/connect";
import { existsOrError, ShowError } from "../../../configs/Functions";
import { useIsLoading } from "../../../contexts/LoadingContext";
import { withRouter } from "react-router-dom";
import CardPlanoFiliado from "../../md_5_filiado/filiado/plano/apoio/CardPlanoFiliado";
import CardCadastroFiliado from "../../md_5_filiado/filiado/cadastro/apoio/CardCadastroFiliado";
import CredCard from "./apoio/CredCard";
import CieloCardShowRecorrencia from "./apoio/CieloCardShowRecorrencia";

export const CieloRecorrenciaById = props => {

    const [pg, setPg] = useState([]);
    const [recorrencia, setRecorrencia] = useState([]);
    const [filiado, setFiliado] = useState([]);
    const [cardRecorrencia, setCardRecorrencia] = useState([]);

    const { setIsLoading } = useIsLoading();


    async function get_data_page() {

        try {
            setIsLoading(true);
            const id = props.match.params.id;
            const { data } = await CONNECT_API.get(`/cielo/recorrencia/${id}`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setRecorrencia(data?.recorrencia);
            setCardRecorrencia(data?.card_recorrencia);
            setFiliado(data?.filiado);
            setPg(data.pg);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }




    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="cielo-recorrencia">
            <HeaderComponent {...pg} />
            <div className="row justify-content-center">

                <div className="col-lg-7">
                    <div className="card-view mb-3">
                        <CredCard
                            expiry={cardRecorrencia?.card_vencimento}
                            name={cardRecorrencia?.card_nome}
                            number={cardRecorrencia?.card_numero}
                        />
                    </div>
                    <CieloCardShowRecorrencia {...recorrencia} />
                </div>
                <div className="col-lg-5">
                    <CardCadastroFiliado {...filiado} />
                    <CardPlanoFiliado {...filiado?.plano} />
                </div>
            </div>
        </div>
    );
}

export default withRouter(CieloRecorrenciaById);