import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import CONNECT_API from "../../../../configs/connect";
import { existsOrError, formatCPF, formatDate, SetNumber } from "../../../../configs/Functions";
import { useIsLoading } from "../../../../contexts/LoadingContext";
import HeaderComponent from "../../../../components/HeaderComponent";
import { ShowError } from "../../../../configs/Functions";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { CardContent, CardTitle } from "../../../../components/Card-z";
import { Modal } from "../../../../components/Modal-z";
import { GroupInput } from "../../../../components/Form";
import ImputMask from 'inputmask';

// props.match.params.{AQUI};

export const FiliadoDependente = props => {

    const [dependentes, setDependentes] = useState([]);
    const [pg, setPg] = useState([]);

    const { setIsLoading } = useIsLoading();
    const { handleSubmit, register, reset } = useForm();

    // PARAMS
    const filiado_id = props.match.params.id;

    async function get_data_page() {

        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/filiado/${filiado_id}/dependente`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setDependentes(data?.dependentes);
            setPg(data.pg);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    async function onSubmit(form_data) {

        try {

            const nome = form_data.nome;
            const cpf = SetNumber(form_data.cpf);
            const nascimento = form_data.nascimento;
            existsOrError(nome, "Nome não informado!");
            existsOrError(cpf, "CPF não informado!");
            existsOrError(nascimento, "Data de Nascimento não informado!");

            setIsLoading(true);
            const ok = await CONNECT_API.post(pg.end_point, { nome, cpf, nascimento });
            if (ok) {
                await get_data_page();
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    // Função que roda ao carregar o modal
    function modalShow(){
        ImputMask({ "mask": '999.999.999-99' }).mask('[name="cpf"]');
    }
    function modalHide(){
        reset();
    }

    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);
    
    
    const modal = <Modal modalId="modal-dependente" icon="plus-square" text="Novo" className={`text-success`} modalHide={modalHide} modalShow={modalShow}>
        <form action="" onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
                <CardTitle>
                    Novo dependente
                </CardTitle>
                <GroupInput col="12" name="nome" label="Nome" register={register} />
                <GroupInput col="6" name="cpf" label="CPF" register={register} />
                <GroupInput col="6" name="nascimento" type="date" label="Data de Nascimento" register={register} />
                <div className=" col-12">
                    <div className="form-group mb-0">
                        <button className="btn btn-primary w-100 btn-lg">Cadastrar</button>
                    </div>
                </div>
            </CardContent>
        </form>
    </Modal>

    return (
        <div className="filiado-dependentes">
            <HeaderComponent {...pg} parent={modal} />
            <div className="row ">
                <div className="col-lg-10">
                    <div className="row">
                        {
                            dependentes?.map(m => {
                                return (
                                    <div className="col-lg-6 col-md-auto col-12" key={m.id}>
                                        <Link to={m.url || '/'} className="card btn ">
                                            <div className="card-body px-2" style={{ "fontSize": "15px" }}>
                                                <div className="text-center text-dark">
                                                    <div>
                                                        <i style={{ "fontSize": "40px" }} className={`las la-${m.icon}`}></i>
                                                        <br />
                                                        <b>{m?.nome}</b>
                                                    </div>
                                                    <div className="row justify-content-between">
                                                        <div className="col-auto">Situação</div>
                                                        <div className="col-auto">{m?.situacao}</div>
                                                    </div>
                                                    <div className="row justify-content-between">
                                                        <div className="col-auto">Matricula</div>
                                                        <div className="col-auto">{m?.matricula}</div>
                                                    </div>
                                                    <div className="row justify-content-between">
                                                        <div className="col-auto">Nome</div>
                                                        <div className="col-auto">{m?.nome}</div>
                                                    </div>
                                                    <div className="row justify-content-between">
                                                        <div className="col-auto">Nascimento</div>
                                                        <div className="col-auto">{formatDate(m?.data)}</div>
                                                    </div>
                                                    <div className="row justify-content-between">
                                                        <div className="col-auto">CPF</div>
                                                        <div className="col-auto">{formatCPF(m?.cpf)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(FiliadoDependente);