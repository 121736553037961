import axios from 'axios';
import { toast } from 'react-toastify';
import api from './api';
import $ from "jquery";
import { DecodeBase64, existsOrError, SetNumber, ShowError, ValidateInput } from './Functions';
const producao = !window.isLocalhost;


const CONNECT_API = [];

const arrayNull = data => {
    if (!data) return null;
    if (typeof (data) != 'object') return null;

    let keys = Object.keys(data);

    keys.forEach(v => {
        data[v] = ValidateInput(data[v]);
    });

    return data;
}

CONNECT_API.get = async (uri) => {


    try {
        //
        // Function base request api
        //

        // URI → url base da requisição.
        // fields → Informações a ser passadas.
        // Success → Mensagem personalizada de sucesso na operação.
        existsOrError(uri, 'URL não informada na requisição!');
        api.defaults.headers['Content-Type'] = `application/json`;
        const data = await api.get(uri);
        if (data?.data) data.data = JSON.parse(DecodeBase64(data?.data));

        if (!!!producao) console.log('GET: ', data);

        document.title = data?.data?.pg?.title || "SGA - Sistema de Gestão de Assinaturas";

        return { ...data };


    } catch (error) {
        if (!!!producao) console.log(error)
        ShowError(error);
        return error;
    }
}


CONNECT_API.post = async function (uri, fields, success = '') {

    try {

        //
        // Function base request api
        //
        // URI → url base da requisição.
        // fields → Informações a ser passadas.
        // Success → Mensagem personalizada de sucesso na operação.

        existsOrError(uri, 'URL não informada na requisição!');
        existsOrError(fields, 'Campo não informada na requisição!');

        fields = await arrayNull(fields);
        api.defaults.headers['Content-Type'] = `application/json`;
        const data = await api.post(uri, { ...fields });
        if (data?.data) data.data = JSON.parse(DecodeBase64(data.data));
        toast.success(success || 'Operação realizada com sucesso!');

        if (!!!producao) console.log('POST: ', data);
        $('.modal').modal('hide');
        //$('.collapse').collapse('hide');
        return { ...data };

    } catch (error) {

        if (!!!producao) console.log(error);
        ShowError(error);
        return error;
    }
}

CONNECT_API.del = async function (uri) {

    try {

        //
        // Function base request api
        //
        // URI → url base da requisição.
        // fields → Informações a ser passadas.
        // Success → Mensagem personalizada de sucesso na operação.

        existsOrError(uri, 'URL não informada na requisição!');

        api.defaults.headers['Content-Type'] = `application/json`;
        await api.delete(uri);

        toast.success('Deletado com sucesso!');
        $('.modal').modal('hide');
        $('.collapse').collapse('hide');
        return true;

    } catch (error) {

        if (!!!producao) console.log(error)
        ShowError(error);
        return error;
    }
}

CONNECT_API.put = async function (uri, fields, success = '') {

    try {

        //
        // Function base request api
        //
        // URI → url base da requisição.
        // fields → Informações a ser passadas.
        // Success → Mensagem personalizada de sucesso na operação.

        existsOrError(uri, 'URL não informada na requisição!');
        existsOrError(fields, 'Campo não informada na requisição!');
        if (typeof (fields) !== 'object') {
            return false;
        }

        fields = await arrayNull(fields);
        api.defaults.headers['Content-Type'] = `application/json`;
        const data = await api.put(uri, { ...fields });
        if (data?.data) data.data = JSON.parse(DecodeBase64(data.data));
        toast.success(success || 'Operação realizada com sucesso!');

        //setIsLoading(false);
        if (!!!producao) console.log('PUT: ', data);
        $('.modal').modal('hide');
        //$('.collapse').collapse('hide');
        return { ...data };

    } catch (error) {

        if (!!!producao) console.log(error)
        ShowError(error);
        return false;
    }
}

CONNECT_API.getCep = async function (cep) {

    cep = SetNumber(cep);

    try {
        const getCep = {
            baseUrl: 'https://viacep.com.br/ws',
            type: 'json'
        }

        const data = await axios.get(`${getCep.baseUrl}/${cep}/${getCep.type}`);
        existsOrError(data, 'Cep não encontrado! Preencha os campos manualmente ou tente novamente!');

        if (!!!producao) console.log('GET CEP: ', data);

        return { ...data };

    } catch (error) {
        if (!!!producao) console.log(error)
        ShowError(error);
        return error;
    }
}



export default CONNECT_API;