import React from "react";
import CONNECT_API from "../../../../../configs/connect";
import { ShowError } from "../../../../../configs/Functions";
import { useIsLoading } from "../../../../../contexts/LoadingContext";


export const CardResp = props => {

    const r = props.resp;

    const { setIsLoading } = useIsLoading();


    async function onClickDelete(id_resp) {
        if (!id_resp || !props.leadId) return false;

        try {
            setIsLoading(true);
            await CONNECT_API.del(`/leads/${props.leadId}/responsavel/${id_resp}`);
            setIsLoading(false);

            if (props?.changedLoad) await props.changedLoad();

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    return (
        <div className="resp" key={r?.id}>
            <div className="card mb-3">
                <div className="card-body" style={{ "fontSize": "12px" }}>
                    <div className="mb-3">
                        <div className="form-row justify-content-between align-items-center">
                            <div className="col-lg-auto">
                                <i className="las la-user-tie mr-2"></i>
                                {r?.colaborador}
                            </div>
                            <div className="col-lg-auto">
                                <i className="las la-briefcase mr-2"></i>
                                {r?.cargo}
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <div className="email mb-2">
                            <i className="las la-envelope mr-2"></i>
                            {r?.email || 'email@email.com'}
                        </div>
                        <div className="whatsapp mb-2" title="Contato Pessoal">
                            <i className="lab la-whatsapp mr-2"></i>
                            {r?.tel_pessoal || '(99) 9 9999 9999'} - Pessoal.
                        </div>
                        <div className="whatsapp" title="Contato Profissional">
                            <i className="lab la-whatsapp mr-2"></i>
                            {r?.tel_profissional || '(99) 9 9999 9999'} - Profissional.
                        </div>
                    </div>
                    <div className="resp-control">
                        <div className="form-row justify-content-end align-items-center">
                            <div className="col-lg-auto">
                                <i className="las la-clock mr-1"></i>
                                {r?.created_at}
                                <span onClick={() => onClickDelete(r?.id)} className="ml-2 badge badge-danger btn " title="Excluir Responsável">
                                    <i className="las la-trash"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default CardResp;