import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ButtonSave from "../../components/ButtonComponent/ButtonSave";
import { CardContent, CardTitle } from "../../components/Card-z";
import { GroupCheckBox, GroupInput, GroupSelect } from "../../components/Form";
import HeaderComponent from "../../components/HeaderComponent";
import CONNECT_API from "../../configs/connect";
import { existsOrError, SetCoin, SetNumber } from "../../configs/Functions";
import { useIsLoading } from "../../contexts/LoadingContext";


export const FinanceiraEditar = props => {


    const [pg, setPg] = useState([]);
    const [financeira, setFinanceira] = useState([]);
    const [tipoConta, setTipoConta] = useState([]);

    const { setIsLoading } = useIsLoading();
    const { register, handleSubmit, reset } = useForm();

    async function get_data_page() {

        try {

            setIsLoading(true);
            const id = props.match.params.id;
            const { data } = await CONNECT_API.get(`/financeira/${id}/editar`);
            existsOrError(data, 'Não foi possivel carregar os dados!');

            setFinanceira(data?.financeira);
            setTipoConta(data?.tipo_conta);
            setPg(data?.pg);

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }


    async function onSubmit(data) {
        try {
            const {
                nome,
                situacao
            } = data;

            const cod_financeira = SetNumber(data.cod_financeira)
            const saldo_inicial = SetCoin(data.saldo_inicial)
            const tipo_conta_id = SetNumber(data.tipo_conta_id)
            const conta = SetNumber(data.conta)
            const agencia = SetNumber(data.agencia)
            const conta_digital = SetNumber(data.conta_digital)
            const asaas = SetNumber(data.asaas)

            existsOrError(nome, "Nome não informado!");

            const fields = {
                nome,
                situacao,
                conta_digital,
                cod_financeira,
                saldo_inicial,
                tipo_conta_id,
                conta,
                agencia,
                asaas
            }

            setIsLoading(true);
            await CONNECT_API.put(`${pg.end_point}`, { ...fields });
            setIsLoading(false);

            // ATUALIZAR OS DADOS NA PÁGINA
            await get_data_page();

        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }



    ///////
    // START
    ///////

    //Carregamnento dos dados
    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {

        if (!financeira?.typeAsassActive) financeira.asaas = false;
        reset({ ...financeira })

        // eslint-disable-next-line
    }, [financeira]);




    return (
        <div className="financeira-editar pb-4">
            <HeaderComponent {...pg}>
                <ButtonSave onClick={handleSubmit(onSubmit)} />
            </HeaderComponent>
            <form action="" onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                    <CardTitle>
                        Cadastro {financeira?.nome}
                    </CardTitle>
                    <GroupInput name="nome" col="6" label="Nome" register={register} />
                    <GroupInput name="cod_financeira" disabled={financeira.asaas ? true : false} col="4" type="number" label="Cód. Banco" register={register} />
                    <GroupSelect name="situacao" col="2" label="Situação" register={register}>
                        <option value="0">Inativo</option>
                        <option value="1">Ativo</option>
                    </GroupSelect>
                    <GroupInput name="conta" disabled={financeira.asaas ? true : false} col="4" type="number" label="Conta" register={register} />
                    <GroupInput name="agencia" disabled={financeira.asaas ? true : false} col="4" type="number" label="Agência" register={register} />
                    <GroupSelect name="tipo_conta_id" disabled={financeira.asaas ? true : false} col="4" label="Tipo da conta" register={register}>
                        {
                            financeira.tipo_conta_id ?
                                <option value={financeira.tipo_conta_id}>{financeira.tipo_conta}</option>
                                :
                                <option value={null} disabled={true}>Selecione...</option>
                        }
                        {
                            tipoConta.map(t => {
                                return <option value={t.id} key={t.id}>{t.nome}</option>
                            })
                        }
                    </GroupSelect>
                    <GroupInput name="saldo_inicial" col="12" type="number" label="Saldo Inicial" register={register} />
                    <GroupCheckBox name="conta_digital" disabled={financeira.asaas ? true : false} id="conta_digital" value="1" col="auto" label="Conta Digital" register={register} />
                    {financeira?.typeAsassActive ? (
                        <GroupCheckBox name="asaas" id="asaas" value="1" col="auto" label="Conta Assas" register={register} />
                    ) : (<></>)}
                    <div className="col-12 text-right">
                        <ButtonSave type="submit" className="mt-3" />
                    </div>
                </CardContent>
            </form>
        </div>
    );
}

export default withRouter(FinanceiraEditar);