import React from "react";
import { Link } from "react-router-dom";
import './Style.css';

export const CardCadastroFiliado = props => {

    /*
    cpf: "834.***.***-61"
    data_cadastro: "09/08/2022 às 19:15:01"
    email: null
    id: 539
    matricula: "SC09 0000 0000 0539"
    nascimento: "08/08/2000"
    nome: "Ezequias - 83489697561"
    situacao: "Regular"
    whats: null
    */

    const p = props;

    return (
        <div className="card-filiado-filiado">
            <div className="card">
                <div className="card-body">
                    <div className="text-muted filiado-header">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="icon d-flex align-items-center">
                                <i className="las la-user text-info mr-2"></i>
                                <b>FILIADO</b>
                            </div>
                            <div className="link">
                                <Link to={`/filiado/${p?.id}`} target="_blank" title="Ver cadastro">
                                    <i className="las la-external-link-alt text-success" style={{"fontSize":"24px"}}></i>
                                </Link>
                            </div>
                        </div>
                        <hr className="mt-1 mb-2" />
                    </div>
                    <div className="filiado-body text-muted">
                        <div className="situacao py-3 d-flex align-items-center justify-content-between">
                            <div className="icon">
                                <i className="las la-user-shield mr-2"></i>
                                Situação
                            </div>
                            <span className="value">{p?.situacao}</span>
                        </div>
                        <div className="filiado py-3 d-flex align-items-center justify-content-between">
                            <div className="icon">
                                <i className="las la-user-tag mr-2"></i>
                                Nome
                            </div>
                            <span className="value">{p?.nome}</span>
                        </div>
                        <div className="cpf py-3 d-flex align-items-center justify-content-between">
                            <div className="icon">
                                <i className="las la-passport mr-2"></i>
                                CPF
                            </div>
                            <span className="value">{p?.cpf}</span>
                        </div>
                        <div className="matricula py-3 d-flex align-items-center justify-content-between">
                            <div className="icon">
                                <i className="las la-id-card mr-2"></i>
                                Matricula
                            </div>
                            <span className="value">{p?.matricula}</span>
                        </div>
                        <div className="cadastro py-3 d-flex align-items-center justify-content-between">
                            <div className="icon">
                                <i className="las la-calendar-check mr-2"></i>
                                Cadastrado
                            </div>
                            <span className="value">{p?.data_cadastro || '-'}</span>
                        </div>
                        <div className="email py-3 d-flex align-items-center justify-content-between">
                            <div className="icon">
                                <i className="las la-envelope mr-2"></i>
                                E-mail
                            </div>
                            <span className="value">{p?.email || '-'}</span>
                        </div>
                        <div className="whats py-3 d-flex align-items-center justify-content-between">
                            <div className="icon">
                                <i className="lab la-whatsapp mr-2"></i>
                                Whatsapp
                            </div>
                            <span className="value">{p?.whats || '-'}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CardCadastroFiliado;