import React from "react";
import { formatCoin, formatDate } from "../../../../../configs/Functions";

export const CardPlanoConfigFiliado = (props) => {
    let plano = props.plano;

    return (
        <>
            <div className="plano mb-3">
                <div className="text-muted">
                    <b>
                        <i className="las la-globe text-primary"></i> PLANO
                    </b>
                    <hr className="mt-1 mb-2" />
                </div>
                <div className="text-muted mb-1">
                    {plano?.nome}{" "}
                    <small className="float-right" title="Data do Cadastro">
                        {formatDate(plano?.created_at)}
                    </small>
                </div>
                <div className="text-muted mb-1">
                    {plano?.recorrencia} → {plano?.vigencia}x{" "}
                    <small className="float-right" title="Valor do plano">
                        {formatCoin(plano?.valor)}
                    </small>
                </div>
            </div>
        </>
    );
};

export default CardPlanoConfigFiliado;
