import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);


export const GraficoPizzaDasboard = props => {

    const list = props.data || [];

    let nome = [];
    let dados = {};
    let cores = {};
    list.map(l => {
        let vendedor = l?.vendedor?.trim();
        if( !vendedor && l?.website ) vendedor = 'Website';
        if (!nome.includes(vendedor)) nome.push(vendedor);

        if (dados[vendedor] === undefined) dados[vendedor] = 0;
        dados[vendedor]++;

        if (cores[vendedor] === undefined) cores[vendedor] = 0;
        cores[vendedor] = `${Math.random() * 180},${Math.random() * 200},${Math.random() * 255}`;
        return true;
    });

    // cores = ['#50E8FA','#47DEC5','#5BF5AF','#47DE6F','#54FA50'];

    const data = {
        labels: nome,
        datasets: [
            {
                label: '',
                data: nome.map(v => dados[v]),
                // backgroundColor: cores,
                backgroundColor: nome.map(v => `rgba(${cores[v]},1)`),
                // borderColor: nome.map(v => `rgba(${cores[v]},1)`),
                borderWidth: 1,
                hoverOffset: 2
            },
        ],
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'VENDAS DA SEMANA',
            },
        },
    };

    return <Pie data={data} options={options} />;
}


// function gerar_cor_hexadecimal() {
//     return '#' + parseInt((Math.random() * 0xFFFFFF))
//         .toString(16)
//         .padStart(6, '0');
// }

// function gerar_cor(opacidade = 1) {
//     let r = Math.random() * 255;
//     let g = Math.random() * 255;
//     let b = Math.random() * 255;
//     return `rgba(${r}, ${g}, ${b}, ${opacidade})`;
// }