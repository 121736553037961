import React, { useState, useEffect } from "react";
import HeaderComponent from "../../components/HeaderComponent";
import CONNECT_API from "../../configs/connect";
import { ShowError } from "../../configs/Functions";
import { useIsLoading } from "../../contexts/LoadingContext";
import Links from "./Links";
import PGMenus from "./menus/Index";

export const IdxAdmin = (props) => {
    const search = new URLSearchParams(document.location.search);

    const [pg, setPg] = useState([]);
    const { setIsLoading } = useIsLoading();

    async function get_data_page() {
        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get("/admin");
            setPg(data.pg);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    //   sempre que a página for atualizada
    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    var BODY = "";
    switch (search.get("pg")) {
        case "modulos":
            BODY = <div>Módulo</div>;
            break;
        case "menus":
            BODY = <PGMenus />;
            break;

        default:
            BODY = <Links />;
            break;
    }

    return (
        <div className="config-admin">
            <HeaderComponent {...pg} />
            {BODY}
        </div>
    );
};

export default IdxAdmin;
