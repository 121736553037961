import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { useIsLoading } from "../../../contexts/LoadingContext";
import CONNECT_API from "../../../configs/connect";
import { existsOrError, formatCoinNotCurrency, SetCoin } from "../../../configs/Functions";
import { toast } from "react-toastify";
import HeaderComponent from "../../../components/HeaderComponent";
import ButtonSave from "../../../components/ButtonComponent/ButtonSave";
import { CardContent, CardTitle } from "../../../components/Card-z";
import { GroupInput, GroupSelect, GroupText } from "../../../components/Form";
import FileSingle from "../../../components/File/Single/input";


export const CartaoById = props => {

    const [cartao, setCartao] = useState([]);
    const [pg, setPg] = useState([]);


    const { register, reset, handleSubmit } = useForm();
    const { setIsLoading } = useIsLoading();

    const [fileCardFrente, setFileCardFrente] = useState([]);
    const [fileCardVerso, setFileCardVerso] = useState([]);


    async function get_data_page() {
        try {
            setIsLoading(true);

            const id = props.match.params.id;
            const { data } = await CONNECT_API.get(`/cartao/${id}`);
            existsOrError(data, 'Não foi possivel carregar os dados!');

            setCartao(data?.cartao);
            setPg(data?.pg);

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }

    async function onSubmit(data) {
        try {
            existsOrError(data.nome, 'Nome não informado!');
            existsOrError(data.codigo, 'Código não informado!');

            const {
                nome,
                codigo,
                situacao,
                url_frente,
                url_verso,
                card_frente,
                card_verso,
                css
            } = data;
            const valor = SetCoin(data.valor);

            const fields = {
                nome,
                situacao,
                codigo,
                url_frente,
                url_verso,
                card_frente,
                card_verso,
                css,
                valor
            }
            setIsLoading(true);
            const ok = await CONNECT_API.put(`${pg.end_point}`, { ...fields });
            if (ok) await get_data_page();
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }



    ///////////
    // START
    ///////////

    //Carregamnento dos dados
    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    /// SET INPUTS
    useEffect(() => {
        if (!cartao) return false;

        cartao.valor = formatCoinNotCurrency(cartao?.valor)

        reset({ ...cartao });

        if (typeof (cartao?.fileCardFrente) == 'object' && cartao?.card_frente) setFileCardFrente([cartao?.fileCardFrente]);
        if (typeof (cartao?.fileCardVerso) == 'object' && cartao?.card_verso) setFileCardVerso([cartao?.fileCardVerso]);

        // eslint-disable-next-line
    }, [cartao]);

    return (
        <div className="cartao-id">
            <HeaderComponent {...pg}>
                <ButtonSave className=" float-right px-5" onClick={handleSubmit(onSubmit)} />
            </HeaderComponent>
            <form action="" onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                    <CardTitle>
                        Cadastro
                    </CardTitle>
                    <GroupInput name="codigo" col="2" label="Código" register={register} />
                    <GroupInput name="nome" col="5" label="Nome" register={register} />
                    <GroupInput name="valor" col="3" label="Valor" register={register} />
                    <GroupSelect name="situacao" col="2" label="Situação" register={register}>
                        <option value="0">Inativo</option>
                        <option value="1">Ativa</option>
                    </GroupSelect>
                    {/* <GroupInput name="url_frente" col="12" label="URL Frente" register={register} />
                    <GroupInput name="url_verso" col="12" label="URL Verso" register={register} /> */}
                    <div className="col-12 mb-4">
                        <div className="row pg-cartao">
                            <div className="col-lg-6">
                                <h4 className="mb-2"><small>Frente do Cartão</small></h4>
                                <FileSingle
                                    type="image"
                                    fileList={fileCardFrente}
                                    setFileList={setFileCardFrente}
                                    register={register}
                                    reset={reset}
                                    label=""
                                    name="card_frente" />
                            </div>
                            <div className="col-lg-6">
                                <h4 className="mb-2"><small>Verso do Cartão</small></h4>
                                <FileSingle
                                    type="image"
                                    fileList={fileCardVerso}
                                    setFileList={setFileCardVerso}
                                    register={register}
                                    reset={reset}
                                    label=""
                                    name="card_verso" />
                            </div>
                        </div>
                    </div>
                    <GroupText name="css" col="12" label="CSS personalizado" register={register} />
                    <div className="col-12 mt-4">
                        <div className="form-group">
                            <ButtonSave type="submit" className="float-right px-5" />
                        </div>
                    </div>
                </CardContent>
            </form>
        </div>
    );
}

export default withRouter(CartaoById);