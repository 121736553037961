import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import CONNECT_API from "../../../../configs/connect";
import { useIsLoading } from "../../../../contexts/LoadingContext";
import HeaderComponent from "../../../../components/HeaderComponent";
import CardLink from "../../../../components/CardLinks";
import { ShowError } from "../../../../configs/Functions";
import CardCadastroConfigFiliado from "../configuracao/CardsFiliado/CardCadastroConfigFiliado";
import CardPlanoConfigFiliado from "../configuracao/CardsFiliado/CardPlanoConfigFiliado";
import CardTerminalConfigFiliado from "../configuracao/CardsFiliado/CardTerminalConfigFiliado";
import CardB2BConfigFiliado from "../configuracao/CardsFiliado/CardB2BConfigFiliado";
import CardEmpresaConfigFiliado from "../configuracao/CardsFiliado/CardEmpresaConfigFiliado";

import Avatar from "../../../../images/default-avatar.png";

// props.match.params.{AQUI};

export const FiliadoById = props => {

    const { setIsLoading } = useIsLoading();
    const [links, setLinks] = useState([]);
    const [pg, setPg] = useState([]);

    const [filiado, setFiliado] = useState([]);
    const [plano, setPlano] = useState([]);
    const [terminal, setTerminal] = useState([]);
    const [empresaFiliado, setEmpresaFiliado] = useState([]);

    // PARAMS
    const filiado_id = props.match.params.id;

    async function get_data_page() {

        try {
            setIsLoading(true);
            // eslint-disable-next-line
            var { data } = await CONNECT_API.get(`/filiado/${filiado_id}`);
            setLinks(data?.links);
            setFiliado(data?.filiado);
            setPg(data.pg);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }

        try {
            setIsLoading(true);
            // eslint-disable-next-line
            var { data } = await CONNECT_API.get(`/filiado/${filiado_id}/configuracao`);
            setFiliado(data?.filiado);
            setPlano(data?.plano);
            setEmpresaFiliado(data?.empresaFiliado);
            setTerminal(data?.terminal);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, [filiado_id]);

    return (
        <div className="filiado-links">
            <HeaderComponent {...pg} />
            <div className="row ">
                <div className="col-lg-3">
                    <div className="card">
                        <div className="card-body">
                            <small
                                style={{ "fontSize": "9px" }}
                                className={`text-uppercase badge badge-${filiado?.titular_id ? 'warning' : 'info'} text-white m-0 px-2`}>
                                {filiado?.titular_id ? 'Dependente' : 'Titular'}
                            </small>
                            <small
                                style={{ "fontSize": "9px" }}
                                title={filiado?.situacao}
                                className={`text-uppercase badge badge-${filiado?.situacao === "Regular" ? 'success' : 'danger'} text-white ml-1 m-0 px-2`}>
                                {filiado?.situacao}
                            </small>
                            <div className="img-container text-center mb-2 py-2">
                                <img
                                    src={Avatar}
                                    size="150"
                                    width="150"
                                    height="150"
                                    className="rounded img-fluid avatar"
                                    alt=""
                                />
                            </div>
                            <div className="informations text-center">
                                <h5 className="mb-3"><b>{filiado?.nome}</b></h5>
                                <div className="sub-info text-left mt-1">
                                    <ul className="list-group list-group-flush">
                                        <li className="list-group-item p-1">
                                            Situação: <span className="float-right">{filiado?.situacao}</span>
                                        </li>
                                        <li className="list-group-item p-1">
                                            Matrícula: <span className="float-right">{filiado?.matricula}</span>
                                        </li>
                                        <li className="list-group-item p-1 d-none">
                                            E-mail: <span className="float-right">{filiado?.email || '-'}</span>
                                        </li>
                                        <li className="list-group-item p-1 d-none">
                                            Telefone: <span className="float-right">{filiado?.tel || '-'}</span>
                                        </li>
                                        <li className="list-group-item p-1">
                                            Whatsapp: <span className="float-right">{filiado?.whats || '-'}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="card-footer text-center">
                            <ul className="nav nav-pills btn-group justify-content-center" role="tablist">
                                <li className="nav-item btn-group">
                                    <a className="btn btn-sm btn-outline-primary active" href="#configuracao" data-toggle="pill" role="tab" aria-controls="configuracao">Detalhes</a>
                                </li>
                                <li className="nav-item btn-group">
                                    <a className="btn btn-sm btn-outline-primary " href="#menus" data-toggle="pill" role="tab" aria-controls="menus">Menus</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-lg-8">
                    <div className="tab-content">
                        <div className="tab-pane fade " id="menus" role="tabpanel">
                            <div className="row">
                                {
                                    links?.map(m => {
                                        return (
                                            <div className="col-lg-4 col-md-auto col-12" key={m.id}>
                                                <CardLink {...m} />
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>
                        <div className="tab-pane fade show active" id="configuracao" role="tabpanel">
                            <div className="row">
                                <div className="col-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-6 mb-3">
                                                    <CardCadastroConfigFiliado filiado={filiado} />
                                                </div>
                                                {plano && (
                                                    <div className="col-lg-6 mb-3">
                                                        <CardPlanoConfigFiliado plano={plano} />
                                                    </div>
                                                )}
                                                {terminal && (
                                                    <div className="col-lg-6 mb-0">
                                                        <CardTerminalConfigFiliado
                                                            terminal={terminal}
                                                        />
                                                    </div>
                                                )}
                                                {empresaFiliado?.id && (
                                                    <div className="col-lg-6 mb-3">
                                                        <CardB2BConfigFiliado empresaFiliado={empresaFiliado} />
                                                    </div>
                                                )}
                                                {(filiado?.vendedor || filiado?.web_link) && (
                                                    <div className="col-lg-6 mb-0">
                                                        <CardEmpresaConfigFiliado
                                                            filiado={filiado}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(FiliadoById);