import React from "react";
import './styles/NavBar.css';
import { Link } from 'react-router-dom';

import { useIsLoading } from "../../contexts/LoadingContext";


function renderGroupNav(group , index) {
    return (
        <div className="navbar-group" key={index}>
            <div className="navbargroup-boby">
                <div className="navbar-group-title">
                    {group?.nome || "Módulo"}
                </div>
                <ul className="navbar-nav">
                    {
                        group?.menu?.map((menu, index) =>
                            <li className="nav-item" key={index}>
                                <Link className="nav-link" to={menu?.url} label={menu.label}>
                                    <div className="nav-container-link">
                                        {menu.icon && <i className={`nav-icon las la-${menu.icon}`} style={{"color":`${menu.color}`}}></i> }
                                        <span className="title text-muted">{menu.label}</span>
                                    </div>
                                </Link>
                            </li>
                        )
                    }
                </ul>
            </div>
            <hr className="m-0" />
        </div>
    );
}


const NavBar = props => {

    const { isNav } = useIsLoading();

    const nav = isNav?.nav_tree;

    return (
        <div className="nav-bar">
            {
                nav?.map((group, index) =>
                    renderGroupNav(group , index)
                )
            }
        </div>
    );
}

export default NavBar;