import ImputMask from 'inputmask';
import { toast } from "react-toastify";
import { useForm } from 'react-hook-form';
import { withRouter } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { existsOrError, SetDate, SetNumber } from "../../configs/Functions";
import { useIsLoading } from "../../contexts/LoadingContext";
import { CardContent, CardTitle } from "../../components/Card-z";
import { GroupInput, GroupSelect, GroupText } from "../../components/Form";
import CONNECT_API from "../../configs/connect";
import HeaderComponent from "../../components/HeaderComponent";
import ButtonSave from "../../components/ButtonComponent/ButtonSave";

export const CompanyDetail = props => {

    const [empresa, setEmpresa] = useState([]);
    const [pg, setPg] = useState([]);
    const { register, handleSubmit, reset } = useForm();
    const { setIsLoading } = useIsLoading();


    /**
     * Funções de apoio do componente
     */
    async function get_data_page() {
        try {
            setIsLoading(true);

            const { data } = await CONNECT_API.get(`/empresa/editar`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setEmpresa(data.empresa);
            setPg(data.pg);

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }


    //Carregamnento dos dados
    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);


    // MARCA OS VALORES NOS CAMPOS
    useEffect(() => {

        if (!empresa) return;

        reset({

            nome: empresa.nome,
            codigo: empresa.codigo,
            situacao: empresa.situacao ? 1 : 0,
            cnpj: empresa.cnpj,
            razao_social: empresa.razao_social,
            data: SetDate(empresa.data),
            cidade: empresa?.cidade,
            logradouro: empresa?.logradouro,
            bairro: empresa?.bairro,
            uf: empresa?.uf,
            cep: empresa.cep,
            numero: empresa.numero,
            complemento: empresa.complemento,
        });

        // eslint-disable-next-line
    }, [empresa]);

    useEffect(() => {
        if (!pg) return;
        document.title = `SGA - ${pg.title}}`;
        // eslint-disable-next-line
    }, [pg]);

    //Masc
    useEffect(() => {
        ImputMask({ "mask": '99.999.999/9999-99' }).mask('[name="cnpj"]');
        ImputMask({ "mask": '(99) 99999-9999' }).mask('[type="tel"]');
        ImputMask({ "mask": '99999-999' }).mask('[name="cep"]');
    }, []);

    async function onSubmit(data) {
        try {
            setIsLoading(true);

            const fields = {
                situacao: data.situacao,
                nome: data.nome,
                codigo: data.codigo,
                razao_social: data.razao_social,
                data: data.data,
                cidade: data.cidade,
                logradouro: data.logradouro,
                bairro: data.bairro,
                uf: data.uf,
                cep: SetNumber(data.cep),
                numero: SetNumber(data.numero),
                complemento: data.complemento,
            }

            existsOrError(fields.nome, 'Nome não informado!');
            existsOrError(fields.codigo, 'Código não informado!');

            // REALIZANDO O PUT NOS DADOS
            await CONNECT_API.put(pg.end_point, fields);

            // Atualizando os dados da página
            await get_data_page();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }


    async function get_data_cep(e) {

        const cep = SetNumber(e.target.value);
        if (!cep) return false;

        if (cep.length === 8) {

            setIsLoading(true);

            const json = await CONNECT_API.getCep(cep);

            reset({
                uf: json?.data.uf,
                cidade: json?.data.localidade,
                bairro: json?.data.bairro,
                logradouro: json?.data.logradouro,
                complemento: json?.data.complemento
            })

            setIsLoading(false);
        }
    }


    return (
        <div className="empresa-cadastro pb-2" >
            <HeaderComponent {...pg}>
                <ButtonSave className="float-right px-5" onClick={handleSubmit(onSubmit)} />
            </HeaderComponent>
            <form action="#" onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                    <CardTitle>
                        Dados
                    </CardTitle>
                    <GroupInput name="nome" required col="5" label="Nome Fantasia" register={register} />
                    <GroupInput name="codigo" required col="5" label="Código" max="4" register={register} />
                    <GroupSelect name="situacao" required col="2" disabled label="Situação" register={register}>
                        <option value="0">Inativo</option>
                        <option value="1">Ativa</option>
                    </GroupSelect>
                    <GroupInput name="razao_social" required col="6" label="Razão Social" register={register} />
                    <GroupInput name="cnpj" required col="3" label="CNPJ" disabled="disabled" register={register} />
                    <GroupInput name="data" required col="3" label="Data de Abertura" type="date" register={register} />
                    <GroupInput name="empresaImage" type="hidden" col="12" register={register} />
                </CardContent>
                <CardContent>
                    <CardTitle>
                        Endereço
                    </CardTitle>
                    <GroupInput name="logradouro" required col="6" label="Logradouro" register={register} />
                    <GroupInput name="bairro" required col="4" label="Bairro" register={register} />
                    <GroupInput name="numero" required col="2" label="Número" type="number" register={register} />
                    <GroupInput name="cep" required onKeyUp={get_data_cep} col="3" label="CEP" register={register} />
                    <GroupInput name="cidade" required col="7" label="Cidade" register={register} />
                    <GroupInput name="uf" required col="2" label="Estado" register={register} />
                    <GroupText name="complemento" required col="12" label="Complemento" register={register} />
                    <div className="col-12 mt-4">
                        <div className="form-group">
                            <ButtonSave type="submit" className="float-right px-5" />
                        </div>
                    </div>
                </CardContent>
            </form>
        </div>
    );
}

export default withRouter(CompanyDetail);