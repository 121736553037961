import React from "react";
import { css } from "@emotion/react";
import { BarLoader } from "react-spinners";
import { useIsLoading } from "../contexts/LoadingContext";

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  //position: fixed;
  z-index: 1090;
  top: 0;
  left: 0;
  width: 100%;
  //background: rgba(0,0,0,0.2);
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

//rgba(98,173,223,1) // Fraca
//rgba(46,81,133,1) // Forte



export const SpinnersComponent = props => {
  const { isLoading } = useIsLoading();

  return (
    <div className="sweet-loading">
      <BarLoader css={override} color="rgba(46,81,133,1)" height={5} loading={isLoading} speedMultiplier={1} />
    </div>
  )
}

export default SpinnersComponent;