import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import HeaderComponent from "../../../components/HeaderComponent";
import CONNECT_API from "../../../configs/connect";
import {
    existsOrError,
} from "../../../configs/Functions";
import { useIsLoading } from "../../../contexts/LoadingContext";
import { Link, useHistory } from "react-router-dom";
import { CardContent } from "../../../components/Card-z";
import { GroupInput, GroupSelect } from "../../../components/Form";
import {
    TableFooter,
    TableHover,
    Tbody,
    Td,
    Th,
    Thead,
} from "../../../components/Table-z";

export const FiliadoSearch = (props) => {
    const history = useHistory();
    var params = Object.fromEntries(
        new URLSearchParams(history.location.search)
    );

    const [pg, setPg] = useState([]);
    const [nav, setNav] = useState([]);
    const [situacao, setSituacao] = useState([]);
    const [filiados, setFiliados] = useState([]);
    const [planos, setPlanos] = useState([]);
    const [pagination, setPagination] = useState([]);
    const [page, setPage] = useState(params.page || 1);

    const { setIsLoading } = useIsLoading();
    const { register, handleSubmit, reset, getValues } = useForm();






    // DEFINE O GET NA URL A CADA ALTERAÇÃO EM CAMPOS NO FORM
    async function setSearchURL(e) {
        const formData = getValues();
        if (e) formData[e.target.name] = e.target.value;
        const { situacao_id, tipo_cadastro, q, plano_id } = formData;
        var query = `?page=${page}`;

        if (situacao_id) query += `&s=${situacao_id}`;
        if (q) query += `&q=${q}`;
        if (tipo_cadastro) query += `&tc=${tipo_cadastro}`;
        if (plano_id) query += `&p=${plano_id}`;
        history.replace({ search: query });
        // history.push({ search: query });
    }


    async function onSubmit() {


        // FORMATA A BUSCA DE ACORDO COM A URL
        const data = Object.fromEntries(
            new URLSearchParams(history.location.search)
        );


        const { s, tc, q, p } = data;
        var query = `?page=${page}`;
        if (s) query += `&situacao_id=${s}`;
        if (q) query += `&q=${q}`;
        if (tc) query += `&tc=${tc}`;
        if (p) query += `&plano_id=${p}`;

        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/filiado/${query}`);
            existsOrError(data, "Não foi possivel carregar os dados!");
            setSituacao(data?.situacao);
            setFiliados(data?.filiados);
            setPlanos(data?.planos);
            setPagination(data?.pagination);
            setNav(data?.nav);
            setPg(data.pg);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    /////////////////
    // START
    /////////////////

    useEffect(() => {
        reset({
            plano_id: params?.p || '',
            situacao_id: params?.s || '',
            tipo_cadastro: params?.tc || '',
            q: params?.q || ''
        });
        // eslint-disable-next-line
    }, [planos]); // planos precisa ser carregado


    useEffect(() => {
        setSearchURL();
        handleSubmit(onSubmit)();
        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {

        var params = Object.fromEntries(
            new URLSearchParams(history.location.search)
        );

        switch (history.action) {
            case 'POP':
                reset({
                    plano_id: params?.p || '',
                    situacao_id: params?.s || '',
                    tipo_cadastro: params?.tc || '',
                    q: params?.q || ''
                });
                handleSubmit(onSubmit)();
                break;
            case 'REPLACE':
                break;
            case 'PUSH':
                break;
            default:
                break;
        }
        // eslint-disable-next-line
    }, [history.location.search]);




    useEffect(() => {
        if (!filiados) return false;

        setTimeout(() => {
            if (document.querySelector(".page > div"))
                document
                    .querySelector(".page > div")
                    .scrollIntoView({ behavior: "smooth", block: "start" });
        }, 100);
        // eslint-disable-next-line
    }, [filiados]);

    return (
        <div className="filiado-busca">
            <HeaderComponent {...pg}>
                <div className="btn-group">
                    {nav?.map((m) => {
                        return (
                            <Link
                                key={m.id}
                                to={m.url || m.link || "/"}
                                className={m.className}
                            >
                                <i className={`las la-${m.icon} mr-2`}></i>
                                Novo filiado
                            </Link>
                        );
                    })}
                </div>
            </HeaderComponent>

            <div className="search">
                <form
                    action=""
                    name="search-filiado"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <CardContent>
                        <div className="col-lg-10">
                            <div className="row justify-content-between">
                                <GroupSelect
                                    name="situacao_id"
                                    col="2"
                                    label="Situação"
                                    classNameGroup="mb-lg-0"
                                    register={register}
                                    onChange={setSearchURL}
                                >
                                    {situacao?.map((r) => {
                                        return (
                                            <option value={r.id} key={r.id}>
                                                {r.nome}
                                            </option>
                                        );
                                    })}
                                    <option value="">Todos...</option>
                                </GroupSelect>
                                <GroupSelect
                                    name="tipo_cadastro"
                                    col="2"
                                    label="Tipo cadastro"
                                    classNameGroup="mb-lg-0"
                                    register={register}
                                    onChange={setSearchURL}
                                >
                                    <option value="titular">Titular</option>
                                    <option value="dependente">
                                        Dependente
                                    </option>
                                    <option value="">Todos...</option>
                                </GroupSelect>
                                <GroupSelect
                                    name="plano_id"
                                    col="3"
                                    label="Plano"
                                    classNameGroup="mb-lg-0"
                                    register={register}
                                    onChange={setSearchURL}
                                >
                                    {planos?.map(p => <option key={p.id} value={p.id}>{p.nome}</option>)}
                                    <option value="">Todos...</option>
                                </GroupSelect>
                                <GroupInput
                                    name="q"
                                    label="Buscar..."
                                    col="5"
                                    classNameGroup="mb-lg-0"
                                    register={register}
                                    onKeyUp={setSearchURL}
                                />
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <button
                                className="btn btn-primary h-100 w-100"
                                style={{ fontSize: "22px" }}
                            >
                                <i className="las la-search mr-2"></i>
                                Buscar
                            </button>
                        </div>
                    </CardContent>
                </form>
            </div>

            <div className="table-list">
                <CardContent>
                    <div className="col-12">
                        <TableHover className="mb-lg-0 text-center">
                            <Thead
                                th={[
                                    "Tipo / Situação",
                                    "Nome",
                                    "Matricula",
                                    "Plano",
                                    "Terminal",
                                    "Comercial",
                                    "Cadastrado",
                                ]}
                            />
                            <Tbody>
                                {filiados?.map((f) => {

                                    let situacao_color_class = (f?.situacao?.toUpperCase() === "REGULAR") ? 'success' : 'danger';

                                    return (
                                        <tr key={f?.id}>
                                            <Th>
                                                <span
                                                    className={`badge badge-${situacao_color_class} mr-1 px-2`}
                                                >
                                                    {f?.dependente
                                                        ? "Dependente / "
                                                        : "Titular / "}{f?.situacao}
                                                </span>
                                            </Th>
                                            <Td>
                                                <Link
                                                    to={f?.url || "/"}
                                                    className={`btn btn-sm m-0 text-dark`}
                                                >
                                                    {f?.nome} <br />
                                                    <small>{f?.cpf}</small>
                                                </Link>
                                            </Td>
                                            <Td>{f?.matricula}</Td>
                                            <Td>{f?.plano || "-"}</Td>
                                            <Td>{f?.terminal || "-"}</Td>
                                            <Td>
                                                <span>
                                                    {f?.vendedor}{f?.vendedor && f?.web_link ? <br /> : ''}
                                                    {f?.web_link ? <small className="">Web Site</small> : ''}
                                                    {!f?.vendedor && !f?.web_link ? '-' : ''}

                                                </span>
                                            </Td>
                                            <Td>
                                                {f?.cad_created_at || "-"}
                                            </Td>
                                        </tr>
                                    );
                                })}
                            </Tbody>
                        </TableHover>
                        <TableFooter
                            {...pagination}
                            setPage={setPage}
                            page={page}
                        />
                    </div>
                </CardContent>
            </div>
        </div>
    );
};

export default FiliadoSearch;
