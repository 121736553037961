import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import ButtonSave from "../../../components/ButtonComponent/ButtonSave";
import { CardContent, CardTitle } from "../../../components/Card-z";
import { GroupCheckBox, GroupInput, GroupSelect, GroupText } from "../../../components/Form";
import HeaderComponent from "../../../components/HeaderComponent";
import CONNECT_API from "../../../configs/connect";
import { formatCoinNotCurrency, SetCoin, SetNumber, ShowError } from "../../../configs/Functions";
import { useIsLoading } from "../../../contexts/LoadingContext";


// HOME DA PÁGINA CONFIGURAÇÃO
export const ConfiguracaoIndex = props => {

    const { setIsLoading } = useIsLoading();
    const { handleSubmit, reset, register } = useForm();

    const [pg, setPg] = useState([]);
    const [planos, setPlanos] = useState([]);
    const [terminais, setTerminais] = useState([]);
    const [beneficios, setBeneficios] = useState([]);
    const [config, setConfig] = useState([]);

    async function get_data_page() {
        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/vendas-online/configuracao`);
            setPlanos(data?.planos);
            setTerminais(data?.terminais);
            setBeneficios(data?.beneficios);
            setConfig(data?.config);
            setPg(data?.pg);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }


    async function onSubmit(formData) {
        try {
            formData.valor_comissao_site = SetCoin(formData.valor_comissao_site);
            formData.limit_link_ativo = SetNumber(formData.limit_link_ativo);
            formData.limit_vendas_link = SetNumber(formData.limit_vendas_link);
            formData.plano_default_id = SetNumber(formData.plano_default_id);
            formData.terminal_default_id = SetNumber(formData.terminal_default_id);

            setIsLoading(true);
            await CONNECT_API.put(`/vendas-online/configuracao`, { ...formData });
            setIsLoading(false);
        } catch (error) {
            ShowError(error);
        }
    }


    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (config)
            config.valor_comissao_site = formatCoinNotCurrency(config?.valor_comissao_site);
        reset({ ...config })
        // eslint-disable-next-line
    }, [config]);



    return (
        <>
            <div className="configuracao">
                <HeaderComponent {...pg} />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-lg-6">
                            <CardContent>
                                <CardTitle className="mb-2">
                                    Definições Gerais
                                </CardTitle>
                                <GroupInput name="url_base" col="12" required label="URL base do Link" placeholder="https://registrese.meusite.com" register={register} />
                                <GroupInput name="url_lead" col="12" required label="URL Cadastro de Leads" placeholder="https://api.meusite.com/lead" register={register} />
                                <GroupInput name="url_callback" col="12" required label="URL redirecionamento" placeholder="https://meusite.com" register={register} />
                                <GroupInput name="limit_vendas_link" col="12" required label="Limite de vendas por Link" type="number" register={register} />
                                <GroupInput name="limit_link_ativo" col="12" required label="Limite de Links ativos por vendedor" type="number" register={register} />
                                <GroupInput name="valor_comissao_site" col="12" label="Valor Comissão Sistema" register={register} />
                                <GroupSelect name="plano_default_id" col="12" required label="Plano Padrão" register={register}>
                                    <option value="">Selecione...</option>
                                    {
                                        planos?.map(p => {
                                            return <option key={p?.id} value={p?.id}>{p?.nome}</option>
                                        })
                                    }
                                </GroupSelect>
                                <GroupSelect name="terminal_default_id" col="12" required label="Terminal Padrão" register={register}>
                                    <option value="">Selecione...</option>
                                    {
                                        terminais?.map(p => {
                                            return <option key={p?.id} value={p?.id}>{p?.nome}</option>
                                        })
                                    }
                                </GroupSelect>
                                <GroupText name="desc_swal" col="12" label="Mensagem ao finalizar cadastro" register={register}/>
                                <div className="col-12 mt-4">
                                    <div className="form-group text-center">
                                        <ButtonSave type="submit" className="px-5" text="Atualizar Cadastro" />
                                    </div>
                                </div>
                            </CardContent>
                        </div>
                        <div className="col-lg-6">
                            <CardContent>
                                <CardTitle className="mb-2">
                                    Planos
                                    <small className="d-block mt-2" style={{ "fontSize": "12px" }}>
                                        Defina quais planos poderam ser vinculados a links de vendas.
                                    </small>
                                </CardTitle>
                                <div className="col-12 mb-3">
                                    <div className="row">
                                        {
                                            planos?.map(t => {
                                                return <GroupCheckBox
                                                    key={t?.id}
                                                    checked={t?.checked && 'checked'}
                                                    name={`planos`}
                                                    id={`plano-${t?.id}`}
                                                    value={t?.id}
                                                    col="12"
                                                    label={`${t?.nome}`}
                                                    register={register} />
                                            })
                                        }
                                    </div>
                                </div>
                                <CardTitle className="mb-2">
                                    Terminais de Pagamento
                                    <small className="d-block mt-2" style={{ "fontSize": "12px" }}>
                                        Defina quais terminais de pagamento pode ser usados no link de vendas.
                                    </small>
                                </CardTitle>
                                <div className="col-12 mb-4">
                                    <div className="row">
                                        {
                                            terminais?.map(t => {
                                                return <GroupCheckBox
                                                    key={t?.id}
                                                    checked={t?.checked && 'checked'}
                                                    name={`terminais`}
                                                    id={`terminal-${t?.id}`}
                                                    value={t?.id}
                                                    col="12"
                                                    label={`${t?.nome}`}
                                                    register={register} />
                                            })
                                        }
                                    </div>
                                </div>
                                <CardTitle className="mb-2">
                                    Benefícios
                                    <small className="d-block mt-2" style={{ "fontSize": "12px" }}>
                                        Defina quais benefícios pode ser concedidos no link de vendas.
                                    </small>
                                </CardTitle>
                                {
                                    beneficios?.map(t => {
                                        return <GroupCheckBox
                                            key={t?.id}
                                            checked={t?.checked && 'checked'}
                                            name={`beneficios`}
                                            id={`beneficio-${t?.id}`}
                                            value={t?.id}
                                            col="12"
                                            label={`${t?.nome}`}
                                            register={register} />
                                    })
                                }
                                <div className="col-12 mt-4">
                                    <div className="form-group">
                                        <ButtonSave type="submit" className="float-right px-5" />
                                    </div>
                                </div>
                            </CardContent>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}


export default ConfiguracaoIndex;