import React, { useEffect, useState } from "react";
import { CardContent } from "../../../../components/Card-z";
import HeaderComponent from "../../../../components/HeaderComponent";
import { Modal } from "../../../../components/Modal-z";
import CONNECT_API from "../../../../configs/connect";
import { formatCoin, ShowError } from "../../../../configs/Functions";
import { useIsLoading } from "../../../../contexts/LoadingContext";


export const ModalCieloDetalhes = props => {


    const [pg, setPg] = useState([]);
    const [pagamento, setPagamento] = useState([]);


    const { setIsLoading } = useIsLoading();

    async function get_data_page() {

        try {

            const id = props?.pagamento_id;
            // if (!id) return false;
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/cielo/pagamento/detalhes/${id}`);
            setPagamento(data.pagamento);
            setPg(data.pg);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    //Carregamnento dos dados
    useEffect(() => {
        if (!props?.pagamento_id) return false;
        get_data_page()
        // eslint-disable-next-line
    }, [props?.pagamento_id]);

    return (
        <div className="cielo-detalhes">
            <Modal modalId={props.modalId} sm="true" title="Ver detalhes das operações">
                <CardContent>
                    <div className="col-12">
                        <HeaderComponent {...pg} />
                    </div>
                    {
                        !pagamento?.length ?
                            <div className="col-12">
                                <div className="text-center">
                                    <i>Sem registros de pagamento!</i> <br />
                                    <small>Lançamentos como adesão e primeira mensalidade não é registrado aqui.</small>
                                </div>
                            </div>
                            : ''
                    }
                    {
                        pagamento?.map((l, index) => (
                            <div className="col-12" key={index}>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="situacao mb-3">
                                            <small className="mr-2"><b>Cobrança</b></small>
                                            <span
                                                className={`float-right btn btn-sm p-2 badge badge-${l?.situacao === "Negado" ? "danger" : "success"}`}
                                                title="Ver cartão"
                                                data-toggle="collapse"
                                                data-target={`#collapse-card-${index}`}
                                                aria-expanded="false"
                                                aria-controls={`collapse-card-${index}`}
                                            >
                                                <i className="las la-credit-card"></i> {l?.situacao}
                                            </span>
                                        </div>
                                        {
                                            !!l?.card?.numero && (
                                                <div className="cartao collapse" id={`collapse-card-${index}`}>
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <div className="numero mb-2">
                                                                <small><b>Número do Cartão</b></small> <br />
                                                                {l?.card.numero}
                                                            </div>
                                                            <div className="nome mb-2">
                                                                <small>
                                                                    <b>Nome impresso no cartão</b> <br />
                                                                    {l?.card.nome}
                                                                </small>
                                                            </div>
                                                            <div className="ven-band">
                                                                <small><b>Vencimento:</b> {l?.card.vencimento}</small>
                                                                <span className="float-right">
                                                                    <small><b>Bandeira:</b> {l?.card.bandeira}</small>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        <div className="cliente mb-3 d-none">
                                            <i className="las la-user-tie"></i> {l?.cliente}
                                        </div>
                                        <div className="valor">
                                            <i className="las la-coins"></i> {formatCoin(l?.valor)}
                                            <span className="float-right">{l?.data}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </CardContent>
            </Modal>
        </div>
    )
}


export default ModalCieloDetalhes;