import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

// LINK, COLOR, ICON, NAME
const CardLink = props => {

    function onLinkClick() {
        $('.modal').modal('hide');
        $('.collapse').collapse('hide');
    }

    const title = props?.label || props?.nome || props?.name;

    return (
        <Link to={props?.url || props?.link || '/'} className={"p-0 m-0 btn w-100"} style={{ "color": props?.color || '#343a40' }} onClick={onLinkClick} title={title}>
            <div className="card">
                <div className="card-body py-4">
                    <div className="form-row justify-content-center align-items-center text-left flex-lg-nowrap-off">
                        <div className="col-lg-3 col-auto">
                            <i style={{ "fontSize": "40px" }} className={`las la-${props?.icon}`}></i>
                        </div>
                        <div className="col-lg-9 col-auto">
                            <h4 className='m-0 p-0' style={{"whiteSpace":"nowrap", "overflow": "hidden","textOverflow": "ellipsis"}}>
                                {props?.label || props?.nome || props?.name}
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default CardLink;