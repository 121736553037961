import React, { useEffect, useState } from "react";
import { GroupSelect } from "../../../../components/Form";
import CONNECT_API from "../../../../configs/connect";
import { existsOrError, formatDate, SetNumber, ShowError } from "../../../../configs/Functions";
import { useIsLoading } from "../../../../contexts/LoadingContext";
import { useForm } from "react-hook-form";
import ButtonSave from "../../../../components/ButtonComponent/ButtonSave";

export const ChangeDataVencimentoMensalidades = props => {

    const [lancamento, setLancamento] = useState([]);
    const [pg, setPg] = useState([]);

    const { setIsLoading } = useIsLoading();
    const { handleSubmit, reset, register } = useForm();

    async function get_data_page() {

        try {


            const id = props.match.params.id;
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`filiado/${id}/configuracao/vencimento`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setPg(data?.pg);
            setLancamento(data?.lancamento);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    async function onSubmit(form) {

        try {

            const dia_vencimento = SetNumber(form.dia_vencimento);
            existsOrError(dia_vencimento, 'Dia do vencimento não informado!');

            setIsLoading(true);
            const vencimento_ok = await CONNECT_API.put(pg.end_point, { dia_vencimento });
            setIsLoading(false);

            if (vencimento_ok) {
                await get_data_page();
            }

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }


    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (lancamento?.dia_vencimento) {
            reset({
                dia_vencimento: lancamento?.dia_vencimento
            })
        }

        // eslint-disable-next-line
    }, [lancamento])

    return (
        <div className="card-vencimento text-muted">
            <div className="card">
                <div className="card-body">
                    <div className="card_header text-uppercase ">
                        <i className="las la-calendar"></i><b> Vencimento das mensalidades</b>
                        <hr className="mt-1" />
                    </div>
                    <div className="change">
                        <div className="text-muted mb-1">
                            Próximo Vencimento <small className="float-right">{formatDate(lancamento?.vencimento)}</small>
                        </div>
                        <div className="text-muted mb-3">
                            Dia do vencimento atual <small className="float-right">{lancamento?.dia_vencimento}</small>
                        </div>
                        <div className="text-muted mb-1">
                            <div className="text-center mb-2">
                                <b>Defina o novo vencimento</b>
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="form-row justify-content-center">
                                    <GroupSelect col="4" label="" name="dia_vencimento" classe="sm" classNameGroup="mb-0" id="dia_vencimento" register={register}>
                                        <option value="1">Dia 01</option>
                                        <option value="2">Dia 02</option>
                                        <option value="3">Dia 03</option>
                                        <option value="4">Dia 04</option>
                                        <option value="5">Dia 05</option>
                                        <option value="6">Dia 06</option>
                                        <option value="7">Dia 07</option>
                                        <option value="8">Dia 08</option>
                                        <option value="9">Dia 09</option>
                                        <option value="10">Dia 10</option>
                                        <option value="11">Dia 11</option>
                                        <option value="12">Dia 12</option>
                                        <option value="13">Dia 13</option>
                                        <option value="14">Dia 14</option>
                                        <option value="15">Dia 15</option>
                                        <option value="16">Dia 16</option>
                                        <option value="17">Dia 17</option>
                                        <option value="18">Dia 18</option>
                                        <option value="19">Dia 19</option>
                                        <option value="20">Dia 20</option>
                                        <option value="21">Dia 21</option>
                                        <option value="22">Dia 22</option>
                                        <option value="23">Dia 23</option>
                                        <option value="24">Dia 24</option>
                                        <option value="25">Dia 25</option>
                                        <option value="26">Dia 26</option>
                                        <option value="27">Dia 27</option>
                                        <option value="28">Dia 28</option>
                                        <option value="29">Dia 29</option>
                                        <option value="30">Dia 30</option>
                                    </GroupSelect>
                                    <ButtonSave type="submit" className="btn-sm" text="Alterar" />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChangeDataVencimentoMensalidades;