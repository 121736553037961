import React from "react";


// HOME DA PÁGINA ANÚNCIOS
export const Anuncios = props => {
    return (
        <>
            <div className="anuncios">
                ANÚNCIOS
            </div>
        </>
    );
}


export default Anuncios;