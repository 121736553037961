import React from 'react';
import { Link } from 'react-router-dom';


export const ButtonEdit = props => {

    if (props.url || props.link || props.href) {


        return (
            <div className="cmp-button">
                <Link to={props.url || props.link || props.href || '/'} className={`btn btn-success text-ligth px-4 py-2 ${props.className}`} >
                    <b style={{"cursor": "pointer"}}>
                        <i className="las la-edit"></i>
                        <label htmlFor="botão editar" className='m-0 ml-2'>Editar</label>
                    </b>
                </Link>
            </div>
        )

    } else {

        return (
            <div className="cmp-button">
                <button type={`${props.type || "button"}`} className={`btn btn-success text-ligth px-4 py-2 ${props.className}`} onClick={props.onClick}>
                    <i className="las la-edit"></i>
                    <label htmlFor="botão editar" className='m-0 ml-2'>Editar</label>
                </button>
            </div>
        )
    }

}



export default ButtonEdit