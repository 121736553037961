import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { CardContent } from "../../../../components/Card-z";
import HeaderComponent from "../../../../components/HeaderComponent";
import { Modal } from "../../../../components/Modal-z";
import { TableHover, Tbody, Td, Th, Thead } from "../../../../components/Table-z";
import CONNECT_API from "../../../../configs/connect";
import { existsOrError, formatCoin, formatDate, ShowError } from "../../../../configs/Functions";
import { useIsLoading } from "../../../../contexts/LoadingContext";
import ModalNewPlano from "./ModalNewPlano";


export const FiliadoPlano = props => {

    const [planos, setPlanos] = useState([]);
    const [pg, setPg] = useState([]);


    const { setIsLoading } = useIsLoading();

    async function get_data_page() {

        try {


            const id = props.match.params.id;
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`filiado/${id}/plano`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setPlanos(data.planos);
            setPg(data.pg);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }


    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);




    return (
        <div className="terminais">
            <HeaderComponent {...pg} />
            <div className="planos">
                <div className="row justify-content-center">
                    {
                        planos?.map(p => {
                            return (
                                <div key={p?.id} className="col-lg-4">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row justify-content-between mb-2">
                                                <div className="col-lg-auto">Situação</div>
                                                <div className="col-lg-auto">
                                                    {
                                                        p?.cancelado ?
                                                            <span className="badge badge-danger px-3 py-1">baixado</span>
                                                            : <span className="badge badge-success px-3 py-1">Ativo</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className="row justify-content-between mb-2">
                                                <div className="col-lg-auto">Nome</div>
                                                <div className="col-lg-auto">{p?.nome}</div>
                                            </div>
                                            <div className="row justify-content-between mb-2">
                                                <div className="col-lg-auto">Código</div>
                                                <div className="col-lg-auto">{p?.codigo}</div>
                                            </div>
                                            <div className="row justify-content-between mb-2">
                                                <div className="col-lg-auto">Recorrência</div>
                                                <div className="col-lg-auto">{p?.recorrencia}</div>
                                            </div>
                                            <div className="row justify-content-between mb-2">
                                                <div className="col-lg-auto">Vigência</div>
                                                <div className="col-lg-auto">{p?.vigencia}</div>
                                            </div>
                                            <div className="row justify-content-between mb-2">
                                                <div className="col-lg-auto">Cadastrado</div>
                                                <div className="col-lg-auto">{formatDate(p?.data_cadastro)}</div>
                                            </div>
                                            {
                                                p?.cancelado ?
                                                    <div className="row justify-content-between mb-2">
                                                        <div className="col-lg-auto">Baixado</div>
                                                        <div className="col-lg-auto">{formatDate(p?.cancelado)}</div>
                                                    </div> : ""
                                            }
                                            {
                                                p?.beneficio ?
                                                    <div className="row justify-content-between mb-2">
                                                        <div className="col-lg-auto">Benefício</div>
                                                        <div className="col-lg-auto">{p?.beneficio}</div>
                                                    </div> : ""
                                            }
                                            <div className="row justify-content-between mb-2">
                                                <div className="col-lg-auto">Valor</div>
                                                <div className="col-lg-auto">{formatCoin(p?.valor)}</div>
                                            </div>
                                            <div className="row justify-content-between mb-2">
                                                <div className="col-lg-auto">Adessão</div>
                                                <div className="col-lg-auto">{formatCoin(p?.valor_adesao)}</div>
                                            </div>
                                            <div className="row justify-content-between mb-3">
                                                <div className="col-lg-auto">Comissão</div>
                                                <div className="col-lg-auto">{formatCoin(p?.valor_comissao)}</div>
                                            </div>
                                            <div className="row justify-content-between ">
                                                <div className="col-lg-auto">
                                                    {
                                                        !p?.cancelado &&
                                                        <ModalNewPlano loaded={get_data_page} filiado_id={props.match.params.id} text="Novo Plano" />
                                                    }
                                                </div>
                                                <div className="col-lg-auto">
                                                    <Modal modalId={`modal-lancamento-${p?.id}`} className="btn btn-sm btn-info" text="Ver os lançamentos">
                                                        <CardContent>
                                                            <div className="col-12">
                                                                <TableHover className="mb-0 text-center">
                                                                    <Thead
                                                                        th={[
                                                                            "id",
                                                                            "Tipo",
                                                                            "Data",
                                                                            "Valor",
                                                                            "Vencimento",
                                                                            "Faturado",
                                                                            "Parcela",
                                                                        ]}
                                                                    />
                                                                    <Tbody>
                                                                        {
                                                                            p?.lancamentos?.map(f => {

                                                                                const receita = <i className="las la-arrow-up text-success" style={{ "fontSize": "25px" }}></i>
                                                                                const faturado = <i className="las la-check-square text-success" style={{ "fontSize": "25px" }}></i>
                                                                                const n_faturado = <i className="las la-exclamation text-danger" style={{ "fontSize": "25px" }}></i>

                                                                                return (
                                                                                    <tr key={f.id}>
                                                                                        <Th>
                                                                                            #{f.id}
                                                                                        </Th>
                                                                                        <Td>
                                                                                            {receita} <br />
                                                                                            <small className="text-success">{f.tipo_receita}</small>
                                                                                        </Td>
                                                                                        <Td>
                                                                                            {formatDate(f.created_at)}
                                                                                        </Td>
                                                                                        <Td>
                                                                                            {formatCoin(f.valor)} <br />
                                                                                        </Td>
                                                                                        <Td>
                                                                                            {formatDate(f.vencimento)}
                                                                                        </Td>
                                                                                        <Td>
                                                                                            {(f.faturado && !f.cancelado)
                                                                                                ?
                                                                                                <>
                                                                                                    {faturado} <br />
                                                                                                    <small className="text-success">{formatDate(f.faturado)}</small>
                                                                                                </>
                                                                                                :
                                                                                                !f.cancelado ? n_faturado :
                                                                                                    <>
                                                                                                        <span className="badge badge-danger mr-1 px-2">Cancelado</span> <br />
                                                                                                        <small className="text-danger">{formatDate(f.cancelado)}</small>
                                                                                                    </>
                                                                                            }
                                                                                        </Td>
                                                                                        <Td>
                                                                                            {f.numero_atual_x}/{f.numero_total_x ? f.numero_total_x : f.numero_atual_x}
                                                                                        </Td>
                                                                                    </tr>
                                                                                );
                                                                            })
                                                                        }
                                                                    </Tbody>
                                                                </TableHover>
                                                            </div>
                                                        </CardContent>
                                                    </Modal>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    );
}


export default withRouter(FiliadoPlano);