import React, { useEffect, useState } from "react";
import HeaderComponent from "../../../components/HeaderComponent";
import CONNECT_API from "../../../configs/connect";
import { SetNumber, ShowError } from "../../../configs/Functions";
import { useIsLoading } from "../../../contexts/LoadingContext";
import { withRouter } from "react-router-dom";
import { CardContent, CardTitle } from "../../../components/Card-z";
import { useForm } from "react-hook-form";
import { GroupInput, GroupRadio, GroupSelect, GroupText } from "../../../components/Form";
import ButtonSave from "../../../components/ButtonComponent/ButtonSave";
import { toast } from "react-toastify";

export const LinkId = props => {

    const link_id = Number(props.match.params.id);
    const [pg, setPg] = useState([]);
    const [link, setLink] = useState([]);
    const [planos, setPlanos] = useState([]);
    const [terminais, setTerminais] = useState([]);
    const [beneficios, setBeneficios] = useState([]);
    const [fornecedores, setFornecedores] = useState([]);

    const { setIsLoading, isCompany } = useIsLoading();
    const { handleSubmit, reset, register } = useForm();


    async function get_data_page() {
        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/vendas-online/meus-links/${link_id}?view=page`);
            setPg(data?.pg);
            setFornecedores(data?.fornecedores);
            setBeneficios(data?.beneficios);
            setTerminais(data?.terminais);
            setPlanos(data?.planos);
            setLink(data?.link);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }


    async function onSubmit(formData) {
        try {

            // caso seja null
            if (!formData.cad_pj_id) formData.cad_pj_id = [];

            const fields = {
                ativo: SetNumber(formData.ativo),
                plano_id: SetNumber(formData.plano_id),
                terminal_id: SetNumber(formData.terminal_id),
                beneficio_id: SetNumber(formData.beneficio_id),
                cad_pj_id: SetNumber(formData.cad_pj_id[0]) || null,
                max_vendas: SetNumber(formData.max_vendas),
                nome: formData.nome,
                desc: formData.desc,
                desc_website: formData.desc_website,
                id: link_id
            };

            setIsLoading(true);
            await CONNECT_API.put(`/vendas-online/meus-links/${link_id}`, { ...fields });
            await get_data_page();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }


    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, [link_id]);

    useEffect(() => {
        if (link?.id) {
            // link.cad_pj_id = [link.cad_pj_id];
            reset({ ...link });
        }
        // eslint-disable-next-line
    }, [link]);

    function shareMob() {
        const shareData = {
            title: "Solicite seu Cartão",
            text: "Vem fazer parte da familia " + isCompany?.nome + ".",
            url: link.url
        }
        navigator.share(shareData);
        return false;
    }

    // 
    // Função de copiar o link para a área de transferência
    function copyUrl() {
        if (link.url) {
            navigator.clipboard.writeText(link.url);
            toast.success('Link Copiado para a área de transferência!');
        } else {
            toast.error('Que pena, não foi possível copiar o link!');
        }
    }


    return (
        <>
            <div className="pagina-link">
                <HeaderComponent {...pg}>
                    <div className="row align-items-center justify-content-end h-100">
                        <div className="col-auto">
                            <button className="btn btn-outline-success mr-2" disabled={link.url ? false : true} onClick={shareMob}>Compartilhar Link <i className="las la-share-alt"></i></button>
                            <button className="btn btn-outline-primary" disabled={link.url ? false : true} onClick={copyUrl}>Copiar Link <i className="las la-copy"></i></button>
                        </div>
                    </div>
                </HeaderComponent>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-lg-6">
                            <CardContent>
                                <CardTitle>
                                    Cadastro
                                </CardTitle>
                                <GroupInput name="nome" col="12" required label="Nome" register={register} />
                                <GroupText name="desc" col="12" label="Descrição" register={register} />
                                <div className="col-12 mb-3">
                                    <div className="mb-1">Ativo</div>
                                    <div className="row">
                                        <GroupRadio name={`ativo`} id={`ativo-1`} value="1" col="auto" label={`Sim`} checked={link?.ativo && "true"} register={register} />
                                        <GroupRadio name={`ativo`} id={`ativo-0`} value="0" col="auto" label={`Não`} checked={!link?.ativo && "true"} register={register} />
                                    </div>
                                </div>
                                <GroupInput name="max_vendas" type="number" col="12" label="Max. Vendas" register={register} />
                                <GroupInput name="hash" disabled col="12" label="Hash" register={register} />
                                <GroupInput name="qtd_vendas" disabled type="number" defaultValue="0" col="12" label="Qtd. Vendas" register={register} />
                                <GroupText name="desc_website" col="12" label="Descrição do link no Web Site" register={register} />
                                <div className="col-12 mt-4">
                                    <div className="form-group text-center">
                                        <ButtonSave type="submit" text="Atualizar Cadastro" className="px-5" />
                                    </div>
                                </div>
                            </CardContent>
                        </div>
                        <div className="col-lg-6">
                            <CardContent>
                                <CardTitle className="mb-2">
                                    Plano
                                    <small className="d-block mt-2" style={{ "fontSize": "12px" }}>
                                        Defina o plano a ser vinculado ao link.
                                    </small>
                                </CardTitle>
                                <div className="col-12 mb-3">
                                    <div className="row">
                                        {!planos?.length && <div className="col-12 text-center"><small><i>Sem Planos liberado para vincular ao link</i></small> <hr /></div>}
                                        {
                                            planos?.map(t => {
                                                return <GroupRadio
                                                    key={t?.id}
                                                    checked={t?.checked && 'checked'}
                                                    name={`plano_id`}
                                                    id={`plano-${t?.id}`}
                                                    value={t?.id}
                                                    col="12"
                                                    label={`${t?.nome}`}
                                                    register={register} />
                                            })
                                        }
                                    </div>
                                </div>
                                <CardTitle className="mb-2">
                                    Terminal de Pagamento
                                    <small className="d-block mt-2" style={{ "fontSize": "12px" }}>
                                        Defina o Terminal de Pagamento ao link.
                                    </small>
                                </CardTitle>
                                <div className="col-12 mb-4">
                                    <div className="row">
                                        {!terminais?.length && <div className="col-12 text-center"><small><i>Sem Terminal Financeiro liberado para vincular ao link</i></small> <hr /></div>}
                                        {
                                            terminais?.map(t => {
                                                return <GroupRadio
                                                    key={t?.id}
                                                    checked={t?.checked && 'checked'}
                                                    name={`terminal_id`}
                                                    id={`terminal-${t?.id}`}
                                                    value={t?.id}
                                                    col="12"
                                                    label={`${t?.nome}`}
                                                    register={register} />
                                            })
                                        }
                                    </div>
                                </div>
                                <CardTitle className="mb-2">
                                    Benefício
                                    <small className="d-block mt-2" style={{ "fontSize": "12px" }}>
                                        Defina um benefício ao seu link.
                                    </small>
                                </CardTitle>
                                <div className="col-12 mb-4">
                                    <div className="row">
                                        {!beneficios?.length && <div className="col-12 text-center"><small><i>Sem Benefícios liberado para vincular ao link</i></small> <hr /></div>}
                                        {
                                            beneficios?.map(t => {
                                                return <GroupRadio
                                                    key={t?.id}
                                                    checked={t?.checked && 'checked'}
                                                    name={`beneficio_id`}
                                                    id={`beneficio-${t?.id}`}
                                                    value={t?.id}
                                                    col="12"
                                                    label={`${t?.nome}`}
                                                    register={register} />
                                            })
                                        }
                                    </div>
                                </div>
                                <CardTitle className="mb-2">
                                    Vinculo empresarial (B2B)
                                    <small className="d-block mt-2" style={{ "fontSize": "12px" }}>
                                        Defina uma empresa para vincular os filiados.
                                    </small>
                                </CardTitle>
                                <GroupSelect name="cad_pj_id" col="12" label="" register={register}>
                                    <option value="">Selecione...</option>
                                    {
                                        fornecedores?.map(p => {
                                            return <option key={p?.id} value={p?.id}>{p?.nome}</option>
                                        })
                                    }
                                </GroupSelect>
                                <div className="col-12 mt-4">
                                    <div className="form-group">
                                        <ButtonSave type="submit" className="float-right px-5" />
                                    </div>
                                </div>
                            </CardContent>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )

}


export default withRouter(LinkId);