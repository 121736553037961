import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './main/App';
import { Autenticado } from '../src/configs/auth'
import Login from './pages/md_1_login/Login';

import { LoadingProvider } from './contexts/LoadingContext';
import { ToastContainer } from 'react-toastify';

const { logado } = Autenticado();

ReactDOM.render(
  <LoadingProvider>
    {logado ? (<App />) : (<Login />)}
    <ToastContainer
      position="bottom-left"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </LoadingProvider>,
  document.getElementById('root')
);