import React, { useEffect, useState } from "react";
import { useIsLoading } from "../../../../contexts/LoadingContext";
import CONNECT_API from "../../../../configs/connect";
import { ShowError } from "../../../../configs/Functions";
import HeaderComponent from "../../../../components/HeaderComponent";
import { CardContent } from "../../../../components/Card-z";
import { TableFooter, TableHover, Tbody, Td, Th, Thead } from "../../../../components/Table-z";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { GroupInput, GroupInputSearch } from "../../../../components/Form";

export const CieloLancamento = props => {

    const { setIsLoading } = useIsLoading();
    const { register, handleSubmit } = useForm();

    const [pg, setPg] = useState([]);
    const [lancamentos, setLancamentos] = useState([]);
    const [pagination, setPagination] = useState([]);
    const [page, setPage] = useState(1);

    //////////////
    // DATA LOCAL DO FILTRO
    //////////////
    const data_local = new Date();
    const hoje = data_local
        .toLocaleDateString("pt-BR")
        .split("/")
        .reverse()
        .join("-");


    async function onSubmit(formData) {

        try {

            var data_in = formData.data_in || hoje;
            var data_end = formData.data_end || hoje;
            var q = formData.q;

            var query = `?view=list&page=${page}`;
            if (data_in) query += `&din=${data_in}`;
            if (data_end) query += `&dend=${data_end}`;
            if (q) query += `&q=${q}`;


            setIsLoading(true);
            const dados = await CONNECT_API.get(`/cielo/lancamento${query}`);
            const data = dados?.data;
            setPg(data?.pg);
            setLancamentos(data?.lancamentos);
            setPagination(data?.pagination);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }


    useEffect(() => {
        handleSubmit(onSubmit)();
        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {

        setTimeout(() => {
            if (document.querySelector(".page > div"))
                document
                    .querySelector(".page > div")
                    .scrollIntoView({ behavior: "smooth", block: "start" });
        }, 100);
        // eslint-disable-next-line
    }, [lancamentos]);



    return (
        <div className="recorrencias">
            <HeaderComponent {...pg} />
            <div className="table-list">
                <CardContent>
                    <div className="col-12">
                        <form className="form pb-3 pt-2" onSubmit={handleSubmit(onSubmit)}>
                            <div className="row justify-content-end">
                                <div className="col text-muted">
                                    <h1 className="h3 mb-lg-0 mb-4 text-gray-800">Operações Cielo</h1>
                                </div>
                                <GroupInput
                                    name="data_in"
                                    type="date"
                                    defaultValue={hoje}
                                    label="Data inicio"
                                    col="auto"
                                    onKeyUp={handleSubmit(onSubmit)}
                                    onChange={handleSubmit(onSubmit)}
                                    register={register}
                                />
                                <GroupInput
                                    name="data_end"
                                    type="date"
                                    defaultValue={hoje}
                                    label="Data final"
                                    col="auto"
                                    onKeyUp={handleSubmit(onSubmit)}
                                    onChange={handleSubmit(onSubmit)}
                                    register={register}
                                />
                                <GroupInputSearch
                                    name="q"
                                    placeholder="Nome, CPF, Matricula ..."
                                    col="4"
                                    classNameGroup="mb-lg-0"
                                    register={register}
                                />
                            </div>
                        </form>
                    </div>
                    <div className="col-12">
                        {lancamentos?.length ?
                            <>
                                <TableHover className="mb-lg-0 text-center">
                                    <Thead
                                        th={[
                                            "Situação",
                                            "Filiado",
                                            "Dados lançamento",
                                            "Dados do Cartão",
                                            "Dados trans.",
                                            "Data operação",
                                        ]}
                                    />
                                    <Tbody>
                                        {
                                            lancamentos?.map(f => {

                                                var pag = f?.data;

                                                return (
                                                    <tr key={f?.id}>
                                                        <Th>
                                                            <span
                                                                title={`${f?.data?.pag_status_str} ${f?.faturado ? 'em ' + f?.faturado : ''}`}
                                                                className={`rounded-circle badge badge-${!f?.faturado ? "danger" : "success"
                                                                    } mx-auto p-2 d-inline-block`}
                                                            ></span>
                                                        </Th>
                                                        <Td>
                                                            <Link
                                                                to={f?.url_filiado || "/"}
                                                                className="btn btn-sm m-0 text-dark px-0 text-center"
                                                                title="Ver Cadastro do filiado"
                                                            >
                                                                {f?.nome} <br />
                                                                <small>{f?.matricula}</small> <br />
                                                                <small>{f?.cpf}</small>
                                                            </Link>
                                                        </Td>
                                                        <Td>
                                                            <Link
                                                                to={f?.url_lancamento || '/'}
                                                                className="btn btn-sm m-0 text-dark px-0 text-center"
                                                                title="Ver lançamento"
                                                            >
                                                                <small>{f?.tipo}</small> <br />
                                                                <small>{f?.vencimento}</small> <br />
                                                                <small>{f?.valor}</small> <br />
                                                                <small>{f?.numero_atual_x} / {f?.numero_total_x}</small>
                                                            </Link>
                                                        </Td>
                                                        <Td>
                                                            <Link
                                                                to={f?.url_rec || '/'}
                                                                className="btn btn-sm m-0 text-dark px-0 text-center"
                                                                title="Ver Cadastro completo do Pagamento"
                                                            >
                                                                <small>{f?.card_nome}</small> <br />
                                                                <small>{f?.card_numero}</small> <br />
                                                                <small>{f?.card_bandeira}</small> <br />
                                                                <small>{f?.card_vencimento}</small>
                                                            </Link>
                                                        </Td>
                                                        <Td>
                                                            <Link
                                                                to={f?.url_pag || '/'}
                                                                className="btn btn-sm m-0 text-dark px-0 text-center"
                                                                title="Ver Cadastro completo da recorrência"
                                                            >
                                                                <small>Cód. aut.: {pag?.pag_code_autorizacao}</small> <br />
                                                                <small>Pag.: {pag?.pag_data}</small> <br />
                                                                <small>ID: {pag?.pag_id}</small> <br />
                                                                <small>Status: {pag?.pag_status_str}</small> <br />
                                                            </Link>
                                                        </Td>
                                                        <Td>
                                                            <span title="Data e hora do registro via tarefa">
                                                                {f?.created_at}
                                                            </span>
                                                        </Td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </Tbody>
                                </TableHover>
                                <TableFooter
                                    {...pagination}
                                    setPage={setPage}
                                    page={page}
                                />
                            </>

                            :
                            <div className="text-center">
                                <hr className="mt-0" />
                                <i>Nada encontrado!</i>
                                <hr />
                            </div>
                        }
                    </div>
                </CardContent>
            </div>
        </div>
    );
}

export default CieloLancamento;