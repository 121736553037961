import React, { useState, useEffect } from "react";
import HeaderComponent from "../../../../components/HeaderComponent";
import { Link } from "react-router-dom";
import { useIsLoading } from "../../../../contexts/LoadingContext";
import { useForm } from "react-hook-form";
import { GroupInput, GroupInputSearch, GroupRadio } from "../../../../components/Form";
import { CardContent } from "../../../../components/Card-z";
import { ShowError, existsOrError, formatCoin } from "../../../../configs/Functions";
import CONNECT_API from "../../../../configs/connect";

export const RelatorioWebsite = props => {

    const { setIsLoading } = useIsLoading();
    const { register, handleSubmit } = useForm();

    const [pg, setPg] = useState([]);
    // eslint-disable-next-line
    const [menus, setMenus] = useState([]);
    const [cadastros, setCadastros] = useState([]);

    //////////////
    // DATA LOCAL DO FILTRO
    //////////////
    const data_local = new Date();
    const hoje = data_local
        .toLocaleDateString("pt-BR")
        .split("/")
        .reverse()
        .join("-");


    async function onSubmit(formData) {

        try {

            var data_in = formData.data_in || hoje;
            var data_end = formData.data_end || hoje;
            var q = formData.q;
            var success = formData.success || '*';

            existsOrError(new Date(data_in.replace(/[-]/g,',')).getTime() <= new Date(data_end.replace(/[-]/g,',')).getTime(), 'Intervalo de datas invalido!');

            var query = `?view=list`;
            query += `&success=${success}`;
            if (data_in) query += `&din=${data_in}`;
            if (data_end) query += `&dend=${data_end}`;
            if (q) query += `&q=${q}`;


            setIsLoading(true);
            const dados = await CONNECT_API.get(`/filiado/relatorio/website/${query}`);
            const data = dados?.data;
            setCadastros(data?.cadastros);
            setPg(data?.pg);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    useEffect(() => {
        handleSubmit(onSubmit)();
        // eslint-disable-next-line
    }, []);


    useEffect(() => {

        setTimeout(() => {
            if (document.querySelector(".page > div"))
                document
                    .querySelector(".page > div")
                    .scrollIntoView({ behavior: "smooth", block: "start" });
        }, 100);
        // eslint-disable-next-line
    }, []);





    return (
        <>
            <div className="relatorio-website">
                <HeaderComponent {...pg} classNameRow="align-items-center">
                    <div className="btn-group-off" role="group">
                        {
                            menus?.map(m => {
                                return (
                                    <Link
                                        key={m?.id}
                                        to={m?.url || '/'}
                                        className="btn bg-white ml-2 shadow-sm mb-lg-0 mb-2"
                                        style={{
                                            "color": `${m?.color ? m?.color : '#343a40'}`,
                                            "borderColor": `${m?.color ? m?.color : '#343a40'}`
                                        }}
                                    >
                                        <i className={`las la-${m?.icon} mr-1`}></i>{m?.label}
                                    </Link>
                                );
                            })
                        }
                    </div>
                </HeaderComponent>
                <div className="table-list">
                    <CardContent>
                        <div className="col-12 mb-lg-3">
                            <form className="form pb-3 pt-2" onSubmit={handleSubmit(onSubmit)}>
                                <div className="row justify-content-end ">
                                    <div className="col text-muted">
                                        <h1 className="h3 mb-lg-0 mb-4 text-gray-800">Cadastros Website</h1>
                                    </div>
                                    <div className="col-lg-auto col-12 mb-lg-0 mb-2">
                                        <div className="row h-100 align-items-center">
                                            <div className="col-lg-auto d-lg-none">Tipo</div>
                                            <GroupRadio classNameGroup="mb-lg-0" name={`success`} id={`situacao-1`} value="1" col="auto" label={`Filiado`} checked={"true"} register={register} />
                                            <GroupRadio classNameGroup="mb-lg-0" name={`success`} id={`situacao-0`} value="0" col="auto" label={`Lead`} register={register} />
                                        </div>
                                    </div>
                                    <GroupInput
                                        name="data_in"
                                        type="date"
                                        required
                                        defaultValue={hoje}
                                        label="Data inicio"
                                        classNameGroup="mb-lg-0"
                                        col="auto"
                                        register={register}
                                    />
                                    <GroupInput
                                        name="data_end"
                                        type="date"
                                        defaultValue={hoje}
                                        label="Data final"
                                        classNameGroup="mb-lg-0"
                                        col="auto"
                                        register={register}
                                    />
                                    <GroupInputSearch
                                        name="q"
                                        placeholder="Nome, CPF, Vendedor, Plano ..."
                                        col="4"
                                        classNameGroup="mb-lg-0"
                                        register={register}
                                    />
                                </div>
                            </form>
                        </div>
                        <div className="col-12">
                            {cadastros?.length ?
                                <>
                                    <hr className="mt-lg-0" />
                                    <div className="row">
                                        {cadastros.map((c, i) => {

                                            var bg_icon = c.success ? 'la-user-check' : 'la-user-times';
                                            var bg_color_class = c.success ? 'bg-success bg-gradient text-white' : 'bg-info bg-gradient text-danger';
                                            var title = ``;
                                            if (c?.plano?.nome) title += 'Plano: ' + c?.plano?.nome + '\n';
                                            if (c?.plano?.valor) title += 'Valor do Plano: ' + formatCoin(c?.plano?.valor) + '\n';
                                            if (c?.vendedor?.nome) title += 'Vendedor: ' + c?.vendedor?.nome + '\n';
                                            if (c?.website?.link?.nome) title += 'Link: ' + c?.website?.link?.nome + '\n';
                                            if (c?.website?.config?.valor_comissao_site) title += 'Comissão: ' + formatCoin(c?.website?.config?.valor_comissao_site) + '\n';
                                            if (c?.error) title += 'Erro: ' + c?.error?.replace(/(<([^>]+)>)/ig, ' ') + '\n';

                                            return (
                                                <div className="col col-lg-4" key={i}>
                                                    <Link to={{ pathname: c?.url }} className="card-cadastros card" title={`${title}`}>
                                                        <div className={`card-img-top ${bg_color_class} text-center p-5`}>
                                                            <i className={`las ${bg_icon}`} style={{ "fontSize": "65px" }}></i>
                                                        </div>
                                                        <div className={`card-body text-muted`}>
                                                            <h5 className="card-title">
                                                                {c?.nome}
                                                            </h5>
                                                            <div className="card-text">
                                                                <small>
                                                                    CPF: <span>{c?.cpf}</span> <br />
                                                                    E-mail: <span>{c?.email}</span> <br />
                                                                    Tel: <span>{c?.tel}</span> <br />
                                                                    Finalizado em {c?.ts}
                                                                </small>
                                                                {
                                                                    c?.card['cc-number'] &&
                                                                    <>
                                                                        <hr />
                                                                        <small>
                                                                            <b>Cartão de Crédito</b> <br />
                                                                            Nome: <span>{c?.card['cc-name']}</span> <br />
                                                                            Número: <span>{c?.card['cc-number']}</span> <br />
                                                                            Vencimento: <span>{c?.card['cc-exp']}</span> <br />
                                                                        </small>
                                                                    </>
                                                                }
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="text-center">
                                        <small className="text-muted">Exibindo {cadastros?.length} itens.</small>
                                    </div>
                                </>
                                :
                                <div className="text-center">
                                    <hr className="mt-0" />
                                    <i>Nada encontrado!</i>
                                    <hr />
                                </div>
                            }
                        </div>
                    </CardContent>
                </div>
            </div>
        </>
    );
}

export default RelatorioWebsite;