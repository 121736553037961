import React, { useState, useRef } from 'react';
import JoditEditor from 'jodit-react';
import './TextEditor.css';

export const TextEditor = props => {

    const editor = useRef(null);

    const [value, setValue] = useState(props.text);

    const onChange = (value) => {
        setValue(value);

        if (props.setText) {
            props.setText(value.toString('html'));
        }
    };

    return (
        <div className="editor-text">
            <JoditEditor
                config={{
                    "useSearch": false,
                    "uploader": {
                        "insertImageAsBase64URI": true
                    },
                    "defaultTimeout":5,
                    "autofocus": true,
                    "toolbarSticky": false,
                    "iframe": true,
                    "toolbarButtonSize": "small",
                    "spellcheck": true,
                    "showCharsCounter": false,
                    "showWordsCounter": false,
                    "showXPathInStatusbar": false,
                    "askBeforePasteHTML": false,
                    "askBeforePasteFromWord": false,
                    "defaultActionOnPaste": "insert_only_text",
                    "buttons": "bold,italic,underline,strikethrough,eraser,ul,ol,font,fontsize,paragraph,classSpan,lineHeight,superscript,subscript,file,image,video,spellcheck,preview,print,find,indent,outdent,paste,selectall,hr,source"
                }}
                ref={editor}
                value={value}
                tabIndex={1} // tabIndex of textarea
                onBlur={onChange} // preferred to use only this option to update the content for performance reasons
                onChange={(newContent) => { }}
            />
        </div>
    );
}


export default TextEditor;
