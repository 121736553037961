import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ButtonSave from "../../../components/ButtonComponent/ButtonSave";
import { CardContent, CardTitle } from "../../../components/Card-z";
import { GroupCheckBox } from "../../../components/Form";
import HeaderComponent from "../../../components/HeaderComponent";
import CONNECT_API from "../../../configs/connect";
import { ShowError } from "../../../configs/Functions";
import { useIsLoading } from "../../../contexts/LoadingContext";


export const LeadConfig = props => {

    const { setIsLoading } = useIsLoading();
    const [colaboradores, setColaboradores] = useState([]);
    const [pg, setPg] = useState([]);

    const { register, handleSubmit } = useForm();



    async function get_data_page() {

        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/leads/configuracao`);
            setColaboradores(data?.colaboradores);
            setPg(data.pg);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    async function onSubmitAdmins(form_data) {

        try {
            setIsLoading(true);
            await CONNECT_API.post(`/leads/configuracao`, { post: 'admins', colaboradores: form_data.colaboradores });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);


    return (
        <div className="lead-config">
            <HeaderComponent {...pg} />

            <div className="row">
                <div className="col-lg-6">
                    <form onSubmit={handleSubmit(onSubmitAdmins)}>
                        <CardContent classNameCard="h-100 mb-0">
                            <CardTitle>
                                Administrador dos leads
                            </CardTitle>
                            <div className="col-12 mb-2">
                                <small>
                                    Selecione os colaboradores com autorização de administrador.
                                </small>
                            </div>
                            {
                                colaboradores?.map(t => {
                                    return <GroupCheckBox
                                        key={t?.id}
                                        checked={t?.admin && 'checked'}
                                        name={`colaboradores`}
                                        id={`${t?.id}`}
                                        value={t?.id}
                                        col="12"
                                        label={`${t?.nome}`}
                                        register={register} />
                                })
                            }
                            <div className="col-12 mt-4">
                                <div className="form-group">
                                    <ButtonSave type="submit" className="float-right px-5" />
                                </div>
                            </div>
                        </CardContent>
                    </form>
                </div>
            </div>

        </div>
    )
}


export default LeadConfig;