import React from "react";
import { Chart } from "react-google-charts";
import { MesStr } from "../../../../configs/Functions";


export const GraficoCadastros = props => {

    var recs = props?.recs?.cadastros;

    if (!recs) return <div className="text-center"><small><i>Sem Dados para gerar o gráfico</i></small></div>;

    var anos = Object.keys(recs);
    var final = [];
    anos.map(a => {
        let m = 1;
        while (m <= 12) {
            if (recs[a][m] === undefined) recs[a][m] = 0;
            if (final[m] === undefined) {
                final[m] = [];
                var mes = MesStr(m);
                final[m].push(mes);
                final[m].push(recs[a][m]);
            } else {
                final[m].push(recs[a][m]);
            }
            m++;
        }
        return true;
    });

    anos.unshift('Meses');
    var meses = Object.keys(final);
    var data = [anos];
    meses.map(m => data.push(final[m]));

    var options = {
        title: "Cadastros de Recorrêcias por Mês",
        chartArea: { width: "70%" },
        hAxis: {
            title: "Total de Recorrências",
            minValue: 0,
        },
        vAxis: {
            title: "Meses",
        },
    };

    return (
        <Chart
            chartType="BarChart"
            width="100%"
            height="400px"
            data={data}
            options={options}
        />
    );
}

export const GraficoDias = props => {

    var recs = props?.recs?.dias_cobranca;

    if (!recs) return <div className="text-center"><small><i>Sem Dados para gerar o gráfico</i></small></div>;

    let d = 1;
    var data = [];
    while (d <= 31) {

        if (recs[d] === undefined) recs[d] = 0;
        data.push([d, recs[d]]);
        d++;
    }

    data.unshift(['Dia', 'Cobranças']);

    var options = {
        title: 'Cobrança das Recorrências por dia do mês',
        legend: { position: "bottom" },
        curveType: "function",
        hAxis: {
            title: "Dia",
        },
        vAxis: {
            title: "Cobranças",
        },
    };

    return (
        <Chart
            chartType="LineChart"
            width="100%"
            height="400px"
            data={data}
            options={options}
        />
    );

}