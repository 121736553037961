import React from "react";


const SemAcesso = props => {

    return (
        <div className="sem-acesso h-100">
            <div className="h-100" style={{ "maxWidth": "500px", "margin": "0 auto" }}>
                <div className="row h-100 align-items-center text-center">
                    <div className="col-auto">
                        <i className="las la-exclamation-triangle text-danger" style={{"fontSize": "80px"}}></i>
                    </div>
                    <div className="col text-left">
                        <h4>
                            Sem permissão de acesso
                            <br /><small>Verifique com seu gerente</small>
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default SemAcesso;