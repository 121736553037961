import React from "react";


export const ButtonVoltar = props => {
    return (
        <div className="cmp-button">
            <button onClick={() => window.history.back()} type={`${props.type || "button"}`} className={`btn px-4 py-2 bg-gradient ${props.className ? props.className : "btn-primary"}`}>
                <i className={`las la-${props.icon ? props.icon : "arrow-left"}`}></i>
                <label htmlFor="botão voltar" className='m-0 ml-2'>{props?.text ? props?.text : "Voltar"}</label>
            </button>
        </div>
    )
}


export default ButtonVoltar;