import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CardContent, CardTitle } from "../../components/Card-z";
import { GroupCheckBox, GroupInput, GroupSelect } from "../../components/Form";
import HeaderComponent from "../../components/HeaderComponent";
import CONNECT_API from "../../configs/connect";
import {
    existsOrError,
    formatCNPJ,
    formatCoin,
    formatCPF,
    formatDate,
    SetCoin,
} from "../../configs/Functions";
import { useIsLoading } from "../../contexts/LoadingContext";
import {
    TableFooter,
    TableHover,
    Tbody,
    Td,
    Th,
    Thead,
} from "../../components/Table-z";
import { Modal } from "../../components/Modal-z";
import $ from "jquery";
import { toast } from "react-toastify";
import ButtonSave from "../../components/ButtonComponent/ButtonSave";
import FileArray from "../../components/File/Array/input";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import LancamentoDespesa from "./despesa/LancamentoDespesa";
import LancamentoReceita from "./receita/LancamentoReceita";

export const LancamentoSearch = (props) => {
    //////////////
    // DATA LOCAL DO FILTRO
    //////////////
    const data_local = new Date();
    const hoje = data_local
        .toLocaleDateString("pt-BR")
        .split("/")
        .reverse()
        .join("-");

    const [pg, setPg] = useState([]);
    const [page, setPage] = useState(1);
    const [pagination, setPagination] = useState([]);
    const [totais, setTotais] = useState([]);
    const [lancamentos, setLancamentos] = useState([]);
    const [colaboradores, setColaboradores] = useState([]);

    const [lanFaturar, setLanFaturar] = useState([]);

    const [fileList, setFileList] = useState([]);

    const { setIsLoading } = useIsLoading();
    const { register, handleSubmit, reset } = useForm();

    // async function get_data_page() {
    //     try {
    //         setIsLoading(true);

    //         const { data } = await CONNECT_API.get(`/lancamento/search`);
    //         existsOrError(data, "Não foi possivel carregar os dados!");
    //         setLancamentos(data.lancamentos);
    //         setPagination(data?.pagination);
    //         setColaboradores(data?.colaboradores);
    //         setTotais(data?.totais);
    //         setPg(data.pg);
    //         setIsLoading(false);
    //     } catch (error) {
    //         setIsLoading(false);
    //     }
    // }

    ///////
    // CONTROLE DOS COLAPSES
    ///////
    async function onCollapse(e) {
        let checked = e.target.checked;
        let id = e.target.id;


        if (checked) {
            $(`div#${id}`).collapse("show");
        } else {
            $(`div#${id}`).collapse("hide");
        }
    }

    ////////////
    // FILTRO
    ////////////
    async function onSubmit(data) {
        const {
            colaborador,
            data_in,
            data_out,
            faturado,
            pf,
            pj,
            receita,
            despesa,
            cancelado,
            nao_faturado,
        } = data;

        var query = `?page=${page}`;
        if (colaborador) query += `&colaborador=${colaborador}`;
        if (data_in) query += `&data_in=${data_in}`;
        if (data_out) query += `&data_out=${data_out}`;
        if (faturado) query += `&faturado=${faturado}`;
        if (nao_faturado) query += `&nao_faturado=${nao_faturado}`;
        if (pf) query += `&pf=${pf}`;
        if (pj) query += `&pj=${pj}`;
        if (receita) query += `&receita=${receita}`;
        if (despesa) query += `&despesa=${despesa}`;
        if (cancelado) query += `&cancelado=${cancelado}`;

        try {
            setIsLoading(true);

            const { data } = await CONNECT_API.get(
                `/lancamento/search/${query}`
            );
            existsOrError(data, "Não foi possivel carregar os dados!");
            setLancamentos(data.lancamentos);
            setPagination(data?.pagination);
            setColaboradores(data?.colaboradores);
            setTotais(data?.totais);
            setPg(data.pg);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    /////////////////
    // CANCELAMENTO
    /////////////////
    async function onCancelarLancamento(id) {
        if (!id) return false;

        var f = lancamentos.filter((l) => l.id === id);
        f = f[0];

        var pj = `
            ${formatCNPJ(f?.cnpj)} <br />
            <small>${f.pj_nome}</small>
        `;
        var pf = `
            ${formatCPF(f?.cpf)} <br />
            <small>${f?.pf_nome}</small>
        `;

        Swal.fire({
            icon: "warning",
            title: `Cancelar o lançamento #${id}`,
            confirmButtonText: "Cancelar lançamento",
            cancelButtonText: "Voltar",
            showCancelButton: true,
            customClass: {
                confirmButton: "btn btn-danger px-5 ",
                cancelButton: "btn btn-success px-5 ml-3",
            },
            buttonsStyling: false,
            html: `
                <div class="text-center" style="font-size: 13px">
                    Valor de ${formatCoin(f?.valor)}; <br />
                    Vecimento em ${formatDate(f?.vencimento)}; <br />
                    Lançamento ${f?.numero_atual_x}/${f?.numero_total_x}; <br />
                    ${f?.cad_pf_id ? pf : pj}
                </div>
            `,
        }).then(async (v) => {
            if (v?.value) {
                setIsLoading(true);
                await CONNECT_API.post(`/lancamento/cancelar`, {
                    lancamento_id: id,
                });
                await handleSubmit(onSubmit)();
                setIsLoading(false);
            }
        });
    }

    ///////////////
    // MODAL FATURAMENTO
    ///////////////
    async function onGetFaturamento(id) {
        if (!id) return false;

        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/lancamento/${id}/faturar`);
            setLanFaturar({ ...data });
            $("#modal-faturamento").modal("show");
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }
    ///////////////
    // POST FATURAMENTO
    ///////////////
    async function onPostFaturamento(data) {
        try {
            const { comissao, colaborador_comissao, files, free } = data;

            const terminal_financeiro = data?.terminal_financeiro;
            const valor_faturado = SetCoin(data?.valor_faturado);
            const valor_comissao = SetCoin(data?.valor_comissao);

            existsOrError(
                terminal_financeiro,
                "Terminal financeiro não informado!"
            );
            if (!free) {
                existsOrError(valor_faturado, "Valor faturado não informado!");
            }

            const fields_faturamento = {
                terminal_financeiro,
                valor_faturado,
                files,
                free,
            };

            if (comissao) {
                fields_faturamento.comissao = comissao;
                fields_faturamento.colaborador_comissao = colaborador_comissao;
                fields_faturamento.valor_comissao = valor_comissao;
            } else {
                delete fields_faturamento.comissao;
                delete fields_faturamento.colaborador_comissao;
                delete fields_faturamento.valor_comissao;
            }

            setIsLoading(true);
            await CONNECT_API.post(`${lanFaturar?.pg?.end_point}`, {
                ...fields_faturamento,
            });
            reset({
                comissao: false,
                valor_comissao: null,
                colaborador_comissao: null,
                valor_faturado: null,
                terminal_financeiro: null,
            });

            await handleSubmit(onSubmit)();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }

    useEffect(() => {
        handleSubmit(onSubmit)();
        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {
        setTimeout(() => {
            document
                .querySelector(".page > div")
                .scrollIntoView({ behavior: "smooth", block: "start" });
        }, 100);
        // eslint-disable-next-line
    }, [lancamentos]);

    /////////////////////////
    // MODAIS
    /////////////////////////
    const lf = lanFaturar?.lancamento;

    const modal_faturar = (
        <Modal modalId="modal-faturamento" sm="true">
            <CardContent classNameRow="justify-content-center">
                <div className="col-12">
                    <HeaderComponent {...lanFaturar?.pg} />
                    <small className="text-center d-block">
                        Uma vez que este lançamento seja faturado, ele pode ser
                        deletado na tela de edição de faturamento.
                    </small>
                </div>
                <div className="col-12 mt-4">
                    <CardContent>
                        <div className="col-12">
                            <div className="title mb-2">
                                {lf?.receita ? (
                                    <>
                                        <small className="text-success">
                                            Receita
                                        </small>{" "}
                                        <br />
                                    </>
                                ) : (
                                    <>
                                        <small className="text-danger">
                                            Despesa
                                        </small>{" "}
                                        <br />
                                    </>
                                )}
                                <b>{lf?.pj_nome ? lf?.pj_nome : lf?.pf_nome}</b>
                            </div>
                            <div className="desc">
                                Vencimento: {formatDate(lf?.vencimento)} <br />
                                Valor: {formatCoin(lf?.valor)} <br />
                            </div>
                        </div>
                    </CardContent>
                    <div className="terminal mt-4">
                        <form
                            action=""
                            onSubmit={handleSubmit(onPostFaturamento)}
                        >
                            <CardContent>
                                <div className="col-12 mb-4 text-center">
                                    <small>
                                        Selecione um terminal financeiro para
                                        lançar o faturamento.
                                    </small>
                                </div>
                                <GroupSelect
                                    name="terminal_financeiro"
                                    required
                                    col="12"
                                    label="Terminal financeiro"
                                    register={register}
                                >
                                    {lanFaturar?.terminais?.length === 0 && (
                                        <option disabled={true}>
                                            Nenhum terminal disponível
                                        </option>
                                    )}
                                    {lanFaturar?.terminais?.map((r) => {
                                        return (
                                            <option value={r.id} key={r.id}>
                                                {r.nome}
                                            </option>
                                        );
                                    })}
                                </GroupSelect>
                                <GroupInput
                                    name="valor_faturado"
                                    label="Valor faturado"
                                    col="12"
                                    register={register}
                                />
                                {
                                    /// Faturamento apenas se for receita
                                    lf?.receita ? (
                                        <>
                                            <GroupCheckBox
                                                name="free"
                                                id="free"
                                                value="1"
                                                col="12"
                                                label="Gratuito"
                                                register={register}
                                            />
                                            <GroupCheckBox
                                                name="comissao"
                                                id="collapse-comissao"
                                                value="1"
                                                col="12"
                                                label="Lançar comissão"
                                                onChange={onCollapse}
                                                register={register}
                                            />
                                            <div className="col-12">
                                                <div
                                                    className="collapse"
                                                    id="collapse-comissao"
                                                >
                                                    <div className="row mt-3">
                                                        <CardTitle>
                                                            Comissão
                                                        </CardTitle>
                                                        <GroupSelect
                                                            name="colaborador_comissao"
                                                            col="12"
                                                            label="Colaborador comissionado"
                                                            register={register}
                                                        >
                                                            {lanFaturar
                                                                ?.colaboradores
                                                                ?.length ===
                                                                0 && (
                                                                    <option
                                                                        disabled={
                                                                            true
                                                                        }
                                                                    >
                                                                        Nenhum
                                                                        colaborador
                                                                        disponível
                                                                    </option>
                                                                )}
                                                            {lanFaturar?.colaboradores?.map(
                                                                (r) => {
                                                                    return (
                                                                        <option
                                                                            value={
                                                                                r?.id
                                                                            }
                                                                            key={
                                                                                r.id
                                                                            }
                                                                        >
                                                                            {
                                                                                r?.nome
                                                                            }
                                                                        </option>
                                                                    );
                                                                }
                                                            )}
                                                        </GroupSelect>
                                                        <GroupInput
                                                            name="valor_comissao"
                                                            label="Valor comissionado"
                                                            col="12"
                                                            register={register}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        ""
                                    )
                                }
                                <div className="col-12 py-4">
                                    <FileArray
                                        register={register}
                                        reset={reset}
                                        fileList={fileList}
                                        setFileList={setFileList}
                                    />
                                </div>
                            </CardContent>
                            <ButtonSave type="submit" className="float-right" />
                        </form>
                    </div>
                </div>
            </CardContent>
        </Modal>
    );

    return (
        <div className="relatorios">
            <HeaderComponent {...pg} >
                <div className="row justify-content-end">
                    <div className="col-lg-auto pr-lg-0">
                        <LancamentoReceita />
                    </div>
                    <div className="col-lg-auto pr-lg-0">
                        <LancamentoDespesa />
                    </div>
                    {totais ? (
                        <div className="col-lg-auto">
                            <div className="card bg-info">
                                <div className="card-body text-center text-light">
                                    <b>
                                        Total{" "}
                                        {totais?.total_lancado}
                                    </b>
                                </div>
                            </div>
                        </div>
                    ) : (
                        ""
                    )}
                </div>
            </HeaderComponent>
            <div className="search">
                <form
                    action=""
                    name="search-fornecedor"
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <CardContent>
                        <div className="col-lg-12">
                            <div className="row justify-content-between">
                                <GroupInput
                                    name="data_in"
                                    type="date"
                                    defaultValue={hoje}
                                    label="Data inicio"
                                    col="4"
                                    register={register}
                                />
                                <GroupInput
                                    name="data_out"
                                    type="date"
                                    defaultValue={hoje}
                                    label="Data final"
                                    col="4"
                                    register={register}
                                />
                                <GroupSelect
                                    name="colaborador"
                                    col="4"
                                    label="Colaborador"
                                    register={register}
                                >
                                    {colaboradores?.map((r) => {
                                        return (
                                            <option value={r.id} key={r.id}>
                                                {r.nome}
                                            </option>
                                        );
                                    })}
                                    <option value="">Todos...</option>
                                </GroupSelect>
                                <GroupCheckBox
                                    name="faturado"
                                    id="faturado"
                                    value="1"
                                    col="6"
                                    label="Lançamento faturado"
                                    register={register}
                                />
                                <GroupCheckBox
                                    name="nao_faturado"
                                    id="nao_faturado"
                                    value="1"
                                    col="6"
                                    label="Lançamento Não Faturado"
                                    register={register}
                                />
                                <GroupCheckBox
                                    name="cancelado"
                                    id="cancelado"
                                    value="1"
                                    col="6"
                                    label="Lançamento cancelado"
                                    register={register}
                                />
                                <GroupCheckBox
                                    name="pf"
                                    id="pf"
                                    value="1"
                                    col="6"
                                    label="Fornecedor PF"
                                    register={register}
                                />
                                <GroupCheckBox
                                    name="pj"
                                    id="pj"
                                    value="1"
                                    col="6"
                                    label="Fornecedor PJ"
                                    register={register}
                                />

                                <GroupCheckBox
                                    name="receita"
                                    id="receita"
                                    value="1"
                                    col="6"
                                    label="Tipo receita"
                                    register={register}
                                />
                                <GroupCheckBox
                                    name="despesa"
                                    id="despesa"
                                    value="1"
                                    col="6"
                                    label="Tipo despesa"
                                    register={register}
                                />
                                <div className="col-lg-12">
                                    <button className="btn btn-primary float-right">
                                        <i className="las la-search mr-2"></i>
                                        Buscar
                                    </button>
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </form>
            </div>

            <div className="table-list">
                <CardContent>
                    <div className="col-12">
                        <TableHover className="mb-0 text-center">
                            <Thead
                                th={[
                                    "id",
                                    "Tipo",
                                    "Cadastro",
                                    "Data",
                                    "Valor",
                                    "Vencimento",
                                    "Faturado",
                                    "Parcela",
                                    "Ação",
                                ]}
                            />
                            <Tbody>
                                {lancamentos?.map((f) => {
                                    const receita = (
                                        <i
                                            className="las la-arrow-up text-success"
                                            style={{ fontSize: "25px" }}
                                        ></i>
                                    );
                                    const despesa = (
                                        <i
                                            className="las la-arrow-down text-danger"
                                            style={{ fontSize: "25px" }}
                                        ></i>
                                    );
                                    //const pj = <span className="badge badge-secondary mr-1 px-3">{formatCNPJ(f?.cnpj)}</span>
                                    //const pf = <span className="badge badge-secondary mr-1 px-3">{formatCPF(f?.cpf)}</span>
                                    const pj = (
                                        <Link
                                            to={f?.url_fornecedor || "/"}
                                            className="btn btn-sm m-0 text-dark"
                                            target="_blank"
                                        >
                                            {formatCNPJ(f?.cnpj)} <br />
                                            <small>{f.pj_nome}</small>
                                        </Link>
                                    );
                                    const pf = (
                                        <Link
                                            to={f?.url_fornecedor || "/"}
                                            className="btn btn-sm m-0 text-dark"
                                            target="_blank"
                                        >
                                            {formatCPF(f?.cpf)} <br />
                                            <small>{f?.pf_nome}</small>
                                        </Link>
                                    );

                                    const faturado = (
                                        <i
                                            className="las la-check-square text-success"
                                            style={{ fontSize: "25px" }}
                                        ></i>
                                    );
                                    const n_faturado =
                                        f?.agendado ?
                                            <><span className="text-warning" title="Cobrança Agendada"><i className="las la-clock" style={{ "fontSize": "25px" }}></i><br /><small>{f?.agendado}</small></span></>
                                            :
                                            <i className="las la-exclamation text-danger" style={{ "fontSize": "25px" }}></i>

                                    const btn_cancelar = (
                                        <button
                                            className="btn btn-sm m-0 text-danger"
                                            title="Cancelar"
                                            onClick={() =>
                                                onCancelarLancamento(f.id)
                                            }
                                        >
                                            <i
                                                className="las la-ban"
                                                style={{ fontSize: "20px" }}
                                            ></i>
                                        </button>
                                    );

                                    const btn_faturar = (
                                        <button
                                            className="btn btn-sm m-0 text-success"
                                            title="Faturar"
                                            onClick={() =>
                                                onGetFaturamento(f.id)
                                            }
                                        >
                                            <i
                                                className="las la-hand-holding-usd"
                                                style={{ fontSize: "20px" }}
                                            ></i>
                                        </button>
                                    );

                                    const btn_ver = (
                                        <Link
                                            to={f.url || f.link || "/"}
                                            className="btn btn-sm m-0 text-success"
                                            title="Ver"
                                            target="_blank"
                                        >
                                            <i
                                                className="las la-external-link-alt"
                                                style={{ fontSize: "20px" }}
                                            ></i>
                                        </Link>
                                    );

                                    const btns = (
                                        <div
                                            className="btn-group"
                                            role="group"
                                            aria-label="buttons ação"
                                        >
                                            {!f.faturado &&
                                                !f.cancelado &&
                                                btn_cancelar}
                                            {!f.faturado &&
                                                !f.cancelado &&
                                                btn_faturar}
                                            {btn_ver}
                                            {/**(!f.faturado && !f.cancelado) ? btn_editar : btn_ver */}
                                        </div>
                                    );

                                    return (
                                        <tr key={f.id}>
                                            <Th>#{f.id}</Th>
                                            <Td>
                                                {f.receita ? (
                                                    <>
                                                        {receita} <br />
                                                        <small className="text-success">
                                                            {f.tipo_receita}
                                                        </small>
                                                    </>
                                                ) : (
                                                    <>
                                                        {despesa} <br />
                                                        <small className="text-danger">
                                                            Despesa
                                                        </small>
                                                    </>
                                                )}
                                            </Td>
                                            <Td>{f.cad_pf_id ? pf : pj}</Td>
                                            <Td>{formatDate(f.created_at)}</Td>
                                            <Td>
                                                {formatCoin(f.valor)} <br />
                                            </Td>
                                            <Td>{formatDate(f.vencimento)}</Td>
                                            <Td>
                                                {f.faturado && !f.cancelado ? (
                                                    <>
                                                        {faturado} <br />
                                                        <small className="text-success">
                                                            {formatDate(
                                                                f.faturado
                                                            )}
                                                        </small>
                                                    </>
                                                ) : !f.cancelado ? (
                                                    n_faturado
                                                ) : (
                                                    <>
                                                        <span className="badge badge-danger mr-1 px-2">
                                                            Cancelado
                                                        </span>{" "}
                                                        <br />
                                                        <small className="text-danger">
                                                            {formatDate(
                                                                f.cancelado
                                                            )}
                                                        </small>
                                                    </>
                                                )}
                                            </Td>
                                            <Td>
                                                {f.numero_atual_x}/
                                                {f.numero_total_x
                                                    ? f.numero_total_x
                                                    : f.numero_atual_x}
                                            </Td>
                                            <Td>{btns}</Td>
                                        </tr>
                                    );
                                })}
                            </Tbody>
                        </TableHover>
                        <TableFooter
                            {...pagination}
                            setPage={setPage}
                            page={page}
                        />
                    </div>
                </CardContent>
            </div>
            {modal_faturar}
        </div>
    );
};

export default LancamentoSearch;
