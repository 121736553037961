import React, { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import ButtonSave from "../../../components/ButtonComponent/ButtonSave";
import { CardContent, CardTitle } from "../../../components/Card-z";
import { GroupCheckBox, GroupInput, GroupRadio, GroupSelect, GroupText } from "../../../components/Form";
import HeaderComponent from "../../../components/HeaderComponent";
import CONNECT_API from "../../../configs/connect";
import { ShowError } from "../../../configs/Functions";
import { useIsLoading } from "../../../contexts/LoadingContext";
import FileSingle from "../../../components/File/Single/input";


// HOME DA PÁGINA CONFIGURAÇÃO
export const Configuracoes = props => {

    const { setIsLoading } = useIsLoading();
    const { handleSubmit, reset, register } = useForm();

    const [pg, setPg] = useState([]);
    const [config, setConfig] = useState([]);

    const [fileListImage, setFileListImage] = useState([]);
    const [fileListIcon, setFileListIcon] = useState([]);
    const [fileListIconDark, setFileListIconDark] = useState([]);
    const [fileListIconLight, setFileListIconLight] = useState([]);


    const [fileListManIcon192, setFileListManIcon192] = useState([]);
    const [fileListManIcon512, setFileListManIcon512] = useState([]);
    const [fileListManIcon16, setFileListManIcon16] = useState([]);
    const [fileListManIcon32, setFileListManIcon32] = useState([]);
    const [fileListManIcon64, setFileListManIcon64] = useState([]);

    async function get_data_page() {
        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/portal-do-filiado/configuracoes`);
            setConfig(data?.config);
            setPg(data?.pg);

            if (typeof (data?.config?.fileIcon) == 'object' && data?.config?.fileIcon) setFileListIcon([data?.config?.fileIcon]);
            if (typeof (data?.config?.fileImage) == 'object' && data?.config?.fileImage) setFileListImage([data?.config?.fileImage]);
            if (typeof (data?.config?.fileIconDark) == 'object' && data?.config?.fileIconDark) setFileListIconDark([data?.config?.fileIconDark]);
            if (typeof (data?.config?.fileIconLight) == 'object' && data?.config?.fileIconLight) setFileListIconLight([data?.config?.fileIconLight]);

            if (typeof (data?.config?.fileManIcon16) == 'object' && data?.config?.fileManIcon16) setFileListManIcon16([data?.config?.fileManIcon16]);
            if (typeof (data?.config?.fileManIcon32) == 'object' && data?.config?.fileManIcon32) setFileListManIcon32([data?.config?.fileManIcon32]);
            if (typeof (data?.config?.fileManIcon64) == 'object' && data?.config?.fileManIcon64) setFileListManIcon64([data?.config?.fileManIcon64]);

            if (typeof (data?.config?.fileManIcon192) == 'object' && data?.config?.fileManIcon192) setFileListManIcon192([data?.config?.fileManIcon192]);
            if (typeof (data?.config?.fileManIcon512) == 'object' && data?.config?.fileManIcon512) setFileListManIcon512([data?.config?.fileManIcon512]);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }


    async function onSubmit(formData) {
        try {

            setIsLoading(true);
            await CONNECT_API.put(`/portal-do-filiado/configuracoes`, { ...formData });
            setIsLoading(false);
        } catch (error) {
            ShowError(error);
        }
    }


    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        reset({ ...config })
        // eslint-disable-next-line
    }, [config]);



    return (
        <>
            <div className="configuracao">
                <HeaderComponent {...pg} />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <div className="col-lg-6">
                            <CardContent>
                                <CardTitle className="mb-2">
                                    Definições Gerais
                                </CardTitle>
                                <div className="col-12 mb-3">
                                    <div className="mb-1">Ativo</div>
                                    <div className="row">
                                        <GroupRadio name={`ativo`} id={`ativo-1`} value="1" col="auto" label={`Sim`} checked={config?.ativo && "true"} register={register} />
                                        <GroupRadio name={`ativo`} id={`ativo-0`} value="0" col="auto" label={`Não`} checked={!config?.ativo && "true"} register={register} />
                                    </div>
                                </div>
                                <CardTitle className="mb-2">
                                    Comunicação
                                    <small className="d-block mt-2" style={{ "fontSize": "12px" }}>
                                        Defina quais formas vai se comunicar com os assinantes no portal.
                                    </small>
                                </CardTitle>
                                <GroupCheckBox checked={config?.push && 'checked'} name={`push`} id={`push`} value={true} col="12" label={`Notificações Push`} register={register} />
                                <GroupCheckBox checked={config?.portal_mail && 'checked'} name={`portal_mail`} id={`portal_mail`} value={true} col="12" label={`Portal Mail`} register={register} />
                                <GroupCheckBox checked={config?.chat && 'checked'} name={`chat`} id={`chat`} value={true} col="12" label={`Portal Chat`} register={register} />


                                <CardTitle className="mb-2 mt-4">
                                    SEO Portal
                                    <small className="d-block mt-2" style={{ "fontSize": "12px" }}>
                                        Defina as configurações do SEO e compartilhamento de páginas do Portal.
                                    </small>
                                </CardTitle>
                                <GroupInput name="title" col="12" required label="Titulo" placeholder="" register={register} />
                                <GroupText name="description" col="12" label="Descrição" register={register} />
                                <GroupInput name="keywords" col="12" label="Palavras-chave" placeholder="" register={register} />
                                <GroupInput name="url_app" col="12" required label="URL do Portal" placeholder="https://meuportal.com" register={register} />
                                <GroupInput name="cor_primaria" type="color" defaultValue={'#4e73df'} col="6" label="Cor Primária" register={register} />
                                <GroupInput name="cor_secundaria" type="color" defaultValue={'#858796'} col="6" label="Cor Secundária" register={register} />

                                <CardTitle className="mb-2 mt-4">
                                    Icones/Imagens
                                    <small className="d-block mt-2" style={{ "fontSize": "12px" }}>
                                        Defina os icones da página e a imagem de compartilhamento.
                                    </small>
                                </CardTitle>
                                <div className="col-12">
                                    <div className="row justify-content-center-">
                                        <div className="col-lg-12 mb-3 logo-manifest-share">
                                            <h4 className="mb-2"><small>Logo compartilhamento</small></h4>
                                            <FileSingle
                                                type="image"
                                                fileList={fileListImage}
                                                setFileList={setFileListImage}
                                                register={register}
                                                reset={reset}
                                                label=""
                                                name="image" />
                                        </div>
                                        <div className="col-lg-auto mb-3 icon-manifest">
                                            <h4 className="mb-2"><small>Icone padrão</small></h4>
                                            <FileSingle
                                                type="image"
                                                fileList={fileListIcon}
                                                setFileList={setFileListIcon}
                                                register={register}
                                                reset={reset}
                                                label=""
                                                name="icon" />
                                        </div>
                                        <div className="col-lg-auto mb-3 icon-manifest">
                                            <h4 className="mb-2"><small>Icone Dark</small></h4>
                                            <FileSingle
                                                type="image"
                                                fileList={fileListIconDark}
                                                setFileList={setFileListIconDark}
                                                register={register}
                                                reset={reset}
                                                label=""
                                                name="icon_dark" />
                                        </div>
                                        <div className="col-lg-auto mb-3 icon-manifest">
                                            <h4 className="mb-2"><small>Icone Light</small></h4>
                                            <FileSingle
                                                type="image"
                                                fileList={fileListIconLight}
                                                setFileList={setFileListIconLight}
                                                register={register}
                                                reset={reset}
                                                label=""
                                                name="icon_light" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 mt-4">
                                    <div className="form-group text-center">
                                        <ButtonSave type="submit" className="px-5" text="Atualizar Cadastro" />
                                    </div>
                                </div>
                            </CardContent>
                        </div>
                        <div className="col-lg-6">
                            <CardContent>
                                <CardTitle className="mb-2">
                                    Config. Manifest
                                    <small className="d-block mt-2" style={{ "fontSize": "12px" }}>
                                        Defina as configurações de identificação das páginas.
                                    </small>
                                </CardTitle>
                                <GroupInput name="name" col="12" required label="Nome" placeholder="" register={register} />
                                <GroupInput name="short_name" col="12" required label="Nome Abreviado" placeholder="" register={register} />
                                <GroupSelect name="display" col="12" label="Exibição (Display)" register={register}>
                                    <option value="">Selecione...</option>
                                    <option value="fullscreen">fullscreen</option>
                                    <option value="standalone">standalone</option>
                                    <option value="minimal-ui">minimal-ui</option>
                                    <option value="browser">browser</option>
                                </GroupSelect>
                                <GroupSelect name="orientation" col="12" label="Orientação" register={register}>
                                    <option value="">Selecione...</option>
                                    <option value="any">any</option>
                                    <option value="natural">natural</option>
                                    <option value="landscape">landscape</option>
                                    <option value="landscape-primary">landscape-primary</option>
                                    <option value="landscape-secondary">landscape-secondary</option>
                                    <option value="portrait">portrait</option>
                                    <option value="portrait-primary">portrait-primary</option>
                                    <option value="portrait-secondary">portrait-secondary</option>
                                </GroupSelect>
                                <GroupInput name="theme_color" type="color" defaultValue={'#4e73df'} col="6" label="Cor do Tema (Navegação)" register={register} />
                                <GroupInput name="background_color" type="color" defaultValue={'#4e73df'} col="6" label="Cor de Fundo (Loading)" register={register} />

                                <div className="col-12">
                                    <div className="row justify-content-center-">
                                        <div className="col-lg-auto mb-3 icon-manifest">
                                            <h4 className="mb-2"><small>Icone 16px</small></h4>
                                            <FileSingle
                                                type="image"
                                                fileList={fileListManIcon16}
                                                setFileList={setFileListManIcon16}
                                                register={register}
                                                reset={reset}
                                                label=""
                                                name="manifest_icon_16" />
                                        </div>
                                        <div className="col-lg-auto mb-3 icon-manifest">
                                            <h4 className="mb-2"><small>Icone 32px</small></h4>
                                            <FileSingle
                                                type="image"
                                                fileList={fileListManIcon32}
                                                setFileList={setFileListManIcon32}
                                                register={register}
                                                reset={reset}
                                                label=""
                                                name="manifest_icon_32" />
                                        </div>
                                        <div className="col-lg-auto mb-3 icon-manifest">
                                            <h4 className="mb-2"><small>Icone 64px</small></h4>
                                            <FileSingle
                                                type="image"
                                                fileList={fileListManIcon64}
                                                setFileList={setFileListManIcon64}
                                                register={register}
                                                reset={reset}
                                                label=""
                                                name="manifest_icon_64" />
                                        </div>
                                        <div className="col-lg-auto mb-3 icon-manifest">
                                            <h4 className="mb-2"><small>Icone 192px</small></h4>
                                            <FileSingle
                                                type="image"
                                                fileList={fileListManIcon192}
                                                setFileList={setFileListManIcon192}
                                                register={register}
                                                reset={reset}
                                                label=""
                                                name="manifest_icon_192" />
                                        </div>
                                        <div className="col-lg-auto mb-3 icon-manifest">
                                            <h4 className="mb-2"><small>Icone 512px</small></h4>
                                            <FileSingle
                                                type="image"
                                                fileList={fileListManIcon512}
                                                setFileList={setFileListManIcon512}
                                                register={register}
                                                reset={reset}
                                                label=""
                                                name="manifest_icon_512" />
                                        </div>
                                    </div>
                                </div>





                                <div className="col-12 mt-4">
                                    <div className="form-group">
                                        <ButtonSave type="submit" className="float-right px-5" />
                                    </div>
                                </div>
                            </CardContent>
                        </div>
                    </div>
                </form>
            </div>
        </>
    );
}


export default Configuracoes;