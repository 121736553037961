import React, { useEffect, useState } from "react";
import HeaderComponent from "../../../components/HeaderComponent";
import CONNECT_API from "../../../configs/connect";
import { useIsLoading } from "../../../contexts/LoadingContext";
import { ShowError } from "../../../configs/Functions";
import { DataCardBefore } from "../../../components/Dashboard/DataCards";
import { Link } from "react-router-dom";

export const AsaasLinks = props => {

    const [pg, setPg] = useState([]);
    const [menus, setMenus] = useState([]);
    const [conta, setConta] = useState([]);

    const { setIsLoading } = useIsLoading();


    async function get_data_page() {

        try {
            setIsLoading(true);

            const dados = await CONNECT_API.get(`/asaas`);
            var data = dados?.data;
            setMenus(data?.menus);
            setConta(data?.conta);
            setPg(data.pg);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }




    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);


    return (
        <div className="asaas-link">
            <HeaderComponent {...pg} classNameRow="align-items-center">
                <div className="btn-group-off" role="group">
                    {
                        menus?.map(m => {
                            return (
                                <Link
                                    key={m?.id}
                                    to={m?.url || '/'}
                                    className="btn bg-white ml-2 shadow-sm mb-lg-0 mb-2"
                                    style={{
                                        "color": `${m?.color ? m?.color : '#343a40'}`,
                                        "borderColor": `${m?.color ? m?.color : '#343a40'}`
                                    }}
                                >
                                    <i className={`las la-${m?.icon} mr-1`}></i>{m?.label}
                                </Link>
                            );
                        })
                    }
                </div>
            </HeaderComponent>
            <div className="row ">
                <div className="col-12">
                    <div className="row">
                        <div className="col-lg-3 pr-0">
                            <DataCardBefore type="success" value={conta?.cobrancas?.quantidade} title="Cobranças Lançadas" icon="check-circle" />
                        </div>
                        <div className="col-lg-3 pr-0">
                            <DataCardBefore type="danger" value={conta?.cobrancas?.valor} title="Total Cobranças Bruto" icon="times-circle" />
                        </div>
                        <div className="col-lg-3 pr-0">
                            <DataCardBefore type="info" value={conta?.cobrancas?.valor_liquido} title="Total Cobranças Liquido" icon="calculator" />
                        </div>
                        <div className="col-lg-3">
                            <DataCardBefore type="primary" value={conta?.saldo} title="Saldo em Conta" icon="coins" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AsaasLinks;