import './Login.css';
import React, { useEffect } from "react";
import api from "../../configs/api";
import { useForm } from 'react-hook-form';
import { GroupInput } from "../../components/Form";
import dashboard from '../../images/dashboard.png';
import bg0 from '../../images/login.jpg';
import bg1 from '../../images/login1.jpg';
import bg2 from '../../images/login2.jpg';
import bg3 from '../../images/login3.jpg';
import bg4 from '../../images/login4.jpg';
import { useIsLoading } from '../../contexts/LoadingContext';
import $ from 'jquery';
import { ShowError, existsOrError } from '../../configs/Functions';
const nameToken = process.env.REACT_APP_NAMETOKEN;

// LISTA DE IMAGENS DO LOGIN
const bgs = [bg0, bg1, bg2, bg3, bg4];
const bg = bgs[parseInt(Math.random() * (4 - 0) + 0)];

export const Login = props => {

    const { register, handleSubmit } = useForm();
    const { setIsLoading } = useIsLoading();

    async function onSubmit({ email, password }) {

        try {
            existsOrError(email && password, 'Informe seus dados de acesso!');

            setIsLoading(true);
            const user = await api.post('/login', { email, password });
            existsOrError(user?.data?.token, 'Ops! não saiu como esperado!');
            localStorage.setItem(nameToken, user.data.token);
            setIsLoading(false);
            window.location.reload();

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }

    }


    useEffect(() => {
        $('[name="defined-layout"]').remove();
        $('body').append(`
            <style name="defined-layout">
                :root{
                    --bg-primary: #1868b9;

                    --color-primary: #00427b;
                    --color-secondary: #1868b9;
                    --primary-color: #00427b;
                    --secondary-color: #1868b9;
                    
                    --btn-primary: #0d6efd;
                    --btn-secondary: #6c757d;
                    --btn-success: #198754;
                    --btn-danger: #dc3545;
                    --btn-warning: #ffc107;
                    --btn-info: #0dcaf0;
                    --btn-light: #f8f9fa;
                    --btn-dark: #212529;
                    --btn-link: #00000000;
                }
            </style>
        `);
        $('body').removeClass('loading').removeAttr('style');
    }, []);

    return (
        <div className="page-login--off">
            <div
                className="login d-flex align-items-center justify-content-center"
                style={{
                    "background": "url(" + bg + ") center center / cover",
                    "minHeight": "100vh"
                }}
            >
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col">
                            <div className="card" style={{ "maxWidth": "410px", "margin": "0 auto" }}>
                                <div className="card-body py-4 px-4">
                                    <div className="container-off">
                                        <div className="text-center mb-4">
                                            <img src={dashboard} alt="" width="70" height="70" className="" /> <br />
                                            <b>Você no Controle</b> <br />
                                            <small className="text-muted">Digite os seus dados de acesso</small>
                                        </div>
                                        <form action="#" onSubmit={handleSubmit(onSubmit)}>
                                            <div className="form-row justify-content-center">
                                                <GroupInput col="col-12" name="email" id="user" label="Email" autoComplete="email" autoFocus register={register} />
                                                <GroupInput col="col-12" type="password" name="password" id="password" autoComplete="current-password" label="Senha" register={register} />
                                                <div className="col-6">
                                                    <div className="form-group mb-0 mt-4">
                                                        <button className="btn btn-primary w-100">Entrar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );

}


export default Login;