import React, { useEffect, useState } from "react";
import { useIsLoading } from "../../../../contexts/LoadingContext";
import CONNECT_API from "../../../../configs/connect";
import { ShowError } from "../../../../configs/Functions";
import HeaderComponent from "../../../../components/HeaderComponent";
import { CardContent } from "../../../../components/Card-z";
import { TableFooter, TableHover, Tbody, Td, Th, Thead } from "../../../../components/Table-z";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { GroupInputSearch, GroupRadio } from "../../../../components/Form";

export const CieloRecorrencia = props => {

    const { setIsLoading } = useIsLoading();
    const { register, handleSubmit } = useForm();

    const [pg, setPg] = useState([]);
    const [recs, setRecs] = useState([]);
    const [pagination, setPagination] = useState([]);
    const [page, setPage] = useState(1);


    async function onSubmit(formData) {

        try {

            var situacao = Number(formData.situacao) ? 0 : 1; // se marcado então é as ativas
            var q = formData.q;

            var query = `?view=list&page=${page}`;
            if (situacao) query += `&cancelado=${situacao}`;
            if (q) query += `&q=${q}`;


            setIsLoading(true);
            const dados = await CONNECT_API.get(`/cielo/recorrencia${query}`);
            const data = dados?.data;
            setPg(data?.pg);
            setRecs(data?.recs);
            setPagination(data?.pagination);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }


    useEffect(() => {
        handleSubmit(onSubmit)();
        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {

        setTimeout(() => {
            if (document.querySelector(".page > div"))
                document
                    .querySelector(".page > div")
                    .scrollIntoView({ behavior: "smooth", block: "start" });
        }, 100);
        // eslint-disable-next-line
    }, [recs]);



    return (
        <div className="recorrencias">
            <HeaderComponent {...pg} />
            <div className="table-list">
                <CardContent>
                    <div className="col-12">
                        <form className="form pb-3 pt-2" onSubmit={handleSubmit(onSubmit)}>
                            <div className="row justify-content-end">
                                <div className="col-lg col-12 text-muted">
                                    <h1 className="h3 mb-lg-0 mb-4 text-gray-800">Recorrências Cielo</h1>
                                </div>
                                <div className="col-lg-auto col-12 mb-lg-0 mb-2">
                                    <div className="row h-100 align-items-center">
                                        <div className="col-lg-auto">Situação</div>
                                        <GroupRadio classNameGroup="mb-0" name={`situacao`} id={`situacao-1`} value="1" col="auto" label={`Ativa`} checked={"true"} register={register} />
                                        <GroupRadio classNameGroup="mb-0" name={`situacao`} id={`situacao-0`} value="0" col="auto" label={`Inativa`} register={register} />
                                    </div>
                                </div>
                                <GroupInputSearch
                                    name="q"
                                    placeholder="Nome, CPF, Matricula ..."
                                    col="4"
                                    classNameGroup="mb-lg-0"
                                    register={register}
                                />
                            </div>
                        </form>
                    </div>
                    <div className="col-12">
                        {recs?.length ?
                            <>
                                <TableHover className="mb-lg-0 text-center">
                                    <Thead
                                        th={[
                                            "Situação",
                                            "Filiado",
                                            "Dados do Cartão",
                                            "Dia da Cobrança",
                                            "Criada em/valor",
                                        ]}
                                    />
                                    <Tbody>
                                        {
                                            recs?.map(f => {
                                                var badge_cron = f?.cron_payment ? <small data-toggle="tooltip" title="Cartão Tokenizado" className="badge badge-success">Token</small> : '';
                                                return (
                                                    <tr key={f?.id}>
                                                        <Th>
                                                            <span
                                                                title={f?.cancelado ? 'Cancelado em ' + f?.cancelado : 'Recorrênica Ativa'}
                                                                data-toggle="tooltip"
                                                                className={`rounded-circle badge badge-${f?.cancelado ? "danger" : "success"
                                                                    } mx-auto p-2 d-inline-block`}
                                                            ></span>
                                                        </Th>
                                                        <Td>
                                                            <Link
                                                                to={f?.url_filiado || "/"}
                                                                className="btn btn-sm m-0 text-dark px-0 text-center"
                                                                title="Ver Cadastro do filiado"
                                                                data-toggle="tooltip"
                                                            >
                                                                {f?.nome} <br />
                                                                <small>{f?.matricula}</small> <br />
                                                                <small>{f?.cpf}</small>
                                                            </Link>
                                                        </Td>
                                                        <Td>
                                                            <Link
                                                                to={f?.url_rec || '/'}
                                                                className="btn btn-sm m-0 text-dark px-0 text-center"
                                                                title="Ver Cadastro completo da recorrência"
                                                                data-toggle="tooltip"
                                                            >
                                                                {f?.card_nome} <br />
                                                                <small>{f?.card_numero}</small> <br />
                                                                <small>{f?.card_bandeira}</small> <br />
                                                                <small>{f?.card_vencimento}</small> <br />
                                                                {badge_cron}
                                                            </Link>
                                                        </Td>
                                                        <Td>
                                                            {f?.dia_cobranca} 
                                                        </Td>
                                                        <Td>
                                                            {f?.created_at} <br />
                                                            {f?.valor}
                                                        </Td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </Tbody>
                                </TableHover>
                                <TableFooter
                                    {...pagination}
                                    setPage={setPage}
                                    page={page}
                                />
                            </>

                            :
                            <div className="text-center">
                                <hr className="mt-0" />
                                <i>Nada encontrado!</i>
                                <hr />
                            </div>
                        }
                    </div>
                </CardContent>
            </div>
        </div>
    );
}

export default CieloRecorrencia;