import '../styles/Bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import 'line-awesome/dist/line-awesome/css/line-awesome.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'jquery/dist/jquery.min.js';
import '@splidejs/react-splide/css';
import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './Routes';

import Header from '../components/layout/Header';
import NavBar from '../components/layout/NavBar';
import Footer from '../components/layout/Footer';
import Content from '../components/layout/Content';
import SpinnersComponent from '../components/Loading';
import { useIsLoading } from '../contexts/LoadingContext';
import $ from 'jquery';
import api from '../configs/api';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
const nameToken = process.env.REACT_APP_NAMETOKEN;

window.Swal = Swal;


function App() {

    const { isLoading, setIsNotificacao } = useIsLoading();

    function controlMenu() {
        if (window.innerWidth <= 992) {
            $('#content-page').removeClass('active show');
        }
    }


    function pushManagerSubscribe(registration, showError) {

        // SE NÃO ESTÁ BLOQUEADA ENTÃO PEDE PRA NOTIFICAR
        if (Notification.permission !== 'denied') {

            registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: 'BKHWTszxYQskjTzxwrSYZ3mIfUlWXq4rHLBxRxwITgGg1oysVWlz75i9-KiKkuHVYtjUFSsLHswTJ1rTilFJz4w'
            }).then(async (subscribe) => {
                // AQUI FAZ O POST E MANDA SALVA NO BANCO A CHAVE QUE AUTORIZO RECEBER NOTIFICAÇÕES
                await api.post('/subscription', { subscription: subscribe });
                $('#pwa-notification').remove();
            }).catch(err => {

                Swal.fire({
                    html: `
                        <img class="mt-4" src="/assets/icons/notification.png" width="50" height="50">
                        <h5 class="pt-2 font-weight-bold">Notificações Bloqueadas!</h5> 
                        <div class="text-center" style="font-size:16px">
                            <span class="text-danger">
                                Sem as notificações você pode perder atualizações importantes.
                            </span>
                        </div>
                    `,
                    showCancelButton: false,
                    showConfirmButton: false,
                })
            });

        } else if (showError) {

            Swal.fire({
                html: `
                    <img class="mt-4" src="/assets/icons/notification.png" width="50" height="50">
                    <h5 class="pt-2 font-weight-bold">Notificações Bloqueadas!</h5> 
                    <div class="text-center" style="font-size:16px">
                        Redefina as permissões do seu navegador clicando no <i class="las la-lock"></i> no canto superior direito do seu navegador 
                        ao lado do link do site (<i class="las la-lock"></i> ${document.location.host}).
                    </div>
                `,
                showCancelButton: false,
                showConfirmButton: false,
            })

        }

        return true;
    }

    useEffect(() => {

        if ('serviceWorker' in navigator) {

            // RECEBENDO MENSAGENS DO SERVER
            navigator.serviceWorker.addEventListener('message', async function (event) {

                var msg = event.data;

                switch (event.data.type) {
                    case 'network':
                        if (msg.online) {
                            // ONLINE
                            // toast.info('Você está online!');
                            $('#badge-off-line').removeClass('show');
                        } else {
                            // OFFLINE
                            if (!$('#badge-off-line').length)
                                $('#root').append(`
                                    <span id="badge-off-line" class="badge badge-danger rounded-0">
                                        Você está off-line!
                                    </span>
                                `);
                            // toast.info('Você está off-line!');
                            $('#badge-off-line').addClass('show');
                        }
                        break;
                    case 'push-notification-denied':
                        // AVISO DE NOVA NOTIFICAÇÃO
                        if (msg.notification) {
                            toast.info('Você tem uma nova notificação!');
                        }
                        break;
                    case 'push-notification-new':
                        // TEVE UMA NOVA NOTIFICAÇÃO, PODE REFAZER A REQUEST DE BADGE
                        const data = await api.get('/search/notificacao');
                        setIsNotificacao(data?.data?.notificacao);
                        break;
                    case 'no-cache':
                        // QUANDO ESTÁ OFFILINE E SEM CACHE
                        if (msg.page) {


                            Swal.fire({
                                html: `
                                    <img class="mt-4" src="/assets/icons/notification.png" width="70" height="70">
                                    <h5 class="pt-2 font-weight-bold">Ops!</h5> 
                                    <div class="" style="font-size:16px">
                                        Está página não está no cache e você encontra-se off-line.
                                    </div>
                                `,
                                customClass: {
                                    confirmButton: 'btn btn-primary px-5'
                                },
                                buttonsStyling: false,
                                showCancelButton: false,
                                confirmButtonText: 'Entendi'
                                // showConfirmButton: false,
                            }).then(e => {
                                document.querySelector('#content-page > .nav-bar .navbargroup-boby:first-child .nav-item:first-child > a').click();
                            });
                        }
                        break;
                    case 'token':
                        // MANDANDO O TOKEN PARA AS REQUESTS
                        navigator.serviceWorker?.registration?.active.postMessage({ token: localStorage.getItem(nameToken) || '' });
                        break;
                    default:
                }
            });


            // REGISTRANDO O SERVICEWORKER
            navigator.serviceWorker.register('/serviceWorker.min.js').then((registration) => {

                // SALVANDO OBJ
                navigator.serviceWorker.registration = registration;

                // MANDANDO O TOKEN PARA AS REQUESTS
                registration.active.postMessage({ token: localStorage.getItem(nameToken) || '' });

                // REGISTRO DAS NOTIFICAÇÕES
                if (Notification.permission !== 'granted') {
                    if (!$('#pwa-notification').length) {
                        $('#root').append(`
                            <div id="pwa-notification" class="" data-toggle="tooltip" data-placement="left" title="Ativar as Notificações">
                                <img src="assets/icons/notification.png" width="30" height="30"/>
                            </div>
                        `);
                        $('[data-toggle="tooltip"]').tooltip()
                    }

                    $('#pwa-notification').click(function () {
                        pushManagerSubscribe(registration, true);
                    })
                }

                pushManagerSubscribe(registration, false);

            });

            let deferredPrompt;
            window.addEventListener('beforeinstallprompt', (e) => {
                deferredPrompt = e;
                setTimeout(() => {
                    if (!$('#pwa-install').length) {
                        $('#dropdown-user-accont .list-group').prepend(`
                            <li class="list-group-item">
                                <a href="javascript:;" id="pwa-install" class="btn w-100 border-0 text-dark p-0 m-0">
                                    <div class="form-row align-items-center justify-content-center">
                                        <div class="col-auto">
                                            <i class="las la-cloud-download-alt" style="font-size: 40px;"></i> 
                                        </div>
                                        <div class="col-auto">
                                            Baixar e Instalar
                                        </div>
                                    </div>
                                </a>
                            </li>
                        `)


                        $('#pwa-install').click(function () {
                            if (deferredPrompt !== null) {
                                deferredPrompt.prompt();
                                deferredPrompt.userChoice.then((response) => {
                                    if (response.outcome === 'accepted') {
                                        deferredPrompt = null;
                                    }
                                });
                            }
                        });

                        // Swal.fire({
                        //     html: `
                        //         <img class="mt-4" src="/assets/icons/notification.png" width="70" height="70">
                        //         <h5 class="pt-2 font-weight-bold">Aplicativo de Controle Está Disponível!</h5> 
                        //         <div class="" style="font-size:16px">
                        //             Você pode instalar o app do sistema em seu dispositivo,<br>deseja fazer isso?
                        //         </div>
                        //         <button type="button" class="swal2-confirm btn btn-primary px-3 mt-4 mb-1" onclick="document.querySelector('#pwa-install').click(); Swal.close();">
                        //             <i class="las la-cloud-download-alt"></i> 
                        //             Baixar e Instalar
                        //         </button>
                        //     `,
                        //     showCancelButton: false,
                        //     showConfirmButton: false,
                        // })
                    }
                }, 500);
            });
        }
        else {
        }
        // eslint-disable-next-line
    }, []);


    useEffect(() => {
        if (!isLoading)
            $('[data-toggle="tooltip"]').tooltip();
        // eslint-disable-next-line
    }, [isLoading]);

    return (
        <Router>
            <div className="App">
                <Header />
                <div id="content-page" onClick={controlMenu} className={`d-flex content-page ${window.innerWidth > 992 ? 'active show' : ''}`} >
                    <NavBar />
                    <Content>
                        <SpinnersComponent />
                        <div className={`${isLoading ? 'loading-page h-100' : 'page h-100'}`} >
                            <Routes />
                        </div>
                    </Content>
                </div>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
