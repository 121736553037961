import "./OfficeDetail.css";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import { GroupInput, GroupSelect, GroupText } from "../../components/Form";
import { useIsLoading } from "../../contexts/LoadingContext";
import { CardContent, CardTitle } from "../../components/Card-z";
import CONNECT_API from "../../configs/connect";
import { existsOrError, TextSlug } from "../../configs/Functions";
import { toast } from "react-toastify";
import HeaderComponent from "../../components/HeaderComponent";
import ButtonSave from "../../components/ButtonComponent/ButtonSave";

export const OfficeDetail = (props) => {
    const [cargo, setCargo] = useState([]);
    const [setor, setSetor] = useState([]);
    const [menus, setMenus] = useState([]);
    const [pg, setPg] = useState([]);

    const { register, reset, handleSubmit } = useForm();
    const { setIsLoading, isCollaborator } = useIsLoading();

    //
    // Monta os menus na permissão
    //
    function menu_tree(m) {
        const mp = m.map((menu) => {
            const ul = (
                <ul key={menu.id}>
                    <li
                        className={
                            menu.sub_menu.length
                                ? "sub_menu d-block"
                                : "menu d-block"
                        }
                    >
                        <div className="form-group">
                            <div className="custom-control custom-checkbox">
                                <input
                                    name="menus"
                                    defaultChecked={menu.permitido && "checked"}
                                    {...register("menus")}
                                    type="checkbox"
                                    className={"custom-control-input"}
                                    id={`${TextSlug(menu.label)}-menu-${
                                        menu.id
                                    }`}
                                    value={menu.id}
                                />
                                <label
                                    className="custom-label custom-control-label"
                                    htmlFor={`${TextSlug(menu.label)}-menu-${
                                        menu.id
                                    }`}
                                >
                                    {menu.label}
                                </label>
                            </div>
                        </div>
                        {menu_tree(menu.sub_menu)}
                    </li>
                </ul>
            );

            return ul;
        });
        return mp;
    }

    /**
     * Funções de apoio do componente
     */
    async function get_data_page() {
        try {
            setIsLoading(true);

            const id = props.match.params.id;
            const { data } = await CONNECT_API.get(`/empresa/cargo/${id}`);
            existsOrError(data, "Não foi possivel carregar os dados!");

            setCargo(data.cargo);
            setSetor(data.setor);
            setMenus(data.menu_tree);
            setPg(data.pg);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    //Carregamnento dos dados
    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    // reset
    useEffect(() => {
        if (!cargo) return false;

        reset({
            situacao: cargo.situacao ? 1 : 0,
            nome: cargo.nome,
            codigo: cargo.codigo,
            setor: cargo.setor_id,
            atribuicoes: cargo.atribuicao,
        });

        // eslint-disable-next-line
    }, [cargo]);

    useEffect(() => {
        if (!pg) return;
        document.title = `SGA - ${pg.title}`;
        // eslint-disable-next-line
    }, [pg]);

    async function onSubmit(data) {
        try {
            existsOrError(data.nome, "Nome não informado!");

            const fields = {
                menus: data.menus,
                nome: data.nome,
                setor: data.setor,
                situacao: data.situacao,
            };
            setIsLoading(true);
            await CONNECT_API.put(`${pg.end_point}/${cargo.id}`, { ...fields });

            // Atualizando os dados da página
            get_data_page();
            setIsLoading(false);
        } catch (error) {
            toast.error(error);
        }
    }

    return (
        <div className="detalhes-do-cargo">
            <HeaderComponent {...pg}>
                <ButtonSave
                    className=" float-right px-5"
                    onClick={handleSubmit(onSubmit)}
                />
            </HeaderComponent>
            <form action="#" onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                    <CardTitle>Cadastro</CardTitle>
                    <GroupInput
                        name="nome"
                        col="10"
                        label="Nome"
                        register={register}
                    />
                    <GroupSelect
                        name="situacao"
                        col="2"
                        label="Situação"
                        register={register}
                    >
                        <option value="0">Inativo</option>
                        <option value="1">Ativa</option>
                    </GroupSelect>
                    <GroupInput
                        disabled="disabled"
                        name="codigo"
                        col="6"
                        label="Código"
                        register={register}
                    />
                    <GroupSelect
                        name="setor"
                        col="6"
                        label="Setor"
                        register={register}
                    >
                        {cargo.setor_id ? (
                            <option value={cargo.setor_id}>
                                {cargo.setor}
                            </option>
                        ) : (
                            <option defaultValue="Selecione..." disabled={true}>
                                Selecione...
                            </option>
                        )}
                        {setor.map((s) => {
                            return (
                                <option key={s.id} value={s.id}>
                                    {s.nome}
                                </option>
                            );
                        })}
                    </GroupSelect>
                    <GroupText
                        name="atribuicoes"
                        col="12"
                        label="Atribuições"
                        register={register}
                    />
                </CardContent>
                {isCollaborator?.admin && (
                    <CardContent>
                        <CardTitle>Permissões</CardTitle>
                        <div className="col-12 lista-de-menus">
                            {menus.map((md) => {
                                return (
                                    <div
                                        className={`card modulo modulo-${TextSlug(
                                            md.nome
                                        )}`}
                                        key={md.id}
                                    >
                                        <div
                                            className="card-header"
                                            data-toggle="collapse"
                                            data-target={`#md-${TextSlug(
                                                md.nome
                                            )}`}
                                            aria-controls={`md-${TextSlug(
                                                md.nome
                                            )}`}
                                        >
                                            <h4 className="mb-0">
                                                <small>
                                                    <b>{md.nome}</b>
                                                </small>
                                            </h4>
                                        </div>
                                        <div
                                            id={`md-${TextSlug(md.nome)}`}
                                            className="collapse"
                                        >
                                            <div className="card-body">
                                                {menu_tree(md.menu)}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </CardContent>
                )}
                <ButtonSave type="submit" className="float-right px-5" />
            </form>
        </div>
    );
};

export default withRouter(OfficeDetail);
