import React from "react";
import { useForm } from "react-hook-form";
import ButtonSave from "../../../../../components/ButtonComponent/ButtonSave";
import { CardContent, CardTitle } from "../../../../../components/Card-z";
import CONNECT_API from "../../../../../configs/connect";
import { existsOrError, ShowError } from "../../../../../configs/Functions";
import { useIsLoading } from "../../../../../contexts/LoadingContext";
import { Modal } from "../../../../../components/Modal-z";
import { GroupSelect } from "../../../../../components/Form";


export const ModalAddResp = props => {

    const { handleSubmit, reset, register } = useForm();
    const { setIsLoading } = useIsLoading();

    if (!props.leadId) return null;

    const resp = props.colaboradores;


    // START LAYOUT MODAL
    async function onSubmit(formData) {

        const { responsavel } = formData;
        
        try {
            
            existsOrError(responsavel, "Responsável não informado!");
            setIsLoading(true);
            await CONNECT_API.post(`/leads/${props.leadId}/responsavel`, { responsavel });
            setIsLoading(false);

            if (props?.changedLoad) await props.changedLoad();

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    // Fechamento do modal
    function modalHide() {
        reset({});
    }


    return (
        <div className="ModalAddResp">
            <Modal sm={true} modalId={props?.modalId} modalHide={modalHide} text="Responsável" className="btn btn-success btn-sm" icon="plus-circle" >
                <form action="" name="cron" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <CardTitle>
                            Novo Responsável
                        </CardTitle>
                        <GroupSelect name="responsavel" col="12" label="Responsável" register={register}>
                            {
                                resp?.map(r => {
                                    return <option key={r?.id} value={r?.id}>{r?.nome}</option>
                                })
                            }
                        </GroupSelect>
                        <div className="col-12">
                            <ButtonSave type="submit" text="Vincular" className="mt-4 float-right" />
                        </div>
                    </CardContent>
                </form>
            </Modal>
        </div>
    )
}


export default ModalAddResp;