import React from "react";
import { useIsLoading } from "../../contexts/LoadingContext";
import "./styles/Footer.css";
import { Link } from "react-router-dom";

const Footer = props => {

    const { isCompany } = useIsLoading();
    return (
        <div className="footer-page container-fluid d-lg-block d-none">
            <div className="row justify-content-between align-items-center h-100 text-center text-lg-left">
                <div className="col-lg-auto col-12" style={{ fontFamily: "sans-serif" }}>
                    <small>
                        Copyright © {new Date().getFullYear()} <Link to={{ pathname: "https://esvsolucoes.com.br" }} target="_blank">SGA by ESV Soluções</Link>. Todos os direitos reservados.
                    </small>
                </div>
                <div className="col-lg-auto col-12"><small>{isCompany?.razao_social}</small></div>
            </div>
        </div>
    )
}

export default Footer;