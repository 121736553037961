import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ButtonSave from "../../../../components/ButtonComponent/ButtonSave";
import { CardContent, CardTitle } from "../../../../components/Card-z";
import { GroupInput } from "../../../../components/Form";
import { Modal } from "../../../../components/Modal-z";
import CONNECT_API from "../../../../configs/connect";
import { existsOrError, ShowError } from "../../../../configs/Functions";
import { Redirect } from 'react-router-dom';
import { useIsLoading } from "../../../../contexts/LoadingContext";


export const ModalAddLink = props => {

    const { handleSubmit, reset, register } = useForm();
    const { setIsLoading } = useIsLoading();

    const [linkId, setLinkId] = useState(undefined);




    async function onSubmit(formData) {

        try {
            existsOrError(formData.nome, 'Nome não informado!');
            setIsLoading(true);
            var data = await CONNECT_API.post('/vendas-online/meus-links', { nome: formData.nome });
            data = data?.data;
            setLinkId(data?.link?.id);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }



    if (linkId) return <Redirect to={`/vendas-online/meus-links/${linkId}`} />
    return <Modal modalId="modal-novo-link" text="Novo Link" icon="plus" className="btn btn-success" sm={true} modalHide={() => reset()}>
        <CardContent>
            <CardTitle>
                Novo Link
            </CardTitle>
            <div className="col-12">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        <GroupInput name="nome" col="12" required label="Nome" register={register} />
                    </div>
                    <div className="form-group text-center">
                        <ButtonSave type="submit" text="Criar Link" icon="link" className="px-5" />
                    </div>
                </form>
            </div>
        </CardContent>
    </Modal>
}

export default ModalAddLink;