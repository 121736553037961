import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import CardLink from "../../components/CardLinks";
import HeaderComponent from "../../components/HeaderComponent";
import CONNECT_API from "../../configs/connect";
import { existsOrError } from "../../configs/Functions";
import { useIsLoading } from "../../contexts/LoadingContext";

export const FinanceiraLinks = props => {


    const [pg, setPg] = useState([]);
    const [financeira, setFinanceira] = useState([]);

    const { setIsLoading } = useIsLoading();

    async function get_data_page() {

        try {

            setIsLoading(true);
            const id = props.match.params.id;
            const { data } = await CONNECT_API.get(`/financeira/${id}`);
            existsOrError(data, 'Não foi possivel carregar os dados!');

            setFinanceira(data?.financeira);
            setPg(data?.pg);

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }



    ///////
    // START
    ///////


    //Carregamnento dos dados
    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="financeira">
            <HeaderComponent {...pg} />
            <div className="row">
                <div className="col-lg-8">
                    <div className="row">
                        {
                            financeira?.menus?.map(e => {
                                return (
                                    <div className="col-lg-4 col-md-auto col-12" key={e.id}>
                                        <CardLink {...e} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(FinanceiraLinks);
