import React, { useEffect, useState } from "react";
import { useIsLoading } from "../../../../contexts/LoadingContext";
import CONNECT_API from "../../../../configs/connect";
import { ShowError, existsOrError, swalConfirm } from "../../../../configs/Functions";
import HeaderComponent from "../../../../components/HeaderComponent";
import { CardContent } from "../../../../components/Card-z";
import { TableFooter, TableHover, Tbody, Td, Th, Thead } from "../../../../components/Table-z";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { GroupInput, GroupInputSearch, GroupSelect } from "../../../../components/Form";

//////////////
// DATA LOCAL DO FILTRO
//////////////
const data_local = new Date();
const hoje = data_local
    .toLocaleDateString("pt-BR")
    .split("/")
    .reverse()
    .join("-");

export const CieloOperacao = props => {

    const { setIsLoading } = useIsLoading();
    const { register, handleSubmit } = useForm();

    const [pg, setPg] = useState([]);
    const [pays, setPays] = useState([]);
    const [colaboradores, setColaboradores] = useState([]);
    const [pagination, setPagination] = useState([]);
    const [page, setPage] = useState(1);


    async function onSubmit(formData) {

        try {

            var situacao = formData.situacao; // se marcado então é as ativas
            var q = formData.q;
            var data_in = formData.data_in;
            var data_out = formData.data_out;
            var colaborador_id = formData.colaborador_id;

            var query = `?view=list&page=${page}`;
            if (situacao) query += `&situacao=${situacao}`;
            if (data_in) query += `&din=${data_in}`;
            if (data_out) query += `&dout=${data_out}`;
            if (colaborador_id) query += `&cid=${colaborador_id}`;
            if (q) query += `&q=${q}`;


            setIsLoading(true);
            const dados = await CONNECT_API.get(`/cielo/operacao${query}`);
            const data = dados?.data;
            setPg(data?.pg);
            setPays(data?.operacoes);
            setColaboradores(data?.colaboradores);
            setPagination(data?.pagination);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    async function onChargeBack(PaymentId, OperationId) {

        try {

            existsOrError(PaymentId, 'Pagamento não informado!');
            existsOrError(OperationId, 'Operação não informada!');
            // MODAL DE CONFIRMAÇÃO
            const confirm = await swalConfirm({
                icon: 'warning',
                title: 'Atenção',
                html: `
                    <div>
                        Deseja realizar o estorno neste momento?<br> 
                        <small>
                            A cobrança vai ser cancelada e o valor estornado no cartão de crédito.<br>
                            Essa cobrança não tem vinculo com algum pagamento?<br>
                            Caso tenha vinculo com pagamento deve ser estornado na sessão do financeiro!
                        </small>
                    </div>`,
                btnText: 'Sim',
                btnText2: 'Não',
            });
            if (!confirm.isConfirmed) return false;
            //

            setIsLoading(true);
            await CONNECT_API.put(`/cielo/operacao`, { acao: 'chargeBack', PaymentId, OperationId });
            setIsLoading(false);

            handleSubmit(onSubmit)();

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }


    useEffect(() => {
        handleSubmit(onSubmit)();
        // eslint-disable-next-line
    }, [page]);

    useEffect(() => {

        setTimeout(() => {
            if (document.querySelector(".page > div"))
                document
                    .querySelector(".page > div")
                    .scrollIntoView({ behavior: "smooth", block: "start" });
        }, 100);
        // eslint-disable-next-line
    }, [pays]);



    return (
        <div className="operacoes-cielo">
            <HeaderComponent {...pg} />
            <div className="table-list">
                <CardContent>
                    <div className="col-12">
                        <form className="form pb-3 pt-2" onSubmit={handleSubmit(onSubmit)}>
                            <div className="row justify-content-end">
                                <div className="col-lg col-12 text-muted">
                                    <h1 className="h3 mb-lg-0 mb-4 text-gray-800">Operações Cielo</h1>
                                </div>
                                <GroupSelect
                                    name="colaborador_id"
                                    col="auto mb-lg-0 mb-2"
                                    label="Colaborador"
                                    classe={`pr-4`}
                                    register={register}
                                >
                                    {colaboradores?.map((r) => {
                                        return (
                                            <option value={r.id} key={r.id}>
                                                {r.nome}
                                            </option>
                                        );
                                    })}
                                    <option value="">Todos</option>
                                </GroupSelect>
                                <GroupSelect
                                    name="situacao"
                                    col="auto mb-lg-0 mb-2"
                                    label="Situação"
                                    defaultValue="sucesso"
                                    classe={`pr-4`}
                                    register={register}
                                >
                                    <option value="sucesso">Sucesso</option>
                                    <option value="error">Error</option>
                                    <option value="charge-back">Charge Back</option>
                                </GroupSelect>
                                <GroupInput
                                    name="data_in"
                                    type="date"
                                    defaultValue={hoje}
                                    label="Data inicio"
                                    col="auto"
                                    register={register}
                                />
                                <GroupInput
                                    name="data_out"
                                    type="date"
                                    defaultValue={hoje}
                                    label="Data Fim"
                                    col="auto"
                                    register={register}
                                />
                                <GroupInputSearch
                                    name="q"
                                    placeholder="Nome ..."
                                    col="2"
                                    classNameGroup="mb-lg-0"
                                    register={register}
                                />
                            </div>
                        </form>
                    </div>
                    <div className="col-12">
                        {pays?.length ?
                            <>
                                <TableHover className="mb-lg-0 text-center">
                                    <Thead
                                        th={[
                                            "Status",
                                            "Dados do Cartão",
                                            "Dia da Cobrança",
                                            "Valor",
                                            "Ação",
                                        ]}
                                    />
                                    <Tbody>
                                        {
                                            pays?.map(f => {


                                                return (
                                                    <tr key={f?.id} className={f?.charge_back ? 'text-danger' : 'text-muted'} title={f?.charge_back ? 'Pagamento Estornado' : ''}>
                                                        <Th>
                                                            <span
                                                                title={`#${f?.id} - ${f?.return}`} data-toggle="tooltip"
                                                                className={`rounded-circle badge badge-${f?.success ? "success" : "danger"
                                                                    } mx-auto p-2 d-inline-block`}
                                                            ></span>
                                                        </Th>
                                                        <Td>
                                                            <Link
                                                                to={{ pathname: f?.url_pay || '' }}
                                                                className={`btn btn-sm m-0 px-0 text-center ${f?.charge_back ? 'text-danger' : 'text-muted'} ${!!!f?.url_pay ? 'disabled' : ''}`}
                                                            >
                                                                <b>{f?.cliente}</b> <br />
                                                                {!!f?.card?.card_nome &&
                                                                    <>
                                                                        <small>{f?.card?.card_nome}</small> <br />
                                                                        <small>{f?.card?.card_numero}</small> <br />
                                                                        <small>{f?.card?.card_bandeira}</small> <br />
                                                                        <small>{f?.card?.card_vencimento}</small>
                                                                    </>
                                                                }
                                                            </Link>
                                                        </Td>
                                                        <Td>
                                                            {f?.created_at}
                                                        </Td>
                                                        <Td>
                                                            {f?.valor}
                                                            {
                                                                f?.charge_back
                                                                    ?
                                                                    <><br /><small className="text-danger">{f?.valor_charge_back}</small></>
                                                                    : ''
                                                            }
                                                        </Td>
                                                        <Td>
                                                            {
                                                                <button
                                                                    className={`btn btn-sm btn-outline-danger  ${(!!!f?.url_pay || !f?.hab_charge_back) ? 'disabled' : ''}`}
                                                                    type="button"
                                                                    onClick={(f?.payment_id && f?.hab_charge_back) ? () => onChargeBack(f?.payment_id, f?.id) : () => false}
                                                                >
                                                                    Estornar
                                                                </button>

                                                            }
                                                        </Td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </Tbody>
                                </TableHover>
                                <TableFooter
                                    {...pagination}
                                    setPage={setPage}
                                    page={page}
                                />
                            </>

                            :
                            <div className="text-center">
                                <hr className="mt-0" />
                                <i>Nada encontrado!</i>
                                <hr />
                            </div>
                        }
                    </div>
                </CardContent>
            </div>
        </div>
    );
}

export default CieloOperacao;