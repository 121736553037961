import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { GroupInput, GroupSelect } from "../../components/Form";
import { useIsLoading } from "../../contexts/LoadingContext";
import { CardContent, CardTitle } from "../../components/Card-z";
import CONNECT_API from "../../configs/connect";
import { existsOrError, SetNumber } from "../../configs/Functions";
import { toast } from 'react-toastify';
import HeaderComponent from '../../components/HeaderComponent';
import ButtonSave from '../../components/ButtonComponent/ButtonSave';
import ImputMask from 'inputmask';

export const SetorById = props => {

    const [setor, setSetor] = useState([]);
    const [pg, setPg] = useState([]);


    const { register, reset, handleSubmit } = useForm();
    const { setIsLoading } = useIsLoading();


    /**
     * Funções de apoio do componente
     */
    async function get_data_page() {
        try {
            setIsLoading(true);

            const id = props.match.params.id;
            const { data } = await CONNECT_API.get(`/empresa/setor/${id}`);
            existsOrError(data, 'Não foi possivel carregar os dados!');

            setSetor(data.setor);
            setPg(data.pg);

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }


    //Carregamnento dos dados
    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    // reset 
    useEffect(() => {

        if (!setor) return false;

        reset({
            situacao: setor.situacao ? 1 : 0,
            nome: setor.nome,
            codigo: setor.codigo,
            email: setor.email,
            tel_fixo: setor.tel_fixo,
            tel_whatsapp: setor.tel_whatsapp
        });

        // eslint-disable-next-line
    }, [setor]);


    // Mask
    useEffect(() => {
        ImputMask({ "mask": '(99) 99999-9999' }).mask('[type="tel"]');
        ImputMask({ "mask": '99.999-999' }).mask('[name="cep"]');
    }, []);


    async function onSubmit(data) {
        try {
            existsOrError(data.nome, 'Nome não informado!');

            const fields = {
                nome: data.nome,
                situacao: data.situacao,
                email: data.email,
                tel_fixo: SetNumber(data.tel_fixo),
                tel_whatsapp: SetNumber(data.tel_whatsapp)
            }
            setIsLoading(true);
            await CONNECT_API.put(`${pg.end_point}`, { ...fields });

            // Atualizando os dados da página
            get_data_page();
            setIsLoading(false);

        } catch (error) {
            toast.error(error);
        }
    }


    return (
        <div className="detalhes-do-cargo">
            <HeaderComponent {...pg}>
                <ButtonSave className=" float-right px-5" onClick={handleSubmit(onSubmit)} />
            </HeaderComponent>
            <form action="#" onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                    <CardTitle>
                        Cadastro
                    </CardTitle>
                    <GroupInput name="nome" col="6" label="Nome" register={register} />
                    <GroupInput disabled="disabled" name="codigo" col="4" label="Código" register={register} />
                    <GroupSelect name="situacao" col="2" label="Situação" register={register}>
                        <option value="0">Inativo</option>
                        <option value="1">Ativa</option>
                    </GroupSelect>
                    <GroupInput name="email" col="6" type="email" label="E-mail" register={register} />
                    <GroupInput name="tel_fixo" col="3" type="tel" label="Telefone" register={register} />
                    <GroupInput name="tel_whatsapp" col="3" type="tel" label="Whatsapp" register={register} />
                    <div className="col-12 mt-4">
                        <div className="form-group">
                            <ButtonSave type="submit" className="float-right px-5" />
                        </div>
                    </div>
                </CardContent>
            </form>
        </div>
    );

}

export default withRouter(SetorById);