import React, { useEffect, useState } from "react";
import { useIsLoading } from '../../contexts/LoadingContext';
import { existsOrError } from "../../configs/Functions";
import CONNECT_API from "../../configs/connect";
import CardLink from "../../components/CardLinks";
import { toast } from "react-toastify";
import HeaderComponent from "../../components/HeaderComponent";


export const Company = props => {

    const [menus, setMenus] = useState([]);
    const [pg, setPg] = useState([]);
    const { setIsLoading } = useIsLoading();


    async function get_data_page() {

        try {
            setIsLoading(true);

            const { data } = await CONNECT_API.get('/empresa');
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setMenus(data.menus);
            setPg(data.pg);

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }



    //Carregamnento dos dados
    useEffect(() => {
        get_data_page()
        // eslint-disable-next-line
    }, []);

    return (
        <div className="empresas">
            <HeaderComponent {...pg} />
            <div className="row ">
                <div className="col-lg-8">
                    <div className="row">
                        {
                            menus?.map(m => {
                                return (
                                    <div className="col-lg-4 col-md-auto  col-12" key={m.id}>
                                        <CardLink {...m} />
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Company;