import React from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';

export const HeaderComponent = props => {
    function onLinkClick() {
        $('.modal').modal('hide');
        $('.collapse').collapse('hide');

        if (!props.url && !props.link) window.history.back();
    }

    const { vf } = props;

    return (
        <div className='cmp-header-page'>
            <div className={`row justify-content-between ${props.classNameRow?props.classNameRow:''}`}>
                <div className="col-lg-auto">
                    <div className="form-row align-items-end">
                        <div className="col-lg-auto">
                            <Link to={props?.url || props?.link || '/'} className={"p-0 m-0 btn w-100"} style={{ "color": props?.color || '#343a40' }} onClick={onLinkClick} >
                                <div className="form-row align-items-center text-left">
                                    <div className="col-auto">
                                        <i style={vf === "sm" ? { "fontSize": "50px" } : { "fontSize": "80px" }} className={`las la-${props?.icon}`}></i>
                                    </div>
                                    <div className="col">
                                        <h4 className='mb-0' style={vf === "sm" ? { "fontSize": "20px" } : {}} >{props.title || props.label || props.nome}</h4>
                                        <small>{props.sub_title}</small>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-lg-auto">
                            <div className="parent pb-3 text-lg-left text-right">
                                {props.parent}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg d-lg-block d-flex justify-content-end text-right">
                    {props.children}
                </div>
            </div>
            <div className={`text-right ${props.info_pg ? "" : "mb-3"}`}>
                <small>{props.info_pg}</small>
            </div>
            <hr className='mb-4 mt-0' />
        </div>
    )
}


export default HeaderComponent;