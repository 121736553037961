import React, { useEffect, useState } from "react";
import CONNECT_API from "../../../../../configs/connect";
import { ShowError, existsOrError } from "../../../../../configs/Functions";
import { useIsLoading } from "../../../../../contexts/LoadingContext";
import { Modal } from "../../../../../components/Modal-z";
import { CardContent } from "../../../../../components/Card-z";
import { GroupCheckBox, GroupInput, GroupRadio, GroupText } from "../../../../../components/Form";
import ButtonSave from "../../../../../components/ButtonComponent/ButtonSave";
import { useForm } from "react-hook-form";


export const ModalNotificacao = props => {

    const notify = props.notify || null;
    const { setIsLoading } = useIsLoading();
    const [notificacao, setNotificacao] = useState([]);
    const { register, handleSubmit, reset } = useForm();

    async function onSubmit(formData) {

        try {

            if (!Number(formData.id)) existsOrError(Number(props.terminal_id), 'Terminal não informado!');

            setIsLoading(true);
            formData.terminal_id = props.terminal_id;
            const ok = await CONNECT_API.put(`/filiado/configuracao?get=notificacao-pagamento-terminal&notify=${formData.id}`, { ...formData });
            setIsLoading(false);

            if (ok) {
                reset();
                if( formData.id ) get_data_page(formData.id);
                if (typeof (props.loaded) == "function") props.loaded();
            }

        } catch (error) {

            setIsLoading(false);
            ShowError(error);
        }
    }


    // titulo do modal
    var titulo = notify ? 'Editando notificação!' : 'Adicionando uma nova notificação!';

    async function get_data_page(id) {

        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/filiado/configuracao?get=notificacao-pagamento-terminal&notify=${id}`);
            setNotificacao(data.notificacao);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    useEffect(() => {

        if (props.notify) {
            reset();
            get_data_page(props.notify);
        }
        // eslint-disable-next-line
    }, [props.notify]);


    useEffect(() => {
        reset({ ...notificacao });
        // eslint-disable-next-line
    }, [notificacao]);


    const page = (
        <div className="modal-notificacao" style={{ "fontSize": "14px" }}>
            <form action="" onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                    <div className="col-12 text-center mb-3">
                        {notificacao.id ? '#' + notificacao.id : ''} {titulo}
                        <hr />
                    </div>
                    <div className="col-12 mb-3">
                        <div className="row">
                            <div className="col-auto">
                                <div className="mb-1">Notificação Ativa</div>
                            </div>
                            <GroupRadio name={`ativo`} id={`ativo-1`} value="1" col="auto" label={`Sim`} checked={notificacao?.ativo && "true"} register={register} />
                            <GroupRadio name={`ativo`} id={`ativo-0`} value="0" col="auto" label={`Não`} checked={!notificacao?.ativo && "true"} register={register} />
                        </div>
                    </div>
                    <GroupInput name="title" label="Título da Notificação" col="12" required register={register} />
                    <GroupInput name="days" type="number" label="Dias [Antes/Depois] do vencimento" required col="12" register={register} />
                    <GroupCheckBox name="after" id="after" value="1" col="12" label="Antes do Vencimento" register={register} />
                    <GroupCheckBox name="before" id="before" value="1" col="12" label="Depois do Vencimento" register={register} />


                    <GroupText name="msg" label="Mensagem da Notificação" row="8" col="12" required register={register} />
                    <div className="col-12 mb-3" style={{ "marginTop": "-20px" }}>
                        <small>
                            Váriaveis disponivel: <br />
                            <ul>
                                <li>%primeiro nome% = Primeiro Nome do cliente</li>
                                <li>%nome% = Nome do cliente</li>
                                <li>%valor% = Valor do lançamento</li>
                                <li>%vencimento% = Data do vencimento</li>
                                <li>%data cobranca agendada% = Data que está agendada a cobrança</li>
                                <li>%info pagamento% = Informações da forma de pagamento</li>
                            </ul>
                        </small>
                    </div>
                    <GroupInput name="mail_from" label="E-mail de Origem (Opcional)" col="12" register={register} />
                    {/* <GroupCheckBox name="attachment" id="attachment" value="1" col="12" label="Enviar os anexos disponíveis no cadastro do lançamento" register={register} /> */}
                    <div className="col-12">
                        <ButtonSave type="submit" className="float-right mt-4" />
                    </div>
                </CardContent>
            </form>
        </div>
    )

    return <Modal modalId={props.modalId || "modal-notificacao-terminal"} text={props.label || ''} icon={props.icon || ''} className={props.btn_class || ''} sm="true">
        {page}
    </Modal>
}

export default ModalNotificacao;