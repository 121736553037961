import React from "react";
import { Link } from "react-router-dom";
import { formatDate } from "../../../../../configs/Functions";
import CredCard from "../../../../md_4_pagamento_integrado/cielo/apoio/CredCard";

export const CardTerminalConfigFiliado = (props) => {
    let terminal = props.terminal;

    return (
        <>
            <div className="terminal mb-3">
                <div className="text-muted">
                    <b>
                        <i
                            className="las la-money-check"
                            style={{
                                color: "var(--terminal)",
                            }}
                        ></i>{" "}
                        TERMINAL
                    </b>
                    <hr className="mt-1 mb-2" />
                </div>

                {terminal?.card && (
                    <Link
                        to={terminal?.card?.card_url || "/"}
                        target="_blank"
                        className="p-0 btn btn-sm w-100 text-left text-muted mb-3"
                        title="Ver detalhes da recorrência"
                    >
                        <CredCard
                            cvc={0}
                            expiry={terminal?.card?.card_vencimento}
                            focus={""}
                            name={terminal?.card?.card_nome}
                            number={terminal?.card?.card_numero}
                        />
                    </Link>
                )}
                <div className="text-muted mb-1">
                    {terminal?.terminal} → {terminal?.banco}{" "}
                    <small className="float-right" title="Data do Cadastro">
                        {formatDate(terminal?.created_at)}
                    </small>
                </div>
            </div>
        </>
    );
};

export default CardTerminalConfigFiliado;
