import $ from 'jquery';
import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';

export const Modal = props => {

    const [show, setShow] = useState(false);

    function onClick() {
        if (props.onClick) props.onClick();
    }


    useEffect(() => {


        // EVENTO AO ESCONDER O MODAL
        $(`#${props.modalId}`).on('hide.bs.modal', async function (e) {
            setShow(false);
            if (props?.modalHide) await props.modalHide();
        });

        // EVENTO AO MOSTRAR O MODAL
        $(`#${props.modalId}`).on('show.bs.modal', async function (e) {
            setShow(true);
            if (props?.modalShow) await props.modalShow();
        });

        // eslint-disable-next-line
    }, [props.modalId]);

    useEffect(() => {
        if (props?.show) $(`#${props.modalId}`).modal('show');
        // eslint-disable-next-line
    }, [props?.show]);

    const modal = (
        <div className="cmp-modal">
            <div className="modal fade" data-backdrop="static" id={props.modalId} tabIndex="-1" role="dialog" aria-labelledby={props.modalId} aria-hidden="true">
                <div className={`modal-dialog ${props.sm ? 'modal-md' : 'modal-lg'}`} style={props.style} role="document">
                    <div className="modal-content" style={{ "background": "transparent", "border": "0" }}>
                        <div className="container-modal-button text-right" style={{
                            "position": "absolute",
                            "top": "10px",
                            "right": "10px",
                            "zIndex": "1020",
                        }}>
                            <div className="close" onClick={() => $(`#${props.modalId}`).modal('hide')} title="Fechar" style={{
                                "margin": "0",
                                "width": "40px",
                                "height": "40px",
                                "borderRadius": "100%",
                                "opacity": "1",
                                "display": "flex",
                                "alignItems": "center",
                                "justifyContent": "center",
                                "cursor": "pointer",
                                "fontSize": "30px",
                                "color": "#000",
                                "background": "#fff"
                            }}>
                                <i className="las la-times"></i>
                            </div>
                        </div>
                        <div className="modal-body p-0 m-0 text-left">
                            <div className="card-off">
                                <div className="card-body--off">
                                    {
                                        show
                                            ?
                                            props.children
                                            : 'Modal Hide'
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <div className="cmp-modal">
                {
                    (props.text || props.icon) && <button
                        className={`btn ${props.className}`}
                        title={`${props.title || ''}`}
                        data-toggle="modal" data-target={`#${props.modalId}`}
                        onClick={onClick}
                    >
                        <i className={`las la-${props.icon}`}></i> {props.text}
                    </button>
                }
            </div>
            {ReactDOM.createPortal(modal, document.body)}
        </>
    );
}

export const BtnModal = props => {
    return (
        <button
            className={`btn ${props.className}`}
            title={`${props.title}`}
            data-toggle="modal" data-target={`#${props.modalId}`}
            onClick={props.onClick}
        >
            <i className={`las la-${props.icon}`}></i> {props.text}
        </button>
    );
}

// eslint-disable-next-line
export default { Modal, BtnModal }