import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import CONNECT_API from "../../../configs/connect";
import { existsOrError } from "../../../configs/Functions";
import { useIsLoading } from "../../../contexts/LoadingContext";
import HeaderComponent from "../../../components/HeaderComponent";
import CardLink from "../../../components/CardLinks";
import { toast } from "react-toastify";

// props.match.params.{AQUI};

export const PlanoLinks = props => {

    const { setIsLoading } = useIsLoading();
    const [links, setLinks] = useState([]);
    const [pg, setPg] = useState([]);

    // PARAMS
    const plano_id = props.match.params.id;

    async function get_data_page() {

        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/plano/${plano_id}`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setLinks(data?.links);
            setPg(data.pg);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }

    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="plano-links">
            <HeaderComponent {...pg} />
            <div className="row ">
                <div className="col-lg-8">
                    <div className="row">
                        {
                            links?.map(m => {
                                return (
                                    <div className="col-lg-4 col-md-auto col-12" key={m.id}>
                                        <CardLink {...m} />
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(PlanoLinks);