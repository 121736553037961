import React from "react";

export const PageNot = props => {

    return (
        <div className="pagina-nao-encontrada h-100">
            <div className="h-100" style={{ "maxWidth": "500px", "margin": "0 auto" }}>
                <div className="row h-100 align-items-center text-center">
                    <div className="col-auto">
                        <i className="las la-exclamation-triangle text-danger" style={{ "fontSize": "80px" }}></i>
                    </div>
                    <div className="col text-left">
                        <h4>
                            Página não encontrada!
                            <br /><small>Verifique o link que está tentando acessar.</small>
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default PageNot;