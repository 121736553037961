import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useForm } from 'react-hook-form';
import HeaderComponent from "../../../../components/HeaderComponent";
import CONNECT_API from "../../../../configs/connect";
import { existsOrError, ShowError } from "../../../../configs/Functions";
import { useIsLoading } from "../../../../contexts/LoadingContext";
import ButtonSave from "../../../../components/ButtonComponent/ButtonSave";
import { CardContent, CardTitle } from "../../../../components/Card-z";
import { GroupCheckBox } from "../../../../components/Form";

// props.match.params.{AQUI};

export const PlanoConfiguracaoById = props => {

    // plano, intervalo_recorrencia, cartoes, pg
    const { setIsLoading } = useIsLoading();
    const { register, handleSubmit } = useForm();

    const [terminais, setTerminais] = useState([]);
    const [beneficios, setBeneficios] = useState([]);
    const [pg, setPg] = useState([]);

    // PARAMS
    const plano_id = props.match.params.id;

    async function get_data_page() {

        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/plano/${plano_id}/configuracao`);
            existsOrError(data, 'Não foi possivel carregar os dados!');

            setTerminais(data?.terminais);
            setBeneficios(data?.beneficios);
            setPg(data.pg);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    async function onSubmit(data) {
        try {

            const terminal = data?.terminal;
            const beneficio = data?.beneficio;
            existsOrError(terminal, "Defina um terminal para o plano!");

            setIsLoading(true);
            const ok = await CONNECT_API.post(pg?.end_point, { terminal, beneficio });
            if (ok) await get_data_page();
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error)
        }

    }

    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);


    return (
        <div className="plano">
            <HeaderComponent {...pg} />
            <form action="" onSubmit={handleSubmit(onSubmit)} >
                <div className="row">
                    <div className="col-12 col-lg-4 col-md-auto mb-4">
                        <CardContent classNameCard="h-100 mb-0">
                            <CardTitle>
                                Terminais de pagamento
                            </CardTitle>
                            <div className="col-12 mb-2">
                                <small>
                                    Selecione os terminais aceitos neste plano.
                                </small>
                            </div>
                            {
                                terminais?.map(t => {
                                    return <GroupCheckBox
                                        key={t?.id}
                                        checked={t?.permitido && 'checked'}
                                        name={`terminal`}
                                        id={`${t?.banco} → ${t?.terminal}`}
                                        value={t?.id}
                                        col="12"
                                        label={`${t?.banco} → ${t?.terminal}`}
                                        register={register} />
                                })
                            }
                            <div className="col-12 mt-4">
                                <div className="form-group">
                                    <ButtonSave type="submit" className="float-right px-5" />
                                </div>
                            </div>
                        </CardContent>
                    </div>

                    <div className="col-12 col-lg-4 col-md-auto mb-4">
                        <CardContent classNameCard="h-100 mb-0">
                            <CardTitle>
                                Benefícios do plano
                            </CardTitle>
                            <div className="col-12 mb-2">
                                <small>
                                    Selecione os benefícios liberado para o plano.
                                </small>
                            </div>
                            {
                                beneficios?.map(t => {
                                    return <GroupCheckBox
                                        key={t?.id}
                                        checked={t?.ativo && 'checked'}
                                        name={`beneficio`}
                                        id={`option-${t?.id}`}
                                        value={t?.id}
                                        col="12"
                                        label={t?.nome}
                                        register={register} />
                                })
                            }
                            <div className="col-12 mt-4">
                                <div className="form-group">
                                    <ButtonSave type="submit" className="float-right px-5" />
                                </div>
                            </div>
                        </CardContent>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default withRouter(PlanoConfiguracaoById);