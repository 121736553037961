import React from "react";
import { useForm } from "react-hook-form";
import ButtonSave from "../../../../../components/ButtonComponent/ButtonSave";
import { CardContent, CardTitle } from "../../../../../components/Card-z";
import CONNECT_API from "../../../../../configs/connect";
import { existsOrError, ShowError } from "../../../../../configs/Functions";
import { useIsLoading } from "../../../../../contexts/LoadingContext";
import { Modal } from "../../../../../components/Modal-z";
import { GroupSelect, GroupText } from "../../../../../components/Form";


export const ModalPutSituacao = props => {

    const { handleSubmit, reset, register } = useForm();
    const { setIsLoading } = useIsLoading();

    if (!props.leadId) return null;

    const status = props?.status;


    // START LAYOUT MODAL
    async function onSubmit(formData) {

        const { situacao, text } = formData;


        try {
            existsOrError(situacao, "Nova situação não informada!");
            existsOrError(text, "Faça um breve relato da mudança de situação do Lead!");

            setIsLoading(true);
            await CONNECT_API.put(`/leads/${props.leadId}/situacao`, { situacao, text });
            setIsLoading(false);

            if (props?.changedLoad) await props.changedLoad();

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    // Fechamento do modal
    function modalHide() {
        reset({});
    }



    return (
        <div className="ModalPutSituacao">
            <Modal sm={true} modalId={props?.modalId} modalHide={modalHide} >
                <form action="" name="cron" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <CardTitle>
                            Situação
                        </CardTitle>
                        <GroupSelect name="situacao" required col="12" label="Situação" register={register}>
                            {
                                status?.map(s => <option key={s?.id} value={s?.id}>{s?.nome}</option>)
                            }
                        </GroupSelect>
                        <GroupText row="4" name="text" col="12" label="Descrição" register={register} />
                        <div className="col-12">
                            <ButtonSave type="submit" className="mt-4 float-right" />
                        </div>
                    </CardContent>
                </form>
            </Modal>
        </div>
    )
}


export default ModalPutSituacao;