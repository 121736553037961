import React, { useEffect, useState } from "react";
import HeaderComponent from "../../../../components/HeaderComponent";
import { useIsLoading } from "../../../../contexts/LoadingContext";
import CONNECT_API from "../../../../configs/connect";
import { ShowError } from "../../../../configs/Functions";
import { Link, withRouter } from "react-router-dom";
import ModalAddCtt from "./ModalAddCtt";
import ModalAddBloco from "./ModalAddBloco";
import ModalEditBloco from "./ModalEditBloco";
import ModalEditCtt from "./ModalEditCtt";
import { Splide, SplideSlide } from '@splidejs/react-splide';

// HOME DA PÁGINA ANÚNCIOS
export const PainelPlanoMenus = props => {


    const { setIsLoading } = useIsLoading();
    const plano_id = props.match.params.idp;
    const menu_id = Number(props.match.params.idm);

    const [pg, setPg] = useState([]);
    const [menu, setMenu] = useState({});
    const [blocos, setBlocos] = useState([]);

    async function get_data_page() {
        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/portal-do-filiado/painel?plano=${plano_id}&menu=${menu_id}`);
            setMenu(data?.menu);
            setBlocos(data?.blocos);
            setPg(data?.pg);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);


    return (
        <>
            <div className="painel">
                <HeaderComponent {...pg} />
                <div className="row justify-content-center">
                    <div className="col-lg-9">
                        <div className="jumbotron py-4 px-4">
                            <div className="jumbotron-body">
                                <div className="" style={{ fontSize: "20px" }}>
                                    <i className={`las la-${menu?.icon}`}></i> {menu?.label}
                                </div>
                                <small className="text-muted">Link: {menu?.url}</small>
                            </div>
                        </div>
                        <div className="container-page">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className=" d-flex justify-content-end mb-3">
                                                <ModalAddBloco plano={plano_id} menu={menu_id} get_data_page={get_data_page} />
                                            </div>
                                        </div>
                                        {
                                            blocos?.map(b => {

                                                const type = b.type;
                                                const config = b.config[type];
                                                switch (type) {
                                                    case 'Card':
                                                        return <BlocCard key={b.id} {...config} {...b} get_data_page={get_data_page} />
                                                    case 'Container':
                                                        return <BlocContainer key={b.id} {...config} {...b} get_data_page={get_data_page} />
                                                    case 'Slide':
                                                        return <BlocSlide key={b.id} {...config} {...b} get_data_page={get_data_page} />
                                                    default:
                                                        return true;
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}


const BlocCard = props => {

    return (
        <div className="col-12 mb-3 bloco bloco-card">
            <div className="card mb-0">
                <div className="card-body">
                    <div className="row">
                        <Conteudos {...props} />
                    </div>
                </div>
            </div>
        </div>
    );
}

const BlocContainer = props => {

    return (
        <div className="col-12 mb-3 bloco bloco-container">
            <div className="row">
                <Conteudos {...props} />
            </div>
        </div>
    );
}

const BlocSlide = props => {

    return (
        <ConteudosSlide {...props} />
    );
}

const Conteudos = props => {

    const col_lg = props.colLg;
    const col_sm = props.colSm;
    const mb = props.mb;
    const bloco = props.id;
    const get_data_page = props.get_data_page
    const conteudos = props.conteudos;

    return (
        <>
            <div className={`col-12 mb-${mb} ${props.class}`} name={props.name}>
                <div className="row justify-content-between">
                    <div className="col">
                        {props?.or} - Bloco: {props.name} - {props.type}
                    </div>
                    <div className="col-auto d-flex">
                        <ModalAddCtt bloco={bloco} get_data_page={get_data_page} />
                        <ModalEditBloco bloco={props} get_data_page={get_data_page} />
                    </div>
                </div>
                <hr />
                <div className="row">
                    {
                        conteudos?.map(ctt => {

                            const type = ctt?.type;
                            const config = ctt.config[type];
                            switch (type) {
                                case 'Link':
                                    return (
                                        <div key={ctt.id} className={`col-lg-${col_lg} col-${col_sm}`}>
                                            <div className="border b-dashed ctt">
                                                <ModalEditCtt ctt={ctt} get_data_page={get_data_page} />
                                                <ContLink {...ctt} config={config} />
                                            </div>
                                        </div>
                                    )
                                case 'Imagem':
                                    return (
                                        <div key={ctt.id} className={`col-lg-${col_lg} col-${col_sm}`}>
                                            <div className="border b-dashed ctt">
                                                <ModalEditCtt ctt={ctt} get_data_page={get_data_page} />
                                                <ContImagem {...ctt} config={config} />
                                            </div>
                                        </div>
                                    )
                                case 'Título':
                                    return (
                                        <div key={ctt.id} className={`col-lg-${col_lg} col-${col_sm}`}>
                                            <div className="border b-dashed ctt">
                                                <ModalEditCtt ctt={ctt} get_data_page={get_data_page} />
                                                <ContTitulo {...ctt} config={config} />
                                            </div>
                                        </div>
                                    )
                                case 'Texto':
                                    return (
                                        <div key={ctt.id} className={`col-lg-${col_lg} col-${col_sm}`}>
                                            <div className="border b-dashed ctt">
                                                <ModalEditCtt ctt={ctt} get_data_page={get_data_page} />
                                                <ContTexto {...ctt} config={config} />
                                            </div>
                                        </div>
                                    )
                                default:
                                    return (<small></small>)
                            }
                        })
                    }
                </div>
            </div>
        </>
    );
}

const ConteudosSlide = props => {

    const mb = props.mb;
    const bloco = props.id;
    const get_data_page = props.get_data_page
    const conteudos = props.conteudos;

    return (
        <>
            <div className={`col-12 mb-${mb} ${props?.class} bloco bloco-slide`} name={props?.name}>
                <div className="row justify-content-between">
                    <div className="col">
                        Bloco: {props?.name} - {props?.type}
                    </div>
                    <div className="col-auto d-flex">
                        <ModalAddCtt bloco={bloco} get_data_page={get_data_page} />
                        <ModalEditBloco bloco={props} get_data_page={get_data_page} />
                    </div>
                </div>
                <hr />
                <Splide
                    aria-label={props.name}
                    options={{
                        type: props.viewType || 'loop',
                        autoplay: props.autoPlay || true,
                        perPage: props.lgPerPage || 1,
                        breakpoints: {
                            780: {
                                perPage: props.smPerPage || 2,
                            },
                        }
                    }}
                >
                    {
                        conteudos?.map(ctt => {

                            const type = ctt?.type;
                            const config = ctt.config[type];
                            switch (type) {
                                case 'Link':
                                    return (
                                        <SplideSlide key={ctt.id} className="px-2">
                                            <div className="border b-dashed ctt h-100">
                                                <ModalEditCtt ctt={ctt} get_data_page={get_data_page} />
                                                <ContLink {...ctt} config={config} />
                                            </div>
                                        </SplideSlide>
                                    )
                                case 'Imagem':
                                    return (
                                        <SplideSlide key={ctt.id} className="px-2">
                                            <div className="border b-dashed ctt h-100">
                                                <ModalEditCtt ctt={ctt} get_data_page={get_data_page} />
                                                <ContImagem {...ctt} config={config} />
                                            </div>
                                        </SplideSlide>
                                    )
                                case 'Título':
                                    return (
                                        <SplideSlide key={ctt.id} className="px-2">
                                            <div className="border b-dashed ctt h-100">
                                                <ModalEditCtt ctt={ctt} get_data_page={get_data_page} />
                                                <ContTitulo {...ctt} config={config} />
                                            </div>
                                        </SplideSlide>
                                    )
                                case 'Texto':
                                    return (
                                        <SplideSlide key={ctt.id} className="px-2">
                                            <div className="border b-dashed ctt h-100">
                                                <ModalEditCtt ctt={ctt} get_data_page={get_data_page} />
                                                <ContTexto {...ctt} config={config} />
                                            </div>
                                        </SplideSlide>
                                    )
                                default:
                                    return (<SplideSlide></SplideSlide>)
                            }
                        })
                    }
                </Splide>
            </div>
        </>
    );
}

const ContLink = props => {

    const config = props.config || {};
    return (
        <>
            <div className={`ctt-link ${config?.class}`}>
                <Link to={config?.link} target={config?.target} name={props.name}>
                    {config?.title || <span className="text-muted">Não informado</span>}
                </Link>
            </div>
        </>
    )
}
const ContImagem = props => {
    const config = props?.config || {};

    const imagem = (
        <div className="img-container">
            <img src={config?.fileImage?.url} alt={config?.name} className={`${config?.display ? config?.display : ''} img-fluid`} />
        </div>
    );

    const legenda = (
        <div className="legenda">
            <span>{config?.legenda}</span>
        </div>
    );

    const title = (
        <div className="titulo">
            <b className="mb-1">{config?.title}</b>
        </div>
    )

    return (
        <>
            <div className={`ctt-imagem ${config?.class}`}>
                {
                    config?.link ? (
                        <Link to={config?.link} target={config?.target} name={props.name}>
                            {config?.fileImage?.url ? imagem : ''}
                            {config?.title ? title : ''}
                            {config?.legenda ? legenda : ''}
                        </Link>
                    ) : (
                        <>
                            {config?.fileImage?.url ? imagem : ''}
                            {config?.title ? title : ''}
                            {config?.legenda ? legenda : ''}
                        </>
                    )
                }
            </div>
        </>
    )
}
const ContTexto = props => {
    const config = props.config || {};

    const texto = (
        <div className="texto-container">
            {config?.texto}
        </div>
    );

    return (
        <>
            <div className={`ctt-texto ${config?.class}`}>
                {
                    config?.link ? (
                        <Link to={config?.link} target={config?.target} name={props.name}>
                            {texto}
                        </Link>
                    ) : (
                        <>
                            {texto}
                        </>
                    )
                }
            </div>
        </>
    )
}
const ContTitulo = props => {
    const config = props.config || {};

    const title = (
        <h2 className="mb-0">
            {config?.title}
            <br />
            <small>{config?.subTitle}</small>
        </h2>
    );

    return (
        <>
            <div className={`ctt-link ${config?.class}`}>
                {
                    config?.link ? (
                        <Link to={config?.link} target={config?.target} name={props.name}>
                            {title}
                        </Link>

                    ) : (
                        <>
                            {title}
                        </>
                    )
                }
            </div>
        </>
    )
}


export default withRouter(PainelPlanoMenus);