import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { useForm } from 'react-hook-form';
import HeaderComponent from "../../../../components/HeaderComponent";
import CONNECT_API from "../../../../configs/connect";
import { existsOrError, formatCoinNotCurrency, SetCoin } from "../../../../configs/Functions";
import { useIsLoading } from "../../../../contexts/LoadingContext";
import ButtonSave from "../../../../components/ButtonComponent/ButtonSave";
import { CardContent, CardTitle } from "../../../../components/Card-z";
import { GroupInput, GroupSelect, GroupText } from "../../../../components/Form";

// props.match.params.{AQUI};

export const PlanoById = props => {

    // plano, intervalo_recorrencia, cartoes, pg
    const { setIsLoading } = useIsLoading();
    const { register, reset, handleSubmit } = useForm();

    const [plano, setPlano] = useState([]);
    const [intervaloRecorrencia, setIntervaloRecorrencia] = useState([]);
    const [cartoes, setCartoes] = useState([]);
    const [pg, setPg] = useState([]);

    // PARAMS
    const plano_id = props.match.params.id;

    async function get_data_page() {

        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/plano/${plano_id}/editar`);
            existsOrError(data, 'Não foi possivel carregar os dados!');

            setPlano(data?.plano);
            setIntervaloRecorrencia(data?.intervalo_recorrencia);
            setCartoes(data?.cartoes);
            setPg(data.pg);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }

    async function onSubmit(data) {

        try {


            const {
                situacao,
                nome,
                codigo,
                cartao_id,
                intervalo_recorrencia_id,
                desc,
                publicTitle,
                publicDescription,
                autoRenovation
            } = data;
            const valor = SetCoin(data.valor);
            const valor_adesao = SetCoin(data.valor_adesao);
            const valor_comissao = SetCoin(data.valor_comissao);
            const vigencia = data.vigencia;

            existsOrError(nome, "Nome não informado!");
            existsOrError(codigo, "Código não informado!");
            existsOrError(intervalo_recorrencia_id, "Recorrência não informada!");
            existsOrError(vigencia, "Vigência não informada!");

            const fields = {
                nome,
                situacao,
                codigo,
                cartao_id,
                intervalo_recorrencia_id,
                vigencia,
                valor,
                valor_adesao,
                valor_comissao,
                desc,
                publicTitle,
                publicDescription,
                autoRenovation
            }

            setIsLoading(true);

            const ok = await CONNECT_API.put(pg?.end_point, { ...fields });

            if (ok) await get_data_page();

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }

    }

    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!plano) return false;

        plano.valor = formatCoinNotCurrency(plano?.valor);
        plano.valor_adesao = formatCoinNotCurrency(plano?.valor_adesao);
        plano.valor_comissao = formatCoinNotCurrency(plano?.valor_comissao);

        reset({ ...plano })

        // eslint-disable-next-line
    }, [plano]);

    return (
        <div className="plano">
            <HeaderComponent {...pg}>
                <ButtonSave className=" float-right px-5" onClick={handleSubmit(onSubmit)} />
            </HeaderComponent>
            <form action="" onSubmit={handleSubmit(onSubmit)} >
                <CardContent>
                    <CardTitle>
                        Cadastro
                    </CardTitle>
                    <GroupInput name="codigo" required col="2" label="Código" register={register} />
                    <GroupInput name="nome" required col="6" label="Nome" register={register} />
                    <GroupSelect name="situacao" col="2" label="Situação" register={register}>
                        <option value="0">Inativo</option>
                        <option value="1">Ativa</option>
                    </GroupSelect>
                    <GroupSelect name="autoRenovation" col="2" label="Renovação Automática" register={register}>
                        <option value="1">Sim</option>
                        <option value="0">Não</option>
                    </GroupSelect>
                    <GroupSelect name="cartao_id" col="6" label="Cartão" register={register}>
                        {plano?.cartao_id
                            &&
                            <option value={plano?.cartao_id}>{plano?.cartao}</option>
                        }
                        {
                            cartoes?.map(c => {
                                return <option value={c?.id} key={c?.id}>{c?.nome}</option>
                            })
                        }
                        <option value=''>Nenhum</option>
                    </GroupSelect>
                    <GroupSelect name="intervalo_recorrencia_id" col="4" label="Tipo de recorrência" register={register}>
                        {plano?.intervalo_recorrencia_id
                            &&
                            <option value={plano?.intervalo_recorrencia_id}>{plano?.recorrencia}</option>
                        }
                        {
                            intervaloRecorrencia?.map(c => {
                                return <option value={c?.id} key={c?.id}>{c?.nome}</option>
                            })
                        }
                        <option value=''>Sem recorrência</option>
                    </GroupSelect>
                    <GroupInput name="vigencia" type="number" col="2" label="Vigência" register={register} />
                    <GroupInput name="valor" col="4" label="Valor" register={register} />
                    <GroupInput name="valor_adesao" col="4" label="Valor da adesão" register={register} />
                    <GroupInput name="valor_comissao" col="4" label="Valor da comissão" register={register} />
                    <GroupText name="desc" col="12" label="Descrição (interna)" register={register} />
                    <div className="col-12 mt-3 mb-3">
                        <div className="row justify-content-between">
                            <div className="col-auto">
                                <h4>Informações exibidas no portal</h4>
                            </div>
                            <div className="col-auto">
                                <button type="button" className="btn btn-secondary" disabled> <i className="las la-edit"></i> Contrato</button>
                            </div>
                        </div>
                        <hr />
                    </div>
                    <GroupInput name="publicTitle" col="12" label="Título do plano" register={register} />
                    <GroupText name="publicDescription" col="12" row="4" label="Descrição do plano" register={register} />
                    <div className="col-12 mt-4">
                        <div className="form-group">
                            <ButtonSave type="submit" className="float-right px-5" />
                        </div>
                    </div>
                </CardContent>
            </form>
        </div>
    );
}

export default withRouter(PlanoById);