import React, { useEffect, useState } from "react";
import $ from 'jquery';
import './input.css';
import api from "../../../configs/api";
import { toast } from "react-toastify";
import { existsOrError, ShowError } from "../../../configs/Functions";
import CONNECT_API from "../../../configs/connect";
import { CardContent } from "../../Card-z";
import { useIsLoading } from '../../../contexts/LoadingContext';

const FileSingle = props => {

    const {
        fileList,
        setFileList
    } = props;

    const nameInput = props?.name || 'files';

    const [fileUploadProgress, setFileUploadProgress] = useState(0);

    const { setIsLoading } = useIsLoading();

    function click_btn_file(e) {
        let btn = e.target;
        let container = $(btn).closest('.dropzone-container');
        let file = container.find('[type="file"]');
        file.click();
    }

    function upload_file(e) {

        try {


            //
            // Function base request api
            //
            // FILE → Arquivo para upload.

            let file_upload = e.target.files[0];
            if (!file_upload) new Error('Anexo não informado na requisição!');




            // TIPO DE ARQUIVO ESPERADO
            if (props.type) {

                if (file_upload?.type?.indexOf(props.type) === -1) {
                    ShowError('Arquivo não suportado!');
                    return false;
                }
            }

            api.defaults.headers['Content-Type'] = `multipart/form-data`;
            const data_file = new FormData();
            data_file.append("file", file_upload);

            let file_upload_ = {
                nome: file_upload.name,
                preview: URL.createObjectURL(file_upload),
                file: file_upload,
                progress: 0
            };

            setIsLoading(true);
            api.post("/files", data_file, {
                onUploadProgress: e => {
                    const progress = parseInt(Math.round((e.loaded * 100) / e.total));
                    setFileUploadProgress(progress);
                }
            })
                .then(response => {

                    const file = response.data.file;
                    setFileList(fileList.concat({ ...file_upload_, ...file, uploaded: true }));
                    e.target.value = '';
                    setFileUploadProgress(0);

                    setIsLoading(false);
                })
                .catch(() => {
                    toast.error('Falha ao carregar o arquivo');
                    setIsLoading(false);
                    setFileUploadProgress(0);
                });

            toast.success('Upload realizado com sucesso!');

        } catch (error) {

            if (!error?.response) {
                ShowError(typeof (error) === 'string' ? error : 'Tente novamente mais tarde!');
            } else {
                const msg = error.response.data;
                ShowError(msg);
            }
        }
    }

    // Define no input o valor do arr de arquivos
    async function set_file_id_input() {

        // Definindo no input o array de files.
        const files_uploads = [];
        /*if (typeof (fileList) != 'object') {
            files_uploads.push(fileList);
            return false;
        };*/

        fileList?.map(file => files_uploads.push(file.id));

        props.reset({
            [nameInput]: files_uploads
        })
        return true;
    }

    async function delete_file(id) {
        try {

            existsOrError(id, 'Arquivo não localizado!');
            setIsLoading(true);
            await CONNECT_API.del(`/files/${id}`);
            $('.modal').modal('hide');
            setIsLoading(false);

            setFileList(
                fileList?.filter(file => file.id !== id)
            );


        } catch (error) {
            toast.error(error);
            setIsLoading(false);
        }
    }

    async function update_file(id) {
        try {

            existsOrError(id, 'Arquivo não localizado!');

            const name = $(`[name="input-name-${id}"]`).val();

            const fields = {
                nome: name,
            }

            setIsLoading(true);
            await CONNECT_API.put(`/files/${id}`, { ...fields });
            setIsLoading(false);

            setFileList(fileList?.filter((file, i) => {
                if (file.id === id) {
                    file.nome = name;
                }
                return true;
            }));

        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }

    useEffect(() => {
        set_file_id_input();
        // eslint-disable-next-line
    }, [fileList]);


    return (
        <div className="dropzone single">
            <div className="card-off">
                <div className="card-body-off">
                    <div className="dropzone-container">
                        {<div className="dropzone-progress">
                            <div className="load-progress" style={{ "width": `${fileUploadProgress}%` }}></div>
                        </div>}
                        <div className="icon-file">
                            <small>{props?.label}</small> <i className="las la-image text-muted"></i>
                        </div>
                        <div className="container-itens h-100">
                            <div
                                className="btn btn-success btn-file-upload"
                                onClick={click_btn_file}>
                                <i className="la la-upload "></i>
                                Enviar Arquivo
                            </div>
                            <input type="hidden" name={nameInput} className="input-dropzone" {...props.register(nameInput)} />
                            <input type="file" onChange={upload_file} className="dropzone_input_file" />
                            {
                                fileList?.map(i => {

                                    const img = <img
                                        className="img-thumbnail img-fluid rounded img-dropzone p-2"
                                        src={i?.url}
                                        alt={i?.nome}
                                    />


                                    const modal = <div className="modal-dropzone">
                                        <div className="modal fade" id={`modal-dropzone-file-${i?.id}`} tabIndex="-1" role="dialog" aria-labelledby={`modal-dropzone-file-${i?.id}`} aria-hidden="true">
                                            <div className="modal-dialog modal-lg" role="document">
                                                <div className="modal-content" style={{ "background": "transparent", "border": "0" }}>
                                                    <div className="modal-body p-0">
                                                        <div className="card">
                                                            <div className="card-body--off">
                                                                <CardContent>
                                                                    <div className="col-12">
                                                                        <div className="cpm text-center">{img}</div>
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <div className="edit py-4">
                                                                            <div className="group mb-4">
                                                                                <ul className="p-0 mb-2 text-muted">
                                                                                    <li className={i?.colaborador ? 'd-block' : 'd-none'}><b>Enviado por: </b>{i?.colaborador}</li>
                                                                                    <li className={i?.empresa ? 'd-block' : 'd-none'}><b>Empresa: </b>{i?.empresa}</li>
                                                                                    <li className={i?.tipo ? 'd-block' : 'd-none'}><b>Tipo do arquivo: </b>{i?.tipo}</li>
                                                                                    <li className={i?.criado ? 'd-block' : 'd-none'}><b>Arquivo criado em: </b>{i?.criado}</li>
                                                                                    <li className={i?.alterado ? 'd-block' : 'd-none'}><b>Arquivo alterado em: </b>{i?.alterado}</li>
                                                                                </ul>
                                                                            </div>
                                                                            <div className={`custom-group group-input`}>
                                                                                <div className={`form-group`}>
                                                                                    <input
                                                                                        name={`input-name-${i?.id}`}
                                                                                        type={'text'}
                                                                                        className={`form-control form-control-lg`}
                                                                                        id={`input-name-${i?.id}`}
                                                                                        preenchido="true"
                                                                                        defaultValue={i?.nome}
                                                                                    />
                                                                                    <label className="custom-label" htmlFor={``}>Nome do arquivo</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="buttons text-center">
                                                                                {
                                                                                    ((i?.tipo?.indexOf('text') > -1) || (i?.tipo?.indexOf('aplication') > -1)) && <a
                                                                                        href={i?.url} target="_blank"
                                                                                        rel="noreferrer"
                                                                                        className="btn btn-warning mr-2">
                                                                                        <i className="las la-download"></i> Baixar/Ampliar
                                                                                    </a>
                                                                                }
                                                                                <div
                                                                                    onClick={() => delete_file(i?.id)}
                                                                                    className="btn btn-danger mr-2">
                                                                                    <i className="las la-trash-alt"></i> Deletar
                                                                                </div>
                                                                                <div
                                                                                    onClick={() => update_file(i?.id)}
                                                                                    className="btn btn-success">
                                                                                    <i className="las la-save"></i> Salvar
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </CardContent>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    return (
                                        <div key={i?.id} className="h-100 d-flex align-items-center justify-content-center">
                                            <div className="bg-file-edit"></div>
                                            <div className="btn btn-file btn-del" onClick={() => delete_file(i?.id)}>
                                                <i className="las la-times"></i>
                                            </div>
                                            <div className="btn btn-file btn-edit" data-toggle="modal" data-target={`#modal-dropzone-file-${i?.id}`}>
                                                <i className="las la-edit"></i>
                                            </div>
                                            <div className="img-container text-center img-thumbnail rounded h-100 d-flex align-items-center justify-content-center" >
                                                <img
                                                    className="img-fluid img-dropzone"
                                                    src={i?.url}
                                                    alt={i?.nome}
                                                />
                                                <figcaption className="figure-caption" data-toggle="modal" data-target={`#modal-dropzone-file-${i?.id}`}>
                                                    <div className="btn btn-link">
                                                        <i className="las la-edit"></i> Ver/Editar
                                                    </div>
                                                </figcaption>
                                            </div>
                                            {modal}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FileSingle;