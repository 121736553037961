import React, { useEffect, useState } from "react";
import CONNECT_API from "../../configs/connect";
import { existsOrError, formatMatricula, ShowError } from "../../configs/Functions";
import { useIsLoading } from "../../contexts/LoadingContext";
import { GraficoAreaDasboard } from "../../components/graficos/dashboard/GraficoArea";
import { GraficoLinhaDasboard } from "../../components/graficos/dashboard/GraficoLinha";
import { GraficoPizzaDasboard } from "../../components/graficos/dashboard/GraficoPizza";

const Dashboard = props => {

    const [dados, setDados] = useState([]);

    const { setIsLoading } = useIsLoading();


    async function get_data_page() {

        try {
            setIsLoading(true);

            const { data } = await CONNECT_API.get('/dashboard/data');
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setDados(data);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }




    /////////////////
    // START
    /////////////////

    //Carregamnento dos dados
    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);


    return (
        <div className="dashboard">

            <div className="cards mt-3">

                <div className="row">
                    {
                        dados?.filiado?.cards?.map((c, i) => {
                            return (
                                <div className="col-lg-4" key={i}>
                                    <div className="card" >
                                        <div className="card-body p-3">
                                            <div className="row">
                                                <div className="col-lg text-muted">
                                                    <h4 className="font-weight-bold">{c?.label}</h4>
                                                    <div className="value">
                                                        {c?.valor}
                                                    </div>
                                                    <div className="descricao">
                                                        <span className="desc">{c?.descricao}</span>
                                                        <span className="d text-success"> {c?.sub_descricao}</span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-auto">
                                                    <span className={`d-flex align-items-center justify-content-center rounded-circle ${c?.icon_class}`} style={{ 'fontSize': '30px', 'height': '60px', 'width': '60px' }}>
                                                        <i className={`las ${c?.icon}`}></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="grafico">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="card">
                            <div className="card-body">
                                <GraficoAreaDasboard title="Vendas por mês" data={dados?.filiado} />
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="card">
                            <div className="card-body">
                                <GraficoLinhaDasboard title="Vendas Colaboradores/Mês" data={dados?.filiado} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                dados?.filiado?.list?.length ?
                    <div className="lista mt-4">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="title-list"><h4>Vendas da semana</h4></div>
                                        <ul className="list-group list-group-flush">
                                            {
                                                dados?.filiado?.list?.map(f => {
                                                    return (
                                                        <li className="px-0 list-group-item" key={f?.id}>
                                                            <div className="item">
                                                                <small>{f?.nome}{f?.website ? ' (Website)' : ''}</small>
                                                                <small className="float-right">{formatMatricula(f?.matricula)}</small>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="card">
                                    <div className="card-body">
                                        <GraficoPizzaDasboard data={dados?.filiado?.list} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''
            }



            <div className=" d-none h-100 w-100 d-flex-- align-items-center justify-content-center img-fluid">
                <div style={{ "fontSize": "60px" }} className="text-muted text-center">
                    DASHBOARD <br />
                    <small className="d-block" style={{ "fontSize": "20px" }}>Em breve</small>
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
