import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ButtonSave from "../../../components/ButtonComponent/ButtonSave";
import { CardContent, CardTitle } from "../../../components/Card-z";
import HeaderComponent from "../../../components/HeaderComponent";
import CONNECT_API from "../../../configs/connect";
import { existsOrError } from "../../../configs/Functions";
import { useIsLoading } from "../../../contexts/LoadingContext";
import { useForm } from 'react-hook-form';
import { GroupInput, GroupSelect } from "../../../components/Form";
import $ from 'jquery';

export const CieloCadastro = props => {

    const [pg, setPg] = useState([]);
    const [cielo, setCielo] = useState([]);
    const [terminais, setTerminais] = useState([]);

    const { setIsLoading } = useIsLoading();
    const { register, handleSubmit, reset } = useForm();


    async function get_data_page() {

        try {
            setIsLoading(true);

            const { data } = await CONNECT_API.get(`/cielo/editar`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setCielo(data?.cielo);
            setTerminais(data?.terminais);
            setPg(data?.pg);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }

    ////////////////
    // COLLAPSE OPERAÇÕES
    ////////////////
    async function onCollapse(e) {
        let sandbox = Number(e.target.value);

        if (sandbox) {
            $('div#collapse-sandbox').collapse('show');
            $('div#collapse-producao').collapse('hide');
        } else {
            $('div#collapse-sandbox').collapse('hide');
            $('div#collapse-producao').collapse('show');
        }

    }

    //////////////////
    // SUBMIT CADASTRO
    //////////////////
    async function onSubmit(data) {

        try {

            const {
                email,
                senha,
                situacao,
                terminal_id,
                sandbox,
                sandbox_cielo_id,
                sandbox_cielo_key,
                api_cielo_id,
                api_cielo_key
            } = data;

            existsOrError(email, "E-mail de acesso não informado!");
            existsOrError(senha, "Senha de acesso não informado!");
            existsOrError(terminal_id, "Terminal financeiro não informado!");

            if (Number(sandbox)) {
                existsOrError(sandbox_cielo_id, "SandBox ID não informado!");
                existsOrError(sandbox_cielo_key, "SandBox KEY não informado!");
            } else {
                existsOrError(api_cielo_id, "API ID não informado!");
                existsOrError(api_cielo_key, "API KEY não informado!");
            }

            const fields = {
                email,
                senha,
                situacao,
                terminal_id,
                sandbox,
                sandbox_cielo_id,
                sandbox_cielo_key,
                api_cielo_id,
                api_cielo_key
            }
            setIsLoading(true);
            await CONNECT_API.put(`${pg?.end_point}`, { ...fields });

            await get_data_page();
            setIsLoading(false);


        } catch (error) {
            setIsLoading(false);
            toast.error(error)
        }
    }




    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!cielo) return false;

        reset({ ...cielo });

        // eslint-disable-next-line
    }, [cielo]);

    return (
        <div className="cielo-link">
            <HeaderComponent {...pg}>
                <ButtonSave className="float-right px-5" onClick={handleSubmit(onSubmit)} />
            </HeaderComponent>
            <form action="" onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                    <CardTitle>
                        Cadastro
                    </CardTitle>
                    <GroupInput name="email" type="email" required col="5" label="E-mail de acesso" register={register} />
                    <GroupInput name="senha" required col="5" label="Senha de acesso" register={register} />
                    <GroupSelect name="situacao" col="2" label="Situação" register={register}>
                        <option value="0">Inativo</option>
                        <option value="1">Ativo</option>
                    </GroupSelect>
                    <GroupSelect name="terminal_id" required col="8" label="Terminal financeiro" register={register}>
                        {terminais.length === 0 && <option disabled={true}>Nenhum terminal disponível</option>}
                        {cielo?.terminal_id && <option value={cielo?.terminal_id} >{cielo?.terminal}</option>}
                        {
                            terminais.map(r => {
                                return <option value={r.id} key={r.id}>{r.nome}</option>
                            })
                        }
                    </GroupSelect>
                    <GroupSelect name="sandbox" col="4" label="Operação em" onChange={onCollapse} register={register}>
                        <option value="">Produção</option>
                        <option value="1">SandBox</option>
                    </GroupSelect>
                    <div className="col-12">
                        <div className={`collapse collapse-sandbox ${cielo?.sandbox ? 'show' : ''}`} id="collapse-sandbox">
                            <div className="row">
                                <GroupInput name="sandbox_cielo_id" col="6" label="SandBox ID" register={register} />
                                <GroupInput name="sandbox_cielo_key" col="6" label="SandBox KEY" register={register} />
                            </div>
                        </div>
                        <div className={`collapse collapse-producao ${cielo?.sandbox ? '' : 'show'}`} id="collapse-producao">
                            <div className="row">
                                <GroupInput name="api_cielo_id" col="6" label="API ID" register={register} />
                                <GroupInput name="api_cielo_key" col="6" label="API KEY" register={register} />
                            </div>
                        </div>
                        <ButtonSave className="float-right mt-3 px-5" type="submit" />
                    </div>
                </CardContent>
            </form>
        </div>
    );
}

export default CieloCadastro;