import React from "react";
import { useForm } from "react-hook-form";
import ButtonSave from "../../../../../components/ButtonComponent/ButtonSave";
import { CardContent, CardTitle } from "../../../../../components/Card-z";
import CONNECT_API from "../../../../../configs/connect";
import { existsOrError, ShowError } from "../../../../../configs/Functions";
import { useIsLoading } from "../../../../../contexts/LoadingContext";
import { Modal } from "../../../../../components/Modal-z";
import { GroupInput, GroupText } from "../../../../../components/Form";


export const ModalAddCron = props => {

    const { handleSubmit, reset, register } = useForm();
    const { setIsLoading } = useIsLoading();

    if (!props.leadId) return null;


    // START LAYOUT MODAL
    async function onSubmit(formData) {

        const { title, start } = formData;


        try {
            existsOrError(title, "Título não informado!");
            existsOrError(start, "Alerta não definido!");

            setIsLoading(true);
            await CONNECT_API.post(`/leads/${props.leadId}/cron`, { ...formData });
            setIsLoading(false);

            if (props?.changedLoad) await props.changedLoad();

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    // Fechamento do modal
    function modalHide() {
        reset({});
    }


    return (
        <div className="ModalAddCron">
            <Modal sm={true} modalId={props?.modalId} modalHide={modalHide} text="Alerta" className="btn btn-danger btn-sm" icon="plus-circle" >
                <form action="" name="cron" onSubmit={handleSubmit(onSubmit)}>
                    <CardContent>
                        <CardTitle>
                            Novo Alerta
                        </CardTitle>
                        <GroupInput name="start" col="12" label="Notificar" type="datetime-local" register={register} />
                        <GroupInput name="title" required col="12" label="Título" register={register} />
                        <GroupText row="4" name="descricao" col="12" label="Descrição" register={register} />
                        <div className="col-12">
                            <ButtonSave type="submit" className="mt-4 float-right" />
                        </div>
                    </CardContent>
                </form>
            </Modal>
        </div>
    )
}


export default ModalAddCron;