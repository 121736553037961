import React from "react";
import { useForm } from "react-hook-form";
import { useIsLoading } from "../../../../contexts/LoadingContext";
import CONNECT_API from "../../../../configs/connect";
import { Modal } from "../../../../components/Modal-z";
import { CardContent, CardTitle } from "../../../../components/Card-z";
import { GroupInput, GroupSelect } from "../../../../components/Form";
import ButtonSave from "../../../../components/ButtonComponent/ButtonSave";
import { ShowError, existsOrError } from "../../../../configs/Functions";



export const ModalAddBloco = props => {

    const { handleSubmit, reset, register } = useForm();
    const { setIsLoading } = useIsLoading();
    const menu = props.menu;
    const plano = props.plano;






    async function onSubmit(formData) {

        try {
            // existsOrError(formData.nome, 'Nome não informado!');
            existsOrError(formData.type, 'Tipo não informado!');
            setIsLoading(true);

            const dados = {}
            dados.nome = formData.nome;
            dados.type = formData.type;
            dados.plano_id = plano;
            dados.menu_id = menu;
            dados.or = formData.or;

            await CONNECT_API.post(`/portal-do-filiado/painel?novo=bloco`, { ...dados });
            if (typeof (props?.get_data_page) == 'function') props.get_data_page();

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }



    return <Modal modalId={`modal-novo-bloco`} text={'Bloco'} icon="plus" className="btn btn-sm btn-outline-success p-0 px-1" sm={true} modalHide={() => reset()}>
        <CardContent>
            <CardTitle>
                {'Novo Bloco de Conteúdos'}
            </CardTitle>
            <div className="col-12">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                        {/* <GroupInput name="nome" col="12" required label="Nome" register={register} /> */}
                        <GroupSelect name="type" col="12" required label="Tipo" register={register}>
                            <option value="Container">Container</option>
                            <option value="Card">Card</option>
                            <option value="Slide">Slide</option>
                        </GroupSelect>
                        <GroupInput name="or" col="12" label="Ordem de Exibição" register={register} />
                    </div>
                    <div className="form-group text-center">
                        <ButtonSave type="submit" text="Criar" icon="link" className="px-5" />
                    </div>
                </form>
            </div>
        </CardContent>
    </Modal>
}

export default ModalAddBloco;