import React from "react";
import "./styles/Content.css";


const Content = props => {
    return (
        <div id="content-body" className="content-body">
            {props.children}
        </div>
    );
}

export default Content;