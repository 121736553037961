import { useForm } from "react-hook-form";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CardContent, CardTitle } from "../../../components/Card-z";
import CardLink from "../../../components/CardLinks";
import { GroupInput } from "../../../components/Form";
import HeaderComponent from "../../../components/HeaderComponent";
import CONNECT_API from "../../../configs/connect";
import { existsOrError } from "../../../configs/Functions";
import { useIsLoading } from "../../../contexts/LoadingContext";
import { Modal } from "../../../components/Modal-z";
import { Redirect } from "react-router-dom";


export const CartaoDashboard = props => {

    const [pg, setPg] = useState([]);
    const [cartoes, setCartoes] = useState([]);
    const [novoCartao, setNovoCartao] = useState(undefined);


    const { setIsLoading } = useIsLoading();
    const { register, handleSubmit, reset } = useForm();

    async function get_data_page() {

        try {
            setIsLoading(true);

            const { data } = await CONNECT_API.get('/cartao');
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setCartoes(data?.cartoes);
            setPg(data.pg);

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }

    async function onSubmit(data) {

        try {

            const {
                nome,
                codigo
            } = data;
            existsOrError(nome, "Nome não informado!");
            existsOrError(codigo, "Código não informado!");

            const fields = {
                nome,
                codigo
            }

            setIsLoading(true);
            const ok = await CONNECT_API.post(pg?.end_point, { ...fields });
            await get_data_page();

            setNovoCartao(ok?.data?.cartao.id);

            if (ok.data) {
                reset({
                    nome: null,
                    codigo: null
                });
            }
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }


    /////////////////
    // START
    /////////////////

    //Carregamnento dos dados
    useEffect(() => {
        get_data_page()
        // eslint-disable-next-line
    }, []);




    const modal =
        <Modal modalId="modal-cartao" icon="plus-square" text="Novo" className={'text-success'}>
            <form action="#" onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                    <CardTitle>
                        Novo cartão
                    </CardTitle>
                    <GroupInput col="12" name="nome" label="Nome" register={register} />
                    <GroupInput col="12" name="codigo" label="Código" register={register} />
                    <div className="col-12">
                        <div className="form-group mb-0">
                            <button className="btn btn-primary w-100 btn-lg">Cadastrar</button>
                        </div>
                    </div>
                </CardContent>
            </form>
        </Modal>


    if (novoCartao) return <Redirect to={`${pg.end_point}/${novoCartao}`} />
    return (
        <div className="cartao-dashboard">
            <HeaderComponent {...pg} parent={modal} />
            <div className="row ">
                <div className="col-lg-8">
                    <div className="row">
                        {
                            cartoes?.map(m => {
                                return (
                                    <div className="col-lg-5 col-md-auto col-12" key={m.id}>
                                        <CardLink {...m} />
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}


export default CartaoDashboard;