import $ from 'jquery';
import { useForm } from "react-hook-form";
import { Redirect } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { existsOrError } from "../../configs/Functions";
import { Modal } from "../../components/Modal-z";
import { useIsLoading } from "../../contexts/LoadingContext";
import { CardContent, CardTitle } from "../../components/Card-z";
import { GroupInput } from "../../components/Form";
import CONNECT_API from '../../configs/connect';
import CardLink from '../../components/CardLinks';
import { toast } from 'react-toastify';
import HeaderComponent from '../../components/HeaderComponent';

const cmp = {
    modalId: 'modal-office',
    baseUrl: '/empresa/cargo',
    title: 'Cargo',
    icon: 'briefcase',
}

export const Office = props => {

    const [cargos, setCargos] = useState([]);
    const [cargosOff, setCargosOff] = useState([]);
    const [pg, setPg] = useState([]);
    const [cargoId, setCargoId] = useState(undefined);


    const { setIsLoading } = useIsLoading();
    const { register, handleSubmit } = useForm();

    async function get_data_page() {

        try {
            setIsLoading(true);

            const { data } = await CONNECT_API.get(cmp.baseUrl);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setCargos(data.cargo);
            setPg(data.pg);

            const cargosOff = data?.cargo.filter(c => !c.situacao);
            setCargosOff(cargosOff);

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }


    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    // Title da página
    useEffect(() => {
        if (!pg) return;

        document.title = `SGA - ${pg.title}`;
        // eslint-disable-next-line 
    }, [pg]);


    async function onSubmit(data) {

        try {
            const { nome, codigo } = data;

            existsOrError(nome, 'Nome não informado!');
            existsOrError(codigo, 'Código não informado!');

            const fields = {
                nome,
                codigo
            }

            // Retorno do ID
            setIsLoading(true);
            const novo_cargo = await CONNECT_API.post(pg.end_point, { ...fields });
            setIsLoading(false);

            
            const cargo = novo_cargo?.data.cargo;
            $(`.modal`).modal('hide');
            setCargoId(cargo?.id);

        } catch (error) {
            toast.error(error);
        }
    }

    const modal =
        <Modal modalId={cmp.modalId} icon="plus-square" text="Novo" className={'text-success'}>
            <form action="#" onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                    <CardTitle>
                        Novo cargo
                    </CardTitle>
                    <GroupInput col="12" name="nome" label="Nome" register={register} />
                    <GroupInput col="12" name="codigo" label="Código" register={register} />
                    <div className="col-12">
                        <div className="form-group mb-0">
                            <button className="btn btn-primary w-100 btn-lg">Cadastrar</button>
                        </div>
                    </div>
                </CardContent>
            </form>
        </Modal>

    const modal_inativos = <Modal modalId={'cargos-inativos'} icon="exclamation-circle" text="Inativos" className={'text-warning'}>
        <CardContent>
            <CardTitle>
                Cargos Inativos
            </CardTitle>
            {
                cargosOff?.map(o => {
                    return (
                        <div className="col-lg-4 col-md-auto col-12" key={o.id}>
                            <CardLink {...o} />
                        </div>
                    )
                })
            }
        </CardContent>
    </Modal>

    const modais = (
        <div className="d-flex justify-content-lg-start justify-content-end">
            {modal}
            {cargosOff[0] && modal_inativos}
        </div>
    );


    if (cargoId) return <Redirect to={`${pg.end_point}/${cargoId}`} />
    return (
        <div className="cargos">
            <HeaderComponent {...pg} parent={modais} />
            <div className="row">
                <div className="col-lg-9">
                    <div className="row">
                        {
                            cargos?.map(o => {
                                if( !o.situacao ) return "";
                                return (
                                    <div className="col-lg-4 col-md-auto col-12" key={o.id}>
                                        <CardLink {...o} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>

        </div>
    );
}


export default Office;