import React, { useState, useEffect } from "react";
import CONNECT_API from "../../../configs/connect";
import { ShowError, TextSlug } from "../../../configs/Functions";
import { useIsLoading } from "../../../contexts/LoadingContext";
import ModalEditMenu from "./ModalEdit";
import $ from "jquery";
import ModalNewMenu from "./ModalNew";

export const PGMenus = (props) => {
    const [menus, setMenus] = useState([]);
    const [menuEdit, setMenuEdit] = useState(undefined);
    const { setIsLoading } = useIsLoading();

    const modalEditId = "modal-edit-menu";

    async function get_data_page() {
        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get("/admin/menus");
            setMenus(data.menus);

            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    //
    // Monta os menus na permissão
    //
    function menu_tree(m) {
        const mp = m?.map((menu) => {
            const ul = (
                <ul key={menu.id}>
                    <li className={menu.sub_menu.length ? "sub_menu" : "menu"}>
                        <button
                            className="btn btn-sm p-0"
                            onClick={() => {
                                setMenuEdit(menu?.id);
                                $(`#${modalEditId}`).modal("show");
                            }}
                        >
                            <small className="mr-1 d-none">#{menu?.id}</small>
                            <span>{menu.label}</span>
                        </button>
                        {menu_tree(menu.sub_menu)}
                    </li>
                </ul>
            );

            return ul;
        });
        return mp;
    }

    //   sempre que a página for atualizada
    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    if (!menus) return <div className="text-center">Sem Menus definido!</div>;

    return (
        <>
            <div className="menus">
                <div className=" lista-de-menus">
                    <div className="card">
                        <div className="card-header">
                            <div className="row justify-content-between">
                                <div className="col-auto">
                                    <h4>Lista de Menus por Módulos</h4>
                                </div>
                                <div className="col-auto">
                                    <ModalNewMenu loaded={get_data_page} />
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            {menus.map((md) => {
                                return (
                                    <div
                                        className={`card modulo modulo-${TextSlug(
                                            md.nome
                                        )}`}
                                        key={md.id}
                                    >
                                        <div
                                            className="card-header cursor-pointer"
                                            data-toggle="collapse"
                                            data-target={`#md-${TextSlug(
                                                md.nome
                                            )}`}
                                            aria-controls={`md-${TextSlug(
                                                md.nome
                                            )}`}
                                        >
                                            <h4 className="mb-0">
                                                <small>
                                                    <b>{md.nome}</b>
                                                </small>
                                            </h4>
                                        </div>
                                        <div
                                            id={`md-${TextSlug(md.nome)}`}
                                            className="collapse"
                                        >
                                            <div className="card-body pl-5">
                                                {menu_tree(md.menu)}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <ModalEditMenu
                modalId={modalEditId}
                menu_id={menuEdit}
                loaded={get_data_page}
            />
        </>
    );
};

export default PGMenus;
