import $ from 'jquery';
import ImputMask from 'inputmask';
import { useForm } from "react-hook-form";
import { Redirect } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import { Modal } from "../../components/Modal-z";
import { useIsLoading } from "../../contexts/LoadingContext";
import { existsOrError, SetNumber } from "../../configs/Functions";
import { CardContent, CardTitle } from '../../components/Card-z';
import { GroupInput } from "../../components/Form";
import CONNECT_API from '../../configs/connect';
import CardLink from '../../components/CardLinks';
import HeaderComponent from '../../components/HeaderComponent';
import { toast } from 'react-toastify';

const cmp = {
    modalId: 'modal-collaborator',
    baseUrl: '/empresa/colaborador',
    title: 'Colaborador',
    icon: 'user-tie'
}

export const Collaborator = props => {

    const [colaboradores, setColaboradores] = useState([]);
    const [colaboradoresOff, setColaboradoresOff] = useState([]);
    const [colaboradorId, setColaboradorId] = useState(undefined);
    const [pg, setPg] = useState([]);

    const { setIsLoading } = useIsLoading();
    const { register, handleSubmit } = useForm();




    async function get_data_page() {

        try {
            setIsLoading(true);

            const { data } = await CONNECT_API.get('/empresa/colaborador');
            existsOrError(data, 'Não foi possivel carregar os dados!');

            setColaboradores(data?.colaboradores);
            setPg(data?.pg);

            const colaboradoresOff = data?.colaboradores.filter(c => !c.situacao);
            setColaboradoresOff(colaboradoresOff);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
        }
    }



    //Carregamnento dos dados
    useEffect(() => {
        get_data_page()
        // eslint-disable-next-line
    }, []);


    // Mask / title
    useEffect(() => {
        //ImputMask({ "mask": '999.999.999-99' }).mask('[name="cpf"]');
        // eslint-disable-next-line
    }, []);


    async function onSubmit(data) {

        try {

            const nome = data?.nome;
            const cpf = SetNumber(data?.cpf);
            existsOrError(nome, "Nome não informado!");
            existsOrError(cpf, "CPF não informado!");

            setIsLoading(true);
            const novo_colaborador = await CONNECT_API.post(pg.end_point, { nome, cpf });
            setIsLoading(false);

            $(`.modal`).modal('hide');
            setColaboradorId(novo_colaborador.data.colaborador.id);


        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }


    const modal = <Modal modalId={cmp.modalId} icon="plus-square" text="Novo" className={`text-success`} modalShow={() => ImputMask({ "mask": '999.999.999-99' }).mask('[name="cpf"]')}>
        <form action="" onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
                <CardTitle>
                    Novo colaborador
                </CardTitle>
                <GroupInput col="12" name="nome" label="Nome" register={register} />
                <GroupInput col="12" name="cpf" label="CPF" register={register} />
                <div className=" col-12">
                    <div className="form-group mb-0">
                        <button className="btn btn-primary w-100 btn-lg">Cadastrar</button>
                    </div>
                </div>
            </CardContent>
        </form>
    </Modal>

    const modal_colaboradores_off = <Modal modalId={'colaboradores-inativos'} icon="exclamation-circle" text="Inativos" className={'text-warning'}>
        <CardContent>
            <CardTitle>
                Colaboradores Inativos
            </CardTitle>
            {
                colaboradoresOff?.map(c => {
                    return (
                        <div className="col-lg-4 col-md-auto col-12" key={c.id}>
                            <CardLink {...c} />
                        </div>
                    )
                })
            }
        </CardContent>
    </Modal>


    const modais = (
        <div className="d-flex justify-content-lg-start justify-content-end">
            {modal}
            {colaboradoresOff[0] && modal_colaboradores_off}
        </div>
    )

    if (colaboradorId) return <Redirect to={`${pg.end_point}/${colaboradorId}`} />
    return (
        <div className="colaboradores">
            <HeaderComponent {...pg} parent={modais} />
            <div className="row">
                <div className="col-lg-8">
                    <div className="row">
                        {
                            colaboradores?.map(e => {
                                if (!e.situacao) return "";
                                return (
                                    <div className="col-lg-4 col-md-auto col-12" key={e.id}>
                                        <CardLink {...e} />
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}



export default Collaborator;
