import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import CONNECT_API from "../../../../configs/connect";
import { existsOrError, formatCPF, formatDate, formatMatricula } from "../../../../configs/Functions";
import { useIsLoading } from "../../../../contexts/LoadingContext";
import HeaderComponent from "../../../../components/HeaderComponent";
import { ShowError } from "../../../../configs/Functions";
import ModalCardFiliado from "./ModalCardFiliado";
import $ from 'jquery';

// props.match.params.{AQUI};

export const FiliadoCard = props => {

    const [cadastros, setCadastros] = useState([]);
    const [cadastrosEdit, setCadastrosEdit] = useState(undefined);
    const [pg, setPg] = useState([]);
    const { setIsLoading } = useIsLoading();

    // PARAMS

    async function get_data_page() {

        try {
            setIsLoading(true);
            const filiado_id = props.match.params.id;
            const { data } = await CONNECT_API.get(`/filiado/${filiado_id}/card`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setCadastros(data?.cadastros);
            setPg(data.pg);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }


    function showModal(id) {
        if (!id) return false;
        setCadastrosEdit(id);
        $(`#modal-filiado-card`).modal('show');
    }


    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);


    return (
        <div className="filiados-cartao-id">
            <HeaderComponent {...pg} />
            <div className="row ">
                <div className="col-lg-10">
                    <div className="row">
                        {
                            cadastros?.map(m => {
                                return (
                                    <div className="col-lg-6 col-md-auto col-12 mb-4" key={m.id}>
                                        <div
                                            className={`border rounded p-3 btn btn-sm m-0 w-100 text-left ${!m?.titular ? "border-primary" : "border-warning"}`}
                                            onClick={() => showModal(m?.id)}
                                        >
                                            <div className="form-row justify-content-between" style={{ "fontSize": "11px" }}>
                                                <div className="col-lg-auto">
                                                    <div className="mb-1">
                                                        Nome <br />
                                                        <b>{m?.nome}</b>
                                                    </div>
                                                    <div className="mb-1">
                                                        Matrícula <br />
                                                        <b>{formatMatricula(m?.matricula)}</b>
                                                    </div>
                                                    <div className="mb-1">
                                                        CPF <br />
                                                        <b>{formatCPF(m?.cpf)}</b>
                                                    </div>
                                                </div>

                                                <div className="col-lg-auto">
                                                    <div className="mb-1">
                                                        Situação <br />
                                                        <b>{m?.situacao}</b>
                                                    </div>
                                                    <div>
                                                        Data de nascimento <br />
                                                        <b>{formatDate(m?.nascimento) || "-"}</b>
                                                    </div>
                                                    <div>
                                                        Data do cadastro <br />
                                                        <b>{formatDate(m?.data_cadastro) || "-"}</b>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
            <ModalCardFiliado loaded={get_data_page} params={cadastrosEdit} modalId="modal-filiado-card" />
        </div>
    );
}

export default withRouter(FiliadoCard);