import React from "react";
import Pagination from "../Pagination";

export const TableHover = props => {

    return (
        <div className="table-responsive">
            <table className={`table table-hover ${props.className ? props.className : ""}`} style={{ "fontSize": "12px" }}>
                {props.children}
            </table>
        </div>
    );
}

export const Thead = props => {

    function clickTr(key) {
        if (props.click) props.click(key);
        return true;
    }

    if (props.td) {

        return (
            <thead>
                <tr>
                    {
                        props?.td?.map((td, i) => {
                            return <td title={`${props.or ? 'Clique para ordernar' : ''}`}  onClick={() => clickTr(td)} className={` ${props.or ? 'or' : ''} ${props.className ? props.className : ""} text-uppercase`} key={i} >{td}</td>
                        })
                    }
                </tr>
            </thead>
        );
    }


    return (
        <thead>
            <tr>
                {
                    props?.th?.map((th, i) => {
                        return <th scope="col" title={`${props.or ? 'Clique para ordernar' : ''}`}  onClick={() => clickTr(th)} className={` ${props.or ? 'or cursor-pointer' : ''} ${props.className ? props.className : ""} text-uppercase`} key={i}>{th}</th>
                    })
                }
            </tr>
        </thead>
    );
}

export const Tbody = props => {


    return (
        <tbody className={`${props.className ? props.className : ""}`} key={props.key}>
            {props.children}
        </tbody>
    );
}

export const Tr = props => {

    return (
        <tr className={`${props.className ? props.className : ""}`} style={{ "verticalAlign": "middle" }}>
            {props.children}
        </tr>
    );
}

export const Td = props => {

    return (
        <td className={`${props.className ? props.className : ""}`} style={{ "verticalAlign": "middle" }}>
            {props.children}
        </td>
    );
}

export const Th = props => {

    return (
        <th className={`${props.className ? props.className : ""}`} style={{ "verticalAlign": "middle" }}>
            {props.children}
        </th>
    );
}

export const TableFooter = props => {

    return (
        <div className="row mt-4 justify-content-between align-items-center">
            <div className="col-auto">
                <small>Total de {props.count} itens encontrado.</small>
            </div>
            <div className="col">
                <Pagination {...props} setPage={props.setPage} page={props.page} />
            </div>
            <div className="col-auto">
                <small>Exibindo {props.limite} itens por página.</small>
            </div>
        </div>
    );
}
