import { GroupCheckBox, GroupInput, GroupSelect } from "../../../components/Form";
import { TableHover, Tbody, Td, Th, Thead } from "../../../components/Table-z";
import ButtonEdit from "../../../components/ButtonComponent/ButtonEdit";
import { CardContent, CardTitle } from "../../../components/Card-z";
import HeaderComponent from "../../../components/HeaderComponent";
import { useIsLoading } from "../../../contexts/LoadingContext";
import { existsOrError } from "../../../configs/Functions";
import { Modal } from "../../../components/Modal-z";
import CONNECT_API from "../../../configs/connect";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import $ from 'jquery';


export const Terminais = props => {

    const [pg, setPg] = useState([]);
    const [terminais, setTerminais] = useState([]);
    const [opcoesAsaas, setOpcoesAsaas] = useState([]);
    const [POST, setPOST] = useState(true);

    const { setIsLoading } = useIsLoading();
    const { register, handleSubmit, reset } = useForm();



    async function get_data_page() {

        try {

            setIsLoading(true);
            const id = props.match.params.id;
            const { data } = await CONNECT_API.get(`/financeira/${id}/terminal`);
            existsOrError(data, 'Não foi possivel carregar os dados!');

            setPg(data?.pg);
            setTerminais(data?.terminais);
            setOpcoesAsaas(data?.tipos_asaas);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }

    async function onSubmit(data) {
        try {
            const {
                nome,
                faturamento,
                pagamento_dia,
                situacao,
                asaas_payment_type_id
            } = data;

            existsOrError(nome, "Nome não informado!");

            const fields = {
                nome,
                faturamento,
                pagamento_dia,
                situacao,
                asaas_payment_type_id
            }

            if (POST) {
                setIsLoading(true);
                await CONNECT_API.post(`${pg.end_point}`, { ...fields });
                onReset();
                setIsLoading(false);
            } else if (data.id) {
                setIsLoading(true);
                await CONNECT_API.put(`${pg.end_point}/${data.id}`, { ...fields });
                onReset();
                setIsLoading(false);
            } else {
                setIsLoading(false);
                toast.error('Verifique a operação!');
            }

            $('.modal').modal('hide');
            await get_data_page();

        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }

    async function onReset() {
        reset({
            id: null,
            nome: null,
            pagamento_dia: null,
            situacao: 1,
            faturamento: 0,
            cadastro: 0
        });

        setPOST(true);
    }

    async function onEdit(id) {
        try {
            existsOrError(id, "Registro não encontrado!");

            setIsLoading(true);

            const { data } = await CONNECT_API.get(`${pg.end_point}/${id}`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            reset({ ...data.terminal });
            setPOST(false);
            setIsLoading(false);
            $('#modal-terminal').modal('show');

        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }




    ///////
    // START
    ///////

    //Carregamnento dos dados
    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);



    const modal = <Modal modalId="modal-terminal" icon="plus-square" text="Novo" className={`text-success`} onClick={onReset}>
        <form action="" onSubmit={handleSubmit(onSubmit)}>
            <CardContent>
                <CardTitle>
                    {POST ? "Novo terminal" : "Editando terminal"}
                </CardTitle>
                <GroupInput name="id" type="hidden" register={register} />
                <GroupInput name="nome" col="8" label="Nome" register={register} />
                <GroupInput name="pagamento_dia" col="2" max="2" label="Dia do pagamento" register={register} />
                <GroupSelect name="situacao" col="2" label="Situação" register={register}>
                    <option value="0">Inativo</option>
                    <option value="1">Ativo</option>
                </GroupSelect>
                {opcoesAsaas.length ? (
                    <GroupSelect name="asaas_payment_type_id" col="12" disabled={!POST ? true : false} label="Tipo de pagamento [ASAAS]" register={register}>
                        {opcoesAsaas?.map(o => <option key={o.id} value={o.id}>{o.name}</option>)}
                    </GroupSelect>
                ) : (<></>)}
                <div className="col-12">
                    <span className="mb-1 d-block"><i>Tornar opção no</i></span>
                    <div className="row">
                        <GroupCheckBox name="faturamento" disabled={opcoesAsaas.length ? true : false} id="faturamento" value="1" col="auto" label="Faturamento" register={register} />
                    </div>
                </div>
                <div className=" col-12">
                    <div className="form-group mb-0">
                        <button className="btn btn-primary w-100 btn-lg">{POST ? "Cadastrar" : "Atualizar"}</button>
                    </div>
                </div>
            </CardContent>
        </form>
    </Modal>


    return (
        <div className="terminais">
            <HeaderComponent {...pg} parent={modal} />
            <CardContent>
                <div className="col-12">
                    <TableHover className="mb-0 text-center">
                        <Thead th={[
                            '#',
                            'Nome',
                            'Opção',
                            'Situação',
                            'Ação',
                        ]} />
                        <Tbody>
                            {
                                terminais?.map(f => {
                                    return (
                                        <tr key={f.id}>
                                            <Th>{f.id}</Th>
                                            <Td>{f.nome}</Td>
                                            <Td>
                                                {f.faturamento ?
                                                    <>
                                                        <span className="badge badge-secondary mr-1 px-2">Faturamento</span>
                                                        <br />
                                                    </>
                                                    : ''
                                                }
                                                {
                                                    f.cadastro ?
                                                        <span className="badge badge-secondary mr-1 px-2">Novo cadastro</span>
                                                        : ''
                                                }
                                            </Td>
                                            <Td>
                                                {f.situacao
                                                    ?
                                                    <span className="badge badge-success mr-1 px-2">Ativo</span>
                                                    :
                                                    <span className="badge badge-danger mr-1 px-2">Inativo</span>
                                                }
                                            </Td>
                                            <Td>
                                                <ButtonEdit onClick={() => onEdit(f.id)} className="btn-sm" />
                                            </Td>
                                        </tr>
                                    );
                                })
                            }
                        </Tbody>
                    </TableHover>
                </div>
            </CardContent>
        </div>
    );
}


export default withRouter(Terminais);