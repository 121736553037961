import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ButtonSave from "../../../components/ButtonComponent/ButtonSave";
import { CardContent, CardTitle } from "../../../components/Card-z";
import { GroupInput, GroupSelect } from "../../../components/Form";
import { Modal } from "../../../components/Modal-z";
import CONNECT_API from "../../../configs/connect";
import { ShowError } from "../../../configs/Functions";
import { useIsLoading } from "../../../contexts/LoadingContext";

const ModalNewMenu = (props) => {
    const [menuTree, setMenuTree] = useState([]);
    const [modulos, setModulos] = useState([]);
    const { setIsLoading } = useIsLoading();
    const { register, handleSubmit } = useForm();

    async function get_data_page() {
        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get("/admin/menu/new");
            setModulos(data.modulos);
            setMenuTree(data.menu_tree);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    // edição do menu
    async function onSubmit(formData) {
        try {
            setIsLoading(true);
            await CONNECT_API.post(`/admin/menu`, {
                ...formData,
            });
            setIsLoading(false);
            if (props.loaded) await props.loaded();
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    return (
        <Modal
            modalId={"modal-menu-new"}
            modalShow={get_data_page}
            text="Novo Menu"
            icon="plus"
            className="btn btn-info"
        >
            <CardContent>
                <CardTitle>Novo Menu</CardTitle>
                <div className="col-12">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                            <GroupSelect
                                name="ativo"
                                col="3"
                                label="Situação"
                                required
                                register={register}
                            >
                                <option value="0">Inativo</option>
                                <option value="1">Ativa</option>
                            </GroupSelect>
                            <GroupInput
                                name="label"
                                col="6"
                                label="Nome"
                                required
                                register={register}
                            />
                            <GroupInput
                                name="icon"
                                col="3"
                                label="Icone"
                                required
                                register={register}
                            />
                            <GroupInput
                                name="url"
                                col="5"
                                label="URL"
                                required
                                register={register}
                            />
                            <GroupInput
                                name="color"
                                type="color"
                                col="4"
                                label="Cor de Fonte"
                                required
                                register={register}
                            />
                            <GroupSelect
                                name="show"
                                col="3"
                                label="Exibição"
                                required
                                register={register}
                            >
                                <option value="0">Hide</option>
                                <option value="1">Show</option>
                            </GroupSelect>
                            <GroupSelect
                                name="modulo_id"
                                col="5"
                                label="Módulo"
                                required
                                register={register}
                            >
                                {modulos?.map((m) => (
                                    <option key={m?.id} value={m?.id}>
                                        {m?.nome}
                                    </option>
                                ))}
                            </GroupSelect>
                            <GroupSelect
                                name="parent_id"
                                col="7"
                                label="Parent ID"
                                register={register}
                            >
                                <option value="">Menu Principal</option>
                                {menuTree?.map((m) => (
                                    <React.Fragment key={m?.id}>
                                        {menu_tree(m?.menu)}
                                    </React.Fragment>
                                ))}
                            </GroupSelect>
                        </div>
                        <ButtonSave type="submit" className="float-right" />
                    </form>
                </div>
            </CardContent>
        </Modal>
    );
};

export default ModalNewMenu;

// MONATANDO A ARVORE DE SUB ITENS
function menu_tree(m) {
    const mp = m?.map((menu) => {
        const op = (
            <React.Fragment key={menu?.id}>
                <option key={menu?.id} value={menu?.id}>
                    MD{menu?.modulo_id} - {menu.labels || menu?.label}
                </option>
                {menu_tree(menu.sub_menu)}
            </React.Fragment>
        );
        return op;
    });
    return mp;
}
