import React, { useEffect, useState } from "react";
import HeaderComponent from "../../../components/HeaderComponent";
import { useIsLoading } from "../../../contexts/LoadingContext";
import CONNECT_API from "../../../configs/connect";
import { ShowError } from "../../../configs/Functions";
import CardLink from "../../../components/CardLinks";
import { Link } from "react-router-dom";


// HOME DA PÁGINA ANÚNCIOS
export const Painel = props => {


    const { setIsLoading } = useIsLoading();

    const [pg, setPg] = useState([]);
    const [planos, setPlanos] = useState([]);

    async function get_data_page() {
        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/portal-do-filiado/painel`);
            setPlanos(data?.planos);
            setPg(data?.pg);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);


    return (
        <>
            <div className="painel">
                <HeaderComponent {...pg} />
                <div className="">
                    <div className="jumbotron">
                        <div className="jumbotron-body">
                            <div className="row justify-content-between align-items-center">
                                <div className="col-lg-auto">
                                    A exibição de conteúdos no aplicativo é personalizada de acordo com o plano ativo no cadastro do usuário. <br />
                                    Aqui, você pode configurar os conteúdos, anúncios e promoções específicos para cada plano.
                                </div>
                                <div className="col-lg-auto">
                                    <Link to={'/portal-do-filiado/painel/default'} className="btn btn-outline-primary">Conteúdo Padrão</Link>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        {
                            planos?.map(m => {
                                return (
                                    <div className="col-lg-4 col-md-6 col-12" key={m.id}>
                                        <CardLink {...m} link={`/portal-do-filiado/painel/${m.id}`} icon="globe" />
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    );
}


export default Painel;