import React from "react";

export const DataCardBefore = props => {


    return <div className="dashboard-data-cards card-before">
        <div className={`card border-left-${props?.type || 'success'} shadow h-100 py-2`}>
            <div className="card-body">
                <div className="row no-gutters align-items-center">
                    <div className="col mr-2">
                        <div
                            className={`font-weight-bold text-${props?.type || 'success'} text-uppercase mb-1`}
                            style={{ "fontSize": ".7rem" }}
                        >
                            <b>{props?.title}</b>
                        </div>
                        <div className="h5 mb-0 font-weight-bold text-gray-800">{props?.value}</div>
                    </div>
                    <div className="col-auto">
                        <i className={`las la-${props?.icon || 'coins'} text-gray-300`} style={{ "fontSize": "2em" }}></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
}


// eslint-disable-next-line
export default { DataCardBefore };