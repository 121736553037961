import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CardContent } from "../../../../components/Card-z";
import { GroupInput, GroupSelect } from "../../../../components/Form";
import CONNECT_API from "../../../../configs/connect";
import { existsOrError, ShowError } from "../../../../configs/Functions";
import { useIsLoading } from "../../../../contexts/LoadingContext";


export const FormQueryRelatorioFiliados = props => {


    const { register, handleSubmit } = useForm();
    const { setIsLoading } = useIsLoading();

    const [situacao, setSituacao] = useState([]);
    const [vendedor, setVendedor] = useState([]);

    // Data de hoje
    let hj = new Date().toLocaleDateString('pt-BR').split('/').reverse().join('-');

    async function get_data_page() {
        try {
            let query = `?data_in=${hj}&data_out=${hj}`;
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/filiado/relatorio/filiados/${query}`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            props.setCadastros(data?.cadastros);
            props.setTotais(data?.totais);
            setSituacao(data?.situacao);
            setVendedor(data?.vendedor);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    async function onSubmit(data) {

        const {
            situacao_id,
            data_in,
            data_out,
            vendedor_id
        } = data;

        var query = `?`;
        if (situacao_id) query += `&situacao_id=${situacao_id}`;
        if (vendedor_id) query += `&vendedor_id=${vendedor_id}`;
        if (data_in) query += `&data_in=${data_in}`;
        if (data_out) query += `&data_out=${data_out}`;


        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/filiado/relatorio/filiados/${query}`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            props.setCadastros(data?.cadastros);
            props.setTotais(data?.totais);
            setSituacao(data?.situacao);
            setVendedor(data?.vendedor);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }


    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    return (
        <div className="search">
            <form action="" name="search-filiado" onSubmit={handleSubmit(onSubmit)}>
                <CardContent>
                    <div className="col-lg-10">
                        <div className="row justify-content-between">
                            <GroupSelect name="situacao_id" col="3" label="Situação" classNameGroup="mb-0" register={register}>
                                {
                                    situacao?.map(r => {
                                        return <option value={r.id} key={r.id}>{r.nome}</option>
                                    })
                                }
                                <option value="">Todos...</option>
                            </GroupSelect>
                            <GroupSelect name="vendedor_id" col="3" label="Vendedor" classNameGroup="mb-0" register={register}>
                                {
                                    vendedor?.map(r => {
                                        return <option value={r.id} key={r.id}>{r.nome}</option>
                                    })
                                }
                                <option value="">Todos...</option>
                            </GroupSelect>
                            <GroupInput name="data_in" type="date" classNameGroup="mb-0" defaultValue={hj} label="Data inicio" col="3" register={register} />
                            <GroupInput name="data_out" type="date" classNameGroup="mb-0" defaultValue={hj} label="Data final" col="3" register={register} />
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <button className="btn btn-primary h-100 w-100" style={{ "fontSize": "30px" }}>
                            <i className="las la-search mr-2"></i>
                            Buscar
                        </button>
                    </div>
                </CardContent>
            </form>
        </div>
    );
}

export default FormQueryRelatorioFiliados;