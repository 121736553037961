import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { CardContent, CardTitle } from "../../components/Card-z";
import HeaderComponent from "../../components/HeaderComponent";
import { Modal } from "../../components/Modal-z";
import CONNECT_API from "../../configs/connect";
import { existsOrError, formatCoin, formatDate } from "../../configs/Functions";
import { useIsLoading } from "../../contexts/LoadingContext";

export const FaturamentoById = props => {

    const [faturamento, setFaturamento] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [logs, setLogs] = useState([]);
    const [pg, setPg] = useState([]);


    const { setIsLoading } = useIsLoading();

    async function get_data_page() {

        try {

            setIsLoading(true);
            const id = props.match.params.id;
            const { data } = await CONNECT_API.get(`/faturamento/${id}`);
            existsOrError(data, 'Não foi possivel carregar os dados!');

            setFaturamento(data?.faturamento);
            setFileList(data?.files);
            setLogs(data?.logs);
            setPg(data?.pg);

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }





    /////////////////
    // START LAYOUT
    /////////////////

    //Carregamnento dos dados
    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);





    /////////////
    // MODAL DE LOGS
    /////////////
    const modal_log =
        <Modal modalId={'log-faturamento'} icon="file-code" text="Ver log" className={'btn btn-secondary btn-sm ml-2'}>
            <CardContent classNameRow="flex-column justify-content-center align-items-center text-center">
                <CardTitle>
                    Lista de logs
                </CardTitle>
                {
                    logs?.map(log => {
                        return (
                            <div className="col-lg-6" key={log?.id}>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="row justify-content-between mb-3">
                                            <div className="col-auto">{log?.colaborador}</div>
                                            <div className="col-auto">{log?.created_at}</div>
                                        </div>
                                        <div className="resumo text-center" >
                                            <i>#{log?.id}</i> <br />
                                            <small dangerouslySetInnerHTML={{ __html: log?.motivo }}></small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })
                }
            </CardContent>
        </Modal>

    ///////////////
    // MODAL DE ARQUIVOS
    ///////////////
    const modal_files =
        <Modal modalId={'files-faturamento'} icon="archive" text="Anexos" className={'btn btn-dark btn-sm'}>
            <CardContent classNameCard="">
                {
                    fileList?.map(file => {
                        return (
                            <div className="col-12" key={file?.id} >
                                <div className="mb-lg-3 text-center bg-light p-lg-3">
                                    <object data={file?.url} type={file?.tipo} width="100%" min-height="500" style={{ "height": "auto", "minHeight": "500px" }}>
                                        <embed src={file?.url} type={file?.tipo} width="100%" height="500" />
                                        <Link to={file?.url} target="_blank" className="btn btn-primary btn-sm mt-2">
                                            Ver anexo em nova guia
                                        </Link>
                                    </object><br />
                                    <small>{file?.nome}</small>
                                </div>
                            </div>
                        )
                    })
                }
            </CardContent>
        </Modal>

    const modal =
        <div className="btn-group">
            {fileList?.length ? modal_files : ''}
            {logs?.length ? modal_log : ''}
        </div>

    return (
        <div className="faturamento">
            <HeaderComponent {...pg} />
            <div className="row justify-content-center pt-4">
                <div className="col-lg-8 ">
                    <CardContent>
                        <div className="col-12">
                            <HeaderComponent  {...faturamento} vf="sm">
                                {modal}
                            </HeaderComponent>
                        </div>
                        <div className="col-12">
                            <div className="table-responsive">
                                {faturamento?.cancelado_faturamento &&
                                    <small className="d-block text-center text-danger">Fatura cancelada em {formatDate(faturamento?.cancelado_faturamento)}</small>
                                }
                                <table className="table table-borderless table-sm">
                                    <tbody>
                                        <tr>
                                            <td>Tipo</td>
                                            <td className="text-right">{faturamento?.tipo_receita ? faturamento?.tipo_receita : "Despesa"}</td>
                                        </tr>
                                        <tr>
                                            <td>Parcela</td>
                                            <td className="text-right">{`${faturamento?.numero_atual_x}/${faturamento?.numero_total_x ? faturamento?.numero_total_x : faturamento?.numero_atual_x}`}</td>
                                        </tr>
                                        <tr>
                                            <td>Valor do lançamento</td>
                                            <td className="text-right">{formatCoin(faturamento?.valor_lancamento)}</td>
                                        </tr>
                                        <tr>
                                            <td>Valor do faturado</td>
                                            <td className="text-right">{formatCoin(faturamento?.valor_faturamento)}</td>
                                        </tr>
                                        <tr>
                                            <td>Banco</td>
                                            <td className="text-right">{faturamento?.financeira_faturamento}</td>
                                        </tr>
                                        <tr>
                                            <td>Terminal financeiro</td>
                                            <td className="text-right">{faturamento?.terminal_faturamento}</td>
                                        </tr>
                                        <tr>
                                            <td>Faturado em</td>
                                            <td className="text-right">{faturamento?.data_faturamento}</td>
                                        </tr>
                                        <tr>
                                            <td>Faturado por</td>
                                            <td className="text-right">{faturamento?.colaborador}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div className="text-right">
                                    <Link to={faturamento?.url_lancamento  || '/'} className="btn btn-sm btn-secondary">
                                        Ver lançamento
                                        <i className="las la-external-link-alt ml-2" style={{ "fontSize": "20px" }}></i>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </CardContent>
                </div>
            </div>
        </div>
    );
}


export default withRouter(FaturamentoById);