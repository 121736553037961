import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ImputMask from 'inputmask';
import { withRouter } from "react-router-dom";
import CONNECT_API from "../../../configs/connect";
import { existsOrError, SetDate, SetNumber } from "../../../configs/Functions";
import { useIsLoading } from "../../../contexts/LoadingContext";
import HeaderComponent from "../../../components/HeaderComponent";
import ButtonSave from "../../../components/ButtonComponent/ButtonSave";
import { CardContent, CardTitle } from "../../../components/Card-z";
import { GroupInput } from "../../../components/Form";
import NavVerticalButton from "../../../components/NavBarVertical/ButtonLink";



export const FornecedorEditarPF = props => {

    const { register, handleSubmit, reset } = useForm();
    const { setIsLoading } = useIsLoading();
    const [fornecedor, setFornecedor] = useState([]);
    const [fornecedorKey, setFornecedorKey] = useState([]);
    const [pg, setPg] = useState([]);

    // PARAMS
    const tipo = "PF";
    const fornecedor_id = props.match.params.id;

    async function get_data_page() {

        try {
            setIsLoading(true);
            const { data } = await CONNECT_API.get(`/fornecedor/${tipo}/${fornecedor_id}/editar`);
            existsOrError(data, 'Não foi possivel carregar os dados!');
            setFornecedor(data.fornecedor);
            setPg(data.pg);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        }
    }

    async function get_data_cep(e) {

        const cep = SetNumber(e.target.value);
        if (!cep) return false;

        if (cep.length === 8) {

            setIsLoading(true);

            const json = await CONNECT_API.getCep(cep);

            reset({
                uf: json?.data.uf,
                cidade: json?.data.localidade,
                bairro: json?.data.bairro,
                logradouro: json?.data.logradouro,
                complemento: json?.data.complemento
            })

            setIsLoading(false);
        }
    }

    async function onSubmit(data_form) {

        try {

            existsOrError(data_form.nome, "Nome não informado!");

            const {
                nome,
                rg_em,
                data,
                cidade,
                logradouro,
                complemento,
                bairro,
                uf,
                instagram,
                linkedin,
                facebook,
                email
            } = data_form;

            const numero = SetNumber(data_form.numero);
            const cep = SetNumber(data_form.cep);
            const rg = SetNumber(data_form.rg);
            const tel = SetNumber(data_form.tel);
            const whats = SetNumber(data_form.whats);

            const fields = {
                nome,
                data,
                rg,
                rg_em,
                cidade,
                logradouro,
                complemento,
                bairro,
                uf,
                instagram,
                linkedin,
                facebook,
                email,
                numero,
                cep,
                tel,
                whats
            }

            setIsLoading(true);
            await CONNECT_API.put(`${pg.end_point}`, { ...fields });
            await get_data_page();
            setIsLoading(false);

        } catch (error) {
            toast.error(error);
        }

    }

    /////
    // START
    /////

    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);

    useEffect(() => {

        setFornecedorKey(Object.keys(fornecedor));

        const set_fornecedor = fornecedor;
        set_fornecedor.data = SetDate(set_fornecedor.data);
        reset({...set_fornecedor});

        // eslint-disable-next-line
    }, [fornecedor]);

    //Masc
    useEffect(() => {
        ImputMask({ "mask": '99.999.999/9999-99' }).mask('[name="cnpj"]');
        ImputMask({ "mask": '(99) 99999-9999' }).mask('[type="tel"]');
        ImputMask({ "mask": '99999-999' }).mask('[name="cep"]');
        ImputMask({ "mask": '999.999.999-99' }).mask('[name*="cpf"]');
        // eslint-disable-next-line
    }, []);


    return (
        <div className="fornecedor-ditar pb-2">
            <HeaderComponent {...pg}>
                <ButtonSave onClick={handleSubmit(onSubmit)} />
            </HeaderComponent>
            <form action="" onSubmit={handleSubmit(onSubmit)}>

                <div className="row align-items-start">
                    <div className="col-lg-3 position-sticky">
                        <div className="nav flex-column nav-pills" role="tablist" aria-orientation="vertical">
                            <NavVerticalButton href="#resumo" label="Resumo" icon="file" active="true" />
                            <NavVerticalButton href="#cadastro" label="Cadastro" icon="file-alt" />
                            <NavVerticalButton href="#endereco" label="Endereço" icon="map-marked-alt" />
                            <NavVerticalButton href="#contato" label="Contato" icon="share-alt-square" />
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade show active" id="resumo" role="tabpanel">
                                <CardContent>
                                    <div className="text-muted">
                                        {
                                            fornecedorKey?.map((f, index) => {
                                                return (
                                                    <div key={index} className="mb-1">
                                                        <b className="mr-1">{f?.toUpperCase()}:</b>
                                                        <span>{fornecedor[f]}</span>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </CardContent>
                            </div>

                            <div className="tab-pane fade" id="cadastro" role="tabpanel">
                                <CardContent>
                                    <CardTitle>
                                        Cadastro
                                    </CardTitle>
                                    <GroupInput col="12" name="nome" label="Nome" id="" register={register} />
                                    <GroupInput col="6" name="cpf" label="CPF" id="" disabled register={register} />
                                    <GroupInput col="6" name="data" type="date" label="Data Nascimento" id="" register={register} />
                                    <GroupInput col="6" name="rg" label="RG" id="" register={register} />
                                    <GroupInput col="6" name="rg_em" label="Emissor" id="" register={register} />
                                </CardContent>
                            </div>

                            <div className="tab-pane fade" id="endereco" role="tabpanel">
                                <CardContent>
                                    <CardTitle>
                                        Endereço
                                    </CardTitle>
                                    <GroupInput col="3" name="cep" label="CEP" id="" onKeyUp={get_data_cep} register={register} />
                                    <GroupInput col="9" name="logradouro" label="Rua" id="" register={register} />
                                    <GroupInput col="4" name="bairro" label="Bairro" id="" register={register} />
                                    <GroupInput col="4" name="cidade" label="Cidade" id="" register={register} />
                                    <GroupInput col="2" name="numero" label="Número" id="" register={register} />
                                    <GroupInput col="2" name="uf" label="UF" id="" register={register} />
                                    <GroupInput col="12" name="complemento" label="Complemento" id="" register={register} />
                                </CardContent>
                            </div>

                            
                            <div className="tab-pane fade" id="contato" role="tabpanel">
                                <CardContent>
                                    <CardTitle>
                                        Contato
                                    </CardTitle>
                                    <GroupInput col="12" name="email" label="E-mail" id="" register={register} />
                                    <GroupInput col="6" name="tel" label="Telefone" type="tel" id="" register={register} />
                                    <GroupInput col="6" name="whats" label="Whatsapp" type="tel" id="" register={register} />
                                    <GroupInput col="12" name="facebook" label="Facebook" id="" register={register} />
                                    <GroupInput col="12" name="instagram" label="Instagram" id="" register={register} />
                                    <GroupInput col="12" name="linkedin" label="Linkedin" id="" register={register} />
                                </CardContent>
                            </div>
                        </div>
                        <ButtonSave type="submit" className="float-right" />
                    </div>
                </div>
            </form>
        </div>
    );
}


export default withRouter(FornecedorEditarPF);