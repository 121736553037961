import React, { useState } from "react";
import { CardContent, CardTitle } from "../../../../components/Card-z";
import { Modal } from "../../../../components/Modal-z";
import { formatCNPJ } from "../../../../configs/Functions";
import $ from "jquery";
import {
    TableHover,
    Tbody,
    Td,
    Thead,
    Tr,
} from "../../../../components/Table-z";
import ModalFaturarLancamento from "../../../md_5_filiado/filiado/lancamento/ModalFaturarLancamento";

export const TotaisFiliados = (props) => {
    const [lancamentoEdit, setLancamentoEdit] = useState(undefined);

    const filiados = props?.filiados;
    const cadastro = props?.cadastro;
    const dados = props?.dados;

    // modal de lançamentos
    const modal = (
        <Modal modalId="modal-lancamentos-b2b">
            <CardContent>
                <CardTitle>Lançamentos dos filiados</CardTitle>
                <div className="col-12">
                    <TableHover>
                        <Thead
                            th={["ID", "Nome", "Valor", "Vencimento", "Ação"]}
                        />
                        <Tbody>
                            {dados?.lancamentos?.map((l) => {
                                return (
                                    <Tr key={l?.id}>
                                        <Td>#{l?.id}</Td>
                                        <Td>{l?.nome}</Td>
                                        <Td>{l?.valor}</Td>
                                        <Td>{l?.vencimento}</Td>
                                        <Td>
                                            <button
                                                className="btn btn-sm m-0 text-success"
                                                title="Faturar"
                                                onClick={() => {
                                                    setLancamentoEdit(l?.id);
                                                    $(
                                                        "#modal-faturamento"
                                                    ).modal("show");
                                                }}
                                            >
                                                <i
                                                    className="las la-hand-holding-usd"
                                                    style={{ fontSize: "20px" }}
                                                ></i>
                                            </button>
                                        </Td>
                                    </Tr>
                                );
                            })}
                        </Tbody>
                    </TableHover>
                </div>
            </CardContent>
        </Modal>
    );

    return (
        <>
            {modal}
            <ModalFaturarLancamento
                params={lancamentoEdit}
                loaded={props.loaded}
                modalId="modal-faturamento"
            />
            <div className="row justify-content-between">
                <div className="col-12 col-lg">
                    <div className="card">
                        <div className="card-body p-3">
                            <div className="row">
                                <div className="col-lg text-muted">
                                    <h4 className="font-weight-bold">
                                        {cadastro?.nome
                                            ?.trim()
                                            ?.split(" ")
                                            ?.shift()}
                                    </h4>
                                    <div
                                        className="descricao"
                                        style={{ lineHeight: "1.3" }}
                                    >
                                        <small className="desc d-block">
                                            {formatCNPJ(cadastro?.cnpj)} <br />
                                            {cadastro?.fin_email} <br />
                                            {`${cadastro?.fin_tel} ${
                                                cadastro?.fin_whats
                                                    ? " / " +
                                                      cadastro?.fin_whats
                                                    : ""
                                            }`}
                                        </small>
                                    </div>
                                </div>
                                <div className="col-lg-auto">
                                    <span
                                        className={`d-flex align-items-center justify-content-center rounded-circle bg-primary text-white`}
                                        style={{
                                            fontSize: "30px",
                                            height: "60px",
                                            width: "60px",
                                        }}
                                    >
                                        <i className={`las la-industry`}></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg">
                    <div className="card">
                        <div className="card-body p-3">
                            <div className="row">
                                <div className="col-lg text-muted">
                                    <h4 className="font-weight-bold">
                                        Filiados
                                    </h4>
                                    <div className="value">
                                        {filiados?.length}
                                    </div>
                                    <div className="descricao">
                                        <span className="desc">
                                            Filiados vinculados a empresa
                                        </span>
                                        <span className="d text-success"></span>
                                    </div>
                                </div>
                                <div className="col-lg-auto">
                                    <span
                                        className={`d-flex align-items-center justify-content-center rounded-circle bg-success text-white`}
                                        style={{
                                            fontSize: "30px",
                                            height: "60px",
                                            width: "60px",
                                        }}
                                    >
                                        <i className={`las la-users`}></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-lg">
                    <div
                        className="card cursor-pointer"
                        onClick={() => {
                            if (dados?.vencimento) {
                                $("#modal-lancamentos-b2b").modal("show");
                            }
                        }}
                        title={`
                            Lançamentos do mês atual 
Clique para ver detalhes`}
                    >
                        <div className="card-body p-3">
                            <div className="row">
                                <div className="col-lg text-muted">
                                    <h4 className="font-weight-bold">
                                        Lançamentos
                                    </h4>
                                    <div className="descricao">
                                        {dados?.vencimento ? (
                                            <span className="desc d-block">
                                                Total: {dados?.total} <br />
                                                Vencimento até:{" "}
                                                {dados?.vencimento}
                                            </span>
                                        ) : (
                                            <i>Sem pendências neste mês!</i>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-auto">
                                    <span
                                        className={`d-flex align-items-center justify-content-center rounded-circle bg-primary text-white`}
                                        style={{
                                            fontSize: "30px",
                                            height: "60px",
                                            width: "60px",
                                        }}
                                    >
                                        <i className={`las la-coins`}></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TotaisFiliados;
