import React, { useState } from "react";
import { useForm } from "react-hook-form";
import ButtonSave from "../../../../../components/ButtonComponent/ButtonSave";
import { CardContent, CardTitle } from "../../../../../components/Card-z";
import CONNECT_API from "../../../../../configs/connect";
import { ShowError } from "../../../../../configs/Functions";
import { useIsLoading } from "../../../../../contexts/LoadingContext";
import { Modal } from "../../../../../components/Modal-z";
import TextEditor from "../../../../../components/TextEditor";


export const ModalAddAnotacao = props => {

    const [text, setText] = useState('')
    const { handleSubmit } = useForm();
    const { setIsLoading } = useIsLoading();

    if (!props.leadId) return null;


    // START LAYOUT MODAL
    async function onSubmit(formData) {

        try {

            setIsLoading(true);
            await CONNECT_API.post(`/leads/${props.leadId}/anotacao`, { text });
            setIsLoading(false);

            if (props?.changedLoad) await props.changedLoad();

        } catch (error) {
            setIsLoading(false);
            ShowError(error);
        }
    }

    // Fechamento do modal
    function modalHide() {
        setText('');
    }


    return (
        <div className="ModalAddAnotacao">
            <Modal modalId={props?.modalId} modalHide={modalHide} text="Anotação" className="btn btn-secondary btn-sm" icon="plus-circle" >
                <CardContent>
                    <CardTitle>
                        Nova Anotação
                    </CardTitle>
                    <div className="col-12">
                        <form action="" name="anotacao" onSubmit={handleSubmit(onSubmit)}>
                            <TextEditor text={text} setText={setText} />
                            <ButtonSave type="submit" className="mt-4 float-right" />
                        </form>
                    </div>
                </CardContent>
            </Modal>
        </div>
    )
}


export default ModalAddAnotacao;