import React, { useEffect, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import { CardContent } from "../../components/Card-z";
import HeaderComponent from "../../components/HeaderComponent";
import { Modal } from "../../components/Modal-z";
import CONNECT_API from "../../configs/connect";
import { existsOrError, formatCoin, formatDate } from "../../configs/Functions";
import { useIsLoading } from "../../contexts/LoadingContext";

export const LancamentoById = props => {

    const [lancamento, setLancamento] = useState([]);
    const [faturamento, setFaturamento] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [pg, setPg] = useState([]);


    const { setIsLoading } = useIsLoading();

    async function get_data_page() {

        try {

            setIsLoading(true);
            const id = props.match.params.id;
            const { data } = await CONNECT_API.get(`/lancamento/${id}`);
            existsOrError(data, 'Não foi possivel carregar os dados!');

            setLancamento(data?.lancamento);
            setFaturamento(data?.faturamento);
            setFileList(data?.files);
            setPg(data?.pg);

            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            toast.error(error);
        }
    }





    /////////////////
    // START LAYOUT
    /////////////////

    //Carregamnento dos dados
    useEffect(() => {
        get_data_page();
        // eslint-disable-next-line
    }, []);






    ///////////////
    // MODAL DE ARQUIVOS
    ///////////////
    const modal_files =
        <Modal modalId={'files-lancamento'} icon="archive" text="Anexos" className={'btn btn-dark btn-sm'}>
            <CardContent classNameCard="">
                {
                    fileList?.map(file => {
                        return (
                            <div className="col-12" key={file?.id} >
                                <div className="mb-lg-3 text-center bg-light p-lg-3">
                                    <object data={file?.url} type={file?.tipo} width="100%" min-height="500" style={{ "height": "auto", "minHeight": "500px" }}>
                                        <embed src={file?.url} type={file?.tipo} width="100%" height="500" />
                                        <Link to={file?.url} target="_blank" className="btn btn-primary btn-sm mt-2">
                                            Ver anexo em nova guia
                                        </Link>
                                    </object><br />
                                    <small>{file?.nome}</small>
                                </div>
                            </div>
                        )
                    })
                }
            </CardContent>
        </Modal>

    return (
        <div className="lancamento">
            <HeaderComponent {...pg} />
            <div className="row justify-content-center pt-4">
                <div className="col-lg-8 ">
                    <CardContent>
                        <div className="col-12">
                            <HeaderComponent  {...lancamento} vf="sm">
                                {fileList?.length ? modal_files : ''}
                            </HeaderComponent>
                        </div>
                        <div className="col-12">
                            <div className="table-responsive">
                                {lancamento?.cancelado &&
                                    <small className="d-block text-center text-danger">Fatura cancelada em {formatDate(lancamento?.cancelado)}</small>
                                }
                                <table className="table table-borderless table-sm">
                                    <tbody>
                                        <tr>
                                            <td>Tipo</td>
                                            <td className="text-right">{lancamento?.tipo_receita ? lancamento?.tipo_receita : "Despesa"}</td>
                                        </tr>
                                        <tr>
                                            <td>Parcela</td>
                                            <td className="text-right">{`${lancamento?.numero_atual_x}/${lancamento?.numero_total_x ? lancamento?.numero_total_x : lancamento?.numero_atual_x}`}</td>
                                        </tr>
                                        <tr>
                                            <td>Valor do lançamento</td>
                                            <td className="text-right">{formatCoin(lancamento?.valor)}</td>
                                        </tr>
                                        <tr>
                                            <td>Valor do faturado</td>
                                            <td className="text-right">{formatCoin(faturamento?.valor)}</td>
                                        </tr>
                                        <tr>
                                            <td>Banco</td>
                                            <td className="text-right">{faturamento?.financeira}</td>
                                        </tr>
                                        <tr>
                                            <td>Terminal financeiro</td>
                                            <td className="text-right">{faturamento?.terminal_financeiro}</td>
                                        </tr>
                                        <tr>
                                            <td>Faturado em</td>
                                            <td className="text-right">{formatDate(faturamento?.data)}</td>
                                        </tr>
                                        <tr>
                                            <td>Faturado por</td>
                                            <td className="text-right">{lancamento?.colaborador}</td>
                                        </tr>
                                        {
                                            lancamento?.obs &&
                                            <tr>
                                                <td>Observações</td>
                                                <td className="text-right">{lancamento?.obs}</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                                {
                                    faturamento?.url &&
                                    <div className="text-right">
                                        <Link to={faturamento?.url || '/'} className="btn btn-sm btn-secondary">
                                            Ver faturamento
                                            <i className="las la-external-link-alt ml-2" style={{ "fontSize": "20px" }}></i>
                                        </Link>
                                    </div>
                                }
                            </div>
                        </div>
                    </CardContent>
                </div>
            </div>
        </div>
    );
}


export default withRouter(LancamentoById);